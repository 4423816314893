import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerComponent } from '../../../../../../../libs/components/src/lib/components/pdf-viewer/pdf-viewer.component';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { OpenPdfViewerParams } from '../../../../../../../libs/components/src/lib/components/pdf-viewer/pdf-viewer.service';
import { ActivatedRoute } from '@angular/router';
import {
  FilingApiService,
  FsxFilingApiService,
  IFilingApiService,
} from '@fsx/fsx-shared';
import { catchError, EMPTY, map, Observable, Subject, takeUntil } from 'rxjs';
import { TransactionInfoService } from '../../../transaction-activity/services/transaction-info.service';

@Component({
  selector: 'fsx-document-viewer',
  standalone: true,
  imports: [CommonModule, PdfViewerComponent, MatDialogModule],
  providers: [
    { provide: FsxFilingApiService, useClass: FilingApiService },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
  /**
   * Observable that will be used to retrieve the PDF document from the API.
   */
  pdfViewerParams!: Observable<OpenPdfViewerParams>;

  // Subject that will be used to unsubscribe from observables.
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly transactionInfoService: TransactionInfoService
  ) {}

  /**
   * Initialize the component and define the info needed to open the pdf viewer.
   */
  ngOnInit(): void {
    // Get Filing Id from url params
    const filingId = this.activatedRoute.snapshot.paramMap.get(
      'filingId'
    ) as string;
    // Get Document Id from url params
    const documentId = this.activatedRoute.snapshot.paramMap.get(
      'documentId'
    ) as string;
    if (filingId !== '' && documentId !== '') {
      this.pdfViewerParams = this.filingApiService
        .getDocumentInfo(filingId, documentId)
        .pipe(
          takeUntil(this.destroy$),
          map((documentInfo) => {
            const documentRendingInfo =
              this.transactionInfoService.getDocumentRenderingInfo(
                documentInfo
              );
            const params: OpenPdfViewerParams = {
              filingId: filingId,
              documentId: documentId,
              renderingName: documentRendingInfo.name,
              fileName: documentInfo.originalFileName,
              documentName: documentInfo.displayName,
              fileSize: documentRendingInfo.size.toString(),
              documentCategory: '-',
              access: '-',
              associatedParty: '-',
              leadingDocument: '-',
              documentIndex: 1,
            };
            return params;
          }),
          catchError((err) => {
            console.error(
              'An error occured while getting document info for email pdf viewer:',
              err
            );
            return EMPTY;
          })
        );
    }
  }

  /**
   * Unsubscribe from observables onDestroy of component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
