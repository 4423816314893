<div class="document-drag-drop-container">
  <input
    [disabled]="disabled"
    type="file"
    class="fileDropRef"
    #fileDropRef3
    [id]="id"
    multiple
    (change)="fileBrowseHandler($event)"
  />

  <div class="no-files-dropzone">
    <div
      class="placeholder"
      fsxUploadDropTarget
      [ngClass]="{
        disabled: disabled,
        mini: small === true
      }"
      (fileDropped)="onFileDropped($event)"
    >
      <div class="placeholder-icon">
        <span class="documents-icon"></span>
      </div>

      <div class="caption-container">
        <div class="caption-l">Drop Documents Here</div>

        <div class="caption-sm" fxLayout="row" fxLayoutAlign="center start">
          <div>or</div>
          <div>
            <label [for]="id" class="caption-sm-highlight"
              >Browse a Document</label
            >
          </div>
        </div>
      </div>

      <div class="file-specs">
        <div class="file-specs-text">
          {{ permittedFileTypesStr | uppercase }} | Max file size
          {{ maxFileSize | fileSize }}
        </div>
      </div>
    </div>
  </div>
</div>
