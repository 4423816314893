<div class="fsx-search-component">
  <label
    >{{ caption }}
    <span class="required" *ngIf="fieldDefinition.required">*</span>
    <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
  </label>

  <div class="search-icon-container" *ngIf="!inputFocused && !searchTerm.value">
    <span class="material-icons"> search </span>
  </div>
  <div class="input-container">
    <input
      #searchInput
      id="search"
      type="text"
      fsxFocusInput
      autocomplete="off"
      [formControl]="searchTerm"
      [placeholder]="hint"
      [matAutocomplete]="auto"
      (focus)="focusInput(true)"
      (blur)="focusInput(false)"
      [title]="searchTerm.value"
    />
    <button
      class="clear-icon-button"
      *ngIf="searchTerm.value"
      (keydown.enter)="clearInput()"
    >
      <span class="material-icons-outlined cancel" (click)="clearInput()">
        cancel
      </span>
    </button>

    <mat-autocomplete
      class="contacts-list"
      [ngClass]="{ 'mru-contacts-list': !searchTerm.value?.length }"
      #auto="matAutocomplete"
    >
      <mat-progress-bar
        *ngIf="showLoading"
        mode="indeterminate"
      ></mat-progress-bar>
      <ng-container *ngIf="filteredOptions | async as options">
        <div *ngIf="!options.length" class="no-items-container">
          <p class="placeholder-text">No Results</p>
        </div>
      </ng-container>

      <mat-option
        class="contact"
        *ngFor="let result of filteredOptions | async"
        (click)="selectSingleOption(result)"
        [value]="result.caption"
      >
        <div class="contact-caption-container">
          <p class="dropdown-option-primary-text">
            {{ result.caption }}
          </p>
        </div>
        <p class="dropdown-option-secondary-text">
          {{ result.name }}
        </p>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
