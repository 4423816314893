<div
  class="fsx-text-component"
  *ngIf="fieldDefinition && formControl && !hidden"
  [ngStyle]="{
    height: height,
    width: width,
    display: fieldDefinition.visible ? 'block' : 'none'
  }"
  [ngClass]="{
    invalid: formControl.invalid && (initialValue || formControl.touched),
    wrap: overflowWrap,
    readonly: fieldDefinition.readOnly
  }"
>
  <ng-container *ngIf="!fieldDefinition.readOnly; else readOnly">
    <label
      >{{ caption }}
      <span class="required" *ngIf="fieldDefinition.required">*</span>
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <input
      autocomplete="never"
      [formControl]="formControl"
      [placeholder]="hint"
      [ngClass]="{
        invalid: formControl.invalid && (initialValue || formControl.touched)
      }"
      (focus)="onFocus()"
      (blur)="removeFocus()"
      (keyup)="onTextChanged($event)"
    />

    <div class="errors-container">
      <p
        class="hint-text"
        *ngIf="inputFocused"
        [ngClass]="{
          invalid: formControl.invalid && formControl.value?.length > 0
        }"
      >
        {{ formControl.value?.length || 0 }}/{{
          fieldDefinition.maxLength || 0
        }}
      </p>
      <p class="error-message">
        {{
          formControl.invalid && (initialValue || formControl.touched)
            ? getErrorMessage()
            : ""
        }}
      </p>
    </div>
  </ng-container>

  <ng-template #readOnly>
    <p class="readonly-text">{{ formControl.value }}</p>
    <label class="readonly-label">{{ caption }}</label>
  </ng-template>
</div>
