import { Component, Input } from '@angular/core';
import { FieldCategory, InstructionDefinition } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-instruction-component',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss'],
})
export class FsxInstructionComponent {
  @Input() caption!: string;
  @Input() width!: string;
  @Input() height: string = '63px';
  @Input() fieldDefinition!: InstructionDefinition | undefined;

  public fieldType = FieldCategory.Instruction;
}
