import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ReferenceResolver } from '../types';

export function charsetValidator(
  resolver: ReferenceResolver,
  characterProfileName?: string | null,
  forceMultiLine?: boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let charset = resolver.getCharacterProfileCharset(characterProfileName);
    if (forceMultiLine) {
      charset += '[\r\n]';
    }
    const regex = new RegExp(
      '[^' + charset.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&') + ']',
      'g'
    );
    if (control.value) {
      const forbiddenCharacters = control.value.match(regex);
      return forbiddenCharacters
        ? {
            forbiddenCharacters: `The following characters are invalid "${forbiddenCharacters.join(
              ''
            )}"`,
          }
        : null;
    }
    return null;
  };
}
