import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxParticipantValidationService,
  IParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  IPartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';

export const FsxValidatePartiesOrchestrationService =
  new InjectionToken<IValidatePartiesOrchestrationService>(
    'FsxValidatePartiesOrchestrationService'
  );

export interface IValidatePartiesOrchestrationService {
  validateParties$: Observable<CaseRequestViewModel>;
  validateParties(): void;
}

@Injectable()
export class ValidatePartiesOrchestrationService
  implements IValidatePartiesOrchestrationService
{
  private validateParties$$ = new Subject<void>();

  // DO NOT DELETE: may need this validation trigger later
  private partiesTabDeactivated$: Observable<void> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.PARTIES &&
          current.name !== FILING_SUB_TABS.PARTIES
        );
      }),
      map(() => {
        return;
      })
    );

  validateParties$: Observable<CaseRequestViewModel> = merge(
    // this.partiesTabDeactivated$, // DO NOT DELETE: may need this validation trigger later
    this.validateParties$$
    // TODO: Add anymore parties validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(([_, combinedFilingData]: [void, CombinedFilingData]) => {
      const { caseRequest, filingProfile, modeSpec } = combinedFilingData;

      // Validate Parties and Representation (includes checks against FilingProfile rules)
      const isPartiesAndRepresentationValid: boolean =
        this.partyValidationService.validateParties(
          caseRequest,
          modeSpec,
          filingProfile
        );

      // Validate Participants (includes checks against FilingProfile rules)
      const isParticipantsValid: boolean =
        this.participantValidationService.validateParticipants(
          caseRequest.participants,
          modeSpec.participant,
          caseRequest,
          filingProfile,
          caseRequest
        );

      // Consolidate 'Parties' validation into a single flag nn the caseRequest object)
      caseRequest.isPartiesValid =
        isPartiesAndRepresentationValid && isParticipantsValid;

      // Trigger a filing-editor instance-wide update of the validated caseRequest object
      this.caseRequestDataService.setCaseRequestData(caseRequest);

      return caseRequest;
    })
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxParticipantValidationService)
    private readonly participantValidationService: IParticipantValidationService,
    @Inject(FsxPartyValidationService)
    private readonly partyValidationService: IPartyValidationService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService
  ) {}

  validateParties(): void {
    this.validateParties$$.next();
  }
}
