import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  ContactType,
  RequestParticipantViewModel,
  TextFieldDefinition,
  getPersonAddress,
  FieldCategory,
  ParticipantSpec,
  FilingProfile,
  formatContactName,
  AddressViewModel,
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  DocumentInfo,
} from '@fsx/fsx-shared';
import {
  FilesUploadedEventParams,
  FormControlWithModel,
  FsxAdditionalFieldsComponent,
  FsxTextComponent,
} from '@fsx/ui-components';

import {
  participantClientNumberFieldDefinition,
  participantPhonesFieldDefinition,
  participantEmailsFieldDefinition,
  participantAddressFieldDefinition,
  participantAliasFieldDefinition,
} from '../parties.field-definitions';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import { ParticipantViewMinMaxValues } from '../parties-grid/parties-grid.component';

interface InfoInterface {
  value: string;
  caption: string;
}

@Component({
  selector: 'fsx-participant-table',
  templateUrl: './participant-table.component.html',
  styleUrls: ['./participant-table.component.scss'],
})
export class ParticipantTableComponent implements OnInit, OnChanges {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() participant!: RequestParticipantViewModel;
  @Input() participantSpec!: ParticipantSpec;
  @Input() showIcons!: boolean;
  @Input() isAssociatedParty!: boolean;
  @Input() resolver!: FsxReferenceResolver;
  @Input() additionalFieldSpec!: AdditionalFieldSpec[] | null | undefined;
  @Input() additionalFieldValues!: AdditionalFieldValue[];
  @Input() fileUploadDocumentInfos!: DocumentInfo[];
  @Input() participantsMap!: Map<string, ParticipantViewMinMaxValues>;
  @Output() clearParticipantEvent = new EventEmitter<void>();
  @Output() editParticipantEvent =
    new EventEmitter<RequestParticipantViewModel>();
  @Output() setAdditionalFieldsEvent = new EventEmitter<
    AdditionalFieldValue[]
  >();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedEventParams>();
  @Output() fileRemovedFromAdditionalFieldEvent =
    new EventEmitter<DocumentInfo>();

  @ViewChildren('addressField') addressFields!: QueryList<FsxTextComponent>;
  @ViewChild('clientNumberField') clientNumberField!: FsxTextComponent;
  @ViewChildren('phoneField') phoneFields!: QueryList<FsxTextComponent>;
  @ViewChildren('emailField') emailFields!: QueryList<FsxTextComponent>;
  @ViewChildren('aliasField') aliasFields!: QueryList<FsxTextComponent>;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxAdditionalFieldsComponent>;

  public participantClientNumberFormControl!: FormControlWithModel<TextFieldDefinition>;
  public participantPhonesFormArray: FormControlWithModel<TextFieldDefinition>[] =
    [];
  public participantEmailsFormArray: FormControlWithModel<TextFieldDefinition>[] =
    [];
  public participantAddressesFormArray: FormControlWithModel<TextFieldDefinition>[] =
    [];
  public contactType = ContactType;
  public fieldType = FieldCategory;
  public participantClientNumberInitialValue!: string;
  public participantAliasInfo: InfoInterface[] = [];
  public participantClientNumberFieldDefinition!: TextFieldDefinition;
  public participantEmailsFieldDefinition!: TextFieldDefinition;
  public participantPhonesFieldDefinition!: TextFieldDefinition;
  public participantAddressFieldDefinition!: TextFieldDefinition;
  public participantAliasFieldDefinition!: TextFieldDefinition;
  public additionalFieldsDefinition!: AdditionalFieldSpec[];
  public filingProfile!: FilingProfile;
  public isReadonly!: boolean;

  constructor() {
    this.participantClientNumberFieldDefinition =
      participantClientNumberFieldDefinition;
    this.participantEmailsFieldDefinition = participantEmailsFieldDefinition;
    this.participantPhonesFieldDefinition = participantPhonesFieldDefinition;
    this.participantAddressFieldDefinition = participantAddressFieldDefinition;
    this.participantAliasFieldDefinition = participantAliasFieldDefinition;
  }

  ngOnInit(): void {
    this.createParticipantAliasFields();
    this.additionalFieldsDefinition =
      this.participantSpec?.additionalFields ?? [];
    this.participantClientNumberInitialValue =
      this.participant.linkedContact?.clientNameText || '';

    if (!!this.participantsMap && !!this.participant) {
      if (this.participantsMap.has(this.participant.name)) {
        this.isReadonly = this.participantsMap.get(this.participant.name)
          ?.isReadOnly as boolean;
      }
    }
  }

  ngOnChanges(): void {
    // if the participant input changes we need to recalculate
    this.createParticipantAliasFields();

    if (this.participant) {
      if (!this.participantSpec) {
        const selectedParty: CasePartyViewModel | undefined =
          this.combinedFilingData?.caseRequest.parties?.find(
            (p) => p.participantName === this.participant.name
          );
        this.participantSpec =
          this.combinedFilingData?.modeSpec.participant.find(
            (pSpec: ParticipantSpec) => {
              return (
                pSpec.participantCategory.name ===
                selectedParty?.participantCategory?.name
              );
            }
          )!;
      }

      this.filingProfile = this.combinedFilingData.filingProfile;
    }

    if (!!this.participantsMap && !!this.participant) {
      if (this.participantsMap.has(this.participant.name)) {
        this.isReadonly = this.participantsMap.get(this.participant.name)
          ?.isReadOnly as boolean;
      }
    }
  }

  onClearParticipantClicked() {
    this.clearParticipantEvent.emit();
  }

  onParticipantCaptionClicked(participant: RequestParticipantViewModel) {
    this.editParticipantEvent.emit(participant);
  }

  onEditParticipantIconClicked(participant: RequestParticipantViewModel) {
    this.editParticipantEvent.emit(participant);
  }

  public setParticipantClientNumberFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>
  ) {
    this.participantClientNumberFormControl = formControl;
  }

  public setParticipantPhoneNumberFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>
  ) {
    this.participantPhonesFormArray = [formControl];
  }

  public setParticipantEmailsFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>
  ) {
    this.participantEmailsFormArray = [formControl];
  }

  public setParticipantAddressesFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>
  ) {
    this.participantAddressesFormArray = [formControl];
  }

  public getAddressCaption(address: AddressViewModel) {
    return getPersonAddress(address, this.participantSpec, this.filingProfile);
  }

  private createParticipantAliasFields(): void {
    this.participantAliasInfo =
      this.participant.aliases?.map((alias) => {
        let value = '';

        if (alias.person) {
          value += formatContactName(alias.person.personalName);
        }

        if (alias.organization) {
          value += alias.organization.caption;
        }

        if (alias.addresses && alias.addresses.length) {
          value +=
            ` | ` +
            getPersonAddress(
              alias.addresses[0],
              this.participantSpec,
              this.filingProfile
            );
        }

        if (alias.emails && alias.emails.length) {
          value += `, ` + alias.emails[0].caption;
        }

        if (alias.phones && alias.phones.length) {
          value += `, ` + alias.phones[0].caption;
        }

        return {
          caption: alias.category.caption,
          value,
        };
      }) ?? [];
  }

  public setAdditionalFieldValues(value: AdditionalFieldValue) {
    this.resolver.updateAdditionalFieldValues(
      this.additionalFieldValues,
      value
    );
    this.setAdditionalFieldsEvent.emit(this.additionalFieldValues);
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedEventParams
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }

  public fileRemovedFromAdditionalFieldEventHandler(
    documentInfo: DocumentInfo
  ) {
    this.fileRemovedFromAdditionalFieldEvent.emit(documentInfo);
  }

  public validate(): void {
    if (this.addressFields) {
      this.addressFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.clientNumberField) {
      this.clientNumberField.validate();
    }
    if (this.phoneFields) {
      this.phoneFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.emailFields) {
      this.emailFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.aliasFields) {
      this.aliasFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.additionalFields) {
      this.additionalFields.toArray().forEach((fld) => fld.validate());
    }
  }
}
