<div id="representation-section" *ngIf="partyGridRow as row">
  <h5 class="section-heading">
    <fsx-row-validation
      [isValid]="row.party.isRepresentationValid"
      [cssClass]="'inline-heading'"
    ></fsx-row-validation>
    Representation
  </h5>

  <div class="representation-grid-items-container" *ngIf="combinedFilingData">
    <fsx-representation-grid-item
      *ngFor="
        let repGridRow of row.representationGridRows;
        let repIndex = index
      "
      [combinedFilingData]="combinedFilingData"
      [partyGridRow]="row"
      [repGridRow]="repGridRow"
      [rowIndex]="repIndex"
      [resolver]="resolver"
      [expandedRowIndex]="expandedRowIndex"
      [participantSpecs]="participantSpecs"
      [attorneySpecs]="attorneySpecs"
      [participantsMap]="participantsMap"
      (removeRepresentationEvent)="removeRepresentationEventHandler($event)"
      (updateRepresentationEvent)="updateRepresentationEventHandler($event)"
      (editRepresentationEventHandler)="editParticipantEventHandler($event)"
      (toggleExpandRowEvent)="toggleExpandRowEventHandler($event)"
    >
    </fsx-representation-grid-item>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="self-represented-row"
    [ngClass]="{ 'is-self-represented': row.party.isSelfRepresented }"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start"
      class="self-rep-search-or-add"
      *ngIf="!row.party.isSelfRepresented"
    >
      <fsx-contacts-search
        #repSearch
        [disabled]="!currentParticipantSpec"
        [searchType]="contactsSearchType.attorneys"
        [displaySearchTypeLabel]="false"
        [disabled]="row.party.isSelfRepresented"
        (selectedContactEvent)="selectedAttorneyEventHandler($event)"
        (selectedContactSummariesEvent)="
          selectedContactSummariesEventHandler($event)
        "
      >
      </fsx-contacts-search>
    </div>

    <div
      *ngIf="
        combinedFilingData?.filing?.mode !== FilingMode.Subsequent;
        else subF
      "
      class="self-rep-checkbox-and-label"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <input
        id="self-rep-checkbox-{{ componentInstanceId }}"
        class="self-rep-checkbox"
        type="checkbox"
        [(ngModel)]="row.party.isSelfRepresented"
        (click)="onSelfRepresentedClicked(row.party)"
      />
      <label
        id="self-rep-text"
        for="self-rep-checkbox-{{ componentInstanceId }}"
        >Self Represented</label
      >
    </div>
    <!-- ToDo: Check if add representation is allowed here (self-represented vs max representation from spec) -->
    <!-- START: Add New Representation Container -->
    <ng-template #subF>
      <ng-container *ngIf="allowNewRepresentation">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="31px"
          class="add-representation"
        >
          <!-- START: Add Representation Button -->
          <button
            #addRepBtn
            fxFlex="42px"
            class="add-btn"
            [disabled]="true"
            [ngClass]="{
              disabled: addRepBtn.disabled
            }"
            (click)="addRepresentationBtnClicked()"
          >
            Add
          </button>
          <!-- START: END Representation Button -->

          <!-- START: Add New Representation Link -->
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            class="add-link"
          >
            <span fxFlex="none" class="material-icons add-icon">
              add_circle_outline
            </span>
            <span
              fxFlex="none"
              class="add-new caption"
              (click)="addNewRepresentationClicked()"
              >Add New</span
            >
          </div>
          <!-- END: Add New Representation Link -->
        </div>
      </ng-container>
    </ng-template>
    <!-- END: Add New Representation Container -->
  </div>
  <!-- ToDo: This is disabled since according to Jacob, we should be ignoring max representation on parties -->
  <!--  -->
  <!--  <div-->
  <!--    *ngIf="-->
  <!--      currentParticipantSpec &&-->
  <!--      currentParticipantSpec.representation &&-->
  <!--      row.representationGridRows.length >-->
  <!--        currentParticipantSpec.representation.maxAllowed-->
  <!--    "-->
  <!--    class="error-message"-->
  <!--  >-->
  <!--    You can only add-->
  <!--    {{ currentParticipantSpec.representation.maxAllowed }}-->
  <!--    {{-->
  <!--      currentParticipantSpec.representation.maxAllowed === 1-->
  <!--        ? "attorney"-->
  <!--        : "attorneys"-->
  <!--    }}-->
  <!--  </div>-->
</div>
