import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  RequestParticipantRepresentationViewModel,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxRemoveRepresentationOrchestrationService =
  new InjectionToken<IRemoveRepresentationOrchestrationService>(
    'FsxRemoveRepresentationOrchestrationService'
  );

export interface IRemoveRepresentationEventParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  representationToRemove: RequestParticipantRepresentationViewModel;
  partyToRemoveFrom: CasePartyViewModel;
}

export interface IRemoveRepresentationOrchestrationService {
  removeRepresentationFromPartyInCaseRequest$: Observable<CaseRequestViewModel>;
  removeParticipant(params: IRemoveRepresentationEventParams): void;
}

@Injectable()
export class RemoveRepresentationOrchestrationService
  implements IRemoveRepresentationOrchestrationService
{
  private removeRepresentation$$ =
    new Subject<IRemoveRepresentationEventParams>();

  removeRepresentationFromPartyInCaseRequest$: Observable<CaseRequestViewModel> =
    this.removeRepresentation$$.pipe(
      switchMap((params: IRemoveRepresentationEventParams) => {
        const caseRequestBackup = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        params.partyToRemoveFrom.representation =
          params.partyToRemoveFrom.representation?.filter((r) => {
            return (
              r.participantName !==
              params.representationToRemove.participantName
            );
          });

        return this.caseRequestBuilderService
          .checkRemoveParticipant(
            params.caseRequest,
            params.representationToRemove.participantName
          )
          .pipe(
            switchMap(() => {
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  params.filingId,
                  params.caseRequest,
                  caseRequestBackup
                )
                .pipe(
                  tap(() => {
                    this.validatePartiesOrchestrationService.validateParties();
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  removeParticipant(params: IRemoveRepresentationEventParams): void {
    this.removeRepresentation$$.next(params);
  }
}
