import {
  CurrencyFieldDefinition,
  NumberFieldDefinition,
} from '@fsx/fsx-shared';
import { FieldDefinition } from '../types';

type RequiredTypes = NumberFieldDefinition & CurrencyFieldDefinition;

export function hasMinValue(
  fieldDefinition: FieldDefinition
): fieldDefinition is RequiredTypes {
  return (fieldDefinition as RequiredTypes).minValue !== undefined;
}
