<div class="filing-checklist">
  <div *ngIf="isVisible$ | async" class="checklist-container">
    <div class="heading-container">
      <div class="heading">Filing Checklist</div>
      <div class="close">
        <button class="icon-button" (click)="close()">
          <span class="material-icons exit">highlight_off</span>
        </button>
      </div>
    </div>

    <div
      class="server-errors"
      *ngIf="validationGroupErrors$ | async as validationGroupErrors"
    >
      <ng-container *ngIf="validationGroupErrors.length > 0">
        <hr />
        <ul
          *ngFor="let validationGroupError of validationGroupErrors"
          class="ul-validation-group"
        >
          <li
            *ngIf="
              validationGroupError.errorCount > 0 ||
              validationGroupError.errorMessages.length > 0
            "
          >
            {{ validationGroupError.group.groupName }}
            <ul class="ul-validation-errors">
              <li *ngIf="validationGroupError.errorCount > 0">
                <span
                  >{{ validationGroupError.errorCount }}
                  <span *ngIf="validationGroupError.errorCount === 1">{{
                    validationGroupError.group.singular
                  }}</span>
                  <span *ngIf="validationGroupError.errorCount > 1">{{
                    validationGroupError.group.plural
                  }}</span>
                  <span>&nbsp;require further attention</span>
                </span>
              </li>
              <li
                *ngFor="
                  let validationError of validationGroupError.errorMessages
                "
              >
                {{ validationError.errorMessage }}
              </li>
            </ul>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
