import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
  CombinedFilingData,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Subject, Observable, switchMap, concatMap } from 'rxjs';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';

export const FsxUpdateDocumentOrchestrationService =
  new InjectionToken<IUpdateDocumentOrchestrationService>(
    'FsxUpdateDocumentOrchestrationService'
  );

export interface UpdateDocumentParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  requestDocument: RequestDocumentViewModel;
  partialRequestDocument: Partial<RequestDocumentViewModel>;
  documentIndex: number;
  combinedFilingData: CombinedFilingData;
}

export interface IUpdateDocumentOrchestrationService {
  updateDocumentStream$: Observable<CaseRequestViewModel>;
  updateDocument(params: UpdateDocumentParams): void;
}

@Injectable()
export class UpdateDocumentOrchestrationService
  implements IUpdateDocumentOrchestrationService
{
  private updateDocumentParams$$ = new Subject<UpdateDocumentParams>();
  private updateDocumentParams$ = this.updateDocumentParams$$.asObservable();

  updateDocumentStream$: Observable<CaseRequestViewModel> =
    this.updateDocumentParams$.pipe(
      concatMap((updateDocumentParams: UpdateDocumentParams) => {
        return this.caseRequestBuilderService
          .updateRequestDocument({
            ...updateDocumentParams,
          })
          .pipe(
            switchMap(() => {
              const caseRequestBackup = {
                ...updateDocumentParams.caseRequest,
              } as CaseRequestViewModel;
              this.validateDocumentsOrchestrationService.validateDocuments();
              return this.caseRequestUpdateService.optimisticPutOrRestore(
                updateDocumentParams.filingId,
                updateDocumentParams.caseRequest,
                caseRequestBackup,
                true
              );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  updateDocument(params: UpdateDocumentParams): void {
    this.updateDocumentParams$$.next(params);
  }
}
