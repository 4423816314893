<div class="file-upload-inline-container">
  <input
    [disabled]="disabled"
    type="file"
    class="fileDropRef"
    #fileDropRef1
    [id]="id"
    (change)="fileBrowseHandler($event)"
  />
  <div
    class="placeholder"
    fsxUploadDropTarget
    [ngClass]="{
      disabled: false
    }"
    (fileDropped)="onFileDropped($event)"
  >
    <div class="caption-sm" fxLayout="row" fxLayoutAlign="start center">
      <span id="drop-here-text">Drop Here</span>
      <span id="or">&nbsp;or&nbsp;</span>
      <label [for]="id" class="browse">Browse a File</label>
    </div>
  </div>
  <p
    *ngIf="displayMultileFileUploadNotPermittedMessage"
    id="multiple-file-upload-not-permitted"
  >
    Multiple File Upload Not Permitted
  </p>
</div>
