import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  PersonalNameViewModel,
  PersonNameSpec,
  IValidatable,
  FilingProfile,
} from '../../../types';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const FsxPersonNameValidationService =
  new InjectionToken<IPersonNameValidationService>(
    'FsxPersonNameValidationService'
  );

export interface IPersonNameValidationService {
  validatePersonName(
    personName: PersonalNameViewModel | null | undefined,
    spec: PersonNameSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class PersonNameValidationService
  implements IPersonNameValidationService
{
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService
  ) {}

  public validatePersonName(
    personName: PersonalNameViewModel | null | undefined,
    spec: PersonNameSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec || !personName) {
      return true;
    }

    personName.isValid = true;

    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.fullName,
        personName.fullName
      )
    ) {
      return false;
    }
    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.givenName,
        personName.givenName
      )
    ) {
      return false;
    }
    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.middleName,
        personName.middleName
      )
    ) {
      return false;
    }
    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.surName,
        personName.surName
      )
    ) {
      return false;
    }
    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.prefix,
        personName.prefix
      )
    ) {
      return false;
    }
    if (
      !this.textFieldValidationService.validateTextField(
        personName,
        filingProfile,
        spec.suffix,
        personName.suffix
      )
    ) {
      return false;
    }

    return true;
  }
}
