<form
  class="fsx-identification-component"
  *ngFor="
    let identificationFormGroup of identificationFormArray.controls;
    let index = index
  "
>
  <ng-container *ngIf="identificationComponentSpec">
    <fsx-profile-single-selection
      #idTypeField
      *ngIf="identificationComponentSpec.category && !showBarId"
      [caption]="'ID Type'"
      [fieldType]="fieldType.Selection"
      [width]="'168px'"
      [resolver]="resolver"
      [fieldDefinition]="identificationComponentSpec.category"
      [selectionType]="
        selectionType.IdentificationCategorySelectionFieldDefinition
      "
      [initialValue]="initialValues[index] ? initialValues[index].category : ''"
      (formControlEmitter)="setControl($event, 'category', index)"
    ></fsx-profile-single-selection>

    <fsx-profile-single-selection
      #idStateField
      [caption]="showBarId ? 'State' : 'ID State'"
      [fieldType]="fieldType.Selection"
      [width]="'100px'"
      [resolver]="resolver"
      [fieldDefinition]="identificationComponentSpec.region"
      [selectionType]="selectionType.StringSelectionFieldDefinition"
      [initialValue]="
        initialValues[index] ? initialValues[index].regionKey : ''
      "
      (formControlEmitter)="setControl($event, 'regionKey', index)"
    ></fsx-profile-single-selection>

    <fsx-text-component
      #idTextField
      *ngIf="identificationComponentSpec.identificationText"
      [caption]="showBarId ? 'Bar Number' : 'ID Text'"
      [width]="'168px'"
      [resolver]="resolver"
      [fieldDefinition]="identificationComponentSpec.identificationText"
      [initialValue]="
        initialValues[index] ? initialValues[index].identificationKey : ''
      "
      (formControlEmitter)="setControl($event, 'identificationKey', index)"
      (textChanged)="textChangedEventHandler($event, index)"
    ></fsx-text-component>

    <button
      class="icon-button"
      [ngClass]="{ disabled: identificationFormArray.disabled }"
      (keydown.enter)="delete(index); preventEvent($event)"
      (click)="delete(index)"
    >
      <div class="form-delete-button"></div>
    </button>
  </ng-container>
</form>

<button
  class="icon-button"
  [ngClass]="{ disabled: identificationFormArray.disabled }"
  (keydown.enter)="addNewForm(); preventEvent($event)"
  (click)="addNewForm()"
  *ngIf="
    identificationFormArray.length <
      identificationFormArray.formArraySpecs.maxAllowed &&
    showAddNewForm &&
    idLength < identificationFormArray.formArraySpecs.maxAllowed &&
      showAddNewForm
  "
>
  <p class="add-new-form">
    <span class="material-icons add-icon"> add_circle_outline </span>Add Another
    ID
  </p>
</button>
