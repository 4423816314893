import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  AdditionalFieldValue,
  AdditionalFieldSpec,
  IValidatable,
  FieldCategory,
  ParticipantFieldDefinition,
  RequestDocumentParticipant,
  CaseRequestViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxAddressValidationService,
  IAddressValidationService,
} from './address-validation.service';
import {
  FsxAliasValidationService,
  IAliasValidationService,
} from './alias-validation.service';
import {
  FsxContactValidationService,
  IContactValidationService,
} from './contact-validation.service';
import {
  FsxCurrencyValidationService,
  ICurrencyValidationService,
} from './currency-validation.service';
import {
  FsxDateTimeValidationService,
  IDateTimeValidationService,
} from './date-time-validation.service';
import {
  FsxDateValidationService,
  IDateValidationService,
} from './date-validation.service';
import {
  FsxDocumentDomainValidationService,
  IDocumentDomainValidationService,
} from './document-domain-validation.service';
import {
  FsxDocumentFilesValidationService,
  IDocumentFilesValidationService,
} from './document-files-validation.service';
import {
  FsxNumberValidationService,
  INumberValidationService,
} from './number-validation.service';
import {
  FsxSearchFieldValidationService,
  ISearchFieldValidationService,
} from './search-field-validation.service';
import {
  FsxSelectionFieldValidationService,
  ISelectionFieldValidationService,
} from './selection-field-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxTimeValidationService,
  ITimeValidationService,
} from './time-validation.service';
import {
  FsxValidationHelperFpService,
  IValidationHelperFpService,
} from './validation-helper-fp.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxAdditionalFieldsValidationService =
  new InjectionToken<IAdditionalFieldsValidationService>(
    'FsxAdditionalFieldsValidationService'
  );

export interface IAdditionalFieldsValidationService {
  validateAdditionalFields(
    additionalFields: AdditionalFieldValue[] | null | undefined,
    specs: AdditionalFieldSpec[] | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class AdditionalFieldsValidationService
  implements IAdditionalFieldsValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxValidationHelperFpService)
    private readonly validationHelperFpService: IValidationHelperFpService,
    @Inject(FsxAddressValidationService)
    private readonly addressValidationService: IAddressValidationService,
    @Inject(FsxAliasValidationService)
    private readonly aliasValidationService: IAliasValidationService,
    @Inject(FsxContactValidationService)
    private readonly contactValidationService: IContactValidationService,
    @Inject(FsxCurrencyValidationService)
    private readonly currencyValidationService: ICurrencyValidationService,
    @Inject(FsxDateValidationService)
    private readonly dateValidationService: IDateValidationService,
    @Inject(FsxDateTimeValidationService)
    private readonly dateTimeValidationService: IDateTimeValidationService,
    @Inject(FsxDocumentDomainValidationService)
    private readonly documentDomainValidationService: IDocumentDomainValidationService,
    @Inject(FsxDocumentFilesValidationService)
    private readonly documentFilesValidationService: IDocumentFilesValidationService,
    @Inject(FsxNumberValidationService)
    private readonly numberValidationService: INumberValidationService,
    @Inject(FsxSearchFieldValidationService)
    private readonly searchFieldValidationService: ISearchFieldValidationService,
    @Inject(FsxSelectionFieldValidationService)
    private readonly selectionFieldValidationService: ISelectionFieldValidationService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxTimeValidationService)
    private readonly timeValidationService: ITimeValidationService
  ) {}

  public validateAdditionalFields(
    additionalFields: AdditionalFieldValue[] | null | undefined,
    specs: AdditionalFieldSpec[] | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!specs) {
      return true;
    }

    if (!additionalFields) {
      additionalFields = [];
    }

    for (let spec of specs) {
      const value = additionalFields.find(
        (f) => f.additionalFieldName === spec.name
      );

      if (!value) {
        // a value object was not found for the spec, which may or may not be ok
        if (this.additionalFieldRequiresValue(spec)) {
          return this.validationHelperService.markItemAsInvalid(scope);
        }
        continue;
      }

      switch (spec.fieldType) {
        case FieldCategory.Address:
          if (
            !this.addressValidationService.validateAddresses(
              value.addressValues,
              spec.addressFieldDefinition,
              scope,
              filingProfile
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Alias:
          if (
            !this.aliasValidationService.validateAliases(
              value.aliasValues,
              caseId,
              spec.aliasFieldDefinition,
              scope,
              filingProfile
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Boolean:
          // nothing to validate on the value directly as all values (true, false, null) are always acceptable
          // but do validate dependent fields if value is true
          if (value.booleanValue === true) {
            this.validateAdditionalFields(
              [value],
              spec.booleanFieldDefinition?.additionalFields,
              caseId,
              scope,
              filingProfile,
              caseRequest
            );
          }
          break;
        case FieldCategory.Contact:
          if (
            !this.contactValidationService.validateContacts(
              value.contactValues,
              spec.contactFieldDefinition,
              scope,
              filingProfile
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Currency:
          if (
            !this.currencyValidationService.validateCurrency(
              value.currencyValue,
              spec.currencyFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Date:
          if (
            !this.dateValidationService.validateDate(
              value.dateValue,
              spec.dateFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.DateTime:
          if (
            !this.dateTimeValidationService.validateDateTime(
              value.dateTimeValue,
              spec.dateTimeFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Document:
          if (
            !this.documentDomainValidationService.validateDocumentDomains(
              value.documentValue,
              spec.documentFieldDefinition,
              caseId,
              scope,
              filingProfile,
              caseRequest
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.File:
          if (
            !this.documentFilesValidationService.validateDocumentFiles(
              value.fileValues,
              spec.fileFieldDefinition,
              scope,
              null
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Number:
          if (
            !this.numberValidationService.validateNumber(
              value.numberValue?.toString(),
              spec.numberFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Participant:
          if (
            !this.validateRequestDocumentParticipant(
              value.participantValues,
              spec.participantFieldDefinition,
              caseId,
              scope,
              filingProfile,
              caseRequest
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Search:
          if (
            !this.searchFieldValidationService.validateSearchField(
              value.searchResultItem,
              spec.searchFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Selection:
          if (
            !this.selectionFieldValidationService.validateSelectionFields(
              value.selectionValue,
              spec.selectionFieldDefinition,
              scope,
              filingProfile
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Text:
          if (
            !this.textFieldValidationService.validateTextField(
              scope,
              filingProfile,
              spec.textFieldDefinition,
              value.textValue
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
        case FieldCategory.Time:
          if (
            !this.timeValidationService.validateTime(
              value.timeValue,
              spec.timeFieldDefinition,
              scope
            )
          ) {
            return this.validationHelperService.markItemAsInvalid(scope);
          }
          break;
      }
    }

    return true;
  }

  private additionalFieldRequiresValue(spec: AdditionalFieldSpec): boolean {
    switch (spec.fieldType) {
      case FieldCategory.Address:
        return (
          (spec.addressFieldDefinition &&
            spec.addressFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Alias:
        return (
          (spec.aliasFieldDefinition &&
            spec.aliasFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Contact:
        return (
          (spec.contactFieldDefinition &&
            spec.contactFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Currency:
        return (
          (spec.currencyFieldDefinition &&
            spec.currencyFieldDefinition?.required) ||
          false
        );
      case FieldCategory.Date:
        return (
          (spec.dateFieldDefinition && spec.dateFieldDefinition?.required) ||
          false
        );
      case FieldCategory.DateTime:
        return (
          (spec.dateTimeFieldDefinition &&
            spec.dateTimeFieldDefinition?.required) ||
          false
        );
      case FieldCategory.Document:
        return (
          (spec.documentFieldDefinition &&
            spec.documentFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.File:
        return (
          (spec.fileFieldDefinition &&
            spec.fileFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Number:
        return (
          (spec.numberFieldDefinition &&
            ((!!spec.numberFieldDefinition?.minValue &&
              spec.numberFieldDefinition.minValue > 0) ||
              (!!spec.numberFieldDefinition?.maxValue &&
                spec.numberFieldDefinition.maxValue < 0))) ||
          false
        );
      case FieldCategory.Participant:
        return (
          (spec.participantFieldDefinition &&
            spec.participantFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Search:
        return (
          (spec.searchFieldDefinition &&
            spec.searchFieldDefinition?.required) ||
          false
        );
      case FieldCategory.Selection:
        return (
          (spec.selectionFieldDefinition &&
            spec.selectionFieldDefinition?.minRequired > 0) ||
          false
        );
      case FieldCategory.Text:
        return (
          (spec.textFieldDefinition && spec.textFieldDefinition?.required) ||
          false
        );
      case FieldCategory.Time:
        return (
          (spec.timeFieldDefinition && spec.timeFieldDefinition?.required) ||
          false
        );
      default:
        return false;
    }
  }

  private validateRequestDocumentParticipant(
    participantValues: RequestDocumentParticipant[] | null | undefined,
    spec: ParticipantFieldDefinition | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!participantValues) {
      participantValues = [];
    }

    if (participantValues.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (participantValues.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const selectedParticipant of participantValues) {
      const caseParties = caseRequest.parties?.filter(
        (p) => p.caseId === caseId
      );
      const matchingCaseParties =
        caseParties?.filter(
          (party) =>
            party.participantName == selectedParticipant.participantName
        ) || [];

      if (matchingCaseParties.length > 1) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      const caseParty = matchingCaseParties[0];

      const representations =
        caseParties?.filter((cp) =>
          cp.representation?.filter(
            (r) => r.participantName == selectedParticipant.participantName
          )
        ) || [];

      if (!caseParty && !representations) {
        // representation not found
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      let participantIsNew =
        !caseParty?.efmKey || !representations.find((r) => r.efmKey);

      if (!spec.allowNewParticipants && participantIsNew) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (!spec.allowExistingParticipants && !participantIsNew) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (spec.allowedParticipantCategoriesList) {
        const categoryNames: string[] = [];

        if (caseParty?.participantCategory?.name) {
          categoryNames.push(caseParty.participantCategory.name);
        }

        const repsWithCategoryNames = representations.filter(
          (r) => !!r?.participantCategory?.name
        );
        if (repsWithCategoryNames && repsWithCategoryNames.length > 0) {
          const repCategoryNames = repsWithCategoryNames
            .map((r) => r.participantCategory?.name || '')
            .filter((r) => !!r);
          categoryNames.push(...repCategoryNames);
        }

        const categoriesForEfmKey = categoryNames.filter((c) =>
          this.validationHelperFpService.getSelectedEfmKey(
            spec.allowedParticipantCategoriesList,
            c,
            filingProfile
          )
        );

        if (!categoriesForEfmKey || categoriesForEfmKey.length === 0) {
          // participant category is not allowed
          return this.validationHelperService.markItemAsInvalid(scope);
        }
      }

      this.validateAdditionalFields(
        selectedParticipant.additionalFieldValues,
        spec.additionalFields,
        caseId,
        scope,
        filingProfile,
        caseRequest
      );
    }

    return true;
  }
}
