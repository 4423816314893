import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxCaseValidationService,
  ICaseValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-validation.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';

export const FsxValidateCaseOrchestrationService =
  new InjectionToken<IValidateCaseOrchestrationService>(
    'FsxValidateCaseOrchestrationService'
  );

export interface IValidateCaseOrchestrationService {
  validateCase$: Observable<CaseRequestViewModel>;
  validateCase(): void;
}

@Injectable()
export class ValidateCaseOrchestrationService
  implements IValidateCaseOrchestrationService
{
  private validateCase$$ = new Subject<void>();

  // DO NOT DELETE: may need this validation trigger later
  private detailsTabDeactivated$: Observable<void> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.DETAILS &&
          current.name !== FILING_SUB_TABS.DETAILS
        );
      }),
      map(() => {
        return;
      })
    );

  validateCase$: Observable<CaseRequestViewModel> = merge(
    // this.detailsTabDeactivated$, // DO NOT DELETE: may need this validation trigger later
    this.validateCase$$
    // TODO: Add anymore case validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(([_, combinedFilingData]: [void, CombinedFilingData]) => {
      const { caseRequest, filingProfile, modeSpec } = combinedFilingData;
      if (caseRequest && caseRequest && caseRequest.cases) {
        this.caseValidationService.validateCase(
          caseRequest.cases[0],
          filingProfile,
          caseRequest,
          modeSpec
        );
        this.caseRequestDataService.setCaseRequestData(caseRequest);
      }
      return caseRequest;
    })
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseValidationService)
    private readonly caseValidationService: ICaseValidationService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService
  ) {}

  validateCase(): void {
    this.validateCase$$.next();
  }
}
