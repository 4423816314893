<div
  class="loading-and-cancellation"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Unknown"
>
  <div
    class="loading-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="loading-text" [ngClass]="{ invalid: isValid === false }">
      No File Uploaded
      <span *ngIf="error" title="{{ error.description }}"
        >({{ error.caption }})</span
      ><sup class="required" *ngIf="isValid === false">*</sup>
    </span>
  </div>
</div>

<div
  class="loading-and-cancellation"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Uploading"
>
  <div
    class="loading-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="loading-text"> Uploading </span>
    <span class="material-icons loading-icon"> timelapse </span>
  </div>
  <div
    class="cancel-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="onCancelUploadClicked($event)"
  >
    <span class="material-icons cancel-icon"> highlight_off </span>
    <span class="cancel-text">Cancel</span>
  </div>
</div>

<div
  class="loading-and-cancellation"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Validating"
>
  <div
    class="loading-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="loading-text"> Validating </span>
    <span class="material-icons loading-icon"> timelapse </span>
  </div>
  <div
    class="cancel-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="onCancelUploadClicked($event)"
  >
    <span class="material-icons cancel-icon"> highlight_off </span>
    <span class="cancel-text">Cancel</span>
  </div>
</div>

<div
  class="loading-and-cancellation"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Converting"
>
  <div
    class="loading-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="loading-text"> Converting </span>
    <span class="material-icons loading-icon"> timelapse </span>
  </div>
  <div
    class="cancel-text-and-icon"
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="onCancelUploadClicked($event)"
  >
    <span class="material-icons cancel-icon"> highlight_off </span>
    <span class="cancel-text">Cancel</span>
  </div>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Failed"
>
  <div class="cancelled" fxLayout="row" fxLayoutAlign="start center">
    <span class="loading-text"> Failed </span>
    <span class="material-icons cancelled-icon"> cancel </span>
  </div>
</div>

<div *ngIf="isCancelled">
  <div class="cancelled" fxLayout="row" fxLayoutAlign="start center">
    <span class="cancelled-text">Canceled</span>
    <span class="material-icons cancelled-icon"> cancel </span>
  </div>
</div>

<div *ngIf="!isCancelled && fileUploadState === fileUploadStateEnum.Done">
  <div class="pdf-conversion" fxLayout="row" fxLayoutAlign="start center">
    <span class="pdf-conversion-text">PDF Conversion</span>
    <span class="material-icons pdf-conversion-icon"> check_circle </span>
  </div>
</div>
