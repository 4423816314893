import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewRoutingModule } from './review-routing.module';
import { ReviewComponent } from './review.component';
import { SharedModule } from '@fsx/fsx-shared';
import { FsxUiComponentsModule } from '@fsx/ui-components';

@NgModule({
  declarations: [ReviewComponent],
  imports: [
    CommonModule,
    ReviewRoutingModule,
    SharedModule,
    FsxUiComponentsModule,
  ],
  exports: [ReviewComponent],
})
export class ReviewModule {}
