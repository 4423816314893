import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilingSubTabItem } from '@fsx/fsx-shared';

export interface FilingSubTabClickedParams {
  filingSubTabItem: FilingSubTabItem;
}

@Component({
  selector: 'fsx-filing-sub-tab-item',
  templateUrl: './filing-sub-tab-item.component.html',
  styleUrls: ['./filing-sub-tab-item.component.scss'],
})
export class FilingSubTabItemComponent {
  @Input() tab!: FilingSubTabItem;
  @Output() filingSubTabClickedEvent =
    new EventEmitter<FilingSubTabClickedParams>();

  onTabClicked() {
    this.filingSubTabClickedEvent.emit({ filingSubTabItem: this.tab });
  }
}
