import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * A config object to pass to the FsxPanelComponent to alter any default
 * styles and attach any behaviour such as when the loading indicator
 * should be displayed.
 */
export interface FsxPanelConfig {
  /**
   * The background colour class to apply to the header section of the panel.
   * As a general rule, the parent is responsible for styling any custom content
   * it passes in to the FsxPanelComponnet. There are cases where we want to
   * override the general panel styling though, such as the header backgreound
   * for the contact form panel.
   */
  headerBackgroundClass?: HeaderBackgroundClass;

  /**
   * An optional css class to apply to the content section of the panel. Passing
   * a contentIndentClass of 'indented' will apply the default intentation which
   * keeps any form inline with the header. Passing a contentIndentClass of
   * 'no-indent' will make the content section appear full-width. We use this
   * for the contacts list for example.
   */
  contentIndentClass?: ContentIndentClass;

  /**
   * An observable stream to attach the loading indicator to. In most cases we
   * will be connecting this to the isOrchestrationInProgress$ stream of the
   * orchestration service that is invoked on adding/saving/updating records
   * when the panel is closed, there may be exceptions though. It's the parent's
   * responsibility to say when this happens, it's the FsxPanelComponeht's to say
   * what happens (e.g. what the loading indicator looks like).
   */
  showLoadingIndicator$?: Observable<boolean>;
}

export type HeaderBackgroundClass = 'pure-white' | 'grey-blue';

export type ContentIndentClass = 'indented' | 'no-indent';

@Component({
  selector: 'fsx-panel',
  templateUrl: './fsx-panel.component.html',
  styleUrls: ['./fsx-panel.component.scss'],
})
export class FsxPanelComponent {
  /**
   * An input binding consisting of the default panel config.
   */
  @Input() config: FsxPanelConfig = {
    // The header background is white by default.
    headerBackgroundClass: 'pure-white',

    // The content section is indented by default.
    contentIndentClass: 'indented',

    // Keep the loading indicator hidden by default.
    showLoadingIndicator$: of(false),
  };

  /**
   * An event to raise to notify the parent component that an
   * attempt was made to close the panel. It's the parent's
   * responsibility to determine how/whether to close the panel.
   */
  @Output() closePanelEvent = new EventEmitter<void>();

  /**
   * An event to raise to notify the parent component that an
   * attempt was made to close the form using the default action.
   */
  @Output() defaultPanelEvent = new EventEmitter<void>();

  /**
   * A DOM event listener, to trigger the close panel event when
   * the user presses the escape key.
   */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closePanelEvent.emit();
  }

  @HostListener('document:keydown.enter', ['$event']) onEnterKeyHandler() {
    this.defaultPanelEvent.emit();
  }

  /**
   * A handler function for the close icon's click event.
   */
  onCloseIconClicked() {
    this.closePanelEvent.emit();
  }

  /**
   * A handler function for the close button's click event.
   */
  onCloseButtonClicked() {
    this.closePanelEvent.emit();
  }
}
