<fsx-panel
  (closePanelEvent)="closePanelEventHandler()"
  (defaultPanelEvent)="defaultPanelEventHandler()"
  [config]="panelConfig"
  fsLayout="column"
>
  <div header>
    <fsx-participant-form-panel-header [config]="headerConfig">
    </fsx-participant-form-panel-header>
  </div>
  <div sub-header *ngIf="data.participant">
    <fsx-contact-link-card
      *ngIf="data.participant.linkedContact"
      [linkedContact]="data.participant.linkedContact"
      (editContactEvent)="editContactEventHandler($event)"
    ></fsx-contact-link-card>
  </div>
  <div content>
    <fsx-participant-form
      [formMode]="data.formMode"
      [participantFormGroup]="participantFormGroup"
      [combinedFilingData]="
        (combinedFilingDataService.combinedFilingData$ | async)!
      "
      [isRepresentation]="data.isRepresentation"
      [participant]="data.participant"
      [participantCategory]="data.participantCategory"
      (isFormValidEvent)="isFormValidEventHandler($event)"
    ></fsx-participant-form>
  </div>
  <div footer>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <div class="footer-left" fxFlex="50">
        <div
          id="save-in-contacts-container"
          *ngIf="data.formMode === participantFormMode.AddParticipant"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <input
            id="save-in-contacts-checkbox"
            type="checkbox"
            class="checkbox"
            [(ngModel)]="saveInContacts"
            (click)="onSaveInContactsCheckboxClicked()"
          />
          Save in Contacts
        </div>
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        <button class="secondary" (click)="onCancelButtonClicked()">
          Cancel
        </button>
        <button
          class="primary"
          [disabled]="canSaveForm === false"
          [ngClass]="{ disabled: canSaveForm === false }"
          (click)="saveButtonClickHandler()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</fsx-panel>
