export const Countries = [
  { name: 'AF', caption: 'Afghanistan', selected: false },
  { name: 'AL', caption: 'Albania', selected: false },
  { name: 'DZ', caption: 'Algeria', selected: false },
  { name: 'AS', caption: 'American Samoa', selected: false },
  { name: 'AD', caption: 'Andorra', selected: false },
  { name: 'AO', caption: 'Angola', selected: false },
  { name: 'AI', caption: 'Anguilla', selected: false },
  { name: 'AQ', caption: 'Antarctica', selected: false },
  { name: 'AG', caption: 'Antigua and Barbuda', selected: false },
  { name: 'AR', caption: 'Argentina', selected: false },
  { name: 'AM', caption: 'Armenia', selected: false },
  { name: 'AW', caption: 'Aruba', selected: false },
  { name: 'AU', caption: 'Australia', selected: false },
  { name: 'AT', caption: 'Austria', selected: false },
  { name: 'AZ', caption: 'Azerbaijan', selected: false },
  { name: 'BS', caption: 'Bahamas', selected: false },
  { name: 'BH', caption: 'Bahrain', selected: false },
  { name: 'BD', caption: 'Bangladesh', selected: false },
  { name: 'BB', caption: 'Barbados', selected: false },
  { name: 'BY', caption: 'Belarus', selected: false },
  { name: 'BE', caption: 'Belgium', selected: false },
  { name: 'BZ', caption: 'Belize', selected: false },
  { name: 'BJ', caption: 'Benin', selected: false },
  { name: 'BM', caption: 'Bermuda', selected: false },
  { name: 'BT', caption: 'Bhutan', selected: false },
  { name: 'BO', caption: 'Bolivia', selected: false },
  { name: 'BA', caption: 'Bosnia and Herzegovina', selected: false },
  { name: 'BW', caption: 'Botswana', selected: false },
  { name: 'BR', caption: 'Brazil', selected: false },
  { name: 'IO', caption: 'British Indian Ocean Territory', selected: false },
  { name: 'VG', caption: 'British Virgin Islands', selected: false },
  { name: 'BN', caption: 'Brunei', selected: false },
  { name: 'BG', caption: 'Bulgaria', selected: false },
  { name: 'BF', caption: 'Burkina Faso', selected: false },
  { name: 'BI', caption: 'Burundi', selected: false },
  { name: 'KH', caption: 'Cambodia', selected: false },
  { name: 'CM', caption: 'Cameroon', selected: false },
  { name: 'CA', caption: 'Canada', selected: false },
  { name: 'CV', caption: 'Cape Verde', selected: false },
  { name: 'KY', caption: 'Cayman Islands', selected: false },
  { name: 'CF', caption: 'Central African Republic', selected: false },
  { name: 'TD', caption: 'Chad', selected: false },
  { name: 'CL', caption: 'Chile', selected: false },
  { name: 'CN', caption: 'China', selected: false },
  { name: 'CX', caption: 'Christmas Island', selected: false },
  { name: 'CC', caption: 'Cocos Islands', selected: false },
  { name: 'CO', caption: 'Colombia', selected: false },
  { name: 'KM', caption: 'Comoros', selected: false },
  { name: 'CK', caption: 'Cook Islands', selected: false },
  { name: 'CR', caption: 'Costa Rica', selected: false },
  { name: 'HR', caption: 'Croatia', selected: false },
  { name: 'CU', caption: 'Cuba', selected: false },
  { name: 'CW', caption: 'Curacao', selected: false },
  { name: 'CY', caption: 'Cyprus', selected: false },
  { name: 'CZ', caption: 'Czech Republic', selected: false },
  { name: 'CD', caption: 'Democratic Republic of the Congo', selected: false },
  { name: 'DK', caption: 'Denmark', selected: false },
  { name: 'DJ', caption: 'Djibouti', selected: false },
  { name: 'DM', caption: 'Dominica', selected: false },
  { name: 'DO', caption: 'Dominican Republic', selected: false },
  { name: 'TL', caption: 'East Timor', selected: false },
  { name: 'EC', caption: 'Ecuador', selected: false },
  { name: 'EG', caption: 'Egypt', selected: false },
  { name: 'SV', caption: 'El Salvador', selected: false },
  { name: 'GQ', caption: 'Equatorial Guinea', selected: false },
  { name: 'ER', caption: 'Eritrea', selected: false },
  { name: 'EE', caption: 'Estonia', selected: false },
  { name: 'ET', caption: 'Ethiopia', selected: false },
  { name: 'FK', caption: 'Falkland Islands', selected: false },
  { name: 'FO', caption: 'Faroe Islands', selected: false },
  { name: 'FJ', caption: 'Fiji', selected: false },
  { name: 'FI', caption: 'Finland', selected: false },
  { name: 'FR', caption: 'France', selected: false },
  { name: 'PF', caption: 'French Polynesia', selected: false },
  { name: 'GA', caption: 'Gabon', selected: false },
  { name: 'GM', caption: 'Gambia', selected: false },
  { name: 'GE', caption: 'Georgia', selected: false },
  { name: 'DE', caption: 'Germany', selected: false },
  { name: 'GH', caption: 'Ghana', selected: false },
  { name: 'GI', caption: 'Gibraltar', selected: false },
  { name: 'GR', caption: 'Greece', selected: false },
  { name: 'GL', caption: 'Greenland', selected: false },
  { name: 'GD', caption: 'Grenada', selected: false },
  { name: 'GU', caption: 'Guam', selected: false },
  { name: 'GT', caption: 'Guatemala', selected: false },
  { name: 'GG', caption: 'Guernsey', selected: false },
  { name: 'GN', caption: 'Guinea', selected: false },
  { name: 'GW', caption: 'Guinea-Bissau', selected: false },
  { name: 'GY', caption: 'Guyana', selected: false },
  { name: 'HT', caption: 'Haiti', selected: false },
  { name: 'HN', caption: 'Honduras', selected: false },
  { name: 'HK', caption: 'Hong Kong', selected: false },
  { name: 'HU', caption: 'Hungary', selected: false },
  { name: 'IS', caption: 'Iceland', selected: false },
  { name: 'IN', caption: 'India', selected: false },
  { name: 'ID', caption: 'Indonesia', selected: false },
  { name: 'IR', caption: 'Iran', selected: false },
  { name: 'IQ', caption: 'Iraq', selected: false },
  { name: 'IE', caption: 'Ireland', selected: false },
  { name: 'IM', caption: 'Isle of Man', selected: false },
  { name: 'IL', caption: 'Israel', selected: false },
  { name: 'IT', caption: 'Italy', selected: false },
  { name: 'CI', caption: 'Ivory Coast', selected: false },
  { name: 'JM', caption: 'Jamaica', selected: false },
  { name: 'JP', caption: 'Japan', selected: false },
  { name: 'JE', caption: 'Jersey', selected: false },
  { name: 'JO', caption: 'Jordan', selected: false },
  { name: 'KZ', caption: 'Kazakhstan', selected: false },
  { name: 'KE', caption: 'Kenya', selected: false },
  { name: 'KI', caption: 'Kiribati', selected: false },
  { name: 'XK', caption: 'Kosovo', selected: false },
  { name: 'KW', caption: 'Kuwait', selected: false },
  { name: 'KG', caption: 'Kyrgyzstan', selected: false },
  { name: 'LA', caption: 'Laos', selected: false },
  { name: 'LV', caption: 'Latvia', selected: false },
  { name: 'LB', caption: 'Lebanon', selected: false },
  { name: 'LS', caption: 'Lesotho', selected: false },
  { name: 'LR', caption: 'Liberia', selected: false },
  { name: 'LY', caption: 'Libya', selected: false },
  { name: 'LI', caption: 'Liechtenstein', selected: false },
  { name: 'LT', caption: 'Lithuania', selected: false },
  { name: 'LU', caption: 'Luxembourg', selected: false },
  { name: 'MO', caption: 'Macau', selected: false },
  { name: 'MK', caption: 'Macedonia', selected: false },
  { name: 'MG', caption: 'Madagascar', selected: false },
  { name: 'MW', caption: 'Malawi', selected: false },
  { name: 'MY', caption: 'Malaysia', selected: false },
  { name: 'MV', caption: 'Maldives', selected: false },
  { name: 'ML', caption: 'Mali', selected: false },
  { name: 'MT', caption: 'Malta', selected: false },
  { name: 'MH', caption: 'Marshall Islands', selected: false },
  { name: 'MR', caption: 'Mauritania', selected: false },
  { name: 'MU', caption: 'Mauritius', selected: false },
  { name: 'YT', caption: 'Mayotte', selected: false },
  { name: 'MX', caption: 'Mexico', selected: false },
  { name: 'FM', caption: 'Micronesia', selected: false },
  { name: 'MD', caption: 'Moldova', selected: false },
  { name: 'MC', caption: 'Monaco', selected: false },
  { name: 'MN', caption: 'Mongolia', selected: false },
  { name: 'ME', caption: 'Montenegro', selected: false },
  { name: 'MS', caption: 'Montserrat', selected: false },
  { name: 'MA', caption: 'Morocco', selected: false },
  { name: 'MZ', caption: 'Mozambique', selected: false },
  { name: 'MM', caption: 'Myanmar', selected: false },
  { name: 'NA', caption: 'Namibia', selected: false },
  { name: 'NR', caption: 'Nauru', selected: false },
  { name: 'NP', caption: 'Nepal', selected: false },
  { name: 'NL', caption: 'Netherlands', selected: false },
  { name: 'AN', caption: 'Netherlands Antilles', selected: false },
  { name: 'NC', caption: 'New Caledonia', selected: false },
  { name: 'NZ', caption: 'New Zealand', selected: false },
  { name: 'NI', caption: 'Nicaragua', selected: false },
  { name: 'NE', caption: 'Niger', selected: false },
  { name: 'NG', caption: 'Nigeria', selected: false },
  { name: 'NU', caption: 'Niue', selected: false },
  { name: 'KP', caption: 'North Korea', selected: false },
  { name: 'MP', caption: 'Northern Mariana Islands', selected: false },
  { name: 'NO', caption: 'Norway', selected: false },
  { name: 'OM', caption: 'Oman', selected: false },
  { name: 'PK', caption: 'Pakistan', selected: false },
  { name: 'PW', caption: 'Palau', selected: false },
  { name: 'PS', caption: 'Palestine', selected: false },
  { name: 'PA', caption: 'Panama', selected: false },
  { name: 'PG', caption: 'Papua New Guinea', selected: false },
  { name: 'PY', caption: 'Paraguay', selected: false },
  { name: 'PE', caption: 'Peru', selected: false },
  { name: 'PH', caption: 'Philippines', selected: false },
  { name: 'PN', caption: 'Pitcairn', selected: false },
  { name: 'PL', caption: 'Poland', selected: false },
  { name: 'PT', caption: 'Portugal', selected: false },
  { name: 'PR', caption: 'Puerto Rico', selected: false },
  { name: 'QA', caption: 'Qatar', selected: false },
  { name: 'CG', caption: 'Republic of the Congo', selected: false },
  { name: 'RE', caption: 'Reunion', selected: false },
  { name: 'RO', caption: 'Romania', selected: false },
  { name: 'RU', caption: 'Russia', selected: false },
  { name: 'RW', caption: 'Rwanda', selected: false },
  { name: 'BL', caption: 'Saint Barthelemy', selected: false },
  { name: 'SH', caption: 'Saint Helena', selected: false },
  { name: 'KN', caption: 'Saint Kitts and Nevis', selected: false },
  { name: 'LC', caption: 'Saint Lucia', selected: false },
  { name: 'MF', caption: 'Saint Martin', selected: false },
  { name: 'PM', caption: 'Saint Pierre and Miquelon', selected: false },
  { name: 'VC', caption: 'Saint Vincent and the Grenadines', selected: false },
  { name: 'WS', caption: 'Samoa', selected: false },
  { name: 'SM', caption: 'San Marino', selected: false },
  { name: 'ST', caption: 'Sao Tome and Principe', selected: false },
  { name: 'SA', caption: 'Saudi Arabia', selected: false },
  { name: 'SN', caption: 'Senegal', selected: false },
  { name: 'RS', caption: 'Serbia', selected: false },
  { name: 'SC', caption: 'Seychelles', selected: false },
  { name: 'SL', caption: 'Sierra Leone', selected: false },
  { name: 'SG', caption: 'Singapore', selected: false },
  { name: 'SX', caption: 'Sint Maarten', selected: false },
  { name: 'SK', caption: 'Slovakia', selected: false },
  { name: 'SI', caption: 'Slovenia', selected: false },
  { name: 'SB', caption: 'Solomon Islands', selected: false },
  { name: 'SO', caption: 'Somalia', selected: false },
  { name: 'ZA', caption: 'South Africa', selected: false },
  { name: 'KR', caption: 'South Korea', selected: false },
  { name: 'SS', caption: 'South Sudan', selected: false },
  { name: 'ES', caption: 'Spain', selected: false },
  { name: 'LK', caption: 'Sri Lanka', selected: false },
  { name: 'SD', caption: 'Sudan', selected: false },
  { name: 'SR', caption: 'Suriname', selected: false },
  { name: 'SJ', caption: 'Svalbard and Jan Mayen', selected: false },
  { name: 'SZ', caption: 'Swaziland', selected: false },
  { name: 'SE', caption: 'Sweden', selected: false },
  { name: 'CH', caption: 'Switzerland', selected: false },
  { name: 'SY', caption: 'Syria', selected: false },
  { name: 'TW', caption: 'Taiwan', selected: false },
  { name: 'TJ', caption: 'Tajikistan', selected: false },
  { name: 'TZ', caption: 'Tanzania', selected: false },
  { name: 'TH', caption: 'Thailand', selected: false },
  { name: 'TG', caption: 'Togo', selected: false },
  { name: 'TK', caption: 'Tokelau', selected: false },
  { name: 'TO', caption: 'Tonga', selected: false },
  { name: 'TT', caption: 'Trinidad and Tobago', selected: false },
  { name: 'TN', caption: 'Tunisia', selected: false },
  { name: 'TR', caption: 'Turkey', selected: false },
  { name: 'TM', caption: 'Turkmenistan', selected: false },
  { name: 'TC', caption: 'Turks and Caicos Islands', selected: false },
  { name: 'TV', caption: 'Tuvalu', selected: false },
  { name: 'VI', caption: 'U.S. Virgin Islands', selected: false },
  { name: 'UG', caption: 'Uganda', selected: false },
  { name: 'UA', caption: 'Ukraine', selected: false },
  { name: 'AE', caption: 'United Arab Emirates', selected: false },
  { name: 'GB', caption: 'United Kingdom', selected: false },
  { name: 'US', caption: 'United States', selected: false },
  { name: 'UY', caption: 'Uruguay', selected: false },
  { name: 'UZ', caption: 'Uzbekistan', selected: false },
  { name: 'VU', caption: 'Vanuatu', selected: false },
  { name: 'VA', caption: 'Vatican', selected: false },
  { name: 'VE', caption: 'Venezuela', selected: false },
  { name: 'VN', caption: 'Vietnam', selected: false },
  { name: 'WF', caption: 'Wallis and Futuna', selected: false },
  { name: 'EH', caption: 'Western Sahara', selected: false },
  { name: 'YE', caption: 'Yemen', selected: false },
  { name: 'ZM', caption: 'Zambia', selected: false },
  { name: 'ZW', caption: 'Zimbabwe', selected: false },
];
