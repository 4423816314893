import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minValueValidator(
  value: number | null,
  allowDecimals: number | boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const inputValue = String(control.value).replace(/,/g, '');

    if (value !== (undefined || null) && +inputValue >= value) {
      return null;
    }

    return {
      invalidMinValue: `Value must be greater or equal to ${Number(
        value
      ).toLocaleString()}${allowDecimals ? '.00' : ''}`,
    };
  };
}
