<!-- START: PDF Viewer Container -->
<div
  class="pdf-viewer-container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxFill
>
  <!-- START: Header bar -->
  <div fxFlex="none" fxLayout="row" class="header" fxLayoutAlign="start center">
    <!-- START: Title, Page/Page# and Page Nav Btns  -->
    <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="none" class="title">Preview Document</div>
      <ng-container *ngIf="!isLoading; else loading">
        <div fxFlex="none">{{ currentPage }} of {{ pagesCount }}</div>
        <button
          fxFlex="none"
          class="back-btn"
          matTooltip="Previous Page"
          [ngClass]="{ disabled: currentPage === 1 }"
          (click)="prevPage()"
          [matTooltipShowDelay]="100"
          [matTooltipPosition]="'left'"
          [disabled]="currentPage === 1"
        >
          <span class="nav-btn material-icons">chevron_left</span>
        </button>
        <button
          fxFlex="none"
          class="next-btn"
          matTooltip="Next Page"
          [matTooltipShowDelay]="100"
          [matTooltipPosition]="'right'"
          [ngClass]="{ disabled: currentPage === pagesCount }"
          (click)="nextPage()"
          [disabled]="currentPage === pagesCount"
        >
          <span class="nav-btn material-icons">chevron_right</span>
        </button>
      </ng-container>
      <ng-template #loading> Loading... </ng-template>
    </div>
    <!-- END: Title, Page/Page# and Page Nav Btns  -->

    <!-- START: Close Btn  -->
    <div fxFlex="none" *ngIf="allowClose">
      <button
        class="close-btn"
        (click)="onCloseButtonClicked()"
        matTooltip="Close"
        matTooltipPosition="left"
        [matTooltipShowDelay]="100"
      ></button>
    </div>
    <!-- END: Close Btn  -->
  </div>
  <!-- END: Header bar -->

  <!-- START: PDF Viewer -->
  <div
    fxFlexFill
    fxLayout="row"
    *ngIf="pdfSrc$ | async as blob"
    class="pdf-viewer-contents-container"
  >
    <!-- START: ngx-extended-pdf-viewer container -->
    <div fxFlex="auto" class="ngx-extended-pdf-viewer-container">
      <ngx-extended-pdf-viewer
        [src]="blob"
        [zoom]="zoom"
        [filenameForDownload]="fileNameForDownload"
        [showBorders]="showBorders"
        [textLayer]="true"
        [showToolbar]="showToolbar"
        [showSidebarButton]="showSidebarButton"
        [showFindButton]="showFindButton"
        [showPagingButtons]="showPagingButtons"
        [showDrawEditor]="showDrawEditor"
        [showTextEditor]="showTextEditor"
        [showZoomButtons]="showZoomButtons"
        [showPresentationModeButton]="showPresentationModeButton"
        [showOpenFileButton]="showOpenFileButton"
        [showPrintButton]="showPrintButton"
        [showDownloadButton]="showDownloadButton"
        [showSecondaryToolbarButton]="showSecondaryToolbarButton"
        [showRotateButton]="showRotateButton"
        [showHandToolButton]="showHandToolButton"
        [showScrollingButton]="showScrollingButton"
        [showSpreadButton]="showSpreadButton"
        [showPropertiesButton]="showPropertiesButton"
        (pagesLoaded)="setPageAndPagesCount($event)"
        [(page)]="currentPage"
        [useBrowserLocale]="true"
      ></ngx-extended-pdf-viewer>
    </div>
    <!-- END: ngx-extended-pdf-viewer container -->

    <!-- START: PDF Info Container -->
    <div>
      <!-- START: Toggle Panel Button -->
      <button
        *ngIf="allowSidePanel"
        class="side-panel-toggle-btn"
        [matTooltip]="!hideSidePanel ? 'Hide Side Panel' : 'Show Side Panel'"
        [matTooltipShowDelay]="100"
        matTooltipPosition="left"
        (click)="hideSidePanel = !hideSidePanel"
      >
        <span class="material-icons">{{
          !hideSidePanel ? "chevron_right" : "chevron_left"
        }}</span>
      </button>
      <!-- END: Toggle Panel Button -->

      <!-- START: Document info content -->
      <div
        class="pdf-info-container"
        fxFlex="500px"
        fxLayout="column"
        [ngClass]="{ hidden: hideSidePanel }"
      >
        <!-- START: Filename -->
        <div fxFlex="none" class="document-name">
          {{ data.fileName || pdfViewerParams.fileName }}
        </div>
        <!-- END: Filename -->

        <!-- START: Document Text toDo: I have no idea what the hell this is supposed to be for... -->
        <div fxFlex="none" class="document-text">
          {{ data.documentName || pdfViewerParams.documentName }}
        </div>
        <!-- END: Document Text -->

        <!-- START: Type, Access, Main/Supporting, and Associated Party -->
        <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="50%" fxLayout="column" fxLayoutGap="14px">
            <div fxFlex="none" fxLayout="column">
              <div fxFlex="none" class="value">
                {{ data.documentCategory || pdfViewerParams.documentCategory }}
              </div>
              <div fxFlex="none" class="label">Type</div>
            </div>
            <div fxFlex="none" fxLayout="column">
              <div fxFlex="none" class="value">
                {{ data.access || pdfViewerParams.access }}
              </div>
              <div fxFlex="none" class="label">Access</div>
            </div>
          </div>

          <div fxFlex="50%" fxLayout="column" fxLayoutGap="14px">
            <div fxFlex="none" fxLayout="column">
              <div fxFlex="none" class="value">
                {{ data.leadingDocument || pdfViewerParams.leadingDocument }}
              </div>
              <div fxFlex="none" class="label">Main/Supporting</div>
            </div>
            <div fxFlex="none" fxLayout="column">
              <div fxFlex="none" class="value">
                {{ data.associatedParty || pdfViewerParams.associatedParty }}
              </div>
              <div fxFlex="none" class="label">Associated Party</div>
            </div>
          </div>
        </div>
        <!-- END: Type, Access, Main/Supporting, and Associated Party -->

        <!-- START: Footer? -->
        <div
          fxFlex="none"
          fxLayout="column"
          fxLayoutAlign="start"
          class="side-panel-footer"
        >
          <header class="file-name">
            {{ data.fileName || pdfViewerParams.fileName }}
          </header>
          <div class="misc-info">
            PDF
            <!--            <span>• Updated [Time] by [User]</span>-->
          </div>
          <div class="file-size">
            {{ data.fileSize || pdfViewerParams.fileSize }}
          </div>
        </div>
        <!-- END: Footer -->
      </div>
      <!-- END: Document info content -->
    </div>
    <!-- END: PDF Info Container -->
  </div>
  <!-- END: PDF Viewer -->
</div>
<!-- END: PDF Viewer Container -->
