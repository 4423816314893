import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CHILD_ROUTE,
  ROUTE,
  TRANSACTIONS_LIST,
  TRANSACTION_ID,
  DOCUMENT_ID,
} from '@fsx/fsx-shared';
import { FilingEditorComponent } from './filing-editor/filing-editor.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { TransactionActivityComponent } from './transaction-activity/transaction-activity.component';
import { TransactionsListComponent } from './transactions/transactions-list/transactions-list.component';
import { NoneDraftTransactionGuard } from './shared/guards/none-draft-transaction-guard';
import { DocumentViewerComponent } from './transactions/email/document-viewer/document-viewer.component';

const routes: Routes = [
  {
    path: ROUTE.HOME,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ROUTE.TRANSACTIONS,
    component: TransactionsComponent,
    children: [
      {
        path: '',
        redirectTo: CHILD_ROUTE.SUBMITTED,
        pathMatch: 'full',
      },
      {
        path: CHILD_ROUTE.SUBMITTED,
        component: TransactionsListComponent,
      },
      {
        path: CHILD_ROUTE.DRAFTS,
        loadComponent: () =>
          import(
            './transactions/transaction-drafts/transaction-drafts.component'
          ).then((m) => m.TransactionDraftsComponent),
      },
      {
        path: `${CHILD_ROUTE.EDITOR}/:${TRANSACTION_ID}`,
        component: FilingEditorComponent,
      },
      {
        path: `${CHILD_ROUTE.ACTIVITY}/:${TRANSACTION_ID}`,
        component: TransactionActivityComponent,
        canActivate: [NoneDraftTransactionGuard],
      },
      {
        path: `${CHILD_ROUTE.DOCUMENT}/:${TRANSACTION_ID}/:${DOCUMENT_ID}`,
        component: DocumentViewerComponent,
      },
      {
        path: '**',
        redirectTo: CHILD_ROUTE.SUBMITTED,
      },
    ],
  },
  {
    path: ROUTE.HELP,
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    pathMatch: 'full',
    path: '**',
    redirectTo: TRANSACTIONS_LIST,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
