import { Pipe, PipeTransform } from '@angular/core';
import { FSXContactQueryResultItem } from '../types';

@Pipe({
  name: 'contactsIdFilter',
  pure: false,
})
export class ContactsIdFilterPipe implements PipeTransform {
  transform(
    contacts: FSXContactQueryResultItem[] | null,
    excludedIds: string[] | undefined
  ): any {
    if (!contacts || !excludedIds) {
      return contacts;
    }
    // filter query result to only include contacts with ids not included as part of the 'excludedIds' filter array arg.
    return contacts.filter(
      (item) => !excludedIds.includes(item.contactSummary.id)
    );
  }
}
