import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApplicationInsightsService } from '../app-insights/application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly zone: NgZone
  ) {}

  handleError(error: Error): Observable<Error> {
    return this.zone.run(() => {
      console.error(error);
      this.applicationInsightsService.logException(error);
      return of(error);
    });
  }
}
