<div
  class="documents-grid-item-container"
  fsxUploadDropTarget
  (fileDropped)="onFileDropped($event)"
>
  <div
    class="data-row documents-item"
    [ngClass]="{
      'supporting-document': !documentsGridRow.requestDocument.isLeadDocument
    }"
    (click)="onToggleExpandDetailRow($event)"
  >
    <div class="inner-data-row">
      <div>
        <fsx-row-validation
          [isValid]="documentsGridRow.requestDocument.isValid"
        ></fsx-row-validation>
      </div>
      <div
        fxFlex="nogrow"
        class="checkbox-col"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-checkbox
          class="documents-checkbox"
          (click)="onDocumentCheckboxClicked($event)"
        ></mat-checkbox>
      </div>
      <div
        fxFlex="nogrow"
        class="document-order-number-col"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <span class="document-order-number">{{ documentOrderNumber }}</span>
        <span
          [hidden]="!hasSupportingDocumentSpecs"
          [ngClass]="{ disabled: documentsGridRow.rowIndex === 0 }"
          class="lead-or-supporting-arrow material-icons-outlined"
          (click)="onToggleLeadOrSupportingDocument($event)"
        >
          {{
            documentsGridRow.requestDocument.isLeadDocument ? "east" : "west"
          }}
        </span>
      </div>
      <div
        fxFlex="none"
        class="title-col"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <span class="title-text" fxLayout="row" fxFlex="none">
          <span
            *ngIf="documentsGridRow.requestDocument.title as documentTitle"
            title="{{ documentTitle }}"
            >{{ documentTitle }}</span
          >
          <span *ngIf="!documentsGridRow.requestDocument.title">{{
            "(New Document)"
          }}</span>
        </span>
        <div class="title-subtext" fxFlex="none">
          <div
            *ngIf="
              (documentsGridRow.documentInfo | fileUploadState) ===
              fileUploadStateEnum.Done
            "
          >
            <div
              *ngIf="
                documentsGridRow.documentInfo | convertedPdf as convertedPdf
              "
            >
              <div
                *ngIf="convertedPdf.size"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <span fxFlex>{{ convertedPdf.size | fileSize }}</span>
                <span fxFlex>Pages: {{ convertedPdf.pageCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="none" class="document-col">
        <div
          *ngIf="
            (documentsGridRow.documentInfo | fileUploadState) !==
              fileUploadStateEnum.Done;
            else truncate
          "
        >
          <fsx-file-upload-status
            #fileUploadStatusField
            [documentInfo]="documentsGridRow.documentInfo"
            [errors]="documentsGridRow.errors"
            (cancelUploadEvent)="cancelUploadEventHandler()"
          >
          </fsx-file-upload-status>
        </div>
        <ng-template #truncate>
          <div
            class="document-category-text"
            title="{{ documentsGridRow.requestDocument.category?.caption }}"
          >
            {{ documentsGridRow.requestDocument.category?.caption }}
          </div>
        </ng-template>
      </div>
      <div fxFlex class="access-col">
        <span *ngIf="combinedFilingData?.filingProfile as filingProfile">
          {{
            filingProfile
              | documentAccessCatgeoryCaption
                : documentsGridRow.requestDocument.accessCategoryName!
          }}
        </span>
      </div>
      <div
        fxFlex="nogrow"
        fxLayout="column"
        fxLayoutAlign="center end"
        class="hover-col"
      >
        <div class="hover-icons" fxLayout="row" fxLayoutAlign="end center">
          <button
            class="icon-button delete-icon-button"
            fsxPreventKeyboardEventBubbling
            (keydown.enter)="onRemoveDocumentClicked($event)"
            (click)="onRemoveDocumentClicked($event)"
          >
            <div class="form-delete-button"></div>
          </button>

          <span
            id="toggle-expand-detail-row-icon"
            class="material-icons-outlined"
            (click)="onToggleExpandDetailRow($event)"
          >
            {{ this.documentsGridRow.expanded ? "expand_less" : "expand_more" }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="detail-row"
    id="documents-detail-row"
    [hidden]="!this.documentsGridRow.expanded"
  >
    <fsx-document-form
      [documentsGridRow]="documentsGridRow"
      [combinedFilingData]="combinedFilingData"
      [validationTimestamp]="validationTimestamp"
      (updateDocumentEvent)="updateDocumentEventHandler($event)"
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromAdditionalFieldEventHandler($event)
      "
    >
    </fsx-document-form>

    <!-- <div class="error-messages" *ngIf="documentsGridRow.requestDocument.errors.length > 0">
      <p>Errors:</p>
      <ul *ngFor="let error of documentsGridRow.requestDocument.errors">
        <li>{{ error.caption}}</li>
      </ul>
    </div> -->
  </div>
</div>
