import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  RequestParticipantRepresentationViewModel,
  ParticipantCategory,
  AdditionalFieldValue,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, of, Subject, switchMap } from 'rxjs';

export const FsxUpdateRepresentationOrchestrationService =
  new InjectionToken<IUpdateRepresentationOrchestrationService>(
    'FsxUpdateRepresentationOrchestrationService'
  );

export interface IUpdateRepresentationParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  caseParty: CasePartyViewModel;
  representation: RequestParticipantRepresentationViewModel;
  participantCategory: ParticipantCategory | null;
  additionalFields: AdditionalFieldValue[] | null;
}

export interface IUpdateRepresentationOrchestrationService {
  updateRepresentationToCaseRequest$: Observable<CaseRequestViewModel>;
  updateRepresentation(params: IUpdateRepresentationParams): void;
}

@Injectable()
export class UpdateRepresentationOrchestrationService
  implements IUpdateRepresentationOrchestrationService
{
  private updateRepresentation$$ = new Subject<IUpdateRepresentationParams>();

  updateRepresentationToCaseRequest$: Observable<CaseRequestViewModel> =
    this.updateRepresentation$$.pipe(
      switchMap((params: IUpdateRepresentationParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return of(params.caseRequest).pipe(
          switchMap(() => {
            if (params.additionalFields) {
              return this.caseRequestUpdateService.optimisticPatchRepresentationAdditionalFieldsOrRestore(
                params.filingId,
                params.caseRequest,
                params.caseParty,
                params.representation,
                params.additionalFields,
                caseRequestBackup
              );
            } else {
              return this.caseRequestUpdateService.optimisticPatchRepresentationTypeOrRestore(
                params.filingId,
                params.caseRequest,
                params.caseParty,
                params.representation,
                params.participantCategory,
                caseRequestBackup
              );
            }
          })
        );
      })
    );

  constructor(
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  updateRepresentation(params: IUpdateRepresentationParams): void {
    this.updateRepresentation$$.next(params);
  }
}
