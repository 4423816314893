import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CaseSpec,
  FilingProfile,
  RequestCaseViewModel,
} from '../../../types';
import {
  FsxAdditionalFieldsValidationService,
  IAdditionalFieldsValidationService,
} from './additional-fields-validation.service';
import {
  FsxAddressValidationService,
  IAddressValidationService,
} from './address-validation.service';
import {
  FsxCurrencyValidationService,
  ICurrencyValidationService,
} from './currency-validation.service';

export const FsxCaseValidationService =
  new InjectionToken<ICaseValidationService>('FsxCaseValidationService');

export interface ICaseValidationService {
  validateCase(
    requestCase: RequestCaseViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    caseSpec?: CaseSpec | null | undefined
  ): boolean;
}

@Injectable()
export class CaseValidationService implements ICaseValidationService {
  constructor(
    @Inject(FsxCurrencyValidationService)
    private readonly currencyValidationService: ICurrencyValidationService,
    @Inject(FsxAddressValidationService)
    private readonly addressValidationService: IAddressValidationService,
    @Inject(FsxAdditionalFieldsValidationService)
    private readonly additionalFieldsValidationService: IAdditionalFieldsValidationService
  ) {}

  public validateCase(
    requestCase: RequestCaseViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    caseSpec?: CaseSpec | null | undefined
  ): boolean {
    requestCase.isValid = true;
    caseRequest.isCaseDetailsValid = true;

    // The caption (Case Name) is always required so this check is always applicable
    if (!requestCase.caption) {
      requestCase.isValid = false;
    }

    if (caseSpec?.amountInControversy) {
      if (
        !this.currencyValidationService.validateCurrency(
          requestCase.amountInControversy,
          caseSpec?.amountInControversy,
          requestCase
        )
      ) {
        requestCase.isValid = false;
      }
    }

    if (caseSpec?.incidentAddress) {
      if (
        !this.addressValidationService.validateAddress(
          requestCase.incidentAddress,
          caseSpec?.incidentAddress,
          requestCase,
          filingProfile
        )
      ) {
        requestCase.isValid = false;
      }
    }

    if (caseSpec?.additionalFields) {
      if (
        !this.additionalFieldsValidationService.validateAdditionalFields(
          requestCase.additionalFieldValues,
          caseSpec?.additionalFields,
          requestCase.caseId,
          requestCase,
          filingProfile,
          caseRequest
        )
      ) {
        requestCase.isValid = false;
      }
    }

    caseRequest.isCaseDetailsValid = requestCase.isValid;
    return requestCase.isValid;
  }
}
