import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  DomainFieldValue,
  DocumentFieldDefinition,
  IValidatable,
  CaseRequestViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxValidationHelperFpService,
  IValidationHelperFpService,
} from './validation-helper-fp.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxDocumentDomainValidationService =
  new InjectionToken<IDocumentDomainValidationService>(
    'FsxDocumentDomainValidationService'
  );

export interface IDocumentDomainValidationService {
  validateDocumentDomains(
    documents: DomainFieldValue[] | null | undefined,
    spec: DocumentFieldDefinition | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class DocumentDomainValidationService
  implements IDocumentDomainValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxValidationHelperFpService)
    private readonly validationHelperFpService: IValidationHelperFpService
  ) {}

  public validateDocumentDomains(
    documents: DomainFieldValue[] | null | undefined,
    spec: DocumentFieldDefinition | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!documents) {
      documents = [];
    }

    if (documents.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (documents.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const doc of documents) {
      const caseDocument = caseRequest.documents?.find(
        (d) => d.name === doc.name
      );

      if (!caseDocument) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      const matchingCases = caseDocument.cases?.filter(
        (c) => c.caseId === caseId
      );

      if (!matchingCases) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (matchingCases?.length > 1) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (spec.allowedDocumentsList) {
        if (
          !this.validationHelperFpService.getSelectedEfmKey(
            spec.allowedDocumentsList,
            caseDocument.category?.name,
            filingProfile
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(scope);
        }
      }
    }

    return true;
  }
}
