import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { FormControlWithModel } from '../../../../models';
import { FSXFormControlService } from '../../../../services';
import {
  DropdownOptionsTypes,
  DropdownOption,
  ReferenceResolver,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../../../types';
import { FsxSingleSelectionBaseSelectionComponent } from '../single-selection-base/single-selection-base.component';
import {
  minRequiredValidator,
  requiredValidator,
} from '../../../../validators';
import { AdditionalFieldSpec, CombinedFilingData } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-profile-single-selection',
  templateUrl: '../single-selection-base/single-selection-base.component.html',
  styleUrls: ['../single-selection-base/single-selection-base.component.scss'],
})
export class FsxProfileSingleSelectionComponent
  extends FsxSingleSelectionBaseSelectionComponent
  implements OnInit, OnDestroy
{
  @Input() fieldDefinition!: SelectionFieldDefinition;
  @Input() override resolver!: ReferenceResolver;
  @Input() initialValue!: DropdownOptionsTypes | string | null | undefined;
  @Input() override combinedFilingData!: CombinedFilingData | null;

  @Output()
  formControlEmitter = new EventEmitter<
    FormControlWithModel<SelectionFieldDefinition>
  >(true);

  public override formControl!: FormControlWithModel<SelectionFieldDefinition>;
  public override formControlTemp!: FormControlWithModel<SelectionFieldDefinition>;
  public override additionalFieldSpec: AdditionalFieldSpec[] = [];

  private destroy$: Subject<void> = new Subject();

  constructor(public fsxFormControlService: FSXFormControlService) {
    super();
  }

  ngOnInit(): void {
    this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
    this.dropdownOptions = this._getDropdownOptions();
    this.formControl = this._createFormControl();
    this.formControlTemp = this._createFormControl();
    this.formControl.dropdownOptions = this.dropdownOptions;
    this.required = !!this.fieldDefinition.minRequired;
    this.visible = !!this.fieldDefinition.visible;
    this.readOnly = !!this.fieldDefinition.readOnly;

    if (this.required) {
      this.formControl.addValidators([
        requiredValidator(),
        minRequiredValidator(1),
      ]);
      this.formControlTemp.addValidators([
        requiredValidator(),
        minRequiredValidator(1),
      ]);
    }

    this.formControlEmitter.emit(this.formControl);

    this.setFilteredOptions();
    this._setInitialValues();

    this.formControl.statusChanges
      .pipe(
        tap((status) => {
          status === 'DISABLED'
            ? this.formControlTemp.disable()
            : this.formControlTemp.enable();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.formControl.valueChanges
      .pipe(
        tap((value) => {
          this.additionalFieldSpec = this._getAdditionalFieldSpec(value);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _getAdditionalFieldSpec(value: string): AdditionalFieldSpec[] {
    if (!this.fieldDefinition.selectionDependentFields) {
      return [];
    }
    const dependentField = this.fieldDefinition.selectionDependentFields.find(
      (dependentField) => dependentField.selectedName === value
    );

    if (dependentField?.additionalFields) {
      return dependentField.additionalFields;
    } else {
      return [];
    }
  }

  private _createFormControl(): FormControlWithModel<SelectionFieldDefinition> {
    let defaultStringValue;

    if (typeof this.fieldDefinition.defaultValue === 'string') {
      defaultStringValue = this.fieldDefinition.defaultValue;
    } else {
      defaultStringValue = this.fieldDefinition.defaultValue?.name;
    }

    return this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      this.fieldType,
      defaultStringValue,
      this.resolver
    );
  }

  private _getDropdownOptions(): DropdownOption<DropdownOptionsTypes>[] {
    switch (this.selectionType) {
      case SelectionFieldType.StringSelectionFieldDefinition: {
        return this.resolver.getStringDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      case SelectionFieldType.EmailCategorySelectionFieldDefinition: {
        return this.resolver.getEmailCategoryDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      case SelectionFieldType.PhoneCategorySelectionFieldDefinition: {
        return this.resolver.getPhoneCategoryDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      case SelectionFieldType.AddressCategorySelectionFieldDefinition: {
        return this.resolver.getAddressCategoryDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      case SelectionFieldType.IdentificationCategorySelectionFieldDefinition: {
        return this.resolver.getIdentificationCategoryDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      case SelectionFieldType.AliasCategorySelectionFieldDefinition: {
        return this.resolver.getAliasCategoryDropdownOptions(
          this.fieldDefinition.listReference
        );
      }
      default:
        return [];
    }
  }

  private _setInitialValues(): void {
    if (this.initialValue && typeof this.initialValue !== 'string') {
      this.formControlTemp.setValue(this.initialValue);
      this.formControl.setValue(this.initialValue.name);
      this.selectedValue.emit(this.initialValue.name);
    } else if (
      this.fieldDefinition.defaultValue &&
      typeof this.fieldDefinition.defaultValue !== 'string'
    ) {
      this.formControlTemp.setValue(this.fieldDefinition.defaultValue);
      this.formControl.setValue(this.fieldDefinition.defaultValue.name);
      this.selectedValue.emit(this.fieldDefinition.defaultValue.name);
    } else if (this.dropdownOptions.length === 1) {
      this.formControlTemp.setValue(this.dropdownOptions[0]);
      this.formControl.setValue(this.dropdownOptions[0].name);
      this.selectedValue.emit(this.dropdownOptions[0].name);
    } else if (
      (this.fieldDefinition.defaultValue &&
        typeof this.fieldDefinition.defaultValue === 'string') ||
      (this.initialValue && typeof this.initialValue === 'string')
    ) {
      const value = this.initialValue || this.fieldDefinition.defaultValue;
      const option = this.dropdownOptions.find((option) =>
        value?.toLowerCase().includes(option.name.toLowerCase())
      );
      this.formControlTemp.setValue(option);
      this.formControl.setValue(option?.name);
      this.selectedValue.emit(option?.name);
    }

    if (this.formControlTemp.value) {
      this.selectedSingleOptionCaption = this.formControlTemp.value.caption;
      this.additionalFieldSpec = this._getAdditionalFieldSpec(
        this.formControlTemp.value.name
      );
    }
  }

  public validate(): void {
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity({ emitEvent: false });

    this.formControlTemp.markAsDirty();
    this.formControlTemp.markAsTouched();
    this.formControlTemp.updateValueAndValidity({ emitEvent: false });
  }
}
