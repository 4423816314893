import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  ContactViewModel,
  ParticipantCategory,
  ParticipantSpec,
  FilingProfile,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, of, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxAddContactAsParticipantOrchestrationService =
  new InjectionToken<IAddContactAsParticipantOrchestrationService>(
    'FsxAddContactAsParticipantOrchestrationService'
  );

export interface IAddContactAsParticipantParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  contact: ContactViewModel;
  participantName: string;
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
}

export interface IAddContactAsParticipantOrchestrationService {
  addContactAsParticipantOrchestration$: Observable<CaseRequestViewModel>;
  addContactAsParticipant(params: IAddContactAsParticipantParams): void;
}

@Injectable()
export class AddContactAsParticipantOrchestrationService
  implements IAddContactAsParticipantOrchestrationService
{
  private addContactAsParticipant$$ =
    new Subject<IAddContactAsParticipantParams>();

  addContactAsParticipantOrchestration$: Observable<CaseRequestViewModel> =
    this.addContactAsParticipant$$.pipe(
      switchMap((params: IAddContactAsParticipantParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;

        const participantExists = params.caseRequest.participants?.find(
          (participant) => participant.linkedContact?.id === params.contact.id
        );

        if (participantExists) {
          return of(caseRequestBackup);
        }

        return this.caseRequestBuilderService
          .createPartyAndParticipantFromContactThenSetInCaseRequest(params)
          .pipe(
            switchMap(() => {
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  params.filingId,
                  params.caseRequest,
                  caseRequestBackup
                )
                .pipe(
                  tap(() => {
                    this.validatePartiesOrchestrationService.validateParties();
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addContactAsParticipant(params: IAddContactAsParticipantParams): void {
    this.addContactAsParticipant$$.next(params);
  }
}
