export enum ValidationConstants {
  FilingNotSupported = 'FIL000',
  MulticaseNotSupported = 'FIL001',
  NoteToClerkNotSupported = 'FIL002',

  TooFewDocuments = 'DOC000',
  TooManyDocuments = 'DOC001',
  DocumentSizeTooLarge = 'DOC002',
  TotalDocumentSizeTooLarge = 'DOC003',
  DocumentFileNameTooLong = 'DOC004',
  TooFewDocParticipants = 'DOC005',
  TooManyDocParticipants = 'DOC006',
  NewDocParticipantsNotSupported = 'DOC007',
  ExistingDocParticipantsNotSupported = 'DOC008',
  InvalidDocParticipantCategory = 'DOC009',
  DocumentParticipantNotFoundInFiling = 'DOC010',
  RenderingConversionPending = 'DOC011',
  RenderingConversionError = 'DOC012',
  RenderingStatusUnknown = 'DOC013',
  RenderingFailedChecks = 'DOC014',
  DocumentCategoryRequired = 'DOC015',

  TooFewParticipantsOfType = 'PAR001',
  TooManyParticipantsOfType = 'PAR002',
  PersonParticipantTypeNotAllowed = 'PAR003',
  OrgParticipantTypeNotAllowed = 'PAR004',
  ParticipantCaseCategoryRequired = 'PAR005',
  ParticipantOrganizationNotAllowed = 'PAR006',
  ParticipantPersonNotAllowed = 'PAR007',
  DuplicateParticipantNameInCase = 'PAR008',

  TooFewSelectedParticipants = 'SPA001',
  TooManySelectedParticipants = 'SPA002',
  SelectedParticipantDoesntExist = 'SPA003',
  NewParticipantNotAllowed = 'SPA004',
  ExistingParticipantNotAllowed = 'SPA005',
  SelectedParticipantPartyNotFound = 'SPA006',
  ParticipantCategoryNotAllowed = 'SPA007',

  PersonContactTypeNotAllowed = 'CON000',
  OrgContactTypeNotAllowed = 'CON001',
  TooFewContacts = 'CON002',
  TooManyContacts = 'CON003',
  ContactOrganizationNotAllowed = 'CON004',
  ContactPersonNotAllowed = 'CON005',

  CurrencyRequired = 'CUR000',
  CurrencyTooLow = 'CUR001',
  CurrencyTooHigh = 'CUR002',
  CurrencyCategoryNotAllowed = 'CUR003',
  CurrencyTooManyDecimals = 'CUR004',
  CurrencyDecimalsNotAllowed = 'CUR005',

  TooFewAddresses = 'ADR000',
  TooManyAddresses = 'ADR001',

  TooFewIds = 'IDS000',
  TooManyIds = 'IDS001',

  TooFewPhones = 'PHN000',
  TooManyPhones = 'PHN001',

  TooFewAliases = 'ALI000',
  TooManyAliases = 'ALI001',
  AliasCategoryRequired = 'ALI002',
  NullAlias = 'ALI003',
  NullParticipantAlias = 'ALI004',
  AliasOrganizationNotAllowed = 'ALI005',
  AliasPersonNotAllowed = 'ALI006',

  DateRequired = 'DAT000',
  DateBeforeRangeStart = 'DAT001',
  DateAfterRangeEnd = 'DAT002',

  DateTimeRequired = 'DTM000',
  DateTimeBeforeRangeStart = 'DTM001',
  DateTimeAfterRangeEnd = 'DTM002',
  DateTimeTimeTooEarly = 'DTM003',
  DateTimeTimeTooLate = 'DTM004',

  TooFewEmailAddresses = 'EML000',
  TooManyEmailAddresses = 'EML001',

  SearchResultRequired = 'SEA000',
  SearchResultEfmKeyRequired = 'SEA001',

  SelectionRequired = 'SEL000',
  TooFewSelections = 'SEL001',
  TooManySelections = 'SEL002',
  SelectionNotAllowed = 'SEL003',

  TextRequired = 'TXT000',
  TooFewCharacters = 'TXT001',
  TooManyCharacters = 'TXT002',
  UnsupportedCharacters = 'TXT003',

  RepNotAmongParticipants = 'REP000',
  TooFewRepresentation = 'REP001',
  TooManyRepresentation = 'REP002',
  RepresentationParticipantNameRequired = 'REP003',
  DuplicateParticipantNameInRepresentation = 'REP004',

  NumberFormatInvalid = 'NUM000',
  NumberTooLow = 'NUM001',
  NumberTooHigh = 'NUM002',
  TooManyDecimals = 'NUM003',
  NumberRequired = 'NUM004',

  DateAfterToday = 'DAT001',
  DateBeforeToday = 'DAT002',
  DateEqualsToday = 'DAT003',
  DateTimeAfterToday = 'DTM001',
  DateTimeBeforeToday = 'DTM002',
  DateTimeInFuture = 'DTM003',
  DateTimeInPast = 'DTM004',

  TimeRequired = 'TIM000',
  TimeFormatInvalid = 'TIM001',
  TimeTooEarly = 'TIM002',
  TimeTooLate = 'TIM003',

  TooFewSelectedDocuments = 'SDO000',
  TooManySelectedDocuments = 'SDO001',
  SelectedDocumentDoesntExist = 'SDO002',
  DocumentCaseNotFound = 'SDO003',
  DocumentCategoryNotAllowed = 'SDO004',

  TooFewFiles = 'ATT000',
  TooManyFiles = 'ATT001',
  FileNotFound = 'ATT002',
  FileRenderingNotFound = 'ATT003',
  FileSizeTooLarge = 'ATT004',
  FileTypeNotAllowed = 'ATT005',

  AdditionalFieldValueRequired = 'ADD000',
  DuplicateAdditionalFieldValue = 'ADD001',

  Unknown = 'UNKOWN',
}
