import { ContactType, ParticipantCommonCategory } from '../api';
import {
  CasePartyViewModel,
  RequestParticipantViewModel,
} from '../view-models';

export const DEFAULT_PARTICIPANT: RequestParticipantViewModel = {
  linkedContact: null,
  name: 'default',
  parentOrganizationCaption: null,
  aliases: null,
  caption: '',
  contactType: ContactType.Unknown,
  person: null,
  addresses: [],
  emails: [],
  identifications: [],
  phones: [],
  organization: {
    title: '',
    caption: '',
  },
} as unknown as RequestParticipantViewModel;

export const DEFAULT_PARTY: CasePartyViewModel = {
  caption: '',
  caseId: '00000000-0000-0000-0000-000000000000',
  participantName: 'default',
  efmKey: null,
  participantCategory: {
    name: '',
    caption: '',
    commonCategory: ParticipantCommonCategory.None,
  },
  participantSubCategoryNames: [],
  representation: [],
  isSelfRepresented: false,
  additionalFieldValues: null,
} as unknown as CasePartyViewModel;
