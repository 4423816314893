import { EfmStatus, FilingMetrics } from '../api';

export type DraftFilingCategory = keyof FilingMetrics | 'recent';
export enum TransactionCategory {
  // BOOKMARKED = 'Bookmarked',
  DRAFT = 'Draft',
  REJECTED = 'Rejected',
  RECENT = 'Recent',
  SCHEDULED = 'Scheduled',
}

export enum TransactionCategoryDisplayName {
  DRAFT = 'Saved',
  SCHEDULED = 'Scheduled',
  REJECTED = 'Rejected',
}

type RoutesDataKey = 'rejected' | 'saved' | 'scheduled';

export interface DraftFilingEntityPagination {
  totalCount: number;
  index: number;
  limit: number;
}
export interface TransactionsRoute {
  readonly name: TransactionCategoryDisplayName;
  readonly path: RoutesDataKey;
  totalTransactions?: number;
}

export interface TransactionsGridRowData {
  isBookmarked?: boolean;
  transactionId?: string;
  caseNum?: string;
  caseName?: string | null;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string | null;
  rowId: string;
  profileId?: string;
  courtId?: string;
}

export const draftFilingMetricsInitValue: FilingMetrics = {
  draft: {
    count: 0,
    efmStatusMetrics: [],
  },
  scheduled: {
    count: 0,
    efmStatusMetrics: [],
  },
  rejected: {
    count: 0,
    efmStatusMetrics: [],
  },
  accepted: {
    count: 0,
    efmStatusMetrics: [
      {
        status: EfmStatus.Queued,
        count: 0,
      },
      {
        status: EfmStatus.Pending,
        count: 0,
      },
      {
        status: EfmStatus.Processed,
        count: 0,
      },
      {
        status: EfmStatus.Error,
        count: 0,
      },
    ],
  },
  cancelled: {
    count: 0,
    efmStatusMetrics: [],
  },
};
