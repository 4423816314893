import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AdditionalFieldSpec, DocumentInfo } from '@fsx/fsx-shared';
import { IUploadedFile } from './base-file-upload.component';

@Component({
  selector: 'fsx-file-upload-wrapper',
  templateUrl: './file-upload-wrapper.component.html',
  styleUrls: ['./file-upload-wrapper.component.scss'],
})
export class FsxFileUploadWrapperComponent implements OnChanges {
  @Input() additionalFieldSpec!: AdditionalFieldSpec;
  @Input() fileUploadDocumentInfos!: DocumentInfo[];
  @Output() filesUploadedWrapperEvent = new EventEmitter<IUploadedFile[]>();
  @Output() fileRemovedEvent = new EventEmitter<DocumentInfo>();

  hoverIndex: number | null = null;
  maxAllowed!: number;
  minRequired!: number;
  isValid = true;

  ngOnChanges(): void {
    if (this.additionalFieldSpec) {
      this.maxAllowed =
        this.additionalFieldSpec.fileFieldDefinition?.maxAllowed || 999;
      this.minRequired =
        this.additionalFieldSpec.fileFieldDefinition?.minRequired || 0;
      this.validate();
    }
  }

  onFilesUploadedEvent(files: IUploadedFile[]) {
    if (this.fileUploadDocumentInfos.length < this.maxAllowed) {
      this.filesUploadedWrapperEvent.emit(files);
      this.validate();
    }
  }

  onRemoveClicked(event: Event, documentInfo: DocumentInfo) {
    event.stopPropagation();
    if (this.fileUploadDocumentInfos.length > this.minRequired) {
      this.fileRemovedEvent.emit(documentInfo);
      this.validate();
    }
  }

  onMouseover(event: Event, index: number) {
    event.stopPropagation();
    this.hoverIndex = index;
  }

  onMouseout(event: Event) {
    event.stopPropagation();
    this.hoverIndex = null;
  }

  validate(): void {
    if (this.fileUploadDocumentInfos.length < this.minRequired) {
      this.isValid = false;
      return;
    }

    if (this.fileUploadDocumentInfos.length > this.maxAllowed) {
      this.isValid = false;
      return;
    }

    this.isValid = true;
  }
}
