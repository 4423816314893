import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const FsxIsLoadingStateService =
  new InjectionToken<IIsLoadingStateService>('FsxIsLoadingStateService');

export interface IIsLoadingStateService {
  isLoading$: Observable<boolean>;
  isLoading(isLoading: boolean): void;
}

@Injectable()
export class IsLoadingStateService implements IIsLoadingStateService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading$$.asObservable();

  isLoading(isLoading: boolean): void {
    this.isLoading$$.next(isLoading);
  }
}
