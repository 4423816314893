import {
  CombinedFilingData,
  FsxValidationService,
  IValidationService,
} from '@fsx/fsx-shared';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, map, of, switchMap, take, combineLatest } from 'rxjs';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ContactViewModel,
  FilingProfile,
  ParticipantCategory,
  ParticipantSpec,
  RequestDocumentViewModel,
  RequestParticipantViewModel,
  RequestParticipantRepresentationViewModel,
  DocumentSpec,
  DocumentTypeEnum,
  CaseRequest,
  RequestDocumentCaseViewModel,
  RequestDocumentParticipantViewModel,
  RequestParticipant,
} from '../../types';
import {
  DefaultParticipantService,
  CreateParticipantService,
  CreatePartyService,
  FsxDefaultPartyService,
  IDefaultPartyService,
} from '../participants';
import { CreateRepresentationService } from '../participants/create-representation.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxCaseRequestBuilderService =
  new InjectionToken<ICaseRequestBuilderService>(
    'FsxCaseRequestBuilderService'
  );

export interface ISetPropertyInCaseRequestParams<T> {
  caseRequest: CaseRequestViewModel;
  property: T;
  participantName: string;
}

export interface ICaseRequestBuilderService {
  updateRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
    partialRequestDocument: Partial<RequestDocumentViewModel>;
    combinedFilingData: CombinedFilingData;
  }): Observable<CaseRequestViewModel>;

  addDefaultPartyAndParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel,
    participantCategory: ParticipantCategory
  ): Observable<CaseRequestViewModel>;

  createParticipantFromContactThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel>;

  createParticipantThenAddToCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel>;

  createPartyFromContactThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantCategory: ParticipantCategory;
  }): Observable<CaseRequestViewModel>;

  createPartyFromParticipantThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    participant: RequestParticipant;
    participantName: string;
    participantCategory: ParticipantCategory;
  }): Observable<CaseRequestViewModel>;

  createPartyAndParticipantFromContactThenSetInCaseRequest(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantCategory: ParticipantCategory;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel>;

  removeRepresentationAndParticipants(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): Observable<CaseRequestViewModel>;

  removeAssociatedParties(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemove: CasePartyViewModel;
  }): Observable<CaseRequestViewModel>;

  removePartyAndParticipant(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemove: CasePartyViewModel;
  }): Observable<CaseRequestViewModel>;

  /**
   * A method for adding a given RequestPartyRepresentation object to a given
   * CaseParty object on a given CaseRequest object. Returns an observable to
   * allow it to be used in orchestration service streams.
   *
   * @param params The params object needed to run the method.
   *
   * @returns The updated CaseRequest object as an observable.
   */
  addRepresentationToPartyInCaseRequest(params: {
    /**
     * THe CaseRequest object that we want to update. This will be the CaseRequest
     * object that contains the CaseParty object to add representation to.
     */
    caseRequest: CaseRequestViewModel;

    /**
     * The CaseParty object that we want to add representation to. This will be a
     * CaseParty object in the CaseRequest.parties collection.
     */
    partyToAddTo: CasePartyViewModel;

    /**
     * THe RequestParticipantRepresentation object to add to the CaseParty object.
     */
    representationToAdd: RequestParticipantRepresentationViewModel;
  }): Observable<CaseRequestViewModel>;

  createRepresentationAndParticipantThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    partyToAddTo: CasePartyViewModel;
    contact: ContactViewModel;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel>;

  setParticipantInCaseRequest(
    params: ISetPropertyInCaseRequestParams<RequestParticipantViewModel>
  ): Observable<CaseRequestViewModel>;

  addParticipantToCaseRequest(
    params: ISetPropertyInCaseRequestParams<RequestParticipantViewModel>
  ): Observable<CaseRequestViewModel>;

  setPartyInCaseRequest(
    params: ISetPropertyInCaseRequestParams<CasePartyViewModel>
  ): Observable<CaseRequestViewModel>;

  checkRemoveParticipant(
    caseRequest: CaseRequestViewModel,
    participantName: string
  ): Observable<CaseRequestViewModel>;

  removeParty(
    caseRequest: CaseRequestViewModel,
    participantName: string
  ): Observable<CaseRequestViewModel>;

  addRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex?: number;
  }): Observable<CaseRequestViewModel>;

  bulkAddRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocuments: RequestDocumentViewModel[];
    documentIndex?: number;
  }): Observable<CaseRequestViewModel[]>;

  removeRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
  }): Observable<CaseRequestViewModel>;
}

@Injectable()
export class CaseRequestBuilderService implements ICaseRequestBuilderService {
  constructor(
    @Inject(FsxDefaultPartyService)
    private readonly defaultPartyService: IDefaultPartyService,
    private readonly defaultParticipantService: DefaultParticipantService,
    private readonly createPartyService: CreatePartyService,
    private readonly createParticipantService: CreateParticipantService,
    private readonly createRepresentationService: CreateRepresentationService,
    @Inject(FsxValidationService)
    private readonly validationService: IValidationService
  ) {}

  updateRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
    partialRequestDocument: Partial<RequestDocumentViewModel>;
    combinedFilingData: CombinedFilingData;
  }): Observable<CaseRequestViewModel> {
    const caseRequestDocuments: RequestDocumentViewModel[] =
      params.caseRequest.documents || [];
    params.caseRequest.documents = caseRequestDocuments.map(
      (requestDocument: RequestDocumentViewModel) => {
        let updatedDocument: RequestDocumentViewModel;

        if (requestDocument.id === params.requestDocument.id) {
          updatedDocument = {
            ...requestDocument,
            ...params.partialRequestDocument,
          } as RequestDocumentViewModel;

          // it's been updated, revalidate
          this.validateDocument(updatedDocument, params.combinedFilingData);
        } else {
          updatedDocument = requestDocument;
        }

        return updatedDocument;
      }
    );
    return of(params.caseRequest);
  }

  private validateDocument(
    requestDocument: RequestDocumentViewModel,
    combinedFilingData: CombinedFilingData
  ) {
    const {
      caseRequest,
      filingProfile,
      documentInfos: documentFiles,
    } = combinedFilingData;
    const documentCategory = requestDocument.category?.commonCategory;
    if (documentCategory) {
      const leadOrSupportingDocument: DocumentTypeEnum =
        requestDocument.isLeadDocument
          ? DocumentTypeEnum.Lead
          : DocumentTypeEnum.Supporting;
      const documentSpecs =
        combinedFilingData?.modeSpec?.[leadOrSupportingDocument] || [];
      const documentSpec: DocumentSpec | undefined = documentSpecs.find(
        (spec: DocumentSpec) =>
          spec.documentCategory.commonCategory === documentCategory
      );
      if (documentSpec) {
        this.validationService.setDocumentFiles(documentFiles);
        this.validationService.validateDocument(
          requestDocument,
          documentSpec,
          caseRequest,
          filingProfile,
          caseRequest
        );
      }
    }
  }

  addDefaultPartyAndParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel,
    participantCategory: ParticipantCategory
  ): Observable<CaseRequestViewModel> {
    return this.defaultParticipantService
      .addDefaultParticipant(uniqueIdentifier, caseRequest)
      .pipe(
        switchMap(() => {
          return this.defaultPartyService.addDefaultParty(
            caseRequest,
            uniqueIdentifier,
            participantCategory
          );
        })
      );
  }

  createParticipantFromContactThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel> {
    return this.createParticipantService
      .createParticipantFromContact({
        ...params,
        uniqueIdentifier: params.participantName,
      })
      .pipe(
        switchMap((participant: RequestParticipantViewModel | undefined) => {
          return this.setParticipantInCaseRequest({
            caseRequest: params.caseRequest,
            property: participant as RequestParticipantViewModel,
            participantName: params.participantName,
          });
        })
      );
  }

  createParticipantThenAddToCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel> {
    return this.createParticipantService
      .createParticipantFromContact({
        ...params,
        uniqueIdentifier: params.participantName,
      })
      .pipe(
        switchMap((participant: RequestParticipantViewModel | undefined) => {
          return this.addParticipantToCaseRequest({
            caseRequest: params.caseRequest,
            property: participant as RequestParticipantViewModel,
            participantName: params.participantName,
          });
        })
      );
  }

  createPartyFromContactThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantCategory: ParticipantCategory;
  }): Observable<CaseRequestViewModel> {
    return this.createPartyService
      .createPartyFromContact({
        ...params,
        uniqueIdentifier: params.participantName,
      })
      .pipe(
        take(1),
        switchMap((party: CasePartyViewModel) => {
          return this.setPartyInCaseRequest({
            caseRequest: params.caseRequest,
            property: party,
            participantName: params.participantName,
          });
        })
      );
  }

  createPartyFromParticipantThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    participant: RequestParticipant;
    participantName: string;
    participantCategory: ParticipantCategory;
  }): Observable<CaseRequestViewModel> {
    return this.createPartyService
      .createPartyFromParticipant({
        ...params,
        uniqueIdentifier: params.participantName,
      })
      .pipe(
        take(1),
        switchMap((party: CasePartyViewModel) => {
          return this.setPartyInCaseRequest({
            caseRequest: params.caseRequest,
            property: party,
            participantName: params.participantName,
          });
        })
      );
  }

  createPartyAndParticipantFromContactThenSetInCaseRequest(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    contact: ContactViewModel;
    participantName: string;
    participantCategory: ParticipantCategory;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel> {
    return this.createPartyFromContactThenSetInCaseRequest(params).pipe(
      switchMap(() => {
        return this.createParticipantFromContactThenSetInCaseRequest(params);
      })
    );
  }

  removeRepresentationAndParticipants(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): Observable<CaseRequestViewModel> {
    // 1) Clear the representation from the party in the case request
    params.caseRequest.parties = params.caseRequest.parties?.map(
      (party: CasePartyViewModel) => {
        return party.participantName ===
          params.partyToRemoveFrom.participantName
          ? ({
              ...params.partyToRemoveFrom,
              representation: [],
            } as unknown as CasePartyViewModel)
          : party;
      }
    );

    // 2) Try to remove the representation participants (if not referenced elsewhere)
    const representation = params.partyToRemoveFrom.representation || [];
    const arrayOfCheckRemoveParticipants$: Observable<CaseRequestViewModel>[] =
      representation.map((rep: RequestParticipantRepresentationViewModel) => {
        return this.checkRemoveParticipant(
          params.caseRequest,
          rep.participantName
        );
      });
    const checkRemoveParticipants$ = combineLatest(
      arrayOfCheckRemoveParticipants$
    ).pipe(map(() => params.caseRequest));
    return representation.length > 0
      ? checkRemoveParticipants$
      : of(params.caseRequest);
  }

  removeAssociatedParties(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemove: CasePartyViewModel;
  }): Observable<CaseRequestViewModel> {
    const { caseRequest, partyToRemove } = params;
    return of(caseRequest).pipe(
      map((caseRequest: CaseRequest) => {
        const caseRequestDocuments: RequestDocumentViewModel[] =
          caseRequest.documents || [];
        caseRequest.documents = caseRequestDocuments.map(
          (requestDocument: RequestDocumentViewModel) => {
            const requestDocumentCases: RequestDocumentCaseViewModel[] =
              requestDocument.cases || [];
            requestDocument.cases = requestDocumentCases.map(
              (requestDocumentCase: RequestDocumentCaseViewModel) => {
                // Remove any related "Filed By" participants...
                const filedByParticipants: RequestDocumentParticipantViewModel[] =
                  requestDocumentCase.filedBy || [];
                requestDocumentCase.filedBy = filedByParticipants.filter(
                  (filedByParticipant: RequestDocumentParticipantViewModel) => {
                    return (
                      filedByParticipant.participantName !==
                      partyToRemove.participantName
                    );
                  }
                );

                // Remove any related "As To" participants...
                const asToParticipants: RequestDocumentParticipantViewModel[] =
                  requestDocumentCase.filedAsTo || [];
                requestDocumentCase.filedAsTo = asToParticipants.filter(
                  (filedByParticipant: RequestDocumentParticipantViewModel) => {
                    return (
                      filedByParticipant.participantName !==
                      partyToRemove.participantName
                    );
                  }
                );

                return requestDocumentCase;
              }
            );
            return requestDocument;
          }
        );
        return caseRequest;
      })
    );
  }

  removePartyAndParticipant(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    partyToRemove: CasePartyViewModel;
  }): Observable<CaseRequestViewModel> {
    return this.removeRepresentationAndParticipants({
      ...params,
      partyToRemoveFrom: params.partyToRemove,
    }).pipe(
      switchMap(() => {
        return this.removeParty(
          params.caseRequest,
          params.partyToRemove.participantName
        ).pipe(
          switchMap(() => {
            return this.checkRemoveParticipant(
              params.caseRequest,
              params.partyToRemove.participantName
            );
          })
        );
      })
    );
  }

  /**
   * A method for adding a given RequestPartyRepresentation object to a given
   * CaseParty object on a given CaseRequest object. Returns an observable to
   * allow it to be used in orchestration service streams.
   *
   * @param params The params object needed to run the method.
   *
   * @returns The updated CaseRequest object as an observable.
   */
  addRepresentationToPartyInCaseRequest(params: {
    /**
     * THe CaseRequest object that we want to update. This will be the CaseRequest
     * object that contains the CaseParty object to add representation to.
     */
    caseRequest: CaseRequestViewModel;

    /**
     * The CaseParty object that we want to add representation to. This will be a
     * CaseParty object in the CaseRequest.parties collection.
     */
    partyToAddTo: CasePartyViewModel;

    /**
     * THe RequestParticipantRepresentation object to add to the CaseParty object.
     */
    representationToAdd: RequestParticipantRepresentationViewModel;
  }): Observable<CaseRequestViewModel> {
    // Defensive Programming. The CaseParty object's representation array should
    // be initialised before calling into this. If it's not we initialise it here
    // to allow the method to do its work (rather than throw error or exit early).
    if (!params.partyToAddTo.representation) {
      params.partyToAddTo.representation = [];
    }

    // Add the representation to the CaseParty object's representation collection,
    params.partyToAddTo.representation?.push(params.representationToAdd);

    // Update the CaseParty object in the CaseReques.parties collection.
    params.caseRequest.parties = params.caseRequest.parties?.map((party) => {
      return party.participantName === params.partyToAddTo.participantName
        ? params.partyToAddTo
        : party;
    });

    // Return the updated CaseRequest object.
    return of(params.caseRequest);
  }

  createRepresentationAndParticipantThenSetInCaseRequest(params: {
    caseRequest: CaseRequestViewModel;
    partyToAddTo: CasePartyViewModel;
    contact: ContactViewModel;
    participantSpec: ParticipantSpec;
    filingProfile: FilingProfile;
  }): Observable<CaseRequestViewModel> {
    return this.createRepresentationService
      .createRepresentationFromContact({
        contact: params.contact,
        participantCategory: params.participantSpec.participantCategory,
      })
      .pipe(
        switchMap(
          (representation: RequestParticipantRepresentationViewModel) => {
            return this.addRepresentationToPartyInCaseRequest({
              ...params,
              representationToAdd: representation,
            }).pipe(
              switchMap(() => {
                return this.createParticipantThenAddToCaseRequest({
                  ...params,
                  participantName: representation.participantName,
                  participantSpec: params.participantSpec,
                  filingProfile: params.filingProfile,
                });
              })
            );
          }
        )
      );
  }

  setParticipantInCaseRequest(
    params: ISetPropertyInCaseRequestParams<RequestParticipantViewModel>
  ): Observable<CaseRequestViewModel> {
    return of(params.caseRequest).pipe(
      map((caseRequest: CaseRequestViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequest.participants = caseRequestParticipants.map((p, i) => {
          return p.name === params.participantName ? params.property : p;
        });
        return caseRequest;
      })
    );
  }

  addParticipantToCaseRequest(
    params: ISetPropertyInCaseRequestParams<RequestParticipantViewModel>
  ): Observable<CaseRequestViewModel> {
    return of(params.caseRequest).pipe(
      map((caseRequest: CaseRequestViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequest.participants = [
          ...caseRequestParticipants,
          params.property,
        ];
        return caseRequest;
      })
    );
  }

  setPartyInCaseRequest(
    params: ISetPropertyInCaseRequestParams<CasePartyViewModel>
  ): Observable<CaseRequestViewModel> {
    return of(params.caseRequest).pipe(
      map((caseRequest: CaseRequestViewModel) => {
        const caseRequestParties: CasePartyViewModel[] =
          caseRequest.parties || [];
        caseRequest.parties = caseRequestParties.map((p, i) => {
          return p.participantName === params.participantName
            ? params.property
            : p;
        });
        return caseRequest;
      })
    );
  }

  checkRemoveParticipant(
    caseRequest: CaseRequestViewModel,
    participantName: string
  ): Observable<CaseRequestViewModel> {
    const caseRequestParticipants: RequestParticipantViewModel[] =
      caseRequest.participants || [];

    // Lookup parties referencing the participant we are considering removing
    const referencingParties: CasePartyViewModel[] =
      caseRequest.parties?.filter((party: CasePartyViewModel) => {
        return party.participantName === participantName;
      }) || [];

    // Lookup representation referencing the participant we are considering removing
    const allRepresentation:
      | RequestParticipantRepresentationViewModel[]
      | undefined = caseRequest?.parties?.flatMap(
      (p: CasePartyViewModel) => p.representation || []
    );
    const referencingRepresentation: RequestParticipantRepresentationViewModel[] =
      allRepresentation?.filter(
        (representation: RequestParticipantRepresentationViewModel) => {
          return representation.participantName === participantName;
        }
      ) || [];

    // Remove the participant using a filter if no references to the participant were found
    if (
      referencingParties.length === 0 &&
      referencingRepresentation.length == 0
    ) {
      caseRequest.participants = caseRequestParticipants.filter((p) => {
        return p.name !== participantName;
      });
    }

    return of(caseRequest);
  }

  removeParty(
    caseRequest: CaseRequestViewModel,
    participantName: string
  ): Observable<CaseRequestViewModel> {
    const caseRequestParties: CasePartyViewModel[] = caseRequest.parties || [];
    const filteredCaseRequestParties: CasePartyViewModel[] =
      caseRequestParties.filter((p) => {
        return p.participantName !== participantName;
      });
    caseRequest.parties = filteredCaseRequestParties;
    return of(caseRequest);
  }

  addRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex?: number;
  }): Observable<CaseRequestViewModel> {
    const { caseRequest, requestDocument, documentIndex } = params;
    const caseRequestDocuments: RequestDocumentViewModel[] =
      caseRequest.documents || [];
    const newDocumentIndex: number =
      documentIndex || caseRequestDocuments.length;

    if (!requestDocument.name) {
      requestDocument.name = requestDocument.id || uuidv4();
    }

    caseRequestDocuments.splice(newDocumentIndex, 0, requestDocument);
    caseRequest.documents = [...caseRequestDocuments];
    return of(caseRequest);
  }

  bulkAddRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocuments: RequestDocumentViewModel[];
    documentIndex?: number;
  }): Observable<CaseRequestViewModel[]> {
    const { requestDocuments, documentIndex } = params;
    const arrayOfAddRequestDocuments$: Observable<CaseRequestViewModel>[] =
      requestDocuments.map((requestDocument: RequestDocumentViewModel) => {
        return this.addRequestDocument({
          ...params,
          requestDocument,
          documentIndex,
        });
      });
    const bulkAddRequestDocument$: Observable<CaseRequestViewModel[]> =
      combineLatest(arrayOfAddRequestDocuments$);
    return bulkAddRequestDocument$;
  }

  removeRequestDocument(params: {
    caseRequest: CaseRequestViewModel;
    requestDocument: RequestDocumentViewModel;
  }): Observable<CaseRequestViewModel> {
    const caseRequestDocuments: RequestDocumentViewModel[] =
      params.caseRequest.documents || [];
    const filteredCaseRequestDocuments: RequestDocumentViewModel[] =
      caseRequestDocuments.filter((doc: RequestDocumentViewModel) => {
        return doc.id !== params.requestDocument.id;
      });
    params.caseRequest.documents = filteredCaseRequestDocuments;
    return of(params.caseRequest);
  }
}
