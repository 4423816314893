<div
  class="document-form-container"
  [ngClass]="{ 'has-errors': !documentsGridRow.requestDocument.isValid }"
>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <fsx-basic-single-selection
      #documentTypeField
      [caption]="'Document Type'"
      [helpText]="''"
      [hint]="''"
      [fieldType]="fieldType.Selection"
      [width]="'480px'"
      [selectionType]="selectionType"
      [required]="true"
      [inputDropdownOptions]="documentTypeList"
      [initialValue]="documentsGridRow.requestDocument.category?.name"
      (selectedValue)="
        onDocumentTypeSelected({
          value: $event,
          requestDocument: documentsGridRow.requestDocument,
          documentIndex: documentsGridRow.rowIndex
        })
      "
    >
    </fsx-basic-single-selection>
    <div *ngIf="!accessTypeFieldDefinition" style="width: 162px"></div>
    <fsx-basic-single-selection
      *ngIf="accessTypeFieldDefinition"
      #accessTypeField
      [caption]="'Access Type'"
      [helpText]="''"
      [hint]="''"
      [fieldType]="fieldType.Selection"
      [width]="'162px'"
      [selectionType]="selectionType"
      [required]="true"
      [inputDropdownOptions]="accessTypeList"
      [initialValue]="documentsGridRow.requestDocument.accessCategoryName"
      (selectedValue)="
        onAccessTypeSelected({
          value: $event,
          requestDocument: documentsGridRow.requestDocument,
          documentIndex: documentsGridRow.rowIndex
        })
      "
    >
    </fsx-basic-single-selection>
  </div>
  <div>
    <fsx-text-component
      #documentTitleField
      cdkDragDisabled="true"
      [caption]="'Document Title'"
      [fieldDefinition]="documentTitleFieldDefinition"
      [initialValue]="documentsGridRow.requestDocument.title"
      [width]="'100%'"
      [resolver]="resolver"
      (textChanged)="
        onDocumentTitleTextChanged({
          value: $event,
          requestDocument: documentsGridRow.requestDocument,
          documentIndex: documentsGridRow.rowIndex
        })
      "
    >
    </fsx-text-component>
  </div>
  <div>
    <fsx-text-component
      #fileNameField
      cdkDragDisabled="true"
      [caption]="'File Name'"
      [fieldDefinition]="fileNameFieldDefinition"
      [initialValue]="documentsGridRow.requestDocument.fileName"
      [width]="'100%'"
      [resolver]="resolver"
      (textChanged)="
        onFileNameTextChanged({
          value: $event,
          requestDocument: documentsGridRow.requestDocument,
          documentIndex: documentsGridRow.rowIndex
        })
      "
    >
    </fsx-text-component>
  </div>

  <div
    id="add-associated-party-row"
    *ngIf="
      documentsGridRow.requestDocument.category?.name &&
      (asToParticipantFieldSpec || filedByParticipantFieldSpec)
    "
  >
    <fsx-row-validation
      [isValid]="hasAssociatedParties || !requiresAssociatedParties"
      [cssClass]="'margin-right'"
    ></fsx-row-validation>

    <button
      id="add-associated-party-button"
      (click)="onAddAssociatedPartyClicked()"
      *ngIf="
        !isAddingAssociatedParty && !hasAssociatedParties;
        else searchForParty
      "
      fxLayout="row"
      fxFlex="nogrow"
      fxLayoutAlign="start center"
    >
      <span class="material-icons add-icon"> add_circle </span>
      Associated Party
    </button>

    <ng-template #searchForParty>
      <div fxLayout="column">
        <h4 id="associated-party-heading">Associated Party</h4>
        <!-- START: FiledBy Associated Party (If allowed) -->
        <fsx-participant-field
          #filedByField
          *ngIf="
            documentsGridRow.requestDocument.category?.name &&
            filedByParticipantFieldSpec
          "
          [participantFieldSpec]="filedByParticipantFieldSpec"
          [targetRequestDocumentParticipant]="associatedPartyFiledBy"
          [combinedFilingData]="combinedFilingData"
          [inputDropdownOptions]="filedByDropdownOptions"
          [resolver]="resolver"
          [caption]="'Filed By'"
          [documentsGridRow]="documentsGridRow"
          [selectedParticipants]="selectedFiledByParticipants"
          [width]="'300px'"
          [fileUploadDocumentInfos]="filedByFileUploadDocumentInfos"
          (removeParticipantEvent)="
            onFiledByRemoved({
              selectedParticipant: $event,
              requestDocument: documentsGridRow.requestDocument,
              documentIndex: documentsGridRow.rowIndex
            })
          "
          (selectedParticipantEvent)="
            onFiledBySelected({
              selectedParticipant: $event,
              requestDocument: documentsGridRow.requestDocument,
              documentIndex: documentsGridRow.rowIndex
            })
          "
          (filesUploadedFromAdditionalFieldEvent)="
            filesUploadedFromFiledByAdditionalFieldEventHandler($event)
          "
          (fileRemovedFromAdditionalFieldEvent)="
            fileRemovedFromFiledByAdditionalFieldEventHandler($event)
          "
        >
        </fsx-participant-field>
        <!-- END: FiledBy Associated Party -->

        <!-- START: AsTo Associated Party (If allowed) -->
        <fsx-participant-field
          #asToField
          *ngIf="
            documentsGridRow.requestDocument.category?.name &&
            asToParticipantFieldSpec
          "
          [participantFieldSpec]="asToParticipantFieldSpec"
          [targetRequestDocumentParticipant]="associatedPartyAsTo"
          [combinedFilingData]="combinedFilingData"
          [inputDropdownOptions]="asToDropdownOptions"
          [caption]="'As To'"
          [width]="'300px'"
          [documentsGridRow]="documentsGridRow"
          [selectedParticipants]="selectedAsToParticipants"
          [resolver]="resolver"
          [fileUploadDocumentInfos]="asToFileUploadDocumentInfos"
          (removeParticipantEvent)="
            onAsToRemoved({
              selectedParticipant: $event,
              requestDocument: documentsGridRow.requestDocument,
              documentIndex: documentsGridRow.rowIndex
            })
          "
          (selectedParticipantEvent)="
            onAsToSelected({
              selectedParticipant: $event,
              requestDocument: documentsGridRow.requestDocument,
              documentIndex: documentsGridRow.rowIndex
            })
          "
          (filesUploadedFromAdditionalFieldEvent)="
            filesUploadedFromAsToAdditionalFieldEventHandler($event)
          "
          (fileRemovedFromAdditionalFieldEvent)="
            fileRemovedFromAsToAdditionalFieldEventHandler($event)
          "
        >
        </fsx-participant-field>
        <!-- END: AsTo Associated Party -->
      </div>
    </ng-template>
  </div>
  <div class="additional-fields-container" *ngIf="showAdditionalFields">
    <fsx-additional-fields-component
      #additionalFields
      *ngFor="
        let addlFieldSpec of additionalFieldsDefinition;
        let index = index
      "
      [combinedFilingData]="combinedFilingData"
      [additionalFieldSpec]="addlFieldSpec"
      [initialValues]="
        resolver.getAdditionalFieldInitialValue(
          addlFieldSpec,
          additionalFieldValues
        )
      "
      [existingAdditionalValues]="additionalFieldValues"
      [resolver]="resolver"
      [fileUploadDocumentInfos]="documentFileUploadDocumentInfos"
      (additionalFieldsValuesEmitter)="
        setAdditionalFieldValues($event, documentsGridRow)
      "
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromDocumentAdditionalFieldEventHandler($event)
      "
      (fileRemovedFromAdditionalFieldEvent)="
        fileRemovedFromDocumentAdditionalFieldEventHandler($event)
      "
    >
    </fsx-additional-fields-component>
  </div>
</div>
