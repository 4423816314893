import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  delay,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  CasePartyViewModel,
  CombinedFilingData,
  ContactFormGroup,
  ContactProfile,
  ContactViewModel,
  FsxContactApiService,
  IContactApiService,
  NewContactViewModel,
  ParticipantFormMode,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { FsxPanelService, IPanelService } from './panel.service';
import {
  FsxContactFormService,
  IContactFormService,
} from '../../contacts/contact-form/contact-form.service';
import { FormGroup } from '@angular/forms';
import {
  FsxContactsListService,
  IContactsListService,
} from '../../contacts/contacts-list/contacts-list.service';
import {
  FsxEditParticipantOrchestrationService,
  IEditParticipantOrchestrationService,
} from '../../parties/orchestration-services/edit-participant-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxFormGroupTransformService,
  IFormGroupTransformService,
} from './form-group-transform.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IApplyParticipantFormUpdatesOrchestrationService to use at runtime.
 */
export const FsxApplyParticipantFormUpdatesOrchestrationService =
  new InjectionToken<IApplyParticipantFormUpdatesOrchestrationService>(
    'FsxApplyParticipantFormUpdatesOrchestrationService'
  );

/**
 * The parameters to pass to the orchestration service so that it can do its work.
 */
export interface ApplyParticipantFormUpdatesParams {
  /**
   * The mode in which to apply the FormGroup updates to the RequestParticipant object.
   */
  formMode: ParticipantFormMode;

  /**
   * The FormGroup containing the updates to apply to the RequestParticipant object.
   */
  participantFormGroup: FormGroup<ContactFormGroup>;

  /**
   * The RequestParticipant object to apply the FormGroup updates to.
   */
  participant: RequestParticipantViewModel | null;

  /**
   * The boolean value indicating whether we should create a contact or not.
   */
  createAsContact: boolean;
}

/**
 * A blueprint for an orchestration service, which applies participant
 * form group updates to a RequestParticipant object on a CaseRequest object.
 */
export interface IApplyParticipantFormUpdatesOrchestrationService {
  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean>;

  /**
   * The orchestration steps needed to update a single participant object.
   */
  applyParticipantFormUpdatesOrchestration$: Observable<void>;
  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  applyParticipantFormUpdates(params: ApplyParticipantFormUpdatesParams): void;
}

/**
 * A concrete implementation an orchestration service, which applies participant
 * form group updates to a RequestParticipant object on a CaseRequest object.
 */
@Injectable()
export class ApplyParticipantFormUpdatesOrchestrationService
  implements IApplyParticipantFormUpdatesOrchestrationService
{
  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * stored in a BehaviorSubject.
   */
  private isOrchestrationInProgress$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean> =
    this.isOrchestrationInProgress$$.asObservable();

  /**
   * A subject to use as the trigger for the orchestration.
   */
  private applyUpdatesAction$ =
    new Subject<ApplyParticipantFormUpdatesParams>();

  /**
   * The orchestration steps needed to update a single participant object.
   */
  applyParticipantFormUpdatesOrchestration$: Observable<void> =
    this.applyUpdatesAction$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      switchMap(
        ([params, combinedFilingData]: [
          ApplyParticipantFormUpdatesParams,
          CombinedFilingData
        ]) => {
          // Conditionally save a new Contact.
          // An observable that returns the newly created contact (if created) or null (if none was created)

          const createAsContact$: Observable<ContactViewModel | null> =
            params.createAsContact
              ? this.contactApiService.getContactProfile().pipe(
                  switchMap((contactProfile: ContactProfile) => {
                    const newContact: NewContactViewModel =
                      this.formGroupTransformService.toNewContact(
                        params.participantFormGroup,
                        contactProfile,
                        combinedFilingData.filingProfile
                      );
                    return this.contactApiService.createContact(newContact);
                  })
                )
              : of(null);

          // Subscribe to the conditional observable using the switchMap.
          return createAsContact$.pipe(
            tap((contact: ContactViewModel | null) => {
              this.isOrchestrationInProgress$$.next(true);

              // Convert the form group to a RequestParticipant object. This gets passed to the orchestration
              // for editing on the CaseRequest object.
              const requestParticipant =
                this.contactFormService.transformToParticipant(
                  params.participant!,
                  params.participantFormGroup,
                  combinedFilingData.filingProfile
                );

              // If a contact was created then assign to the RequestParticipant's linkedContact property.
              if (contact) {
                requestParticipant.linkedContact = {
                  id: contact!.id,
                  caption: contact!.caption,
                  contactType: contact!.type,
                  clientNameText: contact!.effectiveClientNameText,
                };
              }

              // Lookup the CaseParty object associated with the RequestParticipant in the CaseRequest.parties collection.
              let caseParty: CasePartyViewModel | undefined =
                combinedFilingData.caseRequest.parties?.find(
                  (p: CasePartyViewModel) => {
                    return p.participantName === params.participant?.name;
                  }
                );

              // Apply the updates to the CaseParty object
              if (caseParty) {
                caseParty.caption = requestParticipant.caption;
                caseParty.caseId =
                  combinedFilingData.caseRequest.cases![0].caseId;
              }

              // Make the call to edit the RequestParticipant and CaseParty on the CaseRequest object.
              this.editParticipantOrchestrationService.editParticipant({
                filingId: combinedFilingData.filing.id,
                caseRequest: combinedFilingData.caseRequest,
                participant: requestParticipant,
                party: caseParty,
              });
            }),
            delay(1000),
            map(() => {
              this.panelService.closeCurrentDialog();
              this.isOrchestrationInProgress$$.next(false);
              return;
            })
          );
        }
      )
    );

  /**
   *
   * @param contactFormService The service which we use to attempt submission.
   * (May want to create a ParticipantFormSubmissionService containing just the submit method).
   *
   * @param panelService The utility service for opening/closing panels. Here we use it to
   * close the panel after an attempt to submit.
   */
  public constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxFormGroupTransformService)
    private readonly formGroupTransformService: IFormGroupTransformService,
    @Inject(FsxContactFormService)
    private readonly contactFormService: IContactFormService,
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxCombinedFilingDataService)
    readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxEditParticipantOrchestrationService)
    readonly editParticipantOrchestrationService: IEditParticipantOrchestrationService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The parameters needed to initiate this orchestration.
   */
  applyParticipantFormUpdates(params: ApplyParticipantFormUpdatesParams): void {
    this.applyUpdatesAction$.next(params);
  }
}
