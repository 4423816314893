import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minRequiredValidator(minRequired: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value || !control.touched) {
      return null;
    }
    return {
      invalidMinRequired: `You must select at least ${minRequired} ${
        minRequired === 1 ? 'option' : 'options'
      }`,
    };
  };
}
