import {
  ContactType,
  Actor,
  AddressViewModel,
  ContactAliasViewModel,
  EmailAddressViewModel,
  ContactOrganizationViewModel,
  PhoneViewModel,
  UserData,
  ContactPersonViewModel,
  IdentificationViewModel,
  ContactReferenceViewModel,
  IValidatable,
  ValidationDetail,
} from '@fsx/fsx-shared';

export interface JwtUser {
  email: string;
  family_name: string;
  fsx_organization_id: string;
  fsx_organization: string;
  fsx_organization_type_id: string;
  fsx_organization_type: string;
  fsx_user_type: ContactType;
  given_name: string;
  middle_name: string;
  name: string[];
  sub: string;
}

export class FsxContact implements IValidatable {
  type?: ContactType;
  caption?: string;
  user?: UserData;
  actor?: Actor;
  person?: ContactPersonViewModel;
  organization?: ContactOrganizationViewModel;
  addresses?: AddressViewModel[];
  emails?: EmailAddressViewModel[];
  identifications?: IdentificationViewModel[];
  phones?: PhoneViewModel[];
  aliases?: ContactAliasViewModel[];
  parentOrganization?: ContactReferenceViewModel;
  organizationId?: string;
  id?: string;
  createdBy?: string;
  createdAt?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  bookmarked?: boolean;
  effectiveClientNameText?: string;

  isValid!: boolean;
  // errors!: ValidationDetail[];

  constructor(private readonly data?: object) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export type ModifiedApiUser = Omit<FsxContact, 'organizationId'> & {
  organizationId?: string | null;
};

export type UserWithNullProps = {
  [P in keyof ModifiedApiUser]?: ModifiedApiUser[P] | null;
};
