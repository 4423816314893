import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  FieldCategory,
  IdentificationCommonCategory,
  ParticipantCategory,
  ParticipantCommonCategory,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FormControlWithoutModel,
  RepresentationGridRow,
  SelectionFieldType,
} from '@fsx/ui-components';
import { PartiesGridRow } from '../parties-grid/parties-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  EditRepresentationEventParams,
  ParticipantViewMinMaxValues,
} from '../parties-grid/parties-grid.component';

export interface RemoveRepresentationEventParams {
  representationToRemove: RequestParticipantRepresentationViewModel;
  partyToRemoveFrom: CasePartyViewModel;
}

export interface UpdateRepresentationEventParams {
  attorneyParticipantSpec: ParticipantSpec | null;
  attorneyParticipantCategory: ParticipantCategory | null;
  caseParty: CasePartyViewModel;
  representation: RequestParticipantRepresentationViewModel;
  additionalFields: AdditionalFieldValue[] | null;
}

@Component({
  selector: 'fsx-representation-grid-item',
  templateUrl: './representation-grid-item.component.html',
  styleUrls: ['./representation-grid-item.component.scss'],
})
export class RepresentationGridItemComponent implements OnInit, OnChanges {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() rowIndex!: number;
  @Input() repGridRow!: RepresentationGridRow;
  @Input() partyGridRow!: PartiesGridRow;
  @Input() expandedRowIndex!: number | null;
  @Input() participantSpecs!: ParticipantSpec[];
  @Input() attorneySpecs!: ParticipantSpec[];
  @Input() resolver!: FsxReferenceResolver;
  @Input() participantsMap!: Map<string, ParticipantViewMinMaxValues>;

  @Output() toggleExpandRowEvent = new EventEmitter<number>();
  @Output() removeRepresentationEvent =
    new EventEmitter<RemoveRepresentationEventParams>();
  @Output() updateRepresentationEvent =
    new EventEmitter<UpdateRepresentationEventParams>();
  @Output() editRepresentationEventHandler =
    new EventEmitter<EditRepresentationEventParams>();

  public attorneysList: DropdownOption<void>[] = [];
  public attorneysListFormControl!: FormControlWithoutModel;
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  public additionalFieldValues: AdditionalFieldValue[] = [];
  public repParticipantSpec!: ParticipantSpec;
  public isReadOnly: boolean = false;

  ngOnChanges() {
    if (this.combinedFilingData) {
      this.repParticipantSpec =
        this.combinedFilingData?.modeSpec.participant.find(
          (pSpec: ParticipantSpec) => {
            return (
              pSpec.participantCategory.name ===
              this.repGridRow.representation?.participantCategory?.name
            );
          }
        )!;
    }
    // if (!!this.repGridRow) {
    //   if (this.repGridRow.representation.caption !== this.repGridRow.participant.caption) {
    //     this.repGridRow.representation.caption = this.repGridRow.participant.caption;
    //   }
    // }
    this.setReadOnly();
  }

  ngOnInit(): void {
    this.setAttorneyList(this.attorneySpecs);
    if (this.repGridRow.representation.additionalFieldValues) {
      this.additionalFieldValues =
        this.repGridRow.representation.additionalFieldValues;
    }
    this.setReadOnly();
  }

  public onAttorneyDropdownClicked(event: Event) {
    event.stopPropagation();
  }

  public setBasicAttorneyFormControl(controls: FormControlWithoutModel) {
    this.attorneysListFormControl = controls;
  }

  private setAttorneyList(participantSpecs: ParticipantSpec[]): void {
    this.attorneysList = participantSpecs.map((pSpec: ParticipantSpec) => {
      return { ...pSpec.participantCategory, selected: false };
    });
  }

  onToggleExpandRow(event: Event) {
    event.stopPropagation();
    this.toggleExpandRowEvent.emit(this.rowIndex);
  }

  getBarNumber(repGridRow: RepresentationGridRow): string {
    return (
      repGridRow.participant.identifications.find(
        (id) =>
          id.category.commonCategory === IdentificationCommonCategory.BarNumber
      )?.identificationKey ?? ''
    );
  }

  onAttorneysListClicked(event: Event) {
    event.stopPropagation();
  }

  public setAdditionalFieldValues(
    value: AdditionalFieldValue,
    partyIndex: number,
    repGridRow: RepresentationGridRow
  ) {
    this.resolver.updateAdditionalFieldValues(
      this.additionalFieldValues,
      value
    );
    repGridRow.representation.additionalFieldValues =
      this.additionalFieldValues;
    this.resolver.updateCaseRequestPutForRepresentation(partyIndex, repGridRow);
  }

  onAttorneyTypeSelected(params: {
    value: string;
    caseParty: CasePartyViewModel;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }) {
    // Guard cause to prevent infinite loop (of re-setting and re-emitting the initial value)
    const isSameValue =
      params.representation.participantCategory?.name === params.value;

    if (isSameValue) {
      return;
    }

    const attorneyParticipantSpec: ParticipantSpec = this.participantSpecs.find(
      (pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === params.value;
      }
    )!;

    const selectedOption = this.attorneysList.find(
      (option) => option.name === params.value
    );

    if (selectedOption) {
      const attorneyParticipantCategory: ParticipantCategory = {
        name: selectedOption.name,
        caption: selectedOption.caption,
        commonCategory:
          selectedOption.commonCategory as ParticipantCommonCategory,
      };

      this.updateRepresentationEvent.emit({
        ...params,
        attorneyParticipantSpec,
        attorneyParticipantCategory,
      });
    }
  }

  onRemoveRepresentationClicked(
    event: Event,
    rep: RequestParticipantRepresentationViewModel,
    party: CasePartyViewModel
  ) {
    event.stopPropagation();
    this.removeRepresentationEvent.emit({
      partyToRemoveFrom: party,
      representationToRemove: rep,
    });
  }

  onRepresentationClicked(): void {
    this.editRepresentationEventHandler.emit({
      representation: this.repGridRow.representation,
      participant: this.repGridRow.participant,
      participantCategory: this.repGridRow.representation.participantCategory,
    });
  }

  private setReadOnly(): void {
    if (
      !!this.repGridRow &&
      !!this.repGridRow.representation &&
      !!this.participantsMap
    ) {
      if (
        this.participantsMap.has(this.repGridRow.representation.participantName)
      ) {
        this.isReadOnly = this.participantsMap.get(
          this.repGridRow.representation.participantName
        )?.isReadOnly as boolean;
      }
    }
  }
}
