import {
  AuthConfig,
  LoginOptions,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';

// Important: Request offline_access to get a refresh token
// The api scope is a usecase specific one
export const authCodeFlowConfig = (
  identityServerBaseUrl: string
): AuthConfig => {
  return {
    clearHashAfterLogin: false,
    clientId: 'efsp',
    issuer: `${identityServerBaseUrl}`,
    sessionChecksEnabled: false,
    loginUrl: `${identityServerBaseUrl}/Account/Login`,
    logoutUrl: `${identityServerBaseUrl}/Account/Login`,
    redirectUri: `${window.location.origin}/index.html`,
    oidc: true,
    responseType: 'code',
    scope: 'openid profile organization efsp offline_access',
    showDebugInformation: true,
    useSilentRefresh: true,
  };
};

export const tryLoginOptions: LoginOptions = {
  /**
   * Set this to true to disable the oauth2 state check which is a best practice to avoid
   * security attacks. .. As OIDC defines a nonce check that includes
   * this, this can be set to true when only doing OIDC.
   */
  disableOAuth2StateCheck: true,
  /**
   * Set this to true to disable the nonce check which is used to avoid replay attacks.
   * This flag should never be true in production environments.
   */
  disableNonceCheck: false,
  /**
   * Normally, you want to clear your hash fragment after the lib reads the token(s) so that
   * they are not displayed anymore in the url. If not, set this to true. For code flow
   * this controls removing query string values.
   */
  preventClearHashAfterLogin: true,
  /**
   * Set this for code flow if you used a custom redirect Uri when retrieving the code. This is
   * used internally for silent refresh and popup flows.
   */
  // customRedirectUri: '',
};

export const authModuleConfig = (
  identityServerBaseUrl: string
): OAuthModuleConfig => {
  return {
    resourceServer: {
      allowedUrls: [`${identityServerBaseUrl}`],
      sendAccessToken: true,
    },
  };
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}
