<form
  class="fsx-email-component"
  *ngFor="let emailFormGroup of emailFormArray.controls; let index = index"
>
  <ng-container *ngIf="emailComponentSpec">
    <fsx-profile-single-selection
      #categoryField
      *ngIf="emailComponentSpec.category"
      [caption]="'Category'"
      [fieldType]="fieldType.Selection"
      [width]="'250px'"
      [resolver]="resolver"
      [fieldDefinition]="emailComponentSpec.category"
      [selectionType]="selectionType"
      [initialValue]="initialValues[index] ? initialValues[index].category : ''"
      (formControlEmitter)="setControl($event, 'category', index)"
    ></fsx-profile-single-selection>

    <fsx-text-component
      #emailAddressField
      *ngIf="emailComponentSpec.address"
      [caption]="'Address'"
      [width]="'250px'"
      [resolver]="resolver"
      [fieldDefinition]="emailComponentSpec.address"
      [initialValue]="initialValues[index] ? initialValues[index].address : ''"
      (formControlEmitter)="setControl($event, 'address', index)"
    ></fsx-text-component>

    <button
      *ngIf="emailFormArray.length > emailFormArray.formArraySpecs.minRequired"
      class="icon-button"
      [ngClass]="{ disabled: emailFormArray.disabled }"
      (keydown.enter)="delete(index); preventEvent($event)"
      (click)="delete(index)"
    >
      <div class="form-delete-button"></div>
    </button>
  </ng-container>
</form>

<button
  class="icon-button"
  [ngClass]="{ disabled: emailFormArray.disabled }"
  (keydown.enter)="addNewForm(); preventEvent($event)"
  (click)="addNewForm()"
  *ngIf="
    emailFormArray.length < emailFormArray.formArraySpecs.maxAllowed &&
    showAddNewForm
  "
>
  <p class="add-new-form">
    <span class="material-icons add-icon"> add_circle_outline </span>
    <span>Add&nbsp;</span>
    <span *ngIf="emailFormArray.length > 0">Another&nbsp;</span>
    <span>Email</span>
  </p>
</button>
