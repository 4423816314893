import { IConfig } from '@microsoft/applicationinsights-common';
import { IConfiguration } from '@microsoft/applicationinsights-core-js';
import { authEndPoints, AuthEndPoints } from './auth.endpoints';
import { caseEndPoints, CaseEndPoints } from './case.endpoints';
import {
  courtSelectionEndPoints,
  CourtSelectionEndpoints,
} from './court.endpoints';
import { documentEndPoints, DocumentEndPoints } from './document.endpoints';
import { filingEndPoints, FilingEndPoints } from './filing.endpoints';
import { groupEndPoints, GroupEndPoints } from './group.endpoints';
import {
  participantEndPoints,
  ParticipantEndPoints,
} from './participant.endpoints';
import { userEndPoints, UserEndPoints } from './user.endpoints';
import { BehaviorSubject } from 'rxjs';
import { contactEndPoints, ContactEndPoints } from './contact.endpoints';

export enum ENVIRONMENT {
  DEV = 'dev',
  PROD = 'production',
  LOCAL = 'local',
}
export enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2',
}

export enum FeatureFlags {
  AUTOMATE_LOCAL_DEV = 'automateLocalDev',
}

export type FeatureFlagsOptions = 'automateLocalDev';

export interface UserAuthEndpoints extends AuthEndPoints, UserEndPoints {}

export interface FSXEndpoints {
  contact: ContactEndPoints;
  user: UserAuthEndpoints;
  filing: FilingEndPoints;
  court: CourtSelectionEndpoints;
  cases: CaseEndPoints;
  participant: ParticipantEndPoints;
  document: DocumentEndPoints;
  group: GroupEndPoints;
}

export interface EnvConfig {
  ApiServer: {
    BaseURL: string;
  };
  API_VERSION: ApiVersion;
  ApplicationInsights?: {
    InstrumentationKey?: IConfiguration & IConfig;
  };
  CONFIG_FILE: ENVIRONMENT;
  Endpoints: FSXEndpoints;
  Environment: {
    Env: ENVIRONMENT;
  };
  IdentityServer: {
    BaseURL: string;
  };
  MAX_FILE_SIZE: number;
  NG_APP_VERSION: string;
  production: boolean;
  featureFlags?: Record<FeatureFlags, BehaviorSubject<boolean>>;
}

export const endpoints = (versionedUrl: string): FSXEndpoints => {
  return {
    contact: contactEndPoints(versionedUrl),
    cases: caseEndPoints(versionedUrl),
    court: courtSelectionEndPoints(versionedUrl),
    document: documentEndPoints(versionedUrl),
    filing: filingEndPoints(versionedUrl),
    group: groupEndPoints(versionedUrl),
    participant: participantEndPoints(versionedUrl),
    user: { ...authEndPoints(versionedUrl), ...userEndPoints(versionedUrl) },
  };
};

export interface MockCourtData {
  TEST_EFM_COURT_ID?: string;
  TEST_EFM_COURT_CAPTION?: string;
}
