import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IValidatable, DateTimeFieldDefinition } from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import moment from 'moment-timezone';

export const FsxDateTimeValidationService =
  new InjectionToken<IDateTimeValidationService>(
    'FsxDateTimeValidationService'
  );

export interface IDateTimeValidationService {
  validateDateTime(
    value: string | null | undefined,
    spec: DateTimeFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean;
}

@Injectable()
export class DateTimeValidationService implements IDateTimeValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService
  ) {}

  public validateDateTime(
    value: string | null | undefined,
    spec: DateTimeFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!value) {
      if (spec.required) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    const dateTimeValue = moment(value);
    const tzDateTimeValue = dateTimeValue.tz(spec.timeZone);
    const dateRangeStart = tzDateTimeValue.add(
      spec.daysToDateRangeStart,
      'days'
    );
    const dateRangeEnd = tzDateTimeValue.add(spec.daysToDateRangeEnd, 'days');

    if (dateTimeValue < dateRangeStart) {
      scope.isValid = false;
      return false;
    }

    if (dateTimeValue > dateRangeEnd) {
      scope.isValid = false;
      return false;
    }

    return true;
  }
}
