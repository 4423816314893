import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseFileUploadComponent,
  IUploadedFile,
} from './base-file-upload.component';

@Component({
  selector: 'fsx-file-upload-zone',
  templateUrl: './file-upload-zone.component.html',
  styleUrls: ['./file-upload-zone.component.scss'],
})
export class FileUploadZoneComponent extends BaseFileUploadComponent {
  @ViewChild('fileDropRef4') fileDropEl!: ElementRef;
  @Output() filesUploadedEvent: EventEmitter<IUploadedFile[]> =
    new EventEmitter<IUploadedFile[]>();

  override onFileDropped(files: FileList): void {
    super.prepareFilesList(files);
    this.filesUploadedEvent.emit(this.files);
  }
}
