import { Component, Inject, Injector, Input } from '@angular/core';

import { SidePanelButtonsConfig } from '@fsx/ui-components';
import { ContactsSearchTypeEnum } from './contacts.model';
import {
  FsxPanelService,
  IPanelService,
} from '../shared/services/panel.service';

@Component({
  selector: 'fsx-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  @Input() actionButtons: SidePanelButtonsConfig[] = [];
  @Input() searchType!: ContactsSearchTypeEnum;

  constructor(
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    private readonly injector: Injector
  ) {}

  public openContactsList(): void {
    this.panelService.openContactsListPanel({
      contactsListConfig: {
        searchType: this.searchType,
        addCallback: () => {
          return;
        },
      },
      injector: this.injector,
    });
  }
}
