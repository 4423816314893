import { Injectable, InjectionToken } from '@angular/core';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

export const FsxCaseRequestDataService =
  new InjectionToken<ICaseRequestDataService>('FsxCaseRequestDataService');

/**
 * A blueprint for a state service, which stores a CaseRequestViewModel object as a single
 * source of truth that we can safely expose derivations from.
 */
export interface ICaseRequestDataService {
  /**
   * A public member, which exposes the CaseRequestViewModel object as an Observable
   */
  caseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A public method, which sets the CaseRequestViewModel object
   */
  setCaseRequestData(caseRequest: CaseRequestViewModel): void;
}

/**
 * A concrete implementation of a state service, which stores a CaseRequestViewModel object
 * as a single source of truth that we can safely expose derivations from.
 */
@Injectable()
export class CaseRequestDataService implements ICaseRequestDataService {
  /**
   * A private member, which stores the CaseRequestViewModel object in a BehaviorSubject
   */
  private caseRequest$$ = new BehaviorSubject<CaseRequestViewModel | null>(
    null
  );

  /**
   * A public member, which exposes the CaseRequestViewModel object as an Observable
   */
  caseRequest$: Observable<CaseRequestViewModel> = this.caseRequest$$
    .asObservable()
    .pipe(
      filter((caseRequest) => caseRequest !== null),
      map((caseRequest) => caseRequest as CaseRequestViewModel)
    );

  /**
   * A public method, which sets the CaseRequestViewModel object
   *
   * @param caseRequest The CaseRequestViewModel object to store
   */
  setCaseRequestData(caseRequest: CaseRequestViewModel): void {
    const newCaseRequestObj = JSON.parse(JSON.stringify(caseRequest));
    this.caseRequest$$.next(newCaseRequestObj);
  }
}
