import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[fsxFocusInput]',
})
export class FocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    if (!el.nativeElement['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
      if (input) {
        input.focus();
      }
    });
  }
}
