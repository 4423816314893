export enum OLD_ROUTE {
  ALERTS = 'alerts',
  alerts = 'ALERTS',
  AUTH_CALLBACK = 'auth-callback',
  CASE_PARTIES = 'case-parties',
  PARTIES = 'parties',
  CASE = 'case',
  DOCUMENTS = 'documents',
  FILING = 'filings',
  HELP = 'help',
  HOME = 'home',
  LOGIN = 'login',
  'NOT_AUTHORIZED' = '401',
  'NOT_FOUND' = '404',
  PREVIEW = 'preview',
  REPORT = 'report',
  REVIEW = 'review',
  ROOT = '',
  SETTINGS = 'settings',
  SEARCH = 'search',
  TRANSACTIONS = 'transactions',
  TRANSACTIONS_GRID = 'transactions-list',
  TRANSACTIONS_REJECTED = 'rejected',
  TRANSACTIONS_SAVED = 'saved',
  TRANSACTIONS_SCHEDULED = 'scheduled',
}

export enum ROUTE {
  HOME = 'home',
  TRANSACTIONS = 'transactions',
  NEW_FILING = 'new-filing',
  HELP = 'help',
}

export enum CHILD_ROUTE {
  SUBMITTED = 'submitted',
  ACTIVITY = 'activity',
  EDITOR = 'editor',
  DRAFTS = 'drafts',
  DOCUMENT = 'document',
}
export const DOCUMENT_ID = 'documentId';
export const TRANSACTION_ID = 'filingId';
export const TRANSACTIONS_LIST = `${ROUTE.TRANSACTIONS}/${CHILD_ROUTE.SUBMITTED}`;
export const TRANSACTIONS_ACTIVITY = `${ROUTE.TRANSACTIONS}/${CHILD_ROUTE.ACTIVITY}`;
export const TRANSACTIONS_EDITOR = `${ROUTE.TRANSACTIONS}/${CHILD_ROUTE.EDITOR}`;

export enum FILING_SUB_TABS {
  DETAILS = 'details',
  PARTIES = 'parties',
  DOCUMENTS = 'documents',
  REVIEW = 'review',
  SERVICE = 'service',
}

export interface FilingSubTabItem {
  name: FILING_SUB_TABS;
  caption: string;
  isValid: boolean;
  isActive: boolean;
  tabIndex: number;
}

export const TRANSACTIONS_ROUTE = OLD_ROUTE.TRANSACTIONS;
export const FILING_ROUTE = OLD_ROUTE.FILING;
export const CASE_ROUTE = OLD_ROUTE.CASE;
export const DOCUMENTS_ROUTE = OLD_ROUTE.DOCUMENTS;
export const CASE_PARTIES_ROUTE = OLD_ROUTE.CASE_PARTIES;
export const REVIEW_ROUTE = OLD_ROUTE.REVIEW;
export const REPORT_ROUTE = OLD_ROUTE.REPORT;

export type FilingTabs =
  | OLD_ROUTE.DOCUMENTS
  | OLD_ROUTE.CASE_PARTIES
  | OLD_ROUTE.REVIEW;
