import { Injectable, InjectionToken } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { Filing, OpenFilingService } from '@fsx/fsx-shared';

export const FsxOpenFilingOrchestrationService =
  new InjectionToken<IOpenFilingOrchestrationService>(
    'FsxOpenFilingOrchestrationService'
  );

export interface IOpenFilingOrchestrationService {
  openFilingInFilingEditor$: Observable<Filing>;
  openFiling(filingToOpen: Filing): void;
}

@Injectable()
export class OpenFilingOrchestrationService
  implements IOpenFilingOrchestrationService
{
  private openFilingParams$$ = new Subject<Filing>();

  openFilingInFilingEditor$ = this.openFilingParams$$.pipe(
    switchMap((filing: Filing) => {
      return this.openFilingService.openFiling(filing);
    })
  );

  constructor(private readonly openFilingService: OpenFilingService) {}

  openFiling(filingToOpen: Filing): void {
    this.openFilingParams$$.next(filingToOpen);
  }
}
