import {
  CasePartyViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { RepresentationGridRow } from '../../../../../../libs/components/src/lib';

export class PartiesGridRow {
  rowIndex: number;
  party: CasePartyViewModel;
  participant: RequestParticipantViewModel;
  representationGridRows: RepresentationGridRow[];
  partyIndex: number;

  constructor(
    rowIndex: number,
    party: CasePartyViewModel,
    participant: RequestParticipantViewModel,
    representationGridRows: RepresentationGridRow[],
    partyIndex: number
  ) {
    this.rowIndex = rowIndex;
    this.party = party;
    this.participant = participant;
    this.representationGridRows = representationGridRows;
    this.partyIndex = partyIndex;
  }
}
