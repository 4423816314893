import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, of, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxClearRepresentationOrchestrationService =
  new InjectionToken<IClearRepresentationOrchestrationService>(
    'FsxClearRepresentationOrchestrationService'
  );

export interface IClearRepresentationParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  caseParty: CasePartyViewModel;
}

export interface IClearRepresentationOrchestrationService {
  clearRepresentationToCaseRequest$: Observable<CaseRequestViewModel>;
  clearRepresentation(params: IClearRepresentationParams): void;
}

@Injectable()
export class ClearRepresentationOrchestrationService
  implements IClearRepresentationOrchestrationService
{
  private clearRepresentation$$ = new Subject<IClearRepresentationParams>();

  clearRepresentationToCaseRequest$: Observable<CaseRequestViewModel> =
    this.clearRepresentation$$.pipe(
      switchMap((params: IClearRepresentationParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return of(params.caseRequest).pipe(
          switchMap(() => {
            return this.caseRequestBuilderService
              .removeRepresentationAndParticipants({
                ...params,
                partyToRemoveFrom: params.caseParty,
              })
              .pipe(
                switchMap(() => {
                  params.caseRequest.parties = params.caseRequest.parties?.map(
                    (party: CasePartyViewModel) => {
                      return params.caseParty.participantName ===
                        party.participantName
                        ? ({
                            ...party,
                            representation: [],
                            isSelfRepresented: true,
                          } as unknown as CasePartyViewModel)
                        : party;
                    }
                  );

                  return this.caseRequestUpdateService
                    .optimisticPutOrRestore(
                      params.filingId,
                      params.caseRequest,
                      caseRequestBackup
                    )
                    .pipe(
                      tap(() => {
                        this.validatePartiesOrchestrationService.validateParties();
                      })
                    );
                })
              );
          })
        );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  clearRepresentation(params: IClearRepresentationParams): void {
    this.clearRepresentation$$.next(params);
  }
}
