import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CaseAction, Cases } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-shared-global-filing-buttons',
  template: `
    <div fxLayout="row wrap" class="filing-options">
      <button
        *ngFor="let fxsCase of caseData"
        class="filing-options__button"
        mat-flat-button
        color="primary"
        (click)="action(fxsCase.value)"
      >
        <mat-icon
          class="filing-options__button-icon"
          svgIcon="{{ fxsCase.svgIcon }}"
          style=""
        ></mat-icon>
        <div class="filing-options__action-label">{{ fxsCase.label }}</div>
      </button>
    </div>
  `,
  styleUrls: ['./global-filing-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalFilingButtonsComponent {
  @Output() newCase = new EventEmitter<string>();
  @Output() existingCase = new EventEmitter<string>();
  public caseData: Cases[] = [
    {
      imgSrc: 'new-filing',
      label: 'New Case',
      svgIcon: 'colored-new',
      value: CaseAction.NEW_CASE,
    },
    {
      imgSrc: 'existing-filing',
      label: 'Existing Case',
      svgIcon: 'colored-existing',
      value: CaseAction.EXISTING_CASE,
    },
    {
      imgSrc: 'multiple-filings',
      label: 'Multiple Cases',
      svgIcon: 'colored-multiple',
      value: CaseAction.MULTIPLE_CASES,
    },
  ];

  public action(value: CaseAction | undefined): void {
    switch (value) {
      case CaseAction.NEW_CASE:
        this.newCase.emit();
        break;
      case CaseAction.EXISTING_CASE:
        this.existingCase.emit();
        break;
      case CaseAction.MULTIPLE_CASES:
        break;
    }
  }
}
