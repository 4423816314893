import { Observable } from 'rxjs';
import {
  RequestParticipantViewModel,
  CaseSummary,
  PersonalNameViewModel,
} from '@fsx/fsx-shared';

export type SubmitType = 'file' | 'serve' | 'file-and-serve' | 'next';
export type ProfileId = string;
export type CaseNumber = string;

export type IdType = CaseNumber | ProfileId;
export type SearchType = 'caseNo' | 'filingProfileId';

export enum CaseFilingType {
  COURT = 'court',
  PROFILE = 'profile',
}

export enum CaseFilingContext {
  OPF = 'newCase',
  SUBF = 'existingCase',
  MULTIPLE = 'multiCase',
}

export interface ParticipantRepresentation {
  firm: string;
  attorneys: string[];
}

export interface UiCaseParticipant extends RequestParticipantViewModel {
  rowId: number;
  personName: PersonalNameViewModel;
}

export interface FsxCaseSummary extends CaseSummary {
  abbrvTitle?: string;
  rowId: number;
  title: string;
}

export interface FrequentUsedFiling<T> {
  frequentlyUsed$: Observable<T[]>;
  frequentlyUsedSelected(item: T): void;
}

export interface TabRoute {
  disabled: boolean;
  readonly name: string;
  readonly path: string;
  count?: number;
}

export const searchCaseTabs: TabRoute[] = [
  {
    name: 'Search by Cases',
    path: '#search-cases',
    count: 0,
    disabled: false,
  },
  {
    name: 'Search by Group',
    path: '#search-group',
    count: 0,
    disabled: true,
  },
  {
    name: 'Cases in Filing',
    path: '#cases-in-filing',
    count: 0,
    disabled: true,
  },
];

export interface CaseFilterCriteria {
  id: IdType;
  searchType: SearchType;
}

export interface IdOptionsCaseSearch {
  caseNumber?: string | null;
  profileId?: string | null;
}

export interface CaseFilingFormSubmit {
  submitType: SubmitType;
  workflowType: CaseFilingContext | null;
  formFilingType: CaseFilingType | null;
}
