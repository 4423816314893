import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxPanel,
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import { ContactFormMode, ContactSummaryViewModel } from '@fsx/fsx-shared';
import {
  FsxContactsListTwoService,
  IContactsListTwoService,
} from '../contacts-list-two/contacts-list-two.service';
import { FsxPanelConfig } from '../../shared/components/fsx-panel/fsx-panel.component';
import { map, take, tap, merge, takeUntil, Subject } from 'rxjs';
import {
  FsxSelectedContactsService,
  ISelectedContactsService,
} from '../contacts-list/selected-contacts.service';
import {
  FsxOpenContactFormOrchestrationService,
  IOpenContactFormOrchestrationService,
} from '../../shared/services/open-contact-form-orchestration.service';
import { ContactsListConfig } from '../contacts-list-two/contacts-list-two.component';
import { ContactsSearchTypeEnum } from '../contacts.model';

/**
 * A very simple container component that contains an instance of the ContactsListComponent.
 * This allows us to open the contacts list in a panel, and keep the contacts list decoupled
 * from all things panel related.
 */
@Component({
  selector: 'fsx-contacts-list-panel',
  templateUrl: 'contacts-list-panel.component.html',
  styleUrls: ['./contacts-list-panel.component.scss'],
})
export class ContactsListPanelComponent implements FsxPanel, OnInit, OnDestroy {
  /**
   * A subject to trigger the tearing donw of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * The config object to pass to the header panel component.
   */
  panelHeaderConfig = {
    /**
     * The search type (contacts or attprneys), which the header panel component uses
     * to determine what header text to display. (Not this container's responsibility)
     */
    searchType: this.data.searchType,
  };

  /**
   * The observable stream of contact summaries to feed into the
   * contact list component when it emits.
   */
  contactSummaries$ = this.contactListTwoService.contactsData$;

  /**
   * The observable boolean value indicating whether we can submit the form or not.
   * Used in the footer to enable/disable the Add button.
   */
  canSubmitForm$ = this.selectedContactsService.selectedContacts$.pipe(
    map((contactSummaries: ContactSummaryViewModel[]) => {
      const canSubmitForm = contactSummaries.length > 0;
      return canSubmitForm;
    })
  );

  /**
   * The config object to pass to the FsxPanelComponent to override styles.
   */
  panelConfig: FsxPanelConfig = {
    /**
     * Override the defaukt header backgriund, for contacts panel we want grey-blue background.
     */
    headerBackgroundClass: 'grey-blue',

    /**
     * Explicitly set the content section indentation to 'no-indent' which ensures
     * that the nested contact list will appear full width in the panel.
     */
    contentIndentClass: 'no-indent',

    /**
     * Connect the panel loading indicator to the isLoadingContacts$
     * and isOrchestrationInProgress$ streams.
     */
    showLoadingIndicator$: merge(
      this.contactListTwoService.isLoadingContacts$,
      this.openContactFormOrchestrationService.isOrchestrationInProgress$
    ),
  };

  /**
   * @param data The contact list config properties to pass into the contacts list component.
   * @param panelService The panel service, which allows us to close the current panel.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContactsListConfig,
    @Inject(FsxPanelService) public readonly panelService: IPanelService,
    @Inject(FsxContactsListTwoService)
    public readonly contactListTwoService: IContactsListTwoService,
    @Inject(FsxCombinedFilingDataService)
    public readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxSelectedContactsService)
    readonly selectedContactsService: ISelectedContactsService,
    @Inject(FsxOpenContactFormOrchestrationService)
    private readonly openContactFormOrchestrationService: IOpenContactFormOrchestrationService
  ) {
    // Subscribe to the open contacts form orchestration stream. This allows us
    // to opem the form for adding/editing contacts from handler functions below.
    this.openContactFormOrchestrationService.openContactFormOrchestration$
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * The lifecycle hook where we set the filters in the service for either a contact
   * search or an attorney search.
   */
  ngOnInit(): void {
    // Using setTimeout here to allow the showLoadingIndicator$ subscription in the
    // fsx-panel to be setup before triggering the load. The panel's loading indicator
    // will not display without this.
    setTimeout(() => {
      this.data.searchType === ContactsSearchTypeEnum.contacts
        ? this.contactListTwoService.setContactFilters()
        : this.contactListTwoService.setAttorneyFilters();
    });
  }

  /**
   * The lifecycle hook where we teardown inner subscriptions with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A handler function for the FsxPanelComponent's defaultPanelEvent, which is raised from
   * the panel when the user presses the enter key. In most cases this will trigger
   * the same orchestration as one of the buttons we pass in.
   */
  defaultPanelEventHandler(): void {
    this.invokeAddCallbackAndCloseDialog();
  }

  /**
   * A handler function for the Add button's click event.
   */
  onAddButtonClicked() {
    this.invokeAddCallbackAndCloseDialog();
  }

  /**
   * A private method to trigger the add callback that was passed in from the opening component.
   * Here we invoke the callback using the selections held in the service. We then clear the
   * selections in the service and go on to close the dialog.
   */
  private invokeAddCallbackAndCloseDialog() {
    this.selectedContactsService.selectedContacts$
      .pipe(
        take(1),
        tap((contactSummaries: ContactSummaryViewModel[]) => {
          this.data.addCallback(contactSummaries);
          this.closeFormAndClearSelections();
        })
      )
      .subscribe();
  }

  /**
   * A handler function for the FsxPanelComponent's closePanelEvent event. More often than
   * not we just want to close the current dialog; that's all we need to do here right now.
   */
  closePanelEventHandler(): void {
    this.closeFormAndClearSelections();
  }

  /**
   * A handler function for the Cancel button's click event.
   */
  onCancelButtonClicked(): void {
    this.closeFormAndClearSelections();
  }

  /**
   * A private function to close the current dialog and clear
   * any selected contacts in the dialog.
   */
  private closeFormAndClearSelections(): void {
    this.panelService.closeCurrentDialog();
    this.selectedContactsService.clearSelectedContacts();
  }

  /**
   * A handler function for the contact form panel's editContactEvent.
   *
   * @param id the id of the contact to load into the edit contact form.
   */
  editContactEventHandler(id: string): void {
    this.openContactFormOrchestrationService.openContactForm({
      formMode: ContactFormMode.EditContact,
      contactId: id,
    });
  }

  /**
   * A handler function for the ContactListActionComponent's addNewContactEvent.
   */
  addNewContactEventHandler(): void {
    const formMode: ContactFormMode = ContactFormMode.AddContact;
    this.openContactFormOrchestrationService.openContactForm({ formMode });
  }
}
