<div
  *ngIf="errorCount > 0"
  class="errors-container"
  [ngClass]="[filterClass, displayClass]"
>
  <div
    class="errors-section"
    [ngClass]="filterClass"
    title="{{ errorCountTitle }}"
    (click)="filterErrorRows($event)"
  >
    <span class="error-count" *ngIf="filterClass === ''">{{
      errorCountDisplay
    }}</span>
    <span class="error-count" *ngIf="filterClass !== ''">X</span>
  </div>
</div>
