export interface ParticipantEndPoints {
  createParticipant: (filingId: number) => string;
  createParticipantFromExisting: (filingId: number) => string;
  updateParticipant: (filingId: number, participantId: string) => string;
  deleteParticipant: (filingId: number, participantId: string) => string;
  getParticipants: (filingId: number) => string;
  attorneys: (filingId: number, participantId: string) => string;
  searchExistingParticipants: () => string;
}

export const participantEndPoints = (
  versionedUrl: string
): ParticipantEndPoints => {
  const baseUrl = (filingId: number) =>
    `${versionedUrl}/filings/${filingId}/participants`;
  return {
    createParticipant: (filingId) => baseUrl(filingId),

    createParticipantFromExisting: (filingId) =>
      `${baseUrl(filingId)}/existing`,

    getParticipants: (filingId) => `${baseUrl(filingId)}/search`,

    updateParticipant: (filingId, participantId) =>
      `${baseUrl(filingId)}/${participantId}`,

    deleteParticipant: (filingId, participantId) =>
      `${baseUrl(filingId)}/${participantId}`,

    attorneys: (filingId, participantId) =>
      `${baseUrl(filingId)}/${participantId}/attorneys`,

    searchExistingParticipants: () =>
      `${versionedUrl}/participants/party/search`,
  };
};
