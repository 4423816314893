<div fxLayout="row" fxLayoutAlign="start center">
  <img
    id="search-contacts-icon"
    [src]="'../../../assets/icons/search_contacts.svg'"
    alt="search contacts icon"
  />
  <h1 class="dark-blue">
    {{ vm.title }}
  </h1>
</div>
