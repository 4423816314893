import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  AddressViewModel,
  AddressSpec,
  IValidatable,
  AddressProfileSpec,
  FilingProfile,
} from '../../../types';
import {
  FsxSelectionFieldValidationService,
  ISelectionFieldValidationService,
} from './selection-field-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';

export const FsxAddressValidationService =
  new InjectionToken<IAddressValidationService>('FsxAddressValidationService');

export interface IAddressValidationService {
  validateAddresses(
    addresses: AddressViewModel[] | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;

  validateAddress(
    address: AddressViewModel | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class AddressValidationService implements IAddressValidationService {
  constructor(
    @Inject(FsxSelectionFieldValidationService)
    private readonly selectionFieldValidationService: ISelectionFieldValidationService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService
  ) {}

  public validateAddresses(
    addresses: AddressViewModel[] | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!addresses) {
      addresses = [];
    }

    if (addresses.length < spec.minRequired) {
      scope.isValid = false;
      return false;
    }

    if (addresses.length > spec.maxAllowed) {
      scope.isValid = false;
      return false;
    }

    for (let address of addresses) {
      if (!this.validateAddress(address, spec, scope, filingProfile)) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }

  public validateAddress(
    address: AddressViewModel | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec || !address) {
      return true;
    }

    address.isValid = true;

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        address?.category?.name,
        spec.category,
        scope,
        filingProfile
      )
    ) {
      address.isValid = false;
      return false;
    }

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        address?.country,
        spec.country,
        scope,
        filingProfile
      )
    ) {
      address.isValid = false;
      return false;
    }

    const countrySpec = this.getAddressProfileSpec(
      spec,
      filingProfile,
      address.country
    );

    if (countrySpec) {
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.addressLine1,
          address.addressLine1
        )
      ) {
        scope.isValid = false;
        return false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.addressLine2,
          address.addressLine2
        )
      ) {
        scope.isValid = false;
        return false;
      }
      if (
        !this.selectionFieldValidationService.validateSelectionField(
          address.administrativeArea,
          countrySpec.administrativeArea,
          address,
          filingProfile
        )
      ) {
        scope.isValid = false;
        return false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.locality,
          address.locality
        )
      ) {
        scope.isValid = false;
        return false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.postalCode,
          address.postalCode
        )
      ) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }

  private getAddressProfileSpec(
    spec: AddressSpec,
    profile: FilingProfile,
    countryName: string
  ): AddressProfileSpec | null | undefined {
    if (!spec) {
      return null;
    }

    var addressProfileName = !spec.addressProfileName
      ? profile.defaultAddressProfileName
      : spec.addressProfileName;
    var addressProfile = profile.addressProfiles.find(
      (address) => address.name === addressProfileName
    );

    if (!addressProfile) {
      console.warn(
        `Invalid filing profile, cannot find address profile "${addressProfileName}"`
      );
    }

    if (!countryName) {
      return addressProfile?.spec;
    }

    const countrySpec = addressProfile?.countrySpecs.find(
      (p) => p.countryName === countryName
    );

    if (countrySpec) {
      return countrySpec.spec;
    }

    return addressProfile?.spec;
  }
}
