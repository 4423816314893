import { DraftFilingCategory, Operator } from '@fsx/fsx-shared';
import { Direction } from '@fsx/fsx-shared';
import { FilterColumnEnum } from 'projects/apps/fsx-ui/src/app/transactions/services/filings.service';

export type HttpQueryParam = HttpQueryParams | string | number;

// HttpQueryParams and PaginationQuery are used used for services before the transition to the new search format.
export interface HttpQueryParams {
  [key: string]: HttpQueryParam | HttpQueryParam[] | undefined | null;
}

export interface PaginationQuery extends HttpQueryParams {
  skip?: number;
  limit?: number;
  sort?: SortQueryParams[];
}

export interface SortQueryParams extends HttpQueryParams {
  column: string;
  direction: Direction;
}

/**
 * An enum to help specify where an applied filter came from.
 * This is important for when different column filters are applied
 * from the same control (e.g. Filings dropdown). In those cases we
 * want to remove any filter that originated from the same control
 * and apply a new one.
 */
export enum FsxApiFilterSourceControl {
  /** Even when the default filter is a status, it needs a different source control
   * than that of Status. Otherwise it will be removed prematurely with the app
   * thinking that the filter originated from the same source control. */
  DefaultFilter = 'DefaultFilter',
  SearchText = 'SearchTextFilterControl',
  Status = 'StatusFilterControl',
  DateRange = 'DateRangeFilterControl',
  FilingsDropdown = 'FilingsDropdownFilterControl',
}

export interface FsxApiFilter {
  column: FilterColumnEnum;
  sourceControl: FsxApiFilterSourceControl;
  operator: Operator | string;
  value1?: string | number | null | DraftFilingCategory;
  value2?: string | number;
  values?: (string | number)[];
  grouping?: number;
}

export interface FsxApiSort {
  column: string;
  direction: Direction;
}

// Generally a search would be a POST with the body being the query. The API will then return back a RecordSet<T>
// The keys are generally case-insensitive but we standardize in the UI on one format anyway
export interface FsxApiSearchQuery {
  filters: FsxApiFilter[] | null;
  exactTotal?: boolean;
  limit?: number;
  skip?: number;
  sort?: FsxApiSort[];
}

export interface ResultSet<T> {
  data: T[];
  limit: number;
  offset: number;
  pageSize: number;
  total: number;
}
