import { TextFieldDefinition } from '@fsx/fsx-shared';

export const participantClientNumberFieldDefinition: TextFieldDefinition = {
  required: false,
  minLength: 2,
  maxLength: 100,
  characterProfileName: 'alphanumeric',
  readOnly: true,
  visible: true,
};

export const participantPhonesFieldDefinition: TextFieldDefinition = {
  required: false,
  minLength: 2,
  maxLength: 100,
  characterProfileName: 'numeric',
  readOnly: true,
  visible: true,
};

export const participantEmailsFieldDefinition: TextFieldDefinition = {
  required: false,
  minLength: 2,
  maxLength: 100,
  characterProfileName: 'alphanumeric',
  readOnly: true,
  visible: true,
};

export const participantAddressFieldDefinition: TextFieldDefinition = {
  required: false,
  minLength: 2,
  maxLength: 100,
  characterProfileName: 'alphanumeric',
  readOnly: true,
  visible: true,
};

export const participantAliasFieldDefinition: TextFieldDefinition = {
  required: false,
  minLength: 2,
  maxLength: 100,
  characterProfileName: 'alphanumeric',
  readOnly: true,
  visible: true,
};
