<!-- START: Receipt Container -->
<div
  class="filing-submission-receipt-container"
  fxFlex
  *ngIf="showReceipt"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxFill
>
  <!-- START: Receipt Header -->
  <div
    fxFlex="none"
    fxLayout="column"
    fxLayoutAlign="start start"
    class="submission-header"
  >
    <!--  START: Header Title  -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
      <mat-icon
        fxFlex="none"
        class="icon"
        [svgIcon]="'cntr-check-circle-filled'"
      >
      </mat-icon>
      <div fxFlex class="title">Thank you for submitting your transaction</div>
    </div>
    <!--  END: Header Title  -->

    <!--  START: Header Sub-title 1 -->
    <div class="sub-title email">
      <span>Your submission receipt has been sent to:</span
      ><span class="sent-to">{{
        submissionReceiptDetail.filingDetails.sendToEmail
      }}</span>
    </div>
    <!--  END: Header Sub-title 1  -->

    <!--  START: Header Sub-title 2 -->
    <div class="sub-title details">
      Please click on
      <span class="transaction-details" (click)="routeToActivity()"
        >Submitted Transaction Details</span
      >
      to track, review, share documents and access your receipt.
    </div>
    <!--  END: Header Sub-title 2 -->

    <!-- START: Submission Receipt Button -->
    <div class="submission-btn">
      <button (click)="submissionReceiptBtnClicked()">
        <span class="text">Submission Receipt</span>
      </button>
    </div>
    <!-- END: Submission Receipt Button   -->
  </div>
  <!-- END : Receipt Header -->

  <!-- START: Receipt Transaction Info -->
  <div
    class="transaction-details"
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start end"
    fxLayoutGap="10px"
  >
    <div fxFlex fxLayout="column" fxLayoutAlign="end" fxLayoutGap="10px">
      <div fxFlex="none">
        <div class="info-value">
          {{ submissionReceiptDetail.filingDetails.transactionId }}
        </div>
        <div class="info-label">Transaction ID</div>
      </div>

      <div fxFlex="none">
        <div class="info-value">
          {{ submissionReceiptDetail.filingDetails.transactionMode }}
        </div>
        <div class="info-label">Transaction Mode</div>
      </div>

      <div fxFlex="none">
        <div class="info-value">
          {{ submissionReceiptDetail.filingDetails.submittedBy }}
        </div>
        <div class="info-label">Submitted By</div>
      </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="end" fxLayoutGap="10px">
      <div fxFlex="none">
        <div class="info-value">
          {{ submissionReceiptDetail.filingDetails.filingType }}
        </div>
        <div class="info-label">Type</div>
      </div>
      <div fxFlex="none">
        <div class="info-value">
          {{
            submissionReceiptDetail.filingDetails.submittedOn
              | date: "MM/dd/YYYY hh:mmaaa"
          }}
          {{
            !!submissionReceiptDetail.filingDetails.submittedOn
              ? getTimeZoneAbbreviation(
                  submissionReceiptDetail.filingDetails.submittedOn
                )
              : "[Date Submitted]"
          }}
        </div>
        <div class="info-label">Date Submitted</div>
      </div>
    </div>
  </div>
  <!-- END: Receipt Transaction Info -->

  <!-- START: Receipt Case Details  -->
  <div fxFlex="none" class="case-details" fxLayout="column" fxLayoutGap="8px">
    <header fxFlex="none" class="row-title">Case Details</header>

    <div fxFlex="none">
      <div class="info-value">
        {{ submissionReceiptDetail.caseDetails.courtName }}
      </div>
      <div class="info-label">Court</div>
    </div>

    <div fxFlex="none">
      <div class="info-value">
        {{ submissionReceiptDetail.caseDetails.caseClass }}
      </div>
      <div class="info-label">Case Class</div>
    </div>

    <div fxFlex="none">
      <div class="info-value">
        {{ submissionReceiptDetail.caseDetails.caseType }}
      </div>
      <div class="info-label">Case Type</div>
    </div>

    <div fxFlex="none">
      <div class="info-value">
        {{ submissionReceiptDetail.caseDetails.caseName }}
      </div>
      <div class="info-label">Case Name</div>
    </div>

    <div fxFlex="none">
      <div class="info-value">
        {{ submissionReceiptDetail.caseDetails.clientMatterNumber }}
      </div>
      <div class="info-label">Client/Matter Number</div>
    </div>
  </div>
  <!-- END: Receipt Case Details  -->
</div>
<!-- END: Receipt Container -->
