export function validNumericCharacters(value: string): boolean {
  return /^[0-9.,-]+$/.test(value);
}

export function validCurrencyCharacters(value: string): boolean {
  return (
    /^[$][0-9.,-]+$/.test(value) ||
    /^[$]\s+[0-9.,-]+$/.test(value) ||
    /^[0-9.,-]+$/.test(value)
  );
}
