import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  DocumentInfo,
  FilingProfile,
  RequestDocumentViewModel,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  IFilingApiService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxFilingApiService,
} from '@fsx/fsx-shared';
import {
  Subject,
  Observable,
  switchMap,
  combineLatest,
  forkJoin,
  of,
  mergeMap,
  map,
  filter,
  catchError,
  tap,
} from 'rxjs';
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from '../../filing-editor/services/filing-editor-events.service';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';

export const FsxRemoveDocumentOrchestrationService =
  new InjectionToken<IRemoveDocumentOrchestrationService>(
    'FsxRemoveDocumentOrchestrationService'
  );

export interface RequestDocumentAndDocumentInfo {
  requestDocument: RequestDocumentViewModel;
  documentInfo?: DocumentInfo;
}

export interface RemoveDocumentParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  filingProfile: FilingProfile;
  documentsToRemove: RequestDocumentAndDocumentInfo[];
}

export interface IRemoveDocumentOrchestrationService {
  removeDocumentStream$: Observable<CaseRequestViewModel>;
  removeDocument(params: RemoveDocumentParams): void;
}

@Injectable()
export class RemoveDocumentOrchestrationService
  implements IRemoveDocumentOrchestrationService
{
  private removeDocumentParams$$ = new Subject<RemoveDocumentParams>();
  private removeDocumentParams$ = this.removeDocumentParams$$
    .asObservable()
    .pipe();

  removeDocumentStream$: Observable<CaseRequestViewModel> =
    this.removeDocumentParams$.pipe(
      mergeMap((removeDocumentParams: RemoveDocumentParams) => {
        const fakeProfileRuleReached$ = of(false);
        const filingProfileGuards$ = forkJoin([
          fakeProfileRuleReached$,
          // isMinLeadDocumentsRequired$
        ]).pipe(
          filter((guardConditions) => {
            const noGuardConditionsReached = !guardConditions.includes(true);
            return noGuardConditionsReached;
          })
        );

        return filingProfileGuards$.pipe(
          tap(() => {
            // Dispatch the cancel upload event (incase we are uploading at the time of removal)
            removeDocumentParams.documentsToRemove.forEach(
              (reqDocAndDocInfo: RequestDocumentAndDocumentInfo) => {
                this.filingEditorEventService.dispatchCancelUploadEvent(
                  reqDocAndDocInfo.requestDocument
                );
              }
            );
          }),
          mergeMap(() => {
            const caseRequestBackup = {
              ...removeDocumentParams.caseRequest,
            } as CaseRequestViewModel;

            const arrayOfDocInfoDeletionAndReqDocRemovals$ =
              removeDocumentParams.documentsToRemove.map(
                (reqDocAndDocInfo: RequestDocumentAndDocumentInfo) => {
                  const deleteDocumentInfo$: Observable<DocumentInfo[]> =
                    reqDocAndDocInfo.documentInfo
                      ? this.filingApiService.deleteDocumentInfo(
                          removeDocumentParams.filingId,
                          reqDocAndDocInfo.documentInfo?.id!
                        )
                      : of([]);

                  const removeRequestDocument$: Observable<CaseRequestViewModel> =
                    this.caseRequestBuilderService
                      .removeRequestDocument({
                        caseRequest: removeDocumentParams.caseRequest,
                        requestDocument: reqDocAndDocInfo.requestDocument,
                      })
                      .pipe(
                        switchMap(() => {
                          this.validateDocumentsOrchestrationService.validateDocuments();

                          return this.caseRequestUpdateService.optimisticPutOrRestore(
                            removeDocumentParams.filingId,
                            removeDocumentParams.caseRequest,
                            caseRequestBackup,
                            true
                          );
                        })
                      );

                  const deleteDocInfoAndRemoveReqDoc$: Observable<
                    [DocumentInfo[], CaseRequestViewModel]
                  > = forkJoin([deleteDocumentInfo$, removeRequestDocument$]);
                  return deleteDocInfoAndRemoveReqDoc$;
                }
              );

            const docInfoDeletionAndReqDocRemovals$: Observable<
              [DocumentInfo[], CaseRequestViewModel][]
            > = combineLatest(arrayOfDocInfoDeletionAndReqDocRemovals$);

            return docInfoDeletionAndReqDocRemovals$.pipe(
              map(() => {
                return removeDocumentParams.caseRequest;
              }),
              catchError(() => {
                console.error(
                  'Delete Document Info And Remove Request Document Failed.'
                );
                return of(removeDocumentParams.caseRequest);
              })
            );
          })
        );
      })
    );

  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService
  ) {}

  removeDocument(params: RemoveDocumentParams): void {
    this.removeDocumentParams$$.next(params);
  }
}
