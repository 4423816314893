import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import {
  DocumentInfo,
  FsxFilingApiService,
  GetUriResult,
  IFilingApiService,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import {
  catchError,
  EMPTY,
  filter,
  from,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  DocumentsApiService,
  FsxDocumentApiService,
} from './documents-api.service';

// TODO: Code Smell. Think about which way to fix this. Shared services should not depend on feature level services
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/filing-editor-events.service';
import { IUploadedFile } from '@fsx/ui-components';

export const FsxUploadFileService = new InjectionToken<IUploadFileService>(
  'FsxUploadFileService'
);

export interface UploadFileParams {
  uploadedFile: IUploadedFile;
  filingId: string;
  documentId: string;
}

export interface FileUploadedParams {
  uploadedFile: IUploadedFile;
  documentInfo: DocumentInfo;
  filingId: string;
}

export interface IUploadFileService {
  fileUploaded$: Observable<FileUploadedParams>;
  uploadFile(params: UploadFileParams): Observable<FileUploadedParams>;
}

@Injectable()
export class UploadFileService implements IUploadFileService {
  private fileUploaded$$ = new Subject<FileUploadedParams>();
  fileUploaded$: Observable<FileUploadedParams> =
    this.fileUploaded$$.asObservable();

  constructor(
    @Inject(FsxDocumentApiService)
    private readonly documentsApiService: DocumentsApiService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  uploadFile(params: UploadFileParams): Observable<FileUploadedParams> {
    const { filingId, uploadedFile, documentId } = params;
    return this.documentsApiService.fetchSasToken(filingId).pipe(
      switchMap((uriResult: GetUriResult) => {
        const tempuri: string = uriResult.uri as string;
        const blockBlobClient: BlockBlobClient = new BlockBlobClient(tempuri);
        const uploadDataPromise: Promise<BlobUploadCommonResponse> =
          blockBlobClient.uploadData(uploadedFile.file);
        return from(uploadDataPromise).pipe(
          takeUntil(
            this.filingEditorEventService.cancelUpload$.pipe(
              filter((requestDocument: RequestDocumentViewModel) => {
                return requestDocument.id === documentId;
              }),
              switchMap(() => {
                return this.filingApiService.deleteDocumentInfo(
                  filingId,
                  documentId
                );
              })
            )
          ),
          switchMap(() => {
            return this.documentsApiService
              .importUploadedDocument(
                uploadedFile.file,
                tempuri,
                filingId,
                documentId
              )
              .pipe(
                map((documentInfo: DocumentInfo) => {
                  const fileUploadedParams: FileUploadedParams = {
                    documentInfo,
                    filingId,
                    uploadedFile,
                  };
                  this.fileUploaded$$.next(fileUploadedParams);
                  return fileUploadedParams;
                }),
                catchError(() => {
                  console.error('File Import Failed.');
                  return EMPTY;
                })
              );
          }),
          catchError(() => {
            console.error('Block Blob Client Upload Failed.');
            return EMPTY;
          })
        );
      })
    );
  }
}
