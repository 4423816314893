import { Input, Component, OnChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

// the FileList type is what we get from the DOM
export interface IUploadedFile {
  file: File;
  sourceId: string;
}

@Component({
  selector: 'fsx-base-search-component',
  template: '',
})
export class BaseFileUploadComponent implements OnChanges {
  @Input() small!: boolean;
  @Input() disabled!: boolean;
  @Input() permittedFileTypes: string[] = [];
  @Input() maxFileSize!: number;

  id!: string;
  files: IUploadedFile[] = [];

  permittedFileTypesStr!: string;

  constructor() {
    this.id = uuidv4();
  }

  ngOnChanges(): void {
    this.permittedFileTypesStr = this.permittedFileTypes.join(', ');
  }

  onFileDropped(files: FileList): void {
    if (this.disabled) {
      return;
    }
    this.prepareFilesList(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: Event): void {
    if (this.disabled) {
      return;
    }
    const inputTarget: HTMLInputElement = $event.target as HTMLInputElement;
    this.prepareFilesList(inputTarget.files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (FileList)
   */
  protected prepareFilesList(files: FileList | null): void {
    if (!files) {
      return;
    }

    this.files = [];

    Array.from(files).forEach((file: File) => {
      const newFile: IUploadedFile = {
        file: file,
        sourceId: this.id,
      };

      this.files.push(newFile);
    });
  }
}
