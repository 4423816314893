<div class="filing-tabs-container" fxLayout="row">
  <div
    class="filing-tab"
    [ngClass]="{ active: selectedTab === tab.filingId }"
    (click)="onTabClicked(tab)"
    *ngFor="let tab of filingTabs; let idx = index"
    fxLayout="row"
    fxLayoutAlign="space-between"
  >
    <span class="tab-text" [matTooltip]="tab.title">{{ tab.title }}</span>
    <button
      class="icon-button close-icon"
      (click)="onCloseButtonClicked($event, tab)"
    >
      <span class="material-icons exit">highlight_off</span>
    </button>
  </div>
  <span class="material-icons add-icon" [matMenuTriggerFor]="menu">
    add_circle
  </span>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onNewCaseButtonClicked()">
      <span>New Case Filing</span>
    </button>
    <button mat-menu-item (click)="onExistingCaseButtonClicked()">
      <span>Existing Filing</span>
    </button>
  </mat-menu>
</div>
