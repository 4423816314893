<div class="option-selected-card">
  <div class="icon-container" [ngClass]="cardIcon"></div>
  <div class="option-data">
    <p class="caption primary-caption">{{ primaryCaption }}</p>
    <p class="caption secondary-caption" *ngIf="secondaryCaption">
      {{ secondaryCaption }}
    </p>
    <p class="caption tertiary-caption" *ngIf="tertiaryCaption">
      {{ tertiaryCaption }}
    </p>
  </div>
  <button
    class="transparent"
    (keydown.enter)="resetCourtSelected()"
    (click)="resetCourtSelected()"
  >
    <div class="edit-container">
      <img src="../../../../assets/icons/edit-icon.svg" alt="edit" />
    </div>
  </button>
</div>
