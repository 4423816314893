import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[fsxPreventKeyboardEventBubbling]',
})
export class PreventKeyboardEventBubblingDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.addEventListener('keydown', (event: Event) => {
      event.stopImmediatePropagation();
    });
  }
}
