<div id="profile-selection-component">
  <div
    class="profile-selection-row"
    *ngFor="let dropdownOptions of dropdownOptionsArray; let index = index"
  >
    <fsx-basic-single-selection
      #selectionField
      *ngIf="dropdownOptions.length"
      [caption]="labelsArray[index]"
      [hint]="'Select Option'"
      [width]="'100%'"
      [inputDropdownOptions]="dropdownOptions"
      (formControlEmitter)="setControl($event)"
      (selectedValue)="selectedValue($event, index)"
      (clearValue)="clearDropdown(index)"
    ></fsx-basic-single-selection>
  </div>

  <div class="profile-selection-row" *ngIf="profileDropdownOptions.length">
    <fsx-basic-single-selection
      #caseTypeSelectionField
      [caption]="'Case Type'"
      [hint]="'Select Option'"
      [width]="'100%'"
      [inputDropdownOptions]="profileDropdownOptions"
      (selectedValue)="selectedValue($event, null)"
      (clearValue)="clearDropdown()"
      (formControlEmitter)="setControl($event)"
    ></fsx-basic-single-selection>
  </div>
</div>
