import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestParticipantViewModel,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
  CasePartyViewModel,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
} from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, Subject, of, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IEditParticipantOrchestrationService to use at runtime.
 */
export const FsxEditParticipantOrchestrationService =
  new InjectionToken<IEditParticipantOrchestrationService>(
    'FsxEditParticipantOrchestrationService'
  );

/**
 * The parameters needed to run the Edit Participant Orchestration.
 */
export interface IEditParticipantParams {
  /**
   * The filingId of the filing being edited.
   */
  filingId: string;

  /**
   * The caseRequest object on which the participant being edited resides.
   */
  caseRequest: CaseRequestViewModel;

  /**
   * The edited RequestParticipant object.
   */
  participant: RequestParticipantViewModel;

  /**
   * The edited CaseParty object.
   * (Will be undefined if the participant being edited is representation)
   */
  party: CasePartyViewModel | undefined;
}

/**
 * A blueprint for an orchestration service, which handles the editing of a RequestParticipant
 * object on a CaseRequest object for a given Filing. Triggered when the "Save" button is clicked
 * on the ParticpantFormPanelComponent whilst in EditParticipant mode.
 */
export interface IEditParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to apply RequestParticipant object edits on
   * a CaseRequest object.
   */
  editParticipantInCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  editParticipant(params: IEditParticipantParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the editing of a
 * RequestParticipant object on a CaseRequest object for a given Filing. Triggered when the
 * "Save" button is clicked on the ParticpantFormPanelComponent (in both Add and Edit modes).
 */
@Injectable()
export class EditParticipantOrchestrationService
  implements IEditParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private editParticipant$$ = new Subject<IEditParticipantParams>();

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * stored in a BehaviorSubject.
   */
  private isOrchestrationInProgress$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean> =
    this.isOrchestrationInProgress$$.asObservable();

  /**
   * The pipeline of orchestration steps needed to apply RequestParticipant object edits on
   * a CaseRequest object.
   */
  editParticipantInCaseRequest$: Observable<CaseRequestViewModel> =
    this.editParticipant$$.pipe(
      switchMap((params: IEditParticipantParams) => {
        this.isOrchestrationInProgress$$.next(true);
        const caseRequestBackup = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        const { filingId, caseRequest, participant, party } = params;

        return this.caseRequestBuilderService
          .setParticipantInCaseRequest({
            caseRequest,
            property: participant,
            participantName: participant.name,
          })
          .pipe(
            switchMap(() => {
              // Conditionally update the CaseParty object in the CaseRequest.parties collection. Return either the updated CaseRequest object
              // or the unchanged CaseRequest object (when party is undefined and there is no CaseParty to update).
              const setPartyInCaseRequest$: Observable<CaseRequestViewModel> =
                party
                  ? this.caseRequestBuilderService.setPartyInCaseRequest({
                      caseRequest,
                      property: party!,
                      participantName: participant.name,
                    })
                  : of(caseRequest);

              return setPartyInCaseRequest$.pipe(
                switchMap(() => {
                  // Make PUT request to apply the CaseRequest updates on the server.
                  return this.caseRequestUpdateService
                    .optimisticPutOrRestore(
                      filingId,
                      caseRequest,
                      caseRequestBackup
                    )
                    .pipe(
                      tap(() => {
                        this.validatePartiesOrchestrationService.validateParties();
                        this.isOrchestrationInProgress$$.next(false);
                      })
                    );
                })
              );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  editParticipant(params: IEditParticipantParams): void {
    this.editParticipant$$.next(params);
  }
}
