import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  ContactViewModel,
  ContactSummaryViewModel,
  FilingProfile,
  ParticipantCategory,
  ParticipantSpec,
  CasePartyViewModel,
  CreateContactsService,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { forkJoin, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { IAddContactAsParticipantParams } from './add-contact-as-participant-orchestration.service';
import { v4 as uuidv4 } from 'uuid';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxAddSelectedContactsAsParticipantsOrchestrationService =
  new InjectionToken<IAddSelectedContactsAsParticipantsOrchestrationService>(
    'FsxAddSelectedContactsAsParticipantsOrchestrationService'
  );

export interface IAddSelectedContactsAsParticpantsParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  contactSummaries: ContactSummaryViewModel[];
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
  participantName: string;
}

export interface IAddSelectedContactsAsParticipantsOrchestrationService {
  addParticipantsToCaseRequest$: Observable<CaseRequestViewModel>;
  addSelectedContactsAsParticipants(
    params: IAddSelectedContactsAsParticpantsParams
  ): void;
}

@Injectable()
export class AddSelectedContactsAsParticipantsOrchestrationService
  implements IAddSelectedContactsAsParticipantsOrchestrationService
{
  private addSelectedContactsAsParticipants$$ =
    new Subject<IAddSelectedContactsAsParticpantsParams>();

  addParticipantsToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addSelectedContactsAsParticipants$$.pipe(
      switchMap((params: IAddSelectedContactsAsParticpantsParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        // Remove the original default participant record (gets replaced below)...
        const partyToRemove: CasePartyViewModel | undefined =
          params.caseRequest?.parties?.find(
            (p) => p.participantName === params.participantName
          );
        return this.caseRequestBuilderService
          .removePartyAndParticipant({
            filingId: params.filingId,
            caseRequest: params.caseRequest,
            partyToRemove: partyToRemove!,
          })
          .pipe(
            switchMap(() => {
              // Convert array of ContactSummary to parties and particpants on the case request object...

              // Generate the names (ids) for the new records.
              const newParticipantNames: string[] = params.contactSummaries.map(
                () => uuidv4()
              );

              // Convert array of participantNames into array of addDefaultPartyAndParticipant$
              const arrayOfAddDefaultPartyAndParticipant: Observable<CaseRequestViewModel>[] =
                newParticipantNames.map((id: string) => {
                  return this.caseRequestBuilderService.addDefaultPartyAndParticipant(
                    id,
                    params.caseRequest,
                    params.participantCategory
                  );
                });

              // Use forkJoin to only return when all default parties and participants have been added
              const caseRequestWithPartiesAndParticpants$: Observable<CaseRequestViewModel> =
                forkJoin(arrayOfAddDefaultPartyAndParticipant).pipe(
                  map((caseRequests: CaseRequestViewModel[]) => {
                    // Return the last caseRequest after all default parties and particpants have been added
                    return caseRequests[caseRequests.length - 1];
                  })
                );

              return caseRequestWithPartiesAndParticpants$.pipe(
                switchMap(() => {
                  // Convert array of ContactSummary to array of Contacts...
                  return this.createContactsService
                    .createContactsFromContactSummaries(params.contactSummaries)
                    .pipe(
                      switchMap((contacts: ContactViewModel[]) => {
                        // Replace each newly created default party/participant on the caseRequest to
                        // populated parties/participants derived from the contacts (passed in above)...

                        const arrayOfSetPartyAndParticipants$: Observable<CaseRequestViewModel>[] =
                          contacts.map(
                            (contact: ContactViewModel, index: number) => {
                              // Lookup the participantName by index. This is safe because newParticipantNames is
                              // derived from sasme source as the contacts array (params.contactSummaries)
                              const newParticipantName =
                                newParticipantNames[index];

                              // Set the parameters for the add contact as participant operation
                              const addContactAsParticipantParams: IAddContactAsParticipantParams =
                                {
                                  filingId: params.filingId,
                                  caseRequest: params.caseRequest,
                                  contact: contact,
                                  participantName: newParticipantName,
                                  participantCategory:
                                    params.participantCategory,
                                  participantSpec: params.participantSpec,
                                  filingProfile: params.filingProfile,
                                };

                              // returns one instance of the set operation
                              return of(addContactAsParticipantParams).pipe(
                                switchMap(
                                  (
                                    addContactAsParticipantParams: IAddContactAsParticipantParams
                                  ) => {
                                    return this.caseRequestBuilderService.createPartyAndParticipantFromContactThenSetInCaseRequest(
                                      addContactAsParticipantParams
                                    );
                                  }
                                )
                              );
                            }
                          );

                        // Convert the array of observables into a single observable
                        const setPartiesAndParticipants$ = forkJoin(
                          arrayOfSetPartyAndParticipants$
                        );

                        // subscribe to execute all set operations (via enclosing switchMap)
                        return setPartiesAndParticipants$.pipe(
                          switchMap(() => {
                            return this.caseRequestUpdateService
                              .optimisticPutOrRestore(
                                params.filingId,
                                params.caseRequest,
                                caseRequestBackup
                              )
                              .pipe(
                                tap(() => {
                                  this.validatePartiesOrchestrationService.validateParties();
                                })
                              );
                          })
                        );
                      })
                    );
                })
              );
            })
          );
      })
    );

  constructor(
    private readonly createContactsService: CreateContactsService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService
  ) {}

  addSelectedContactsAsParticipants(
    params: IAddSelectedContactsAsParticpantsParams
  ): void {
    this.addSelectedContactsAsParticipants$$.next(params);
  }
}
