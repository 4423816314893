import { DocumentsGridRow } from '../documents-grid/documents-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import { Moment } from 'moment';
import {
  Observable,
  Subject,
  combineLatest,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DocumentInfoAndUploadedFile } from '../services/upload-additional-field-files-orchestration.service';
import {
  SimpleChanges,
  SimpleChange,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  Inject,
  Component,
} from '@angular/core';
import {
  RequestDocumentViewModel,
  CombinedFilingData,
  DocumentInfo,
  FieldCategory,
  TextFieldDefinition,
  DocumentCommonCategoryDomainCategoryValue,
  AdditionalFieldValue,
  AdditionalFieldSpec,
  DocumentSpec,
  NamedList,
  CasePartyViewModel,
  RequestParticipantViewModel,
  ParticipantFieldSpec,
  RequestDocumentParticipantViewModel,
  FilingProfileHelperService,
  ICaseRequestUpdateService,
  CreateDocumentInfoService,
  RequestDocumentCaseViewModel,
  FilingProfile,
  CaseRequestViewModel,
  NamedListItem,
  AccessCommonCategoryDomainCategoryValue,
  IFilingApiService,
  FsxCaseRequestUpdateService,
  FsxFilingApiService,
} from '@fsx/fsx-shared';
import {
  FilesUploadedEventParams,
  FsxTextComponent,
  FsxBasicSingleSelectionComponent,
  FsxParticipantComponent,
  SelectionFieldType,
  DropdownOption,
  SelectionFieldDefinition,
} from '@fsx/ui-components';

export enum DocumentTypeEnum {
  Lead = 'leadDocument',
  Supporting = 'supportingDocument',
}

export interface UpdateDocumentEventParams {
  documentIndex: number;
  requestDocument: RequestDocumentViewModel;
  partialRequestDocument: Partial<RequestDocumentViewModel>;
  combinedFilingData: CombinedFilingData | null;
}

interface DocumentFormComponentBindings extends SimpleChanges {
  validationTimestamp: SimpleChange;
}

export interface FilesUploadedFromAdditionalFieldEventParams
  extends FilesUploadedEventParams {
  documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[];
}

export interface FileRemovedFromAdditionalFieldEventParams {
  documentInfo: DocumentInfo;
  documentIndex: number;
}

@Component({
  selector: 'fsx-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnChanges, OnDestroy {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() validationTimestamp!: Moment;
  @Output() updateDocumentEvent = new EventEmitter<UpdateDocumentEventParams>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('fileNameField') fileNameField!: FsxTextComponent;
  @ViewChild('documentTypeField')
  documentTypeField!: FsxBasicSingleSelectionComponent;
  @ViewChild('accessTypeField')
  accessTypeField?: FsxBasicSingleSelectionComponent;
  @ViewChild('documentTitleField') documentTitleField!: FsxTextComponent;
  @ViewChildren('filedByField')
  filedByFields!: QueryList<FsxParticipantComponent>;
  @ViewChildren('asToField') asToFields!: QueryList<FsxParticipantComponent>;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxParticipantComponent>;

  resolver!: FsxReferenceResolver;
  fieldType = FieldCategory;
  selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  documentTypeList: DropdownOption<void>[] = [];
  accessTypeList: DropdownOption<void>[] = [];

  fileNameFieldDefinition!: TextFieldDefinition;
  documentTitleFieldDefinition!: TextFieldDefinition;

  private documentCategories!: DocumentCommonCategoryDomainCategoryValue[];

  public additionalFieldValues: AdditionalFieldValue[] = [];
  public additionalFieldsDefinition!: AdditionalFieldSpec[] | null | undefined;
  documentSpec: DocumentSpec | undefined;
  filedByAdditionalListName: string | undefined;
  asToAdditionalListName: string | undefined;
  filedByAdditionalList: NamedList | undefined;
  asToAdditionalList: NamedList | undefined;
  filedByParticipants: CasePartyViewModel[] | undefined;
  asToParticipants: CasePartyViewModel[] | undefined;
  filedByDropdownOptions: DropdownOption<void>[] = [];
  asToDropdownOptions: DropdownOption<void>[] = [];
  selectedFiledByParticipants: RequestParticipantViewModel[] = [];
  selectedAsToParticipants: RequestParticipantViewModel[] = [];
  isAddingAssociatedParty = false;
  filedByParticipantFieldSpec!: ParticipantFieldSpec;
  asToParticipantFieldSpec!: ParticipantFieldSpec;
  hasAssociatedParties: boolean = false;
  requiresAssociatedParties: boolean = false;
  accessTypeFieldDefinition!: SelectionFieldDefinition | null;
  associatedPartyAsTo!: RequestDocumentParticipantViewModel;
  associatedPartyFiledBy!: RequestDocumentParticipantViewModel;

  documentAdditionalFieldValues!: AdditionalFieldValue[];
  filedByAdditionalFieldValues!: AdditionalFieldValue[];
  asToAdditionalFieldValues!: AdditionalFieldValue[];
  documentFileUploadDocumentInfos!: DocumentInfo[];
  filedByFileUploadDocumentInfos!: DocumentInfo[];
  asToFileUploadDocumentInfos!: DocumentInfo[];

  showAdditionalFields = false;

  constructor(
    private filingProfileHelperService: FilingProfileHelperService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    private readonly createDocumentInfoService: CreateDocumentInfoService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(bindings: DocumentFormComponentBindings) {
    if (this.combinedFilingData) {
      const { filingProfile, caseRequest } = this.combinedFilingData;
      const isLeadDocument =
        this.documentsGridRow.requestDocument.isLeadDocument;

      this.setDocumentTypeList(this.combinedFilingData, isLeadDocument);
      this.setAccessTypeList(filingProfile);

      this.resolver = new FsxReferenceResolver(filingProfile, {
        filingApi: this.filingApiService,
        filingId: this.combinedFilingData?.filing.id,
        caseRequestUpdateService: this.caseRequestUpdateService,
        cfd: this.combinedFilingData,
      });

      if (this.documentsGridRow.requestDocument.isNew) {
        this.documentsGridRow.requestDocument.isNew = false;
      }

      if (
        bindings.validationTimestamp &&
        bindings.validationTimestamp.currentValue &&
        !bindings.validationTimestamp.isFirstChange()
      ) {
        this.validate();
      }

      this.fileNameFieldDefinition =
        this.filingProfileHelperService.getFirstDocumentSpecFileNameTextFieldDefinition(
          this.combinedFilingData,
          isLeadDocument
        )!;
      this.documentTitleFieldDefinition =
        this.filingProfileHelperService.getFirstDocumentSpecTitleTextFieldDefinition(
          this.combinedFilingData,
          isLeadDocument
        )!;

      // DocumentSpec doesn't yet have accessCategory as a SelectionFieldDefinition
      // see https://dev.azure.com/fileandserve/FileAndServeXpress/_workitems/edit/148563
      // So this hack is just to allow us to check for the property so it will appear once
      // that case is done, in which case we can revert to standard property access
      this.accessTypeFieldDefinition =
        filingProfile.documentAccessCategories.length > 0
          ? ({} as SelectionFieldDefinition)
          : null;

      // Set the selected as to and filed by participants...

      const requestDocumentCases: RequestDocumentCaseViewModel[] =
        this.documentsGridRow.requestDocument.cases || [];

      this.selectedAsToParticipants = requestDocumentCases.flatMap(
        (rdq: RequestDocumentCaseViewModel) => {
          return rdq.filedAsTo!?.map(
            (rdq: RequestDocumentParticipantViewModel) => {
              // Get asTo Participants to be able to edit and save to it's appropriate additional fields
              this.associatedPartyAsTo = rdq;
              const particpants: RequestParticipantViewModel[] =
                this.combinedFilingData?.caseRequest.participants || [];
              const asToParticipant: RequestParticipantViewModel | undefined =
                particpants.find((p: RequestParticipantViewModel) => {
                  return rdq.participantName === p.name;
                });
              return asToParticipant!;
            }
          );
        }
      );

      this.selectedFiledByParticipants = requestDocumentCases.flatMap(
        (rdq: RequestDocumentCaseViewModel) => {
          return rdq.filedBy!?.map(
            (rdq: RequestDocumentParticipantViewModel) => {
              // Get filedBy Participants to be able to edit and save to it's appropriate additional fields
              this.associatedPartyFiledBy = rdq;
              const particpants: RequestParticipantViewModel[] =
                this.combinedFilingData?.caseRequest.participants || [];
              const filedByParticipant:
                | RequestParticipantViewModel
                | undefined = particpants.find(
                (p: RequestParticipantViewModel) => {
                  return rdq.participantName === p.name;
                }
              );
              return filedByParticipant!;
            }
          );
        }
      );

      if (requestDocumentCases[0].additionalFieldValues) {
        this.additionalFieldValues =
          requestDocumentCases[0].additionalFieldValues;
      }
      // Set the DocumentSpec here...
      const docType = this.documentsGridRow.requestDocument.category?.caption;
      if (this.documentsGridRow.requestDocument.isLeadDocument) {
        if (docType) {
          this.documentSpec =
            this.combinedFilingData.modeSpec?.leadDocument.find((leadDoc) => {
              return leadDoc.documentCategory.caption === docType;
            });
        } else {
          this.documentSpec = this.combinedFilingData.modeSpec?.leadDocument[0];
        }
      } else {
        if (docType) {
          this.documentSpec =
            this.combinedFilingData.modeSpec?.supportingDocument.find(
              (leadDoc) => {
                return leadDoc.documentCategory.caption === docType;
              }
            );
        } else {
          this.documentSpec =
            this.combinedFilingData.modeSpec?.supportingDocument[0];
        }
      }
      this.asToParticipantFieldSpec = this.documentSpec!?.asTo!;
      this.filedByParticipantFieldSpec = this.documentSpec!?.filedBy!;

      // Set additional fields definition
      this.additionalFieldsDefinition = this.documentSpec?.additionalFields;

      // Set the filedBy and AsTo additional list names (to look up next)
      if (this.documentSpec) {
        this.filedByAdditionalListName =
          this.documentSpec.filedBy?.participantFieldDefinition.allowedParticipantCategoriesList.additionalListName;
        this.asToAdditionalListName =
          this.documentSpec.asTo?.participantFieldDefinition.allowedParticipantCategoriesList.additionalListName;
        this.setRequiresAssociatedParties(this.documentSpec);
      }

      // Lookup and set Filed By dropdown options...
      this.setFiledByDropdownOptions(filingProfile, caseRequest);

      // Lookup and set As To dropdown options...
      this.setAsToDropdownOptions(filingProfile, caseRequest);
      ('');
      // Check if there are any associated parties (asTo or FiledBy)
      requestDocumentCases.map((rdc: RequestDocumentCaseViewModel) => {
        const asToParticipants: RequestDocumentParticipantViewModel[] =
          rdc.filedAsTo || [];
        const filedByParticipants: RequestDocumentParticipantViewModel[] =
          rdc.filedBy || [];
        if (!this.hasAssociatedParties) {
          this.hasAssociatedParties =
            [...asToParticipants, ...filedByParticipants].length > 0;
        }
      });

      const caseRequestDocuments: RequestDocumentViewModel[] =
        this.combinedFilingData!?.caseRequest!.documents || [];
      const documentInfos = this.combinedFilingData.documentInfos || [];

      const docIndex: number = this.documentsGridRow.rowIndex;
      const documentCases: RequestDocumentCaseViewModel[] =
        caseRequestDocuments[docIndex].cases || [];
      if (documentCases.length > 0) {
        this.documentAdditionalFieldValues =
          documentCases[0].additionalFieldValues || [];
        const documentFileValues: string[] =
          this.documentAdditionalFieldValues.flatMap(
            (addlFieldValue: AdditionalFieldValue) => {
              return addlFieldValue.fileValues || [];
            }
          );
        this.documentFileUploadDocumentInfos = documentInfos.filter(
          (docInfo: DocumentInfo) => {
            return documentFileValues.includes(docInfo.id);
          }
        );

        const filedByParticipants: RequestDocumentParticipantViewModel[] =
          documentCases[0].filedBy || [];
        if (filedByParticipants.length > 0) {
          this.filedByAdditionalFieldValues =
            filedByParticipants[0].additionalFieldValues || [];
          this.filedByFileUploadDocumentInfos =
            this.getFileUploadDocmentInfosFromAdditionaFieldValues(
              this.filedByAdditionalFieldValues,
              documentInfos
            );
        }

        const asToParticipants: RequestDocumentParticipantViewModel[] =
          documentCases[0].filedAsTo || [];
        if (asToParticipants.length > 0) {
          this.asToAdditionalFieldValues =
            asToParticipants[0].additionalFieldValues || [];
          this.asToFileUploadDocumentInfos =
            this.getFileUploadDocmentInfosFromAdditionaFieldValues(
              this.asToAdditionalFieldValues,
              documentInfos
            );
        }
      }
    }
    if (this.documentsGridRow) {
      this.showAdditionalFields =
        !!this.documentsGridRow.requestDocument.category?.name;
    }
  }

  private getFileUploadDocmentInfosFromAdditionaFieldValues(
    additionalFieldValues: AdditionalFieldValue[],
    documentInfos: DocumentInfo[]
  ): DocumentInfo[] {
    if (additionalFieldValues) {
      const filedByFileValues: string[] = additionalFieldValues.flatMap(
        (addlFieldValue: AdditionalFieldValue) => {
          return addlFieldValue.fileValues || [];
        }
      );
      return documentInfos.filter((docInfo: DocumentInfo) => {
        return filedByFileValues.includes(docInfo.id);
      });
    }

    return [];
  }

  private setRequiresAssociatedParties(documentSpec: DocumentSpec): void {
    this.requiresAssociatedParties =
      (documentSpec.filedBy &&
        documentSpec.filedBy.participantFieldDefinition.minRequired > 0) ||
      (documentSpec.asTo &&
        documentSpec.asTo.participantFieldDefinition.minRequired > 0) ||
      false;
  }

  private setFiledByDropdownOptions(
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ) {
    // Set the filedByAdditionalList
    if (this.filedByAdditionalListName) {
      this.filedByAdditionalList = filingProfile.additionalLists.find(
        (list) => {
          return list.name === this.filedByAdditionalListName;
        }
      );
    }

    // Filter the participants by the looked up filedByAdditionalList allowed category names
    this.filedByParticipants = caseRequest.parties?.filter(
      (party: CasePartyViewModel) => {
        // Check to include only those parties with an allowed participant category
        const allowedNames: string[] =
          this.filedByAdditionalList?.items.map(
            (namedListItem: NamedListItem) => namedListItem.name
          ) || [];
        const isAllowedCategoryName: boolean = allowedNames.some(
          (name: string) => name === party.participantCategory?.name
        );

        // Check to exclude new particpants if the spec doesn't allow them.
        const specAllowNewParticipants: boolean | undefined =
          this.documentSpec?.filedBy?.participantFieldDefinition
            .allowNewParticipants;
        const allowNewParticipants = !!specAllowNewParticipants;
        const isNewParticipant = party.efmKey === null;
        const isAllowedNewParticipant =
          allowNewParticipants && isNewParticipant;

        // Check to exclude existing particpants if the spec doesn't allow them.
        const specAllowExistingParticipants: boolean | undefined =
          this.documentSpec?.filedBy?.participantFieldDefinition
            .allowExistingParticipants;
        const allowExistingParticipants = !!specAllowExistingParticipants;
        const isExistingParticipant = party.efmKey !== null;
        const isAllowedExistingParticipant =
          allowExistingParticipants && isExistingParticipant;

        // Check to exclude default participants.
        const isDefaultParticipant = !party.caption;

        // Return the party only if all test conditions pass
        const result =
          isAllowedCategoryName &&
          (isAllowedNewParticipant || isAllowedExistingParticipant) &&
          !isDefaultParticipant;
        return result;
      }
    );

    // Set filedBy dropdown options
    this.filedByDropdownOptions =
      this.filedByParticipants?.map((p) => {
        const dropdownOption: DropdownOption<void> = {
          name: p.participantName,
          caption: p.caption,
          selected: false,
        };
        return dropdownOption;
      }) || [];
  }

  private setAsToDropdownOptions(
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ) {
    // Set the asToAdditionalList
    if (this.asToAdditionalListName) {
      this.asToAdditionalList = filingProfile.additionalLists.find((list) => {
        return list.name === this.asToAdditionalListName;
      });
    }

    // Filter the participants by the looked up asToAdditionalList allowed category names
    this.asToParticipants = caseRequest.parties?.filter(
      (party: CasePartyViewModel) => {
        const allowedNames: string[] =
          this.asToAdditionalList?.items.map(
            (namedListItem: NamedListItem) => namedListItem.name
          ) || [];
        const isAllowedCategoryName: boolean = allowedNames.some(
          (name: string) => name === party.participantCategory?.name
        );

        // Check to exclude new particpants if the spec doesn't allow them.
        const specAllowNewParticipants: boolean | undefined =
          this.documentSpec?.asTo?.participantFieldDefinition
            .allowNewParticipants;
        const allowNewParticipants = !!specAllowNewParticipants;
        const isNewParticipant = party.efmKey === null;
        const isAllowedNewParticipant =
          allowNewParticipants && isNewParticipant;

        // Check to exclude existing particpants if the spec doesn't allow them.
        const specAllowExistingParticipants: boolean | undefined =
          this.documentSpec?.asTo?.participantFieldDefinition
            .allowExistingParticipants;
        const allowExistingParticipants = !!specAllowExistingParticipants;
        const isExistingParticipant = party.efmKey !== null;
        const isAllowedExistingParticipant =
          allowExistingParticipants && isExistingParticipant;

        // Check to exclude default participants.
        const isDefaultParticipant = !party.caption;

        // Return the party only if all test conditions pass
        const result =
          isAllowedCategoryName &&
          (isAllowedNewParticipant || isAllowedExistingParticipant) &&
          !isDefaultParticipant;
        return result;
      }
    );

    // Set asTo dropdown options
    this.asToDropdownOptions =
      this.asToParticipants?.map((p) => {
        const dropdownOption: DropdownOption<void> = {
          name: p.participantName,
          caption: p.caption,
          selected: false,
        };
        return dropdownOption;
      }) || [];
  }

  private setDocumentTypeList(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ) {
    this.documentCategories =
      this.filingProfileHelperService.geDocumentCategories(
        combinedFilingData,
        isLeadDocument
      );
    this.documentTypeList = this.documentCategories.map(
      (documentCommonCategory: DocumentCommonCategoryDomainCategoryValue) => {
        return { ...documentCommonCategory, selected: false };
      }
    );
  }

  private setAccessTypeList(filingProfile: FilingProfile) {
    this.accessTypeList =
      filingProfile.documentAccessCategories.map(
        (accessCommonCategory: AccessCommonCategoryDomainCategoryValue) => {
          return { ...accessCommonCategory, selected: false };
        }
      ) || [];
  }

  public setAdditionalFieldValues(
    value: AdditionalFieldValue,
    documentsGridRow: DocumentsGridRow
  ) {
    this.resolver.updateAdditionalFieldValues(
      this.additionalFieldValues,
      value
    );

    if (documentsGridRow.requestDocument.cases) {
      documentsGridRow.requestDocument.cases[0].additionalFieldValues =
        this.additionalFieldValues;
    }

    if (this.combinedFilingData?.caseRequest.documents) {
      this.combinedFilingData.caseRequest.documents[documentsGridRow.rowIndex] =
        documentsGridRow.requestDocument;
      this.resolver.updateCaseRequestPut(
        this.combinedFilingData.caseRequest,
        this.combinedFilingData.filing.id
      );
    }
  }

  onDocumentTypeSelected(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    // Guard clause to prevent infinite loop
    const isSameValue = params.requestDocument.category?.name === params.value;
    if (isSameValue) {
      return;
    }

    const documentCategory = this.documentCategories.find(
      (documentCat: DocumentCommonCategoryDomainCategoryValue) => {
        return documentCat.name === params.value;
      }
    );

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      category: documentCategory,
    };

    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onAccessTypeSelected(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    // Guard clause to prevent infinite loop
    const isSameValue =
      params.requestDocument.accessCategoryName === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      accessCategoryName: params.value,
    };

    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onFileNameTextChanged(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const isSameValue = params.requestDocument.fileName === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      fileName: params.value,
    };

    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onDocumentTitleTextChanged(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const isSameValue = params.requestDocument.title === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      title: params.value,
    };

    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onAddAssociatedPartyClicked() {
    this.isAddingAssociatedParty = true;
  }

  onAsToRemoved(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      params.requestDocument.cases || [];
    const requestDocumentCase: RequestDocumentCaseViewModel =
      requestDocumentCases[0];
    requestDocumentCase.filedAsTo = requestDocumentCase.filedAsTo!?.filter(
      (rdp: RequestDocumentParticipantViewModel) => {
        return rdp.participantName !== params.selectedParticipant.name;
      }
    );

    const otherRequestDocumentCases = requestDocumentCases.filter(
      (rdc: RequestDocumentCaseViewModel) =>
        rdc.caseId !== requestDocumentCase.caseId
    );
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: [...otherRequestDocumentCases, requestDocumentCase],
    };

    // persist changes here
    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onFiledByRemoved(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      params.requestDocument.cases || [];
    const requestDocumentCase: RequestDocumentCaseViewModel =
      requestDocumentCases[0];
    requestDocumentCase.filedBy = requestDocumentCase.filedBy!?.filter(
      (rdp: RequestDocumentParticipantViewModel) => {
        return rdp.participantName !== params.selectedParticipant.name;
      }
    );

    const otherRequestDocumentCases = requestDocumentCases.filter(
      (rdc: RequestDocumentCaseViewModel) =>
        rdc.caseId !== requestDocumentCase.caseId
    );
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: [...otherRequestDocumentCases, requestDocumentCase],
    };

    // persist changes here
    this.updateDocumentEvent.emit({
      ...params,
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
    });
  }

  onAsToSelected(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    if (params.selectedParticipant) {
      this.selectedAsToParticipants.push(params.selectedParticipant);
      const requestDocumentCases: RequestDocumentCaseViewModel[] =
        params.requestDocument.cases || [];

      // Index accessor okay as we always create one requestDocumentCase with the RequestDocument
      const requestDocumentCase: RequestDocumentCaseViewModel =
        requestDocumentCases[0];

      const requestDocumentParticipant: RequestDocumentParticipantViewModel = {
        participantName: params.selectedParticipant.name,
        additionalFieldValues: [],
        isValid: true,
      };

      requestDocumentCase.filedAsTo!.push(requestDocumentParticipant);
      const otherRequestDocumentCases = requestDocumentCases.filter(
        (rdc: RequestDocumentCaseViewModel) =>
          rdc.caseId !== requestDocumentCase.caseId
      );
      const partialRequestDocument: Partial<RequestDocumentViewModel> = {
        cases: [...otherRequestDocumentCases, requestDocumentCase],
      };

      // persist changes here
      this.updateDocumentEvent.emit({
        ...params,
        partialRequestDocument,
        combinedFilingData: this.combinedFilingData,
      });
    }
  }

  onFiledBySelected(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    if (params.selectedParticipant) {
      this.selectedFiledByParticipants.push(params.selectedParticipant);

      let requestDocumentCases: RequestDocumentCaseViewModel[] =
        params.requestDocument.cases || [];

      // Index accessor okay as we always create one requestDocumentCase with the RequestDocument
      const requestDocumentCase: RequestDocumentCaseViewModel =
        requestDocumentCases[0];

      const requestDocumentParticipant: RequestDocumentParticipantViewModel = {
        participantName: params.selectedParticipant.name,
        additionalFieldValues: [],
        isValid: true,
      };

      requestDocumentCase.filedBy!?.push(requestDocumentParticipant);
      const otherRequestDocumentCases = requestDocumentCases.filter(
        (rdc: RequestDocumentCaseViewModel) =>
          rdc.caseId !== requestDocumentCase.caseId
      );
      const partialRequestDocument: Partial<RequestDocumentViewModel> = {
        cases: [...otherRequestDocumentCases, requestDocumentCase],
      };

      // persist changes here
      this.updateDocumentEvent.emit({
        ...params,
        partialRequestDocument,
        combinedFilingData: this.combinedFilingData,
      });
    }
  }

  clearFiledByParticipantEventHandler(
    participantToClear: RequestParticipantViewModel
  ) {
    this.selectedFiledByParticipants = this.selectedFiledByParticipants.filter(
      (p) => {
        return p.name !== participantToClear.name;
      }
    );
  }

  public filesUploadedFromDocumentAdditionalFieldEventHandler(
    params: FilesUploadedEventParams
  ) {
    of(params)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number
                    ) => {
                      // Conditionally set the case additionalFieldValues here
                      if (recDocCaseIndex === 0) {
                        const existingAdditionalFieldValues: AdditionalFieldValue[] =
                          reqDocCase.additionalFieldValues || [];
                        let specAdditionalField =
                          existingAdditionalFieldValues.find(
                            (f) =>
                              f.additionalFieldName ===
                              params.additionalFieldName
                          );

                        if (!specAdditionalField) {
                          specAdditionalField = {
                            additionalFieldName: params.additionalFieldName,
                            fileValues: [],
                          };
                          reqDocCase.additionalFieldValues?.push(
                            specAdditionalField
                          );
                        }
                        documentInfoAndUploadedFiles.forEach((f) =>
                          specAdditionalField!.fileValues!.push(
                            f.documentInfo.id
                          )
                        );
                        reqDocCase.additionalFieldValues =
                          existingAdditionalFieldValues;

                        this.additionalFieldValues =
                          reqDocCase.additionalFieldValues;
                        this.documentFileUploadDocumentInfos =
                          this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                            reqDocCase.additionalFieldValues,
                            this.combinedFilingData.documentInfos || []
                          );
                      }
                      return reqDocCase;
                    }
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentEvent.emit({
                  partialRequestDocument,
                  combinedFilingData: this.combinedFilingData,
                  documentIndex: this.documentsGridRow.rowIndex,
                  requestDocument: this.documentsGridRow.requestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...params,
                  documentInfoAndUploadedFiles,
                });
              }
            )
          );
        })
      )
      .subscribe();
  }

  public filesUploadedFromAsToAdditionalFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams
  ) {
    of(filesUploadedEventParams)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].filedBy[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number
                    ) => {
                      const asToParticipants: RequestDocumentParticipantViewModel[] =
                        reqDocCase.filedAsTo || [];
                      reqDocCase.filedAsTo =
                        recDocCaseIndex !== 0
                          ? asToParticipants // Not the first case so just return it as is.
                          : asToParticipants.map(
                              (
                                asToParticipant: RequestDocumentParticipantViewModel,
                                asToParticipantIndex: number
                              ) => {
                                // Conditionally set the filedBy additionalFieldValues here
                                if (asToParticipantIndex === 0) {
                                  const existingAdditionalFieldValues: AdditionalFieldValue[] =
                                    asToParticipant.additionalFieldValues || [];
                                  let specAdditionalField =
                                    existingAdditionalFieldValues.find(
                                      (f) =>
                                        f.additionalFieldName ===
                                        filesUploadedEventParams.additionalFieldName
                                    );

                                  if (!specAdditionalField) {
                                    specAdditionalField = {
                                      additionalFieldName:
                                        filesUploadedEventParams.additionalFieldName,
                                      fileValues: [],
                                    };
                                    asToParticipant.additionalFieldValues?.push(
                                      specAdditionalField
                                    );
                                  }
                                  documentInfoAndUploadedFiles.forEach((f) =>
                                    specAdditionalField!.fileValues!.push(
                                      f.documentInfo.id
                                    )
                                  );
                                  asToParticipant.additionalFieldValues =
                                    existingAdditionalFieldValues;

                                  this.additionalFieldValues =
                                    asToParticipant.additionalFieldValues;
                                  this.asToFileUploadDocumentInfos =
                                    this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                                      asToParticipant.additionalFieldValues,
                                      this.combinedFilingData.documentInfos ||
                                        []
                                    );
                                }
                                return asToParticipant;
                              }
                            );
                      return reqDocCase;
                    }
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentEvent.emit({
                  partialRequestDocument,
                  combinedFilingData: this.combinedFilingData,
                  documentIndex: this.documentsGridRow.rowIndex,
                  requestDocument: this.documentsGridRow.requestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...filesUploadedEventParams,
                  documentInfoAndUploadedFiles,
                });
              }
            )
          );
        })
      )
      .subscribe();
  }

  public filesUploadedFromFiledByAdditionalFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams
  ) {
    of(filesUploadedEventParams)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].filedBy[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number
                    ) => {
                      const filedByParticipants: RequestDocumentParticipantViewModel[] =
                        reqDocCase.filedBy || [];
                      reqDocCase.filedBy =
                        recDocCaseIndex !== 0
                          ? filedByParticipants // Not the first case so just return it as is.
                          : filedByParticipants.map(
                              (
                                filedByParticipant: RequestDocumentParticipantViewModel,
                                filedByParticipantIndex: number
                              ) => {
                                // Conditionally set the filedBy additionalFieldValues here
                                if (filedByParticipantIndex === 0) {
                                  let existingAdditionalFieldValues: AdditionalFieldValue[] =
                                    filedByParticipant.additionalFieldValues ||
                                    [];

                                  let specAdditionalField =
                                    existingAdditionalFieldValues.find(
                                      (f) =>
                                        f.additionalFieldName ===
                                        filesUploadedEventParams.additionalFieldName
                                    );

                                  if (!specAdditionalField) {
                                    specAdditionalField = {
                                      additionalFieldName:
                                        filesUploadedEventParams.additionalFieldName,
                                      fileValues: [],
                                    };
                                    filedByParticipant.additionalFieldValues?.push(
                                      specAdditionalField
                                    );
                                  }
                                  documentInfoAndUploadedFiles.forEach((f) =>
                                    specAdditionalField!.fileValues!.push(
                                      f.documentInfo.id
                                    )
                                  );
                                  filedByParticipant.additionalFieldValues =
                                    existingAdditionalFieldValues;

                                  this.additionalFieldValues =
                                    filedByParticipant.additionalFieldValues;
                                  this.filedByFileUploadDocumentInfos =
                                    this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                                      filedByParticipant.additionalFieldValues,
                                      this.combinedFilingData.documentInfos ||
                                        []
                                    );
                                }
                                return filedByParticipant;
                              }
                            );
                      return reqDocCase;
                    }
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentEvent.emit({
                  partialRequestDocument,
                  combinedFilingData: this.combinedFilingData,
                  documentIndex: this.documentsGridRow.rowIndex,
                  requestDocument: this.documentsGridRow.requestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...filesUploadedEventParams,
                  documentInfoAndUploadedFiles,
                });
              }
            )
          );
        })
      )
      .subscribe();
  }

  fileRemovedFromDocumentAdditionalFieldEventHandler(
    documentInfo: DocumentInfo
  ) {
    // 1. Create a new object with the caseRequest property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          // Conditionally update the additional field values
          const additionalFieldValues: AdditionalFieldValue[] =
            reqDocCase.additionalFieldValues || [];
          reqDocCase.additionalFieldValues =
            reqDocCaseIndex !== 0
              ? additionalFieldValues // Not the first case so just return as is.
              : additionalFieldValues.map(
                  (addlFieldVal: AdditionalFieldValue) => {
                    // Filter out the fileValue that has the id we want to remove
                    const fileValues: string[] = addlFieldVal.fileValues || [];
                    addlFieldVal.fileValues = fileValues.filter(
                      (fileValue: string) => {
                        return fileValue !== documentInfo.id;
                      }
                    );
                    return addlFieldVal;
                  }
                );
          return reqDocCase;
        }
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentEvent.emit({
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
      documentIndex: this.documentsGridRow.rowIndex,
      requestDocument: this.documentsGridRow.requestDocument,
    });
  }

  fileRemovedFromFiledByAdditionalFieldEventHandler(
    documentInfo: DocumentInfo
  ) {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const filedByParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedBy || [];
          // Conditionally update the filedBy property
          reqDocCase.filedBy =
            reqDocCaseIndex !== 0
              ? filedByParticipants // Not the first case so just return as is.
              : filedByParticipants.map(
                  (
                    filedByParticipant: RequestDocumentParticipantViewModel,
                    filedByIndex
                  ) => {
                    // Conditionally update the additional field values
                    const additionalFieldValues: AdditionalFieldValue[] =
                      filedByParticipant.additionalFieldValues || [];
                    filedByParticipant.additionalFieldValues =
                      filedByIndex !== 0
                        ? additionalFieldValues // Not the first filedByParticpant so just return as is.
                        : additionalFieldValues.map(
                            (addlFieldVal: AdditionalFieldValue) => {
                              // Filter out the fileValue that has the id we want to remove
                              const fileValues: string[] =
                                addlFieldVal.fileValues || [];
                              addlFieldVal.fileValues = fileValues.filter(
                                (fileValue: string) => {
                                  return fileValue !== documentInfo.id;
                                }
                              );
                              return addlFieldVal;
                            }
                          );

                    this.additionalFieldValues =
                      filedByParticipant.additionalFieldValues;
                    this.filedByFileUploadDocumentInfos =
                      this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                        filedByParticipant.additionalFieldValues,
                        this.combinedFilingData.documentInfos || []
                      );
                    return filedByParticipant;
                  }
                );

          return reqDocCase;
        }
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentEvent.emit({
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
      documentIndex: this.documentsGridRow.rowIndex,
      requestDocument: this.documentsGridRow.requestDocument,
    });

    // 4. And remove the actual file
    // otherwise, if they add another
  }

  fileRemovedFromAsToAdditionalFieldEventHandler(documentInfo: DocumentInfo) {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const asToParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedAsTo || [];
          // Conditionally update the filedAsTo property
          reqDocCase.filedAsTo =
            reqDocCaseIndex !== 0
              ? asToParticipants // Not the first case so just return as is.
              : asToParticipants.map(
                  (
                    asToParticipant: RequestDocumentParticipantViewModel,
                    asToIndex
                  ) => {
                    // Conditionally update the additional field values
                    const additionalFieldValues: AdditionalFieldValue[] =
                      asToParticipant.additionalFieldValues || [];
                    asToParticipant.additionalFieldValues =
                      asToIndex !== 0
                        ? additionalFieldValues // Not the first filedByParticpant so just return as is.
                        : additionalFieldValues.map(
                            (addlFieldVal: AdditionalFieldValue) => {
                              // Filter out the fileValue that has the id we want to remove
                              const fileValues: string[] =
                                addlFieldVal.fileValues || [];
                              addlFieldVal.fileValues = fileValues.filter(
                                (fileValue: string) => {
                                  return fileValue !== documentInfo.id;
                                }
                              );
                              return addlFieldVal;
                            }
                          );
                    this.additionalFieldValues =
                      asToParticipant.additionalFieldValues;
                    this.asToFileUploadDocumentInfos =
                      this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                        asToParticipant.additionalFieldValues,
                        this.combinedFilingData.documentInfos || []
                      );
                    return asToParticipant;
                  }
                );
          return reqDocCase;
        }
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentEvent.emit({
      partialRequestDocument,
      combinedFilingData: this.combinedFilingData,
      documentIndex: this.documentsGridRow.rowIndex,
      requestDocument: this.documentsGridRow.requestDocument,
    });
  }

  private validate(): void {
    if (this.fileNameField) {
      this.fileNameField.validate();
    }
    if (this.documentTypeField) {
      this.documentTypeField.validate();
    }
    if (this.accessTypeField) {
      this.accessTypeField.validate();
    }
    if (this.documentTitleField) {
      this.documentTitleField.validate();
    }
    if (this.filedByFields) {
      this.filedByFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.asToFields) {
      this.asToFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.additionalFields) {
      this.additionalFields.toArray().forEach((fld) => fld.validate());
    }
  }
}
