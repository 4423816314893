import { SimpleChange } from '@angular/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface TabValidationChanges extends SimpleChanges {
  isValid: SimpleChange;
}

@Component({
  selector: 'fsx-tab-validation',
  templateUrl: './tab-validation.component.html',
  styleUrls: ['./tab-validation.component.scss'],
})
export class TabValidationComponent implements OnChanges {
  @Input() isValid!: boolean | null | undefined;

  ngOnChanges(changes: TabValidationChanges): void {
    this.isValid = changes.isValid.currentValue;
  }
}
