<fsx-panel
  [config]="panelConfig"
  (closePanelEvent)="closePanelEventHandler()"
  (defaultPanelEvent)="defaultPanelEventHandler()"
>
  <div header>
    <fsx-contacts-list-panel-header [config]="panelHeaderConfig">
    </fsx-contacts-list-panel-header>
  </div>
  <div content *ngIf="contactSummaries$ | async as contactSummaries">
    <fsx-contacts-list-actions
      [searchType]="data.searchType"
      (addNewContactEvent)="addNewContactEventHandler()"
    ></fsx-contacts-list-actions>
    <fsx-contacts-list-two
      [contactSummaries]="contactSummaries"
      (editContactEvent)="editContactEventHandler($event)"
    >
    </fsx-contacts-list-two>
  </div>
  <div footer>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        <button class="secondary" (click)="onCancelButtonClicked()">
          Cancel
        </button>
        <button
          class="primary"
          [disabled]="(canSubmitForm$ | async) === false"
          [ngClass]="{ disabled: (canSubmitForm$ | async) === false }"
          (click)="onAddButtonClicked()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</fsx-panel>
