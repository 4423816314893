export interface GroupEndPoints {
  searchGroups: string;
  getGroup: (groupId: number) => string;
  searchGroupMembers: (groupId: number) => string;
  addGroupMembers: (groupId: number) => string;
  addGroupMembersFromExisting: (groupId: number) => string;
  deleteGroupMembers: (groupId: number) => string;
  createGroup: () => string;
  updateGroup: (groupId: number) => string;
  deleteGroup: (groupId: number) => string;
}

export const groupEndPoints = (versionedUrl: string): GroupEndPoints => {
  const baseUrl = `${versionedUrl}/groups`;
  const membersUrl = (groupId: number) => `${baseUrl}/${groupId}/members`;

  return {
    searchGroups: `${baseUrl}/search`,

    getGroup: (groupId) => `${baseUrl}/${groupId}`,

    searchGroupMembers: (groupId) => `${membersUrl(groupId)}/search`,

    addGroupMembers: (groupId) => `${membersUrl(groupId)}`,

    addGroupMembersFromExisting: (groupId) => `${membersUrl(groupId)}/existing`,

    deleteGroupMembers: (groupId) => `${membersUrl(groupId)}`,

    createGroup: () => baseUrl,

    updateGroup: (groupId) => `${baseUrl}/${groupId}`,

    deleteGroup: (groupId) => `${baseUrl}/${groupId}`,
  };
};
