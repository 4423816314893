import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import { CHILD_ROUTE, TRANSACTION_ID } from '@fsx/fsx-shared';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  /** Determines if this route (and its subtree) should be detached to be reused later */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route?.routeConfig?.path;
    const shouldDetach = path
      ? path === `${CHILD_ROUTE.EDITOR}/:${TRANSACTION_ID}`
      : false;
    return shouldDetach;
  }

  /** Stores the detached route */
  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle
  ): void {
    const handlerKey = this.getUrlHandle(route);
    this.handlers[handlerKey] = handle;
  }

  /** Determines if this route (and its subtree) should be reattached */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const handlerKey = this.getUrlHandle(route);
    const shouldAttach = !!route.routeConfig && !!this.handlers[handlerKey];
    return shouldAttach;
  }

  /** Retrieves the previously stored route */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const handlerKey = this.getUrlHandle(route);
    const retrievedRouteHandler = this.handlers[
      handlerKey
    ] as DetachedRouteHandle;
    return retrievedRouteHandler;
  }

  public shouldReuseRoute(
    curr: ActivatedRouteSnapshot,
    previous: ActivatedRouteSnapshot
  ): boolean {
    /** Determines if a route should be reused */
    const hasPrevRouteConfig = !!previous.routeConfig;
    const wasFilingEditorRoute =
      hasPrevRouteConfig &&
      previous.routeConfig.path === `${CHILD_ROUTE.EDITOR}/:${TRANSACTION_ID}`;
    const hasCurrentRouteConfig = !!curr.routeConfig;
    const isFilingEditorRoute =
      hasCurrentRouteConfig &&
      curr.routeConfig.path === `${CHILD_ROUTE.EDITOR}/:${TRANSACTION_ID}`;
    const isFilingEditorSwitch =
      wasFilingEditorRoute &&
      isFilingEditorRoute &&
      // tslint:disable-next-line: no-string-literal
      previous.params[TRANSACTION_ID] !== curr.params[TRANSACTION_ID];

    const shouldReuseRoute = isFilingEditorSwitch;
    return shouldReuseRoute;
  }

  private getUrlHandle(route: ActivatedRouteSnapshot): string {
    return route.url.join('/'); // route.routeConfig.path
  }
}
