import { FieldCategory } from '@fsx/fsx-shared';
import {
  validCurrencyCharacters,
  validNumericCharacters,
} from './numeric-input.helper';

export function parseValue(value: string, type: string): number | null {
  switch (type) {
    case FieldCategory.Number: {
      const isValidNumber = validNumericCharacters(value);
      if (!isValidNumber) {
        return null;
      }

      const valueWithoutCommas = value.replace(/,/g, '');
      const parsedValue = parseFloat(valueWithoutCommas);

      return parsedValue;
    }

    case FieldCategory.Currency: {
      const isValidCurrency = validCurrencyCharacters(value);
      if (!isValidCurrency) {
        return null;
      }

      const valueWithoutCommas = value.replace(/,/g, '');
      const valueWithoutCurrency = valueWithoutCommas.replace(/[$]/g, '');
      const parsedValue = parseFloat(valueWithoutCurrency);

      return parsedValue;
    }
    default:
      return 0;
  }
}
