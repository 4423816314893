import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  IValidatable,
  IdentificationViewModel,
  IdentificationSpec,
  FilingProfile,
} from '../../../types';
import {
  FsxSelectionFieldValidationService,
  ISelectionFieldValidationService,
} from './selection-field-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';

export const FsxIdentificationValidationService =
  new InjectionToken<IIdentificationValidationService>(
    'FsxIdentificationValidationService'
  );

export interface IIdentificationValidationService {
  validateIdentifications(
    identifications: IdentificationViewModel[],
    spec: IdentificationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;

  validateIdentification(
    identification: IdentificationViewModel,
    spec: IdentificationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class IdentificationValidationService
  implements IIdentificationValidationService
{
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxSelectionFieldValidationService)
    private readonly selectionFieldValidationService: ISelectionFieldValidationService
  ) {}

  public validateIdentifications(
    identifications: IdentificationViewModel[],
    spec: IdentificationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!identifications) {
      identifications = [];
    }

    if (identifications.length < spec.minRequired) {
      scope.isValid = false;
      return false;
    }

    if (identifications.length > spec.maxAllowed) {
      scope.isValid = false;
      return false;
    }

    for (let index = 0; index < identifications.length; index++) {
      const identification = identifications[index];

      if (
        !this.validateIdentification(identification, spec, scope, filingProfile)
      ) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }

  public validateIdentification(
    identification: IdentificationViewModel,
    spec: IdentificationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    identification.isValid = true;

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        identification.category.name,
        spec.category,
        identification,
        filingProfile
      )
    ) {
      identification.isValid = false;
      return false;
    }

    if (
      !this.textFieldValidationService.validateTextField(
        identification,
        filingProfile,
        spec.identificationText,
        identification.identificationKey
      )
    ) {
      identification.isValid = false;
      return false;
    }

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        identification.regionKey,
        spec.region,
        identification,
        filingProfile
      )
    ) {
      identification.isValid = false;
      return false;
    }

    return true;
  }
}
