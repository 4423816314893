import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxPanel,
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import { ParticipantFormConfig } from '../participant-form/participant-form.component';
import { FsxPanelConfig } from '../../shared/components/fsx-panel/fsx-panel.component';
import { ParticipantFormPanelHeaderConfig } from '../participant-form-panel-header/participant-form-panel-header.component';
import {
  FsxApplyParticipantFormUpdatesOrchestrationService,
  IApplyParticipantFormUpdatesOrchestrationService,
} from '../../shared/services/apply-participant-form-updates-orchestration.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ContactFormGroup,
  ContactFormMode,
  ContactType,
  ParticipantFormMode,
} from '@fsx/fsx-shared';
import {
  AddOrUpdateContactOrchestrationService,
  FsxAddOrUpdateContactOrchestrationService,
  IAddOrUpdateContactOrchestrationService,
} from '../../shared/services/add-or-update-contact-orchestration.service';
import { Subject, takeUntil, merge } from 'rxjs';
import {
  FsxOpenContactFormOrchestrationService,
  IOpenContactFormOrchestrationService,
} from '../../shared/services/open-contact-form-orchestration.service';

/**
 * A very simple container component that contains an instance of the ContactsFormComponent.
 * This allows us to open the contacts form in a panel, and keep the contacts form decoupled
 * from all things panel related.
 */
@Component({
  selector: 'fsx-participant-form-panel',
  templateUrl: 'participant-form-panel.component.html',
  styleUrls: ['./participant-form-panel.component.scss'],
  providers: [
    {
      provide: FsxAddOrUpdateContactOrchestrationService,
      useClass: AddOrUpdateContactOrchestrationService,
    },
  ],
})
export class ParticipantFormPanelComponent implements FsxPanel, OnDestroy {
  /**
   * A subject to trigger the tearing donw of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  public participantFormGroup!: FormGroup<ContactFormGroup>;

  /**
   * The mode that the child participant form component is operating in.
   * Used in the view to show/hide the "Save in Contacts" checkbox.
   */
  participantFormMode = ParticipantFormMode;

  /**
   * The boolean value indicating whether we should also save this
   * participant as a contact on submitting the form. Used in the
   * footer on a checkbox control.
   */
  saveInContacts: boolean = false;

  /**
   * The config object to pass to the FsxPanelComponent to override styles.
   */
  panelConfig: FsxPanelConfig = {
    /**
     * Override the defaukt header backgriund, for contacts panel we want grey-blue background.
     */
    headerBackgroundClass: 'pure-white',

    /**
     * Explicitly set the content section indentation to 'indented' which will ensure
     * that the nested form highlight background appears inline with the header.
     */
    contentIndentClass: 'indented',

    /**
     * Attach the panel's loading indicator to the apply participant form updates
     * and open contact form orchestration isOrchestrationInProgress$ streams.
     */
    showLoadingIndicator$: merge(
      this.applyParticipantFormUpdatesOrchestrationService
        .isOrchestrationInProgress$,
      this.openContactFormOrchestrationService.isOrchestrationInProgress$
    ),
  };

  /**
   * The config object to pass to the ContactFormPanelHeaderComponent.
   */
  headerConfig: ParticipantFormPanelHeaderConfig = {
    formMode: this.data.formMode,
    participant: this.data.participant,
  };

  /**
   * The boolean value indicating whether we can save the form or not.
   * Used in the footer to enable/disable the Save button.
   */
  canSaveForm = false;

  /**
   *
   * @param data The participant form config properties to pass into the participant form component.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParticipantFormConfig,
    @Inject(FsxPanelService) public readonly panelService: IPanelService,
    @Inject(FsxCombinedFilingDataService)
    public readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxApplyParticipantFormUpdatesOrchestrationService)
    public readonly applyParticipantFormUpdatesOrchestrationService: IApplyParticipantFormUpdatesOrchestrationService,
    @Inject(FsxAddOrUpdateContactOrchestrationService)
    public readonly addOrUpdateContactOrchestrationService: IAddOrUpdateContactOrchestrationService,
    @Inject(FsxOpenContactFormOrchestrationService)
    private readonly openContactFormOrchestrationService: IOpenContactFormOrchestrationService
  ) {
    const contactType = ContactType.Person;
    this.participantFormGroup = new FormGroup<ContactFormGroup>({
      contactType: new FormControl(contactType, { nonNullable: true }),
    });

    // Subscribe to the open contacts form orchestration stream. This allows us
    // to opem the form for adding/editing contacts from handler functions below.
    this.openContactFormOrchestrationService.openContactFormOrchestration$
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    // Subscribe to the add or update contact orchestration stream. This allows us
    // to add/update the contact from handler functions below.
    this.addOrUpdateContactOrchestrationService.addOrUpdateContactOrchestration$
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * The lifecycle hook where we teardown inner subscriptions with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A handler function for the FsxPanelComponent's defaultPanelEvent, which is raised from
   * the panel when the user presses the enter key. In most cases this will trigger
   * the same orchestration as one of the buttons we pass in.
   */
  defaultPanelEventHandler(): void {
    this.applyParticipantFormUpdates();
  }

  /**
   * A handler function for the Save button's click event.
   */
  saveButtonClickHandler(): void {
    this.applyParticipantFormUpdates();
  }

  private applyParticipantFormUpdates(): void {
    // Always update the RequestParticipant object we opened the form for.
    this.applyParticipantFormUpdatesOrchestrationService.applyParticipantFormUpdates(
      {
        formMode: this.data.formMode,
        participantFormGroup: this.participantFormGroup,
        participant: this.data.participant,
        createAsContact: this.saveInContacts,
      }
    );
  }

  /**
   * A handler function for the Cancel buttons click event.
   */
  onCancelButtonClicked(): void {
    this.panelService.closeCurrentDialog();
  }

  /**
   * A handler function for the FsxPanelComponent's closePanelEvent event. More often than
   * not we just want to close the current dialog; that's all we need to do here right now.
   */
  closePanelEventHandler(): void {
    this.panelService.closeCurrentDialog();
  }

  /**
   * A handler function for the participant form's isFormValidEvent.
   * We use this here to determine whether to enable/disable the save button.
   *
   * @param isValid The validity of the contact form.
   */
  isFormValidEventHandler(isValid: boolean): void {
    this.canSaveForm = isValid;
  }

  /**
   * A handler function for the "Save in Contacts" checkbox's click event.
   */
  onSaveInContactsCheckboxClicked(): void {
    this.saveInContacts = !this.saveInContacts;
  }

  /**
   * A handler function for the contact link card's editContactEvent.
   *
   * @param contactId The id of the contact we want to edit
   */
  editContactEventHandler(contactId: string): void {
    const formMode = ContactFormMode.EditContact;
    this.openContactFormOrchestrationService.openContactForm({
      formMode,
      contactId,
    });
  }
}
