import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  RequestParticipantAliasViewModel,
  AliasFieldDefinition,
  CaseRequestViewModel,
  AliasSpec,
  ContactType,
  FilingProfile,
} from '../../../types';
import {
  FsxAddressValidationService,
  IAddressValidationService,
} from './address-validation.service';
import {
  FsxEmailAddressValidationService,
  IEmailAddressValidationService,
} from './email-address-validation.service';
import {
  FsxIdentificationValidationService,
  IIdentificationValidationService,
} from './identification-validation.service';
import {
  FsxPersonValidationService,
  IPersonValidationService,
} from './person-validation.service';
import {
  FsxPhoneValidationService,
  IPhoneValidationService,
} from './phone-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxAliasValidationService =
  new InjectionToken<IAliasValidationService>('FsxAliasValidationService');

export interface IAliasValidationService {
  validateAliases(
    aliases: RequestParticipantAliasViewModel[] | null | undefined,
    caseId: string | null | undefined,
    spec: AliasFieldDefinition | null | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile
  ): boolean;

  validateAlias(
    alias: RequestParticipantAliasViewModel,
    caseId: string | null | undefined,
    spec: AliasSpec | null | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class AliasValidationService implements IAliasValidationService {
  constructor(
    @Inject(FsxPersonValidationService)
    private readonly personValidationService: IPersonValidationService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxAddressValidationService)
    private readonly addressValidationService: IAddressValidationService,
    @Inject(FsxEmailAddressValidationService)
    private readonly emailAddressValidationService: IEmailAddressValidationService,
    @Inject(FsxPhoneValidationService)
    private readonly phoneValidationService: IPhoneValidationService,
    @Inject(FsxIdentificationValidationService)
    private readonly identificationValidationService: IIdentificationValidationService,
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService
  ) {}

  public validateAliases(
    aliases: RequestParticipantAliasViewModel[] | null | undefined,
    caseId: string | null | undefined,
    spec: AliasFieldDefinition | null | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!aliases) {
      aliases = [];
    }

    if (aliases.length < spec.minRequired) {
      scope.isValid = false;
      return false;
    }

    if (aliases.length > spec.maxAllowed) {
      scope.isValid = false;
      return false;
    }

    for (let index = 0; index < aliases.length; index++) {
      const alias = aliases[index];

      if (!alias) {
        scope.isValid = false;
        return false;
      }

      if (!alias.category.name) {
        scope.isValid = false;
        return false;
      }

      this.validationHelperService.validateListReference(
        spec.allowedAliasCategoriesList,
        alias.category.name,
        alias,
        filingProfile
      );

      const aliasSpec = filingProfile.alias.find(
        (a) => a.aliasCategory.name === alias.category.name
      );
      if (!aliasSpec) {
        scope.isValid = false;
        return false;
      }

      if (!this.validateAlias(alias, caseId, aliasSpec, scope, filingProfile)) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }

  public validateAlias(
    alias: RequestParticipantAliasViewModel,
    caseId: string | null | undefined,
    spec: AliasSpec | null | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    alias.isValid = true;

    if (
      !spec.allowOrganization &&
      alias.contactType === ContactType.Organization
    ) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    if (!spec.allowPerson && alias.contactType == ContactType.Person) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    if (
      spec.allowOrganization &&
      alias.contactType == ContactType.Organization
    ) {
      if (
        this.textFieldValidationService.validateTextField(
          alias,
          filingProfile,
          spec.organization?.title,
          alias.organization?.title
        )
      ) {
        return this.validationHelperService.markItemAsInvalid(alias, scope);
      }

      if (alias?.person !== null) {
        return this.validationHelperService.markItemAsInvalid(alias, scope);
      }
    }

    if (spec.allowPerson && alias.contactType === ContactType.Person) {
      this.personValidationService.validatePerson(
        alias.person,
        spec.person,
        scope,
        filingProfile
      );

      if (alias?.organization !== null) {
        return this.validationHelperService.markItemAsInvalid(alias, scope);
      }
    }

    if (
      !this.addressValidationService.validateAddresses(
        alias?.addresses,
        spec.address,
        alias,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    if (
      !this.emailAddressValidationService.validateEmailAddresses(
        alias?.emails,
        spec.email,
        alias,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    if (
      !this.phoneValidationService.validatePhones(
        alias.phones,
        spec.phone,
        alias,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    if (
      !this.identificationValidationService.validateIdentifications(
        alias.identifications,
        spec.identification,
        alias,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(alias, scope);
    }

    return true;
  }
}
