<div class="add-associated-party-row" fxLayout="column">
  <div
    *ngFor="let participant of selectedParticipantsViewModel; let index = index"
  >
    <fsx-participant-table-comp
      #participantField
      *ngIf="participant"
      [combinedFilingData]="combinedFilingData"
      [participant]="participant"
      [isAssociatedParty]="true"
      [resolver]="resolver"
      [additionalFieldSpec]="participantFieldDefinition.additionalFields"
      [additionalFieldValues]="
        selectedParticipants[index].additionalFieldValues ?? []
      "
      (clearParticipantEvent)="clearParticipantEventHandler(participant)"
      (setAdditionalFieldsEvent)="setAdditionalFieldValues($event, participant)"
    >
    </fsx-participant-table-comp>
  </div>
  <fsx-basic-single-selection
    #selectionComponent
    *ngIf="selectedParticipants.length < maxAllowed; else maxAllowedReached"
    [caption]="caption"
    [helpText]="helpText"
    [hint]="''"
    [fieldType]="fieldType.Selection"
    [width]="!!width ? width : '162px'"
    [selectionType]="selectionType"
    [inputDropdownOptions]="filteredDropdownOptions"
    [autoSelectSingleOption]="false"
    [required]="required"
    (selectedValue)="onParticipantSelected($event, [])"
  >
  </fsx-basic-single-selection>
  <ng-template #maxAllowedReached>
    <p>
      The maximum number of associated parties ({{ maxAllowed }}) has been
      reached
    </p>
  </ng-template>
</div>
