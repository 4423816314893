import { Injectable } from '@angular/core';
import { Filing } from '@fsx/fsx-shared';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionsEventService {
  private transactionRemoved$$ = new Subject<Filing>();
  transactionRemoved$ = this.transactionRemoved$$.asObservable();

  private transactionUpdated$$ = new Subject<Filing>();
  transactionUpdated$ = this.transactionUpdated$$.asObservable();

  private staleTransactionsList$$ = new Subject<void>();
  staleTransactionsList$ = this.staleTransactionsList$$.asObservable();

  dispatchTransactionRemovedEvent(filing: Filing) {
    this.transactionRemoved$$.next(filing);
    this.staleTransactionsList$$.next();
  }

  dispatchTransactionUpdatedEvent(filing: Filing) {
    this.transactionUpdated$$.next(filing);
    this.staleTransactionsList$$.next();
  }
}
