export interface CaseEndPoints {
  searchCases: () => string;
}

export const caseEndPoints = (versionedUrl: string): CaseEndPoints => {
  const baseUrl = `${versionedUrl}/cases`;
  return {
    searchCases: () => `${baseUrl}/search`,
  };
};
