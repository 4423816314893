import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import {
  FsxFilingTabsService,
  IFilingTabsService,
  ROUTE,
  TRANSACTIONS_EDITOR,
  TransactionTab,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';

export const FsxCloseFilingOrchestrationService =
  new InjectionToken<ICloseFilingOrchestrationService>(
    'FsxCloseFilingOrchestrationService'
  );

export interface ICloseFilingOrchestrationService {
  closeFilingInFilingEditor$: Observable<TransactionTab[]>;
  closeFiling(filingId: string): void;
}

@Injectable()
export class CloseFilingOrchestrationService
  implements ICloseFilingOrchestrationService
{
  private closeFilingWithFilingIdParams$$ = new Subject<string>();

  closeFilingInFilingEditor$: Observable<TransactionTab[]> =
    this.closeFilingWithFilingIdParams$$.pipe(
      switchMap((filingId: string) => {
        return this.filingTabsService.removeTab(filingId).pipe(
          tap((filingTabs: TransactionTab[]) => {
            if (filingTabs.length > 0) {
              const lastFilingTab = filingTabs[filingTabs.length - 1];
              this.router.navigate([
                TRANSACTIONS_EDITOR,
                lastFilingTab.filingId,
              ]);
            } else {
              this.router.navigate([ROUTE.TRANSACTIONS]);
            }
          })
        );
      })
    );

  public constructor(
    private readonly router: Router,
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService
  ) {}

  closeFiling(filingId: string): void {
    this.closeFilingWithFilingIdParams$$.next(filingId);
  }
}
