import {
  AfterContentChecked,
  AfterViewInit,
  Directive,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[fsxFocusFirstInput]',
})
export class FocusFirstElementDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    if (!el.nativeElement['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    const element: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    const inputs = element.getElementsByTagName('input');

    if (inputs && inputs[0]) {
      inputs[0].focus();
    }
  }
}
