import { Pipe, PipeTransform } from '@angular/core';
import {
  DocumentInfo,
  DocumentInfoHelperService,
  FileUploadState,
} from '@fsx/fsx-shared';

@Pipe({
  name: 'fileUploadState',
})
export class FileUploadStatePipe implements PipeTransform {
  constructor(private documentInfoHelperService: DocumentInfoHelperService) {}

  transform(documentInfo: DocumentInfo): FileUploadState {
    return this.documentInfoHelperService.getFileUploadState(documentInfo);
  }
}
