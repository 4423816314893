import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { minRequiredValidator } from '@fsx/ui-components';
import { Subject, asyncScheduler, takeUntil, tap } from 'rxjs';
import { FormControlWithoutModel } from '../../../../models';
import { FSXFormControlService } from '../../../../services';
import { DropdownOption } from '../../../../types';
import { FsxSingleSelectionBaseSelectionComponent } from '../single-selection-base/single-selection-base.component';

interface FsxBasicSingleSelectionComponentChanges extends SimpleChanges {
  inputDropdownOptions: SimpleChange;
  initialValue: SimpleChange;
}

@Component({
  selector: 'fsx-basic-single-selection',
  templateUrl: '../single-selection-base/single-selection-base.component.html',
  styleUrls: ['../single-selection-base/single-selection-base.component.scss'],
})
export class FsxBasicSingleSelectionComponent
  extends FsxSingleSelectionBaseSelectionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() inputDropdownOptions: DropdownOption<void>[] = [];
  @Input() initialValue!: string | null | undefined;
  @Input() autoSelectSingleOption: boolean = true;

  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>(
    true
  );

  public override formControl!: FormControlWithoutModel;
  public override formControlTemp!: FormControlWithoutModel;

  private destroy$: Subject<void> = new Subject();

  constructor(public fsxFormControlService: FSXFormControlService) {
    super();
  }

  ngOnInit(): void {
    this.setControls();
  }

  ngOnChanges(changes: FsxBasicSingleSelectionComponentChanges) {
    let hasInitialValueChanged = false;

    if (changes.inputDropdownOptions) {
      this.dropdownOptions = changes.inputDropdownOptions.currentValue;

      if (this.formControl) {
        // to enable the autocomplete to set correctly, update the filtered observable
        this.formControl.dropdownOptions =
          changes.inputDropdownOptions.currentValue;
        this.formControlTemp.dropdownOptions =
          changes.inputDropdownOptions.currentValue;
      }
    }

    if (changes.initialValue) {
      if (
        changes.initialValue.previousValue !== changes.initialValue.currentValue
      ) {
        hasInitialValueChanged = true;
      }
      this.initialValue = changes.initialValue.currentValue;
    }

    this.setControls();

    if (hasInitialValueChanged) {
      // let's not reset, and thus emit, if there's no change
      this._setInitialValues();
    }
  }

  private setControls() {
    if (!this.formControl) {
      this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
      this.dropdownOptions = this.inputDropdownOptions;
      this.formControl = this._createFormControl();
      this.formControlTemp = this._createFormControl();
      this.formControl.dropdownOptions = this.inputDropdownOptions;
      this.formControlEmitter.emit(this.formControl);

      if (this.required) {
        this.formControl.addValidators(minRequiredValidator(1));
        this.formControlTemp.addValidators(minRequiredValidator(1));
      }

      this.setFilteredOptions();
      this._setInitialValues();

      this.formControl.statusChanges
        .pipe(
          tap((status) => {
            status === 'DISABLED'
              ? this.formControlTemp.disable()
              : this.formControlTemp.enable();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  private _createFormControl(): FormControlWithoutModel {
    return this.fsxFormControlService.createFormControlWithoutProfile(
      this.fieldType,
      this.initialValue
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _setInitialValues(): void {
    if (this.dropdownOptions.length === 1 && this.autoSelectSingleOption) {
      this.formControlTemp.setValue(this.dropdownOptions[0]);
      this.formControl.setValue(this.dropdownOptions[0].name);
      this.selectedSingleOptionCaption = this.dropdownOptions[0].caption;
      this.selectedValue.emit(this.dropdownOptions[0].name);
    } else if (
      this.initialValue !== null &&
      typeof this.initialValue !== undefined
    ) {
      const optionSelected = this.inputDropdownOptions.find(
        (option) => option.name === this.initialValue
      );

      this.formControlTemp.setValue(optionSelected);
      this.formControl.setValue(optionSelected?.name);
      this.selectedSingleOptionCaption = optionSelected?.caption ?? '';
      this.selectedValue.emit(optionSelected?.name);
    }
  }

  public validate(): void {
    asyncScheduler.schedule(() => {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity({ emitEvent: false });

      this.formControlTemp.markAsTouched();
      this.formControlTemp.markAsDirty();
      this.formControlTemp.updateValueAndValidity({ emitEvent: false });
    });
  }
}
