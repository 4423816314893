<div class="fsx-panel-component" fxLayout="column">
  <div
    class="fsx-panel-header {{ config.headerBackgroundClass }}"
    fxLayout="column"
  >
    <div class="fsx-panel-header-title-row" fxLayout="row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <ng-content select="[header]"></ng-content>
      </div>
      <div
        class="close-icon-column"
        fxLayout="column"
        fxLayoutAlign="center end"
      >
        <button class="icon-button" (click)="onCloseIconClicked()">
          <span class="material-icons exit">highlight_off</span>
        </button>
      </div>
    </div>
    <div>
      <ng-content select="[sub-header]"></ng-content>
    </div>
  </div>
  <div
    class="progress-bar-container"
    *ngIf="config.showLoadingIndicator$ | async"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="fsx-panel-content {{ config.contentIndentClass }}" fxFlex="100">
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="fsx-panel-footer" fxFlex>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
