import { Injectable, InjectionToken } from '@angular/core';
import { Countries } from '../../../data/countries.data';
import {
  FilingProfile,
  IValidatable,
  ProfileListReference,
  ProfileLists,
} from '../../../types';

export const FsxValidationHelperService =
  new InjectionToken<IValidationHelperService>('FsxValidationHelperService');

export interface IValidationHelperService {
  countDecimals(decimalValue: number | string): number;

  markItemAsInvalid(
    item: IValidatable,
    scope?: IValidatable | null | undefined
  ): boolean;

  validateListReference(
    listReference: ProfileListReference,
    selectedValue: string | null | undefined,
    scope: IValidatable | null | undefined,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class ValidationHelperService implements IValidationHelperService {
  public countDecimals(decimalValue: number | string): number {
    let decimalString: string;

    if (typeof decimalValue === 'number') {
      decimalString = decimalValue.toString();
    } else {
      decimalString = decimalValue;
    }

    const dotIndex = decimalString.indexOf('.');

    if (dotIndex === -1) {
      return 0;
    }

    return decimalString.length - dotIndex - 1;
  }

  public markItemAsInvalid(
    item: IValidatable,
    scope: IValidatable | null | undefined = null
  ): boolean {
    item.isValid = false;

    if (scope) {
      scope.isValid = false;
    }

    return false;
  }

  public validateListReference(
    listReference: ProfileListReference,
    selectedValue: string | null | undefined,
    scope: IValidatable | null | undefined,
    filingProfile: FilingProfile
  ): boolean {
    if (!selectedValue) {
      return false;
    }

    if (
      listReference.list !== ProfileLists.CaseAdditionalList &&
      !this.listContainsName(listReference, selectedValue, filingProfile)
    ) {
      return false;
    }

    return true;
  }

  private listContainsName(
    listReference: ProfileListReference,
    name: string,
    filingProfile: FilingProfile
  ): boolean {
    const loweredName = name.toLocaleLowerCase();
    switch (listReference.list) {
      case ProfileLists.AdditionalList:
        const additionalList = filingProfile.additionalLists.find(
          (l) => l.name === listReference.additionalListName
        );
        if (
          additionalList &&
          additionalList.items &&
          additionalList.items.length > 0
        ) {
          return !!additionalList.items.find(
            (i) => i.name.toLocaleLowerCase() == loweredName
          );
        }
        return false;
      case ProfileLists.AddressCategories:
        return !!filingProfile.addressCategories.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
      case ProfileLists.AliasCategories:
        return !!filingProfile.aliasCategories.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
      case ProfileLists.EmailCategories:
        return !!filingProfile.emailCategories.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
      case ProfileLists.IdentificationCategories:
        return !!filingProfile.identificationCategories.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
      case ProfileLists.PhoneCategories:
        return !!filingProfile.phoneCategories.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
      case ProfileLists.Countries:
        return !!Countries.find(
          (c) => c.name.toLocaleLowerCase() == loweredName
        );
    }

    return false;
  }
}
