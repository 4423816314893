import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ContactType,
  ParticipantFormMode,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';

/**
 * A configuration object consisting of the minimal properties that the
 * contact form panel header component needs to do it'S work.
 */
export interface ParticipantFormPanelHeaderConfig {
  /**
   * The mode the form should operate in (Add or Edit mode).
   */
  formMode: ParticipantFormMode;

  /**
   * The participant being edited, used in Edit mode to set the form fields.
   * Or null when in Add mode,
   */
  participant: RequestParticipantViewModel | null;

  /**
   * The contact type being added, used in Add mode to set the header
   * title text and icon when adding a contact (Person or Organization).
   */
  contactType?: ContactType;
}

/**
 * The view model consisting of properties derived from the passed in
 * config object. Passed to the template to render the header.
 */
export interface ParticipantFormPanelHeaderViewModel {
  title: string;
}

@Component({
  selector: 'fsx-participant-form-panel-header',
  templateUrl: 'participant-form-panel-header.component.html',
  styleUrls: ['./participant-form-panel-header.component.scss'],
})
export class ParticipantFormPanelHeaderComponent implements OnChanges {
  @Input() config!: ParticipantFormPanelHeaderConfig;

  vm!: ParticipantFormPanelHeaderViewModel;

  ngOnChanges(_changes: SimpleChanges): void {
    this.setVmPropertiesForFormMode(this.config.formMode);
  }

  private setVmPropertiesForFormMode(formMode: ParticipantFormMode) {
    if (formMode === ParticipantFormMode.EditParticipant) {
      this.vm = {
        ...this.vm,
        title: 'Edit Party',
      };
    } else if (formMode === ParticipantFormMode.EditRepresentation) {
      this.vm = {
        ...this.vm,
        title: 'Edit Representation',
      };
    } else {
      this.vm = {
        ...this.vm,
        title: 'Add New Party',
      };
    }
  }
}
