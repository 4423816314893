import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

export interface RowValidationChanges extends SimpleChanges {
  isValid: SimpleChange;
}

@Component({
  selector: 'fsx-row-validation',
  templateUrl: './row-validation.component.html',
  styleUrls: ['./row-validation.component.scss'],
})
export class RowValidationComponent implements OnChanges {
  @Input() isValid: boolean | null | undefined = true;
  @Input() cssClass: string = '';

  ngOnChanges(changes: RowValidationChanges): void {
    if (changes && typeof changes.isValid !== undefined) {
      this.isValid = changes.isValid.currentValue;
    }
  }
}
