import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  CaseQueryResultItem,
  FieldCategory,
  ProfileLists,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import { FSXFormControlService } from '../../../services';
import { DropdownOption } from '../../../types';
import { FsxBaseSearchComponent } from '../base-search/base-search.component';

@Component({
  selector: 'fsx-case-search-component',
  templateUrl: '../base-search/base-search.component.html',
  styleUrls: ['../base-search/base-search.component.scss'],
})
export class FsxCaseSearchComponent
  extends FsxBaseSearchComponent
  implements OnInit, OnChanges
{
  @Input() casesData!: CaseQueryResultItem[];
  @Input() caseCaption!: string;

  override fieldDefinition: StringSelectionFieldDefinition = {
    defaultValue: '',
    listReference: {
      list: ProfileLists.AdditionalList,
      additionalListName: '',
    },
    maxAllowed: 1,
    minRequired: 0,
    selectionDependentFields: [],
    readOnly: false,
    visible: true,
  };
  override hint = 'Type Case Name / Number';
  override caption!: string;
  constructor(private readonly fsxFormControlService: FSXFormControlService) {
    super();
    this.inputFormControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      FieldCategory.Selection,
      this.inputFormControl?.value || this.fieldDefinition.defaultValue
    );
  }

  ngOnChanges(): void {
    this.dropdownOptions = this.generateDropdownOptions(this.casesData);
    this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
    this.setDropdownOptions();
  }

  override ngOnInit(): void {
    this.caption = this.caseCaption;
  }

  public generateDropdownOptions(
    cases: CaseQueryResultItem[]
  ): DropdownOption<void>[] {
    let itemsArray: DropdownOption<void>[] = [];
    if (cases) {
      cases.forEach((item) => {
        const dropdownOption = this.createCaseDropdownOption(item);
        itemsArray.push(dropdownOption);
      });
    }
    return itemsArray;
  }

  public createCaseDropdownOption(
    item: CaseQueryResultItem
  ): DropdownOption<void> {
    const dropdownOption = {
      caption: item.pendingAttestation
        ? item.summary.caption
        : item.summary.title,
      secondaryCaption: item.pendingAttestation
        ? 'Restricted to participants on the Case'
        : item.summary.caption,
      name: item.summary.id,
      efmKey: item.summary.efmKey,
      selected: false,
      keywords: [],
      itemPath: [],
      icon: 'gavel',
      rating: 0,
    };
    this.addWordToKeyWords(item.summary.caption, dropdownOption, false);
    return dropdownOption;
  }
}
