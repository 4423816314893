import { Pipe, PipeTransform } from '@angular/core';
import { RepresentationGridRow } from '@fsx/ui-components';
import { PartiesGridRow } from './parties-grid.model';

@Pipe({ name: 'partyIsValid' })
export class PartyIsValid implements PipeTransform {
  // we pass in the individual booleans, as the pipe doesn't get refreshed if top level objects don't change
  // (which they won't, it's just the validation properties that do)
  transform(
    _row: PartiesGridRow,
    participantIsValid: boolean | undefined,
    partyIsValid: boolean | undefined,
    partyRepresentationIsValid: boolean | undefined,
    representations: RepresentationGridRow[] | null | undefined
  ): boolean {
    if (participantIsValid === false) {
      return false;
    }

    if (partyIsValid === false || partyRepresentationIsValid === false) {
      return false;
    }

    if (representations) {
      for (let rep of representations) {
        if (rep.participant.isValid === false) {
          return false;
        }

        if (rep.representation.isValid === false) {
          return false;
        }
      }
    }

    return true;
  }
}
