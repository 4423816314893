<label>{{ additionalFieldSpec.caption }}</label>
<div
  *ngFor="let documentInfo of fileUploadDocumentInfos; let i = index"
  class="file"
  fxLayoutAlign="start center"
  (mouseover)="onMouseover($event, i)"
  (mouseout)="onMouseout($event)"
>
  <div fxFlex class="displayName">
    {{ documentInfo.displayName }}
  </div>
  <div
    fxFlex="nogrow"
    fxLayout="column"
    fxLayoutAlign="center end"
    class="hover-col"
  >
    <div class="file-size" *ngIf="hoverIndex !== i; else hoverIcons">
      {{ documentInfo | convertedPdfFileSize }}
    </div>
    <ng-template #hoverIcons>
      <button
        class="icon-button delete-icon-button"
        fsxPreventKeyboardEventBubbling
        (keydown.enter)="onRemoveClicked($event, documentInfo)"
        (click)="onRemoveClicked($event, documentInfo)"
      >
        <div class="form-delete-button"></div>
      </button>
    </ng-template>
  </div>
</div>
<div *ngIf="fileUploadDocumentInfos">
  <div class="infos-wrapper">
    <fsx-file-upload-inline
      #fileUploadField
      *ngIf="
        fileUploadDocumentInfos.length < maxAllowed;
        else maxAllowedReached
      "
      (filesUploadedInlineEvent)="onFilesUploadedEvent($event)"
    >
    </fsx-file-upload-inline>
    <div *ngIf="isValid === false" class="invalid">*</div>
  </div>
  <ng-template #maxAllowedReached>
    <p>The maximum number of files ({{ maxAllowed }}) has been reached</p>
  </ng-template>
</div>
