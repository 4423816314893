<mat-table
  id="contacts-container"
  class="contact-table"
  matSort
  matSortActive="created"
  matSortDisableClear
  matSortDirection="desc"
  [dataSource]="contactSummaries"
  (matSortChange)="onSortChanged($event)"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300"
  [infiniteScrollContainer]="'#contacts-container'"
  [fromRoot]="true"
  (scrolled)="onScrollDown()"
>
  <ng-container matColumnDef="select-contact">
    <mat-header-cell fxFlex="8" *matHeaderCellDef disableClear>
      <!-- <input type="checkbox" class="checkbox" /> -->
    </mat-header-cell>
    <mat-cell fxFlex="8" *matCellDef="let row">
      <input
        type="checkbox"
        class="checkbox"
        [(ngModel)]="row.isSelected"
        (click)="onContactCheckboxClicked(row)"
      />
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="caption">
    <mat-header-cell fxFlex="25" *matHeaderCellDef mat-sort-header disableClear>
      Name
    </mat-header-cell>
    <mat-cell class="name" fxFlex="25" *matCellDef="let row">{{
      row.caption
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell fxFlex="12" *matHeaderCellDef mat-sort-header disableClear>
      Entity
    </mat-header-cell>
    <mat-cell fxFlex="12" *matCellDef="let row">{{ row.type }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="primary-email">
    <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Email
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.primaryEmailAddress }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="client-name">
    <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header disableClear>
      Client No
    </mat-header-cell>
    <mat-cell fxFlex="10" *matCellDef="let row">{{
      row.clientNameText
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="hover-icons">
    <mat-header-cell fxFlex="8" *matHeaderCellDef disableClear>
      <!-- <input type="checkbox" class="checkbox" /> -->
    </mat-header-cell>
    <mat-cell
      fxFlex="8"
      fxLayoutAlign="end center"
      *matCellDef="let row"
      class="hover-icons"
    >
      <mat-icon
        matTooltip="Edit"
        [matTooltipShowDelay]="200"
        [matTooltipHideDelay]="0"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'fsx-tooltip'"
        class="hover-icon edit-icon"
        (click)="onEditIconClicked(row, 0)"
      >
      </mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
