import { TextFieldDefinition } from '@fsx/fsx-shared';
import { FieldDefinition } from '../types';

type RequiredTypes = TextFieldDefinition;

export function hasMinLength(
  fieldDefinition: FieldDefinition
): fieldDefinition is RequiredTypes {
  return (fieldDefinition as RequiredTypes).minLength !== undefined;
}
