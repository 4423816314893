export interface ContactEndPoints {
  contactProfile: () => string;
  createContact: () => string;
  getContacts: () => string;
  getContact: (id: string) => string;
  updateContact: (id: string) => string;
}

export const contactEndPoints = (versionedUrl: string): ContactEndPoints => {
  const baseUrl = `${versionedUrl}/contacts`;

  return {
    contactProfile: () => `${baseUrl}/profile`,
    createContact: () => baseUrl,
    getContacts: () => `${baseUrl}/query`,
    getContact: (id: string) => `${baseUrl}/${id}`,
    updateContact: (id: string) => `${baseUrl}/${id}`,
  };
};
