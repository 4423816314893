import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AddressViewModel,
  AliasFieldDefinition,
  CombinedFilingData,
  ContactAliasFormGroup,
  ContactAliasViewModel,
  ContactFormGroup,
  ContactFormMode,
  ContactOrganizationFormGroup,
  ContactOrganizationViewModel,
  ContactPersonFormGroup,
  ContactProfile,
  ContactSpec,
  ContactType,
  ContactViewModel,
  EmailAddressSpec,
  EmailAddressViewModel,
  FilingProfile,
  IdentificationCommonCategory,
  IdentificationSpec,
  IdentificationViewModel,
  OrganizationSpec,
  ParticipantCategory,
  ParticipantSpec,
  PersonalNameViewModel,
  PersonNameSpec,
  PhoneSpec,
  PhoneViewModel,
  RequestContactOrganizationViewModel,
  RequestParticipantAliasViewModel,
  RequestParticipantViewModel,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  EmailFormGroup,
  FormArrayWithModel,
  FormControlWithModel,
  FsxAddressComponent,
  FsxAliasComponent,
  FsxEmailComponent,
  FsxIdentificationComponent,
  FsxPersonNameComponent,
  FsxPhoneComponent,
  FsxTextComponent,
  IdentificationFormGroup,
  PersonNameFormGroup,
  PhoneFormGroup,
} from '@fsx/ui-components';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import {
  FsxEditParticipantOrchestrationService,
  IEditParticipantOrchestrationService,
} from '../../parties/orchestration-services/edit-participant-orchestration.service';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  ContactFormService,
  FsxContactFormService,
  IContactFormService,
} from './contact-form.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import {
  FsxContactsListService,
  IContactsListService,
} from '../contacts-list/contacts-list.service';

/**
 * The config object for the contacts form component.
 */
export interface ContactFormConfig {
  formMode: ContactFormMode;

  /**
   * The contact to edit in the contact form (or null if there is no contact)
   */
  contact: ContactViewModel | null;

  /**
   * The contact profile as loaded from the server.
   */
  contactProfile: ContactProfile;
}

const ShowAllOptions = {
  YES: true,
  NO: false,
};
@Component({
  selector: 'fsx-contacts-form',
  templateUrl: 'contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: FsxContactFormService,
      useClass: ContactFormService,
    },
  ],
})
export class ContactsFormComponent implements OnInit, OnDestroy {
  /**
   * The initialised formGroup passed in from the parent component. We pass this in
   * as an input binding (rather than instantiate it in this class) so that the parent
   * can take responsibility for triggering the form submission; this form component
   * can then be simplified as just a presenter component.
   */
  @Input() contactFormGroup!: FormGroup<ContactFormGroup>;

  @Input() contact!: ContactViewModel | null;

  @Input() contactProfile!: ContactProfile;

  @Input() formMode!: ContactFormMode;
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() participantCategory!: ParticipantCategory;
  @Output() contactTypeChanged = new EventEmitter<ContactType>();
  @Output() isFormValidEvent = new EventEmitter<boolean>();

  @ViewChild('personNameField') personNameField!: FsxPersonNameComponent;
  @ViewChild('organizationField') organizationField!: FsxTextComponent;
  @ViewChildren('barNumberField')
  barNumberFields!: QueryList<FsxIdentificationComponent>;
  @ViewChildren('addressField') addressFields!: QueryList<FsxAddressComponent>;
  @ViewChildren('phoneField') phoneFields!: QueryList<FsxPhoneComponent>;
  @ViewChildren('emailField') emailFields!: QueryList<FsxEmailComponent>;
  @ViewChildren('identificationField')
  identificationFields!: QueryList<FsxIdentificationComponent>;
  @ViewChildren('aliasField') aliasFields!: QueryList<FsxAliasComponent>;

  public ContactType = ContactType;
  public contactType: ContactType = ContactType.Person;
  public contactFormMode = ContactFormMode;

  public showProgressBar = false;
  public participantSpec!: ParticipantSpec | undefined;

  public resolver!: FsxReferenceResolver;
  public personNameFieldDefinition!: PersonNameSpec;
  public personNameInitialValues!: PersonalNameViewModel;
  public organizationFieldDefinition!: OrganizationSpec;
  public organizationInitialValues!:
    | ContactOrganizationViewModel
    | RequestContactOrganizationViewModel;
  public addressFieldDefinition!: AddressComponentFieldDefinition;
  public addressInitialValues: AddressViewModel[] = [];
  public phoneFieldDefinition!: PhoneSpec;
  public phoneInitialValues: PhoneViewModel[] = [];
  public barIdentificationInitialValues: IdentificationViewModel[] = [];
  public otherIdentificationInitialValues: IdentificationViewModel[] = [];
  public identificationFieldDefinition!: IdentificationSpec;
  public identificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;
  public showBarIdentificationComponent = false;
  public barFormArray!: FormArrayWithModel<FormGroup<IdentificationFormGroup>>;
  public showOtherIdentificationComponent = false;
  public otherIdentificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;
  public emailFieldDefinition!: EmailAddressSpec;
  public emailInitialValues: EmailAddressViewModel[] = [];
  public aliasFieldDefinition!: AliasFieldDefinition;
  public aliasInitialValues:
    | RequestParticipantAliasViewModel[]
    | ContactAliasViewModel[] = [];
  public idLength!: number;

  private destroy$: Subject<unknown> = new Subject();

  constructor(
    @Inject(FsxEditParticipantOrchestrationService)
    readonly editParticipantOrchestrationService: IEditParticipantOrchestrationService,
    @Inject(FsxContactFormService)
    readonly contactFormService: IContactFormService,
    @Inject(FsxPanelService) public readonly panelService: IPanelService,
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxValidatePartiesOrchestrationService)
    readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService
  ) {}

  ngOnInit(): void {
    if (!this.contactFormGroup) {
      this.contactFormGroup = new FormGroup<ContactFormGroup>({
        contactType: new FormControl(this.contactType, { nonNullable: true }),
      });
    }

    this.setContactForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setActiveForm(contactType: ContactType): void {
    this.contactType = contactType;
    this.contactFormGroup.controls.contactType.setValue(this.contactType);
    this.contactTypeChanged.emit(this.contactType);
  }

  private _setFormValidityListener(forceValidation: boolean): void {
    combineLatest([
      this.contactFormGroup.valueChanges,
      this.contactFormGroup.statusChanges,
    ])
      .pipe(
        debounceTime(500),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        tap(() => {
          const validForm = this.contactFormService.validateFormGroup(
            this.contactFormGroup,
            forceValidation
          );

          const isValid = validForm && this.contactFormGroup.valid;
          this.isFormValidEvent.emit(isValid);

          // this.validate();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public closeFormDialog(): void {
    this.panelService.closeCurrentDialog();
  }

  private setContactForm(): void {
    // this.contactProfile = contactProfile;
    this.resolver = new FsxReferenceResolver(this.contactProfile, {});
    this._setComponentsData(this.contact);
    this._setContactType(this.contact);
    this._setComponentsSpecs(this.contactProfile.contact, this.contactProfile);

    if (this.contact?.type) {
      this.formMode = ContactFormMode.EditContact;
      this.contactType = this.contact?.type;
    } else {
      this.formMode = ContactFormMode.AddContact;
    }
    this._setFormValidityListener(false);
  }

  public setPersonNameFormGroup(
    formGroup: FormGroup<PersonNameFormGroup>
  ): void {
    const personNameForm = new FormGroup<ContactPersonFormGroup>({
      personalName: formGroup,
    });
    this.contactFormGroup.setControl('person', personNameForm);
  }

  public setOrganizationFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>
  ): void {
    const organizationForm = new FormGroup<ContactOrganizationFormGroup>({
      title: formControl,
    });
    this.contactFormGroup.setControl('organization', organizationForm);
  }

  public setAddressFromArray(
    addressFormArray: FormArrayWithModel<FormGroup<AddressFormGroup>>
  ): void {
    this.contactFormGroup.setControl('addresses', addressFormArray);
  }

  public setPhoneFormArray(
    setPhoneFormArray: FormArrayWithModel<FormGroup<PhoneFormGroup>>
  ): void {
    this.contactFormGroup.setControl('phones', setPhoneFormArray);
  }

  public setEmailFormArray(
    emailFormArray: FormArrayWithModel<FormGroup<EmailFormGroup>>
  ): void {
    this.contactFormGroup.setControl('emails', emailFormArray);
  }

  public setBarFormArray(
    identificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >
  ): void {
    this.contactFormGroup.setControl(
      'barIdentifications',
      identificationFormArray
    );
    this.barFormArray = identificationFormArray;
    this.barFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength = this.getIdentificationsCount(
            this.barFormArray,
            this.otherIdentificationFormArray
          );
        })
      )
      .subscribe();
  }

  public setOtherIdFormArray(
    identificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >
  ): void {
    this.contactFormGroup.setControl(
      'otherIdentifications',
      identificationFormArray
    );
    this.otherIdentificationFormArray = identificationFormArray;
    this.otherIdentificationFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength = this.getIdentificationsCount(
            this.barFormArray,
            this.otherIdentificationFormArray
          );
        })
      )
      .subscribe();
  }

  public setAliasFromArray(
    formArray: FormArrayWithModel<FormGroup<ContactAliasFormGroup>>
  ): void {
    this.contactFormGroup.setControl('aliases', formArray);
  }

  public validate(): void {
    // TODO - this needs to be cleverer, taking into account the specs for
    // each child-component. For example, if emails has a minRequired of 0, it's OK
    // to have an empty email (event though the email address field itself is required)

    if (this.personNameField) {
      this.personNameField.validate();
    }

    if (this.organizationField) {
      this.organizationField.validate();
    }

    if (this.barNumberFields && this.barNumberFields.length > 0) {
      this.barNumberFields.forEach((fld: FsxIdentificationComponent) => {
        return fld.validate();
      });
    }

    if (this.addressFields && this.addressFields.length > 0) {
      this.addressFields.forEach((fld: FsxAddressComponent) => {
        fld.validate();
      });
    }

    if (this.phoneFields && this.phoneFields.length > 0) {
      this.phoneFields.forEach((fld: FsxPhoneComponent) => {
        fld.validate();
      });
    }

    if (this.emailFields && this.emailFields.length > 0) {
      this.emailFields.forEach((fld: FsxEmailComponent) => {
        fld.validate();
      });
    }

    if (this.identificationFields && this.identificationFields.length > 0) {
      this.identificationFields.forEach((fld: FsxIdentificationComponent) => {
        fld.validate();
      });
    }

    if (this.aliasFields && this.aliasFields.length > 0) {
      this.aliasFields.forEach((fld: FsxAliasComponent) => {
        fld.validate();
      });
    }
  }

  private getIdentificationsCount(
    barFormArray: FormArrayWithModel<FormGroup<IdentificationFormGroup>>,
    otherIdentificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >
  ) {
    const barControlsCount = barFormArray?.controls?.length ?? 0;
    const otherControlsCount =
      otherIdentificationFormArray?.controls?.length ?? 0;

    return barControlsCount + otherControlsCount;
  }

  private _setComponentsSpecs(
    spec: ParticipantSpec | ContactSpec | undefined,
    profile: ContactProfile | FilingProfile
  ): void {
    if (spec?.person?.personalName) {
      this.personNameFieldDefinition = spec.person.personalName;
    }

    if (spec?.organization) {
      this.organizationFieldDefinition = spec.organization;
    }

    if (spec?.address) {
      const profileName =
        spec.address.addressProfileName ?? profile.defaultAddressProfileName;

      const addressCategoriesDefinition = profile.addressProfiles.find(
        (prof) => prof.name === profileName
      );

      if (addressCategoriesDefinition) {
        this.addressFieldDefinition = {
          ...spec.address,
          ...addressCategoriesDefinition.spec,
        };
      }
    }

    if (spec?.phone) {
      this.phoneFieldDefinition = spec.phone;
    }

    if (spec?.email) {
      this.emailFieldDefinition = spec.email;
    }

    if (spec?.identification) {
      this.identificationFieldDefinition = spec.identification;
      if (spec.identification.category?.listReference) {
        const idCategories =
          this.resolver.getIdentificationCategoryDropdownOptions(
            spec.identification.category?.listReference,
            ShowAllOptions.YES
          );
        if (
          idCategories.filter((option) => {
            return (
              option.category?.commonCategory !=
              IdentificationCommonCategory.BarNumber
            );
          }).length > 0
        ) {
          this.showOtherIdentificationComponent = true;
        }
        if (
          idCategories.filter((option) => {
            return option.category?.commonCategory == 'BarNumber';
          }).length > 0
        ) {
          this.showBarIdentificationComponent = true;
        }
      }
    }

    if (spec?.alias) {
      this.aliasFieldDefinition = spec.alias;
    }
  }

  private _setParticipantContactType(
    contact: RequestParticipantViewModel | null
  ): void {
    if (contact?.contactType) {
      this.contactType = contact?.contactType;
      this.contactFormGroup.controls.contactType.setValue(this.contactType);
    }
  }

  private _setContactType(contact: ContactViewModel | null): void {
    if (contact?.type) {
      this.contactType = contact?.type;
      this.contactFormGroup.controls.contactType.setValue(this.contactType);
    }
  }

  private _setComponentsData(contact: ContactViewModel | null): void {
    if (contact?.person?.personalName) {
      this.personNameInitialValues = contact?.person?.personalName;
    }

    if (contact?.organization) {
      this.organizationInitialValues = contact.organization;
    }

    if (contact?.addresses) {
      this.addressInitialValues = contact.addresses;
    }

    if (contact?.emails) {
      this.emailInitialValues = contact.emails;
    }

    if (contact?.phones) {
      this.phoneInitialValues = contact.phones;
    }

    if (contact?.identifications) {
      this.barIdentificationInitialValues = contact.identifications.filter(
        (identification) => {
          return (
            identification.category.commonCategory ===
            IdentificationCommonCategory.BarNumber
          );
        }
      );
      this.otherIdentificationInitialValues = contact.identifications.filter(
        (identification) => {
          return (
            identification.category.commonCategory !==
            IdentificationCommonCategory.BarNumber
          );
        }
      );
    }

    if (contact?.aliases) {
      this.aliasInitialValues = contact.aliases;
    }
  }
}
