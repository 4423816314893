import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxValueValidator(
  value: number,
  allowDecimals: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const inputValue = String(control.value).replace(/,/g, '');

    if (+inputValue <= value) {
      return null;
    }

    return {
      invalidMaxValue: `Value must be less than or equal to ${Number(
        value
      ).toLocaleString()}${allowDecimals ? '.00' : ''}`,
    };
  };
}
