<div
  class="activity-menu-bar-container"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <span
    [routerLink]="[childRouteEnum.SUBMITTED]"
    [ngClass]="
      (activeChildRoute$ | async) === childRouteEnum.SUBMITTED
        ? 'routeLink active'
        : 'routeLink inactive'
    "
    >Transaction Activity
  </span>
  <ng-container *ngIf="activityTransactionName | async as transactionId">
    <span class="spacer">></span>
    <span class="routeLink active sub"> Transaction {{ transactionId }}</span>
  </ng-container>
  <span>
    <span class="spacer">|</span>
    <span
      [routerLink]="[childRouteEnum.DRAFTS]"
      [ngClass]="
        (activeChildRoute$ | async) === childRouteEnum.DRAFTS
          ? 'routeLink active'
          : 'routeLink inactive'
      "
      >Drafts
    </span>
  </span>
  <ng-container *ngIf="recentTransactionId && workspaceTabCount">
    <span class="spacer">|</span>
    <span
      *ngIf="recentTransactionId && workspaceTabCount"
      [ngClass]="
        (activeChildRoute$ | async) === childRouteEnum.EDITOR
          ? 'routeLink active'
          : 'routeLink inactive'
      "
      (click)="onWorkspaceClicked()"
    >
      Workspace
      <div id="number">
        <span>{{ workspaceTabCount }}</span>
      </div>
    </span>
  </ng-container>
  <mat-divider class="activity-divider"></mat-divider>
</div>
