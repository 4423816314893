<div id="new-filing-component">
  <ng-container *ngIf="browsingCourts; else searchCourts">
    <div class="browse-courts-container">
      <button
        class="transparent text-button"
        (click)="toggleBrowsingCourts()"
        (keydown.enter)="toggleBrowsingCourts()"
      >
        <p class="static-text-small">Back to Court Search</p>
      </button>

      <p class="static-text-medium">Select Court</p>

      <fsx-court-selection-component
        *ngIf="courtSelectedData"
        fsxFocusFirstInput
        [courtSelectionData]="courtSelectedData"
        (browsedCourtSelected)="browsedCourtSelected($event)"
      >
      </fsx-court-selection-component>
    </div>
  </ng-container>

  <ng-template #searchCourts>
    <div *ngIf="courtSelectedDropdownOption; else noCourtSelected">
      <p class="static-text-medium">Filing into Court</p>

      <fsx-option-selected-card
        [cardIcon]="'court'"
        [primaryCaption]="courtSelectedDropdownOption.caption"
        [secondaryCaption]="courtPath"
        [tertiaryCaption]="courtState"
        (resetOptionEmitter)="resetCourtSelected()"
      ></fsx-option-selected-card>
    </div>
    <ng-template #noCourtSelected>
      <div class="search-courts-container">
        <p class="static-text-medium">Which Court are you filing into?</p>

        <fsx-court-search-component
          fsxFocusFirstInput
          *ngIf="courtSelectedData"
          [courtData]="courtSelectedData"
          (optionSelected)="courtSelected($event)"
        ></fsx-court-search-component>

        <button
          class="browse-courts-button transparent text-button"
          (click)="toggleBrowsingCourts()"
          (keydown.enter)="toggleBrowsingCourts()"
        >
          <p class="static-text-small">Browse for courts</p>
        </button>
      </div>
    </ng-template>
  </ng-template>

  <div class="profile-container" *ngIf="courtId">
    <ng-container *ngIf="browsingProfiles; else searchProfiles">
      <button
        class="back transparent text-button"
        (click)="toggleBrowsingProfiles()"
        (keydown.enter)="toggleBrowsingProfiles()"
      >
        <p class="static-text-small">Back to Case Type Search</p>
      </button>

      <p class="static-text-medium">Select the Case Type you are Filing into</p>

      <fsx-profile-selection-component
        *ngIf="profileSelectedData"
        fsxFocusFirstInput
        [profileSelectionData]="profileSelectedData"
        (browsedProfileSelected)="browsedProfileSelected($event)"
      ></fsx-profile-selection-component>
    </ng-container>

    <ng-template #searchProfiles>
      <div *ngIf="profileSelectedDropdownOption; else noProfileSelected">
        <p class="static-text-medium">Type of Case</p>

        <fsx-option-selected-card
          [cardIcon]="'profile'"
          [primaryCaption]="profileSelectedDropdownOption.caption"
          [secondaryCaption]="profilePath"
          (resetOptionEmitter)="resetProfileSelected()"
        ></fsx-option-selected-card>
      </div>

      <ng-template #noProfileSelected>
        <p class="static-text-medium">
          Which Type of Case are you Filing into?
        </p>

        <fsx-profile-search-component
          *ngIf="profileSelectedData"
          fsxFocusFirstInput
          [profileData]="profileSelectedData"
          (optionSelected)="profileSelected($event)"
        ></fsx-profile-search-component>

        <button
          class="transparent text-button"
          (click)="toggleBrowsingProfiles()"
          (keydown.enter)="toggleBrowsingProfiles()"
        >
          <p class="static-text-small">Select Case Type</p>
        </button>
      </ng-template>
    </ng-template>
  </div>
</div>
