import { NgModule } from '@angular/core';
import { PartiesRoutingModule } from './parties-routing.module';
import { PartiesComponent } from './parties.component';
import { PartiesGridComponent } from './parties-grid/parties-grid.component';
import { PartiesActionsComponent } from './parties-actions/parties-actions.component';
import { ParticipantTableComponent } from './participant-table/participant-table.component';
import { SharedModule } from '@fsx/fsx-shared';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { RepresentationGridComponent } from './representation-grid/representation-grid.component';
import { RepresentationGridItemComponent } from './representation-grid-item/representation-grid-item.component';
import { ValidationModule } from '../validation/validation.module';
import { ContactsModule } from '../contacts/contacts.module';
import { PartyIsValid } from './parties-grid/parties-grid-model-validation.pipe';

@NgModule({
  declarations: [
    PartiesComponent,
    PartiesGridComponent,
    PartiesActionsComponent,
    ParticipantTableComponent,
    RepresentationGridComponent,
    RepresentationGridItemComponent,
    PartyIsValid,
  ],
  imports: [
    SharedModule,
    FsxUiComponentsModule,
    PartiesRoutingModule,
    ContactsModule,
    ValidationModule,
  ],
  exports: [
    PartiesActionsComponent,
    PartiesComponent,
    ParticipantTableComponent,
  ],
})
export class PartiesModule {}
