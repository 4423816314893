import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AddressViewModel,
  ContactFieldDefinition,
  ContactFormGroup,
  ContactFormMode,
  ContactOrganizationFormGroup,
  ContactOrganizationViewModel,
  ContactPersonFormGroup,
  ContactType,
  EmailAddressSpec,
  EmailAddressViewModel,
  IdentificationCommonCategory,
  IdentificationSpec,
  IdentificationViewModel,
  OrganizationSpec,
  PersonalNameViewModel,
  PersonNameSpec,
  PhoneSpec,
  PhoneViewModel,
  RequestContact,
  RequestContactOrganizationViewModel,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import { FormArrayWithModel, FormControlWithModel } from '../../models';
import { FormGroup } from '@angular/forms';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  EmailFormGroup,
  IdentificationFormGroup,
  PersonNameFormGroup,
  PhoneFormGroup,
  ReferenceResolver,
} from '../../types';
import { debounceTime, tap } from 'rxjs';
import { FsxPersonNameComponent } from '../person-name/person-name.component';
import { FsxTextComponent } from '../text/text.component';
import { FsxIdentificationComponent } from '../identification/identification.component';
import { FsxAddressComponent } from '../address/address.component';
import { FsxPhoneComponent } from '../phone/phone.component';
import { FsxEmailComponent } from '../email/email.component';
// import { FsxAliasComponent } from '../alias/alias.component';

@Component({
  selector: 'fsx-contact-component',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class FsxContactComponent implements OnInit {
  @Input() fieldDefinition!: ContactFieldDefinition;
  @Input() formMode!: ContactFormMode;
  @Input() initialValues!: RequestContact[];
  @Input() readOnly = false;
  @Input() resolver!: ReferenceResolver;

  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<ContactFormGroup>>
  >(true);

  @ViewChildren('contactNameField')
  contactNameFields!: QueryList<FsxPersonNameComponent>;
  @ViewChildren('organizationField')
  organizationFields!: QueryList<FsxTextComponent>;
  @ViewChildren('barNumberField')
  barNumberFields!: QueryList<FsxIdentificationComponent>;
  @ViewChildren('addressField') addressFields!: QueryList<FsxAddressComponent>;
  @ViewChildren('phoneField') phoneFields!: QueryList<FsxPhoneComponent>;
  @ViewChildren('emailField') emailField!: QueryList<FsxEmailComponent>;
  @ViewChildren('identificationField')
  identificationFields!: QueryList<FsxIdentificationComponent>;
  // @ViewChildren('aliasField') aliasField!: QueryList<FsxAliasComponent>;

  public contactType: ContactType = ContactType.Person;

  // Person
  public personNameFieldDefinition!: PersonNameSpec;
  public personNameInitialValues: PersonalNameViewModel[] = [];

  // Organization
  public organizationFieldDefinition!: OrganizationSpec;
  public organizationInitialValues!:
    | ContactOrganizationViewModel
    | RequestContactOrganizationViewModel;

  // Address
  public addressFieldDefinition!: AddressComponentFieldDefinition | null;
  public addressInitialValues: AddressViewModel[] = [];

  // Phone
  public phoneFieldDefinition!: PhoneSpec;
  public phoneInitialValues: PhoneViewModel[] = [];

  // Email
  public emailFieldDefinition!: EmailAddressSpec;
  public emailInitialValues: EmailAddressViewModel[] = [];

  // Identification
  public identificationFieldDefinition!: IdentificationSpec;
  public identificationInitialValues: IdentificationViewModel[] = [];
  public barIdentificationInitialValues: IdentificationViewModel[] = [];
  public otherIdentificationInitialValues: IdentificationViewModel[] = [];
  public idLength!: number;
  public barFormArray!: FormArrayWithModel<FormGroup<IdentificationFormGroup>>;
  public otherIdentificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;
  public showBarIdentificationComponent = false;
  public showOtherIdentificationComponent = false;

  // Alias??

  // FormArray
  public contactFormArray!: FormArrayWithModel<FormGroup<ContactFormGroup>>;

  protected readonly contactTypeEnum = ContactType;
  protected readonly contactFormMode = ContactFormMode;

  ngOnInit(): void {
    const contactFormGroups = this.initialValues.length
      ? this.initialValues.map(
          () => new FormGroup<ContactFormGroup>({} as ContactFormGroup)
        )
      : [new FormGroup<ContactFormGroup>({} as ContactFormGroup)];
    this.contactFormArray = new FormArrayWithModel<FormGroup<ContactFormGroup>>(
      [...contactFormGroups],
      {
        minRequired: this.fieldDefinition?.minRequired ?? 0,
        maxAllowed: this.fieldDefinition?.maxAllowed ?? 0,
      }
    );

    this.setFieldDefinitions();
    this.setComponentData();

    // ToDo: Emit the form values
    this.formArrayEmitter.emit(this.contactFormArray);
  }

  private setFieldDefinitions(): void {
    if (!!this.fieldDefinition) {
      this.personNameFieldDefinition = this.fieldDefinition.person
        ?.personalName as PersonNameSpec; // Set Persona Field Definition
      this.organizationFieldDefinition = this.fieldDefinition
        .organization as OrganizationSpec; // Set Organization Field Definition
      this.addressFieldDefinition = this.setAddressFieldDefinition(); // Set Address Field Definition
      this.phoneFieldDefinition = this.fieldDefinition.phone as PhoneSpec;
      this.emailFieldDefinition = this.fieldDefinition
        .email as EmailAddressSpec;
      this.identificationFieldDefinition = this.fieldDefinition
        .identification as IdentificationSpec; // ToDo: Is there a profile with identificationFieldDefinition?

      if (
        !!this.identificationFieldDefinition &&
        this.identificationFieldDefinition.category?.listReference
      ) {
        const idCategories =
          this.resolver.getIdentificationCategoryDropdownOptions(
            this.identificationFieldDefinition.category?.listReference
          );
        if (
          idCategories.filter((option) => {
            return (
              option.category?.commonCategory !=
              IdentificationCommonCategory.BarNumber
            );
          }).length > 0
        ) {
          this.showOtherIdentificationComponent = true;
        }
        if (
          idCategories.filter((option) => {
            return option.category?.commonCategory == 'BarNumber';
          }).length > 0
        ) {
          this.showBarIdentificationComponent = true;
        }
      }
    }
  }

  private setComponentData(): void {
    const contactFormGroups: FormGroup<ContactFormGroup>[] = this.initialValues
      .length
      ? this.initialValues.map(
          () => new FormGroup<ContactFormGroup>({} as ContactFormGroup)
        )
      : [new FormGroup<ContactFormGroup>({} as ContactFormGroup)];
    this.contactFormArray = new FormArrayWithModel<FormGroup<ContactFormGroup>>(
      [...contactFormGroups],
      {
        minRequired: this.fieldDefinition?.minRequired ?? 0,
        maxAllowed: this.fieldDefinition?.maxAllowed ?? 0,
      }
    );
    if (this.initialValues.length) {
      this.initialValues.forEach((contact, index) => {
        // Person
        if (contact?.person?.personalName) {
          this.personNameInitialValues[index] = contact?.person?.personalName;
        }
        // Organization
        if (contact?.organization) {
          this.organizationInitialValues = contact.organization;
        }
        // Address
        if (contact?.addresses) {
          this.addressInitialValues = contact.addresses;
        }
        // Email
        if (contact?.emails) {
          this.emailInitialValues = contact.emails;
        }
        // Phone
        if (contact?.phones) {
          this.phoneInitialValues = contact.phones;
        }
        // Identifications
        if (contact?.identifications) {
          this.barIdentificationInitialValues = contact.identifications.filter(
            (identification) => {
              return (
                identification.category.commonCategory ===
                IdentificationCommonCategory.BarNumber
              );
            }
          );
          this.otherIdentificationInitialValues =
            contact.identifications.filter((identification) => {
              return (
                identification.category.commonCategory !==
                IdentificationCommonCategory.BarNumber
              );
            });
        }
        // Aliases
        // if (contact?.aliases) {
        //   this.aliasInitialValues = contact.aliases;
        // }
      });
    }
  }

  private setAddressFieldDefinition(): AddressComponentFieldDefinition | null {
    const profileName = this.fieldDefinition.address?.addressProfileName;
    const addressCategoriesDefinition = this.resolver.getAddressProfile(
      profileName ? profileName : null
    );
    if (addressCategoriesDefinition) {
      return {
        minRequired: this.fieldDefinition.address!.minRequired,
        maxAllowed: this.fieldDefinition.address!.maxAllowed,
        addressProfileName: this.fieldDefinition.address!.addressProfileName,
        country: this.fieldDefinition.address!.country,
        category: this.fieldDefinition.address!.category,
        description: this.fieldDefinition.address!.description,
        additionalFields: this.fieldDefinition.address!.additionalFields,
        ...addressCategoriesDefinition.spec,
      };
    }
    return null;
  }

  public setPersonNameFormGroup(
    formGroup: FormGroup<PersonNameFormGroup>,
    index: number
  ): void {
    const personNameForm = new FormGroup<ContactPersonFormGroup>({
      personalName: formGroup,
    });
    this.contactFormArray.controls[index].setControl('person', personNameForm);
  }

  public setOrganizationFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>,
    index: number
  ): void {
    const organizationForm = new FormGroup<ContactOrganizationFormGroup>({
      title: formControl,
    });
    let formGroup = this.getContactFormGroup(index);
    formGroup.setControl('organization', organizationForm);
  }

  // ToDo: Instead of just doing the Bar ID, set ALL identification entities
  public setBarFormArray(
    identificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >,
    index: number
  ): void {
    let formGroup = this.getContactFormGroup(index);
    formGroup.setControl('otherIdentifications', identificationFormArray);
    this.barFormArray = identificationFormArray;
    this.barFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength =
            this.barFormArray.controls.length +
            this.otherIdentificationFormArray.controls.length;
        })
      )
      .subscribe();
  }

  public setOtherIdFormArray(
    identificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >,
    index: number
  ): void {
    let formGroup = this.getContactFormGroup(index);
    formGroup.setControl('otherIdentifications', identificationFormArray);
    this.otherIdentificationFormArray = identificationFormArray;
    this.otherIdentificationFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength =
            this.barFormArray.controls.length +
            this.otherIdentificationFormArray.controls.length;
        })
      )
      .subscribe();
  }

  public setAddressFromArray(
    addressFormArray: FormArrayWithModel<FormGroup<AddressFormGroup>>,
    index: number
  ): void {
    const formGroup = this.getContactFormGroup(index);
    formGroup.setControl('addresses', addressFormArray);
  }

  public setPhoneFormArray(
    setPhoneFormArray: FormArrayWithModel<FormGroup<PhoneFormGroup>>,
    index: number
  ): void {
    let formGroup = this.getContactFormGroup(index);
    formGroup.setControl('phones', setPhoneFormArray);
  }

  public setEmailFormArray(
    emailFormArray: FormArrayWithModel<FormGroup<EmailFormGroup>>,
    index: number
  ): void {
    let formGroup = this.getContactFormGroup(index);
    formGroup.setControl('emails', emailFormArray);
  }

  public getContactFormGroup(index: number): FormGroup<ContactFormGroup> {
    let formGroup = this.contactFormArray.at(
      index
    ) as FormGroup<ContactFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<ContactFormGroup>({} as ContactFormGroup);
    }
    return formGroup;
  }

  public validate(): void {
    if (this.contactNameFields) {
      this.contactNameFields.forEach((fld) => fld.validate());
    }
    if (this.organizationFields) {
      this.organizationFields.forEach((fld) => fld.validate());
    }
    if (this.barNumberFields) {
      this.barNumberFields.forEach((fld) => fld.validate());
    }
    if (this.addressFields) {
      this.addressFields.forEach((fld) => fld.validate());
    }
    if (this.phoneFields) {
      this.phoneFields.forEach((fld) => fld.validate());
    }
    if (this.emailField) {
      this.emailField.forEach((fld) => fld.validate());
    }
    if (this.identificationFields) {
      this.identificationFields.forEach((fld) => fld.validate());
    }
    // if (this.aliasField) { this.aliasField.forEach(fld => fld.validate()); }
  }
}
