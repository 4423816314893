import { Injectable, InjectionToken } from '@angular/core';
import { FilingFees } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFilingFeesDataService to use at runtime.
 */
export const FsxFilingFeesDataService =
  new InjectionToken<IFilingFeesDataService>('FsxFilingFeesDataService');

/**
 * The data structure in which to store Filing Fees data
 */
export interface FilingFeesData {
  filingFees: FilingFees | null;
  isRefreshing: boolean;
}

/**
 * The initial state of the FilingFeesData as it's stored in
 * the FilingFeesDataService
 */
const initialFilingFeesData: FilingFeesData = {
  filingFees: null,
  isRefreshing: false,
};

/**
 * A blueprint for a state service, which stores FilingFeesData
 */
export interface IFilingFeesDataService {
  /**
   * The FilingFeesData exposed as an Observable
   */
  filingFeesData$: Observable<FilingFeesData>;

  /**
   * A method to allow setting of the filingFees object in the FilingFeesData
   */
  setFilingFees(filingFees: FilingFees): void;

  /**
   * A method to allow setting of the isRefreshing flag in the FilingFeesData
   */
  setIsRefreshingFlag(isRefreshing: boolean): void;
}

/**
 * A concrete implementation of a state service, which stores FilingFeesData
 */
@Injectable()
export class FilingFeesDataService implements IFilingFeesDataService {
  /**
   * The FilingFeesData stored in a BehaviorSubject so that it can be easily
   * exposed as an Observable.
   */
  private readonly filingFeesData$$ = new BehaviorSubject<FilingFeesData>(
    initialFilingFeesData
  );

  /**
   * The FilingFeesData exposed as an Observable
   */
  readonly filingFeesData$ = this.filingFeesData$$.asObservable();

  /**
   * A method to allow setting of the filingFees object in the FilingFeesData
   */
  setFilingFees(filingFees: FilingFees): void {
    this.filingFeesData$$.next({
      ...this.filingFeesData$$.value,
      filingFees: filingFees,
    });
  }

  /**
   * A method to allow setting of the isRefreshing flag in the FilingFeesData
   */
  setIsRefreshingFlag(isRefreshing: boolean): void {
    this.filingFeesData$$.next({
      ...this.filingFeesData$$.value,
      isRefreshing: isRefreshing,
    });
  }
}
