import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NumberFieldDefinition, IValidatable } from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxNumberValidationService =
  new InjectionToken<INumberValidationService>('FsxNumberValidationService');

export interface INumberValidationService {
  validateNumber(
    value: string | null | undefined,
    spec: NumberFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean;
}

@Injectable()
export class NumberValidationService implements INumberValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService
  ) {}

  public validateNumber(
    value: string | null | undefined,
    spec: NumberFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean {
    if (!spec) {
      return true;
    }

    let decimalValue: number;

    if (value) {
      decimalValue = parseFloat(value);
    } else {
      if (spec.required) {
        scope.isValid = false;
        return false;
      }
      return true;
    }

    if (Number.isNaN(decimalValue)) {
      scope.isValid = false;
      return false;
    }

    if (spec.minValue && decimalValue < spec.minValue) {
      scope.isValid = false;
      return false;
    }

    if (spec.maxValue && decimalValue > spec.maxValue) {
      scope.isValid = false;
      return false;
    }

    if (
      !!value &&
      this.validationHelperService.countDecimals(value) > spec.allowedDecimals
    ) {
      scope.isValid = false;
      return false;
    }

    return true;
  }
}
