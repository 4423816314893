import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FilingFees,
  FsxFilingApiService,
  IFilingApiService,
} from '@fsx/fsx-shared';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { GetFeesError } from './orchestration/load-filing-fees-orchestration.service';
import {
  FsxFilingChecklistService,
  IFilingChecklistService,
} from '../../validation/filing-checklist/filing-checklist.service';
import {
  FsxFilingFeesDataService,
  IFilingFeesDataService,
} from './filing-fees-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ValidationError,
} from './validation-errors.service';
import {
  FsxValidationResultMappingService,
  IValidationResultMappingService,
} from './validation-result-mapping.service';
import { ValidationGroupConstants } from './validation-group-errors.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFilingFeesGetterService to use at runtime.
 */
export const FsxFilingFeesGetterService =
  new InjectionToken<IFilingFeesGetterService>('FsxFilingFeesGetterService');

/**
 * A blueprint for a worker service, which handles the retrieval of filing fees, as
 * consumed by the LoadFilingFeesOrchestrationService and SubmitFilingOrchestrationService.
 */
export interface IFilingFeesGetterService {
  /**
   * a method for getting fees and handling the response whether successful or failure.
   *
   * @param filingId The id of the filing to load fees for.
   */
  getFilingFees(
    filingId: string,
    suppressErrors: boolean
  ): Observable<FilingFeesResponse>;
}

/**
 * The data structure of the object returned by the FilingFeesGetterService's
 * getFilingFees method.
 */
export interface FilingFeesResponse {
  filingFees: FilingFees | null;
  error: GetFeesError | null;
}

/**
 * A concrete implementation of a worker service, which handles the retrieval of filing fees,
 * as consumed by the LoadFilingFeesOrchestrationService and SubmitFilingOrchestrationService.
 */
@Injectable()
export class FilingFeesGetterService implements IFilingFeesGetterService {
  /**
   * a method for getting fees and handling the response whether successful or failure.
   *
   * @param filingId The id of the filing to load fees for.
   * @param suppressErrors Whether or not to display errors when the call fails.
   */
  getFilingFees(
    filingId: string,
    suppressErrors: boolean
  ): Observable<FilingFeesResponse> {
    return of(filingId).pipe(
      switchMap((filingId: string) => {
        // Remove any existing server validation error messages, before making any server calls
        // which could result in the setting of server validation error messages. This enables us
        // to get out of the error state if we have run a failed get fees call previously.
        this.validationErrorsService.removeServerValidationErrors();

        // Also remove our generic 'fees' error.
        this.validationErrorsService.removeValidationError('fees');

        // Set a flag to use in the ui, to notify the user that the fees are being loaded.
        this.filingFeesDataService.setIsRefreshingFlag(true);

        // Make the call to get fees
        return this.filingApiService.getFees(filingId).pipe(
          map((filingFees: FilingFees) => {
            // Handle the success response...
            this.filingFeesDataService.setFilingFees(filingFees);
            return {
              filingFees,
              error: null,
            };
          }),
          catchError((err: GetFeesError) => {
            console.error('Error getting fees:', err);
            // Handle the error response...
            if (!suppressErrors) {
              const serverValidationErrors: ValidationError[] =
                this.validationResultMappingService.map(
                  err.error.validationResult!
                );

              // Add any server errors to our validation errors service.
              serverValidationErrors.forEach(
                (validationError: ValidationError) => {
                  this.validationErrorsService.addValidationError(
                    validationError
                  );
                }
              );

              // Add our own generic error to the validation error service, in case we get nothing back from the server.
              this.validationErrorsService.addValidationError({
                errorCode: 'fees',
                errorMessage:
                  'Unable to obtain fees. Please review validation information and retry',
                group: ValidationGroupConstants.review,
              });

              // Set the checklist visible since we know we have new errors to display.
              this.filingChecklistService.setVisibility(true);
            }
            return of({
              filingFees: null,
              error: err,
            });
          })
        );
      }),
      tap(() => {
        // Whether the call was a success or failure, we always reset the flag to update the ui.
        this.filingFeesDataService.setIsRefreshingFlag(false);
      })
    );
  }
  /**
   *
   * @param filingChecklistService The ui state service that can handle the displaying of the checklist when we have errors.
   * @param validationErrorsService The validation errors service, which we use to add any new errors to for display in the checklist.
   * @param filingApiService The api service, which contains the endpoint for getting fees.
   * @param filingFeesDataService The ui state service, which we add the loaded fees to and set the refreshing flag.
   * @param validationResultMappingService The mapping service for converting server validation errors into validation errors that can be displayed in the checklist.
   */
  public constructor(
    @Inject(FsxFilingChecklistService)
    private readonly filingChecklistService: IFilingChecklistService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxFilingFeesDataService)
    private readonly filingFeesDataService: IFilingFeesDataService,
    @Inject(FsxValidationResultMappingService)
    private readonly validationResultMappingService: IValidationResultMappingService
  ) {}
}
