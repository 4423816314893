import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseFileUploadComponent,
  IUploadedFile,
} from './base-file-upload.component';

@Component({
  selector: 'fsx-file-upload-small',
  templateUrl: './file-upload-small.component.html',
  styleUrls: ['./file-upload-small.component.scss'],
})
export class FileUploadSmallComponent extends BaseFileUploadComponent {
  @ViewChild('fileDropRef3') fileDropEl!: ElementRef;
  @Output() filesUploadedEvent: EventEmitter<IUploadedFile[]> =
    new EventEmitter<IUploadedFile[]>();

  override prepareFilesList(files: FileList | null): void {
    super.prepareFilesList(files);
    this.fileDropEl.nativeElement.value = '';
    this.filesUploadedEvent.emit(this.files);
  }
}
