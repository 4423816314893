import { ValidationModule } from './../validation/validation.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsComponent } from './documents.component';
import { FileUploadDropTargetDirective } from '../../../../../libs/components/src/lib/components/file-upload/file-drag-drop.directive';
import { SharedModule } from '@fsx/fsx-shared';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { DocumentsGridComponent } from './documents-grid/documents-grid.component';
import { DocumentsGridItemComponent } from './documents-grid-item/documents-grid-item.component';
import { DocumentsActionsComponent } from './documents-actions/documents-actions.component';
import { TotalDocumentsComponent } from './total-documents/total-documents.component';
import { TotalSizeComponent } from './total-size/total-size.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import { FileUploadStatusComponent } from './file-upload-status/file-upload-status.component';
import { DocumentOrderNumberPipe } from '../../../../../libs/shared/src/lib/pipes/document-order-number.pipe';
import { ParticipantFieldComponent } from './participant-field/participant-field.component';
import { PartiesModule } from '../parties/parties.module';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentsGridComponent,
    DocumentsGridItemComponent,
    DocumentsActionsComponent,
    TotalDocumentsComponent,
    TotalSizeComponent,
    DocumentFormComponent,
    FileUploadStatusComponent,
    ParticipantFieldComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FsxUiComponentsModule,
    DocumentsRoutingModule,
    ValidationModule,
    PartiesModule,
  ],
  exports: [
    DocumentsComponent,
    FileUploadDropTargetDirective,
    DocumentsActionsComponent,
    TotalDocumentsComponent,
  ],
  providers: [DocumentOrderNumberPipe],
})
export class DocumentsModule {}
