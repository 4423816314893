import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NgxExtendedPdfViewerModule,
  PagesLoadedEvent,
} from 'ngx-extended-pdf-viewer';
import { FsxFilingApiService, IFilingApiService } from '@fsx/fsx-shared';
import {
  catchError,
  filter,
  ignoreElements,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { OpenPdfViewerParams } from './pdf-viewer.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'fsx-pdf-viewer',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    NgxExtendedPdfViewerModule,
    MatTooltipModule,
  ],
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @Input() allowSidePanel: boolean = true;
  @Input() allowClose: boolean = true;
  @Input() pdfViewerParams!: OpenPdfViewerParams;
  // The following properties are used by the ngx-extended-pdf-viewer component, and will be the default values if not set.
  @Input() showBorders: boolean = false;
  @Input() showToolbar: boolean = true;
  @Input() showSidebarButton: boolean = true;
  @Input() showFindButton: boolean = true;
  @Input() showPagingButtons: boolean = false;
  @Input() showDrawEditor: boolean = false;
  @Input() showTextEditor: boolean = false;
  @Input() showZoomButtons: boolean = true;
  @Input() showPresentationModeButton: boolean = true;
  @Input() showOpenFileButton: boolean = false;
  @Input() showPrintButton: boolean = true;
  @Input() showDownloadButton: boolean = true;
  @Input() showSecondaryToolbarButton: boolean = true;
  @Input() showRotateButton: boolean = false;
  @Input() showHandToolButton: boolean = false;
  @Input() showScrollingButton: boolean = false;
  @Input() showSpreadButton: boolean = true;
  @Input() showPropertiesButton: boolean = true;
  @Input() zoom: number = 80;

  // Observable that will be used to retrieve the PDF document from the API.
  public pdfSrc$: Observable<ArrayBuffer> | undefined;
  public pdfSrcError$: Observable<Error> | undefined;

  // Subject that will be used to unsubscribe from observables.
  private destroy$: Subject<void> = new Subject<void>();

  // Component properties that will be used to control the ngx-extended-pdf-viewer component.
  public isLoading = true;
  public currentPage!: number;
  public pagesCount!: number;
  public fileNameForDownload: string = 'document.pdf';
  public hideSidePanel: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OpenPdfViewerParams,
    public dialogRef: MatDialogRef<PdfViewerComponent>,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.fileNameForDownload = this.data.fileName;
    this.pdfSrc$ = this.filingApiService.getDocumentRendering(
      this.data.filingId ?? this.pdfViewerParams.filingId,
      this.data.documentId ?? this.pdfViewerParams.documentId,
      this.data.renderingName ?? this.pdfViewerParams.renderingName
    );
    // Handle an error if something goes wrong when retrieving the PDF document since using Async pipe in the template.
    this.pdfSrcError$ = this.pdfSrc$.pipe(
      takeUntil(this.destroy$),
      ignoreElements(),
      catchError((error: Error) => of(error))
    );

    // Close dialog ref on route changes
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationStart),
        tap(() => this.onCloseButtonClicked()),
        take(1)
      )
      .subscribe();
  }

  onCloseButtonClicked() {
    this.dialogRef.close();
  }

  public setPageAndPagesCount(event: PagesLoadedEvent): void {
    this.pagesCount = event.pagesCount;
    this.currentPage = 1;
    this.isLoading = false;
  }

  public prevPage(): void {
    this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
  }

  public nextPage(): void {
    this.currentPage =
      this.currentPage < this.pagesCount
        ? this.currentPage + 1
        : this.pagesCount;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
