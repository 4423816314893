import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import {
  CaseRequestCaseSummary,
  CaseRequestSubStatus,
  CombinedFilingData,
  DocumentReviewRequestStatus,
  Filing,
  FilingMode,
  FilingState,
  FsxFilingApiService,
  IFilingApiService,
  RenderingNameEnum,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import {
  catchError,
  combineLatest,
  EMPTY,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  OpenPdfViewerParams,
  PdfViewerService,
} from '../../../../../../libs/components/src/lib/components/pdf-viewer/pdf-viewer.service';
import { BookmarkTransactionOrchestrationService } from '../../transactions/services/orchestration/bookmark-transaction-orchestration.service';
import { FilingSubmissionReceiptService } from '../../filing-submission-receipt/filing-submission-receipt.service';
import {
  TransactionInfo,
  TransactionDocument,
  TransactionInfoService,
} from '../services/transaction-info.service';

@Component({
  selector: 'fsx-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  // Component Inputs/Outputs
  @Input() combinedFilingData!: CombinedFilingData | null;

  // Component Variables
  firstCase!: CaseRequestCaseSummary;
  isDetailsCollapsed = false;
  requestDocuments!: RequestDocumentViewModel[];
  transaction!: TransactionInfo;

  showDocumentsServed = false;
  showDocumentHistory = false;

  activeDocument!: TransactionDocument;
  activeDocumentIndex!: number;

  // Observables
  private destroy$: Subject<void> = new Subject<void>();

  // Misc.
  protected readonly FilingMode = FilingMode;
  protected readonly FilingState = FilingState;
  protected readonly DocumentReviewRequestStatus = DocumentReviewRequestStatus;
  protected readonly CaseRequestSubStatus = CaseRequestSubStatus;

  private actionStreams$ = combineLatest([
    this.bookmarkTransactionOrchestrationService.bookmarkTransaction$,
  ]);

  constructor(
    private readonly bookmarkTransactionOrchestrationService: BookmarkTransactionOrchestrationService,
    private domSanitizerService: DomSanitizer,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly pdfViewerService: PdfViewerService,
    private readonly filingSubmissionReceiptService: FilingSubmissionReceiptService,
    private readonly transactionInfoService: TransactionInfoService
  ) {
    this.actionStreams$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnInit(): void {
    this._setComponentData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _setComponentData(): void {
    // ToDo: Implement better way for loading display value.
    if (!!this.combinedFilingData) {
      this.firstCase = this.combinedFilingData.filing.courtCases[0];
      // ToDo: Hardcoded case set to first index. At some point need to support multi-case
      this.requestDocuments =
        this.combinedFilingData?.caseRequest.documents ?? [];
      this.transaction = this.transactionInfoService.getTransactionInfo(
        this.combinedFilingData,
        this.requestDocuments
      );
    }
  }

  public collapseDetails(): void {
    this.isDetailsCollapsed = !this.isDetailsCollapsed;
  }

  public downloadURL(
    documentId: string,
    renderingName: string,
    fileName: string
  ): void {
    this.filingApiService
      .getDocumentRendering(
        this.combinedFilingData?.filing.id as string,
        documentId,
        renderingName
      )
      .pipe(
        takeUntil(this.destroy$),
        tap((r) => {
          const arrayBuffer = r as ArrayBuffer;
          const url = URL.createObjectURL(
            new Blob([arrayBuffer], { type: 'application/pdf' })
          );
          this.domSanitizerService.bypassSecurityTrustResourceUrl(url);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `${fileName}-${renderingName}`;
          a.target = '_blank';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          return EMPTY;
        }),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  public downloadClerkStamped(transactionDocument: TransactionDocument): void {
    if (
      transactionDocument.availableRenderings.clerkStamped &&
      !!this.combinedFilingData?.filing.id
    ) {
      this.downloadURL(
        transactionDocument.id,
        RenderingNameEnum.ClerkStamped,
        transactionDocument.fileName
      );
    }
  }

  public downloadOriginal(transactionDocument: TransactionDocument): void {
    if (
      transactionDocument.availableRenderings.original &&
      !!this.combinedFilingData?.filing.id
    ) {
      this.downloadURL(
        transactionDocument.id,
        RenderingNameEnum.Original,
        transactionDocument.fileName
      );
    }
  }

  public downloadConvertedPdf(transactionDocument: TransactionDocument): void {
    if (
      transactionDocument.availableRenderings.converted &&
      !!this.combinedFilingData?.filing.id
    ) {
      this.downloadURL(
        transactionDocument.id,
        RenderingNameEnum.ConvertedPdf,
        transactionDocument.fileName
      );
    }
  }

  public goToDocumentHistory(
    transactionDocument: TransactionDocument,
    docIndex: number
  ): void {
    this.showDocumentHistory = !this.showDocumentHistory;
    this.activeDocument = transactionDocument;
    this.activeDocumentIndex = docIndex;
  }

  public openPdfViewer(
    transactionDocument: TransactionDocument,
    index: number
  ): void {
    const params: OpenPdfViewerParams = {
      filingId: this.combinedFilingData?.filing.id as string,
      documentId: transactionDocument.id,
      renderingName: transactionDocument.renderingName,
      fileName: transactionDocument.fileName,
      documentName: transactionDocument.documentName,
      documentCategory: transactionDocument.documentCategory,
      access: transactionDocument.access,
      associatedParty: '-',
      leadingDocument: '-',
      fileSize: transactionDocument.pdfSize,
      documentIndex: index,
    };
    this.pdfViewerService.openPdfViewer(params);
  }

  public onBookmarkedClicked(event: Event, filing: Filing | undefined): void {
    event.stopPropagation();
    if (!!filing) {
      this.bookmarkTransactionOrchestrationService.bookmarkTransaction(filing);
      filing.bookmarked = !filing.bookmarked;
    }
  }

  public onViewReceiptClicked(event: Event): void {
    event.stopPropagation();
    this.filingSubmissionReceiptService.openFilingSubmissionReceipt(
      this.combinedFilingData?.filing.id as string
    );
  }
}
