<div
  class="fsx-instruction-component"
  *ngIf="fieldDefinition"
  [ngStyle]="{
    height: height,
    width: width,
    display: 'block'
  }"
>
  <div>
    <p class="caption">{{ caption }}</p>
    <label class="description">{{ fieldDefinition.description }}</label>
  </div>
</div>
