import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxRemoveParticipantOrchestrationService =
  new InjectionToken<IRemoveParticipantOrchestrationService>(
    'FsxRemoveParticipantOrchestrationService'
  );

export interface IRemoveParticipantEventParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  partyToRemove: CasePartyViewModel;
}

export interface IRemoveParticipantOrchestrationService {
  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel>;
  removeParticipant(params: IRemoveParticipantEventParams): void;
}

@Injectable()
export class RemoveParticipantOrchestrationService
  implements IRemoveParticipantOrchestrationService
{
  private removeParticipant$$ = new Subject<IRemoveParticipantEventParams>();

  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel> =
    this.removeParticipant$$.pipe(
      switchMap((params: IRemoveParticipantEventParams) => {
        const caseRequestBackup = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.caseRequestBuilderService
          .removeAssociatedParties(params)
          .pipe(
            switchMap(() => {
              return this.caseRequestBuilderService
                .removePartyAndParticipant(params)
                .pipe(
                  switchMap(() => {
                    return this.caseRequestUpdateService
                      .optimisticPutOrRestore(
                        params.filingId,
                        params.caseRequest,
                        caseRequestBackup
                      )
                      .pipe(
                        tap(() => {
                          this.validatePartiesOrchestrationService.validateParties();
                        })
                      );
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  removeParticipant(params: IRemoveParticipantEventParams): void {
    this.removeParticipant$$.next(params);
  }
}
