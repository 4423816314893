import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContactAliasViewModel,
  ContactAliasFormGroup,
  ContactFormGroup,
  FilingProfile,
  NewContactViewModel,
  PhoneViewModel,
  PhoneCategory,
  PhoneCommonCategory,
  PhoneSpec,
  RequestParticipantViewModel,
  RequestParticipantAliasViewModel,
} from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class PhoneFormService {
  public setPhoneCategories(
    contact:
      | NewContactViewModel
      | RequestParticipantViewModel
      | RequestParticipantAliasViewModel
      | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>
  ): void {
    formGroup.controls.phones?.controls.forEach((phone, index) => {
      const phoneCategory = phone.controls.category?.value;
      const dropdownOptions = phone.controls.category?.dropdownOptions;

      const selectedOption = dropdownOptions?.find(
        (option) => option.name === phoneCategory
      );

      if (contact && contact.phones && contact.phones[index]) {
        contact.phones[index].caption = '';

        if (contact.phones[index].category && selectedOption) {
          contact.phones[index].category = {
            name: selectedOption.name,
            caption: selectedOption.caption,
            commonCategory: selectedOption.category
              ?.commonCategory as PhoneCommonCategory,
          };
        }
      }
    });
  }

  public setPhoneCaptions(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>
  ): void {
    formGroup.controls.phones?.controls.forEach((_, index) => {
      if (contact.phones) {
        contact.phones[index].caption = contact.phones[index].fullNumber;
      }
    });
  }

  public getPhonesFromFilingProfile(
    contactPhones: PhoneViewModel[] | undefined,
    phoneSpecs: PhoneSpec | undefined,
    filingProfile: FilingProfile | null
  ): PhoneViewModel[] {
    const participantPhones: PhoneViewModel[] = [];
    const phonesList = phoneSpecs?.category?.listReference;
    let selectedCategory: PhoneCategory | undefined;

    contactPhones?.forEach((phone) => {
      if (phonesList && phonesList.list === 'AdditionalList') {
        const list = filingProfile?.additionalLists?.find(
          (list) => list.name === phonesList.additionalListName
        );
        const listElement = list?.items?.find(
          (item) => item.name === phone.category?.name
        );
        if (listElement && phone.category) {
          selectedCategory = phone.category;
        }
      } else {
        selectedCategory = filingProfile?.phoneCategories?.find(
          (item) => item.commonCategory === phone.category?.commonCategory
        );
      }

      const newPhone: PhoneViewModel = {
        ...phone,
        category: selectedCategory,
      } as PhoneViewModel;

      if (
        newPhone.category &&
        phoneSpecs &&
        participantPhones.length <= phoneSpecs.maxAllowed
      ) {
        participantPhones.push(newPhone);
      }
    });

    return participantPhones;
  }
}
