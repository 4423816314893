import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  EnvConfig,
  ENVIRONMENT,
  FsxContact,
  ISidenavComponent,
  NavLink,
  AuthService,
  ENV_CONFIG,
} from '@fsx/fsx-shared';
import { Observable, Subject } from 'rxjs';
import { NAV_LINKS } from './main-menu-sidenav-links';

@Component({
  selector: 'fsx-shared-sidenav',
  templateUrl: './main-menu-sidenav.component.html',
  styleUrls: ['./main-menu-sidenav.component.scss'],
})
export class MainMenuSidenavComponent implements ISidenavComponent, OnDestroy {
  public user$: Observable<FsxContact | null> = this.authService.sessionUser;
  public navLinks = NAV_LINKS;
  public ENVIRONMENT = ENVIRONMENT;
  private destroy$ = new Subject<unknown>();

  public constructor(
    @Inject(ENV_CONFIG) public envConfig$: Observable<EnvConfig>,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public toggleSideNavRoutesTooltip(index: number): void {
    this.navLinks[index].tooltipVisible = !this.navLinks[index].tooltipVisible;
  }

  public logout(): void {
    this.authService.logout();
  }

  private routeTo(input: KeyboardEvent) {
    const shortcutKey = `Alt + ${input.key}`;
    const navLink: NavLink =
      this.navLinks.find((link: NavLink) => link.shortcutKey === shortcutKey) ??
      NAV_LINKS[0];
    this.router.navigate([navLink.route]);
  }
}
