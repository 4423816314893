<ng-container *ngIf="additionalFieldSpec">
  <fsx-address-component
    #addressField
    *ngIf="additionalFieldSpec.addressFieldDefinition"
    [resolver]="resolver"
    [caption]="additionalFieldSpec.caption"
    [fieldDefinition]="
      getAddressFieldDefinition(additionalFieldSpec.addressFieldDefinition)
    "
    [initialValues]="
      initialValues
        ? initialValues.addressValues
          ? initialValues.addressValues
          : []
        : []
    "
    (formArrayEmitter)="setAddressFormArray($event)"
  ></fsx-address-component>

  <fsx-instruction-component
    *ngIf="additionalFieldSpec.instructionDefinition"
    [caption]="additionalFieldSpec.caption"
    [width]="'100%'"
    [height]="'auto'"
    [fieldDefinition]="additionalFieldSpec.instructionDefinition"
  ></fsx-instruction-component>

  <fsx-text-component
    #textField
    *ngIf="additionalFieldSpec.textFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [width]="'200px'"
    [fieldDefinition]="additionalFieldSpec.textFieldDefinition"
    [initialValue]="
      initialValues
        ? initialValues.textValue
          ? initialValues.textValue
          : ''
        : ''
    "
    [resolver]="resolver"
    (formControlEmitter)="setTextFormControl($event)"
  ></fsx-text-component>

  <fsx-number-component
    #numberField
    *ngIf="additionalFieldSpec.numberFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [fieldType]="additionalFieldSpec.fieldType"
    [fieldDefinition]="additionalFieldSpec.numberFieldDefinition"
    [initialValue]="
      initialValues
        ? initialValues.numberValue
          ? initialValues.numberValue
          : additionalFieldSpec.numberFieldDefinition.defaultValue ?? null
        : additionalFieldSpec.numberFieldDefinition.defaultValue ?? null
    "
    [width]="'100%'"
    (formControlEmitter)="setNumberFormControl($event)"
  ></fsx-number-component>

  <fsx-date-component
    #dateField
    *ngIf="additionalFieldSpec.dateFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [fieldType]="additionalFieldSpec.fieldType"
    [width]="'100%'"
    [fieldDefinition]="additionalFieldSpec.dateFieldDefinition"
    [initialValue]="
      initialValues
        ? initialValues.dateValue
          ? initialValues.dateValue
          : ''
        : ''
    "
    (formControlEmitter)="setDateFormControl($event)"
  ></fsx-date-component>

  <fsx-currency-component
    #currencyField
    *ngIf="additionalFieldSpec.currencyFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [fieldType]="additionalFieldSpec.fieldType"
    [width]="'100%'"
    [fieldDefinition]="additionalFieldSpec.currencyFieldDefinition"
    [initialValue]="
      initialValues &&
      initialValues.currencyValue &&
      initialValues.currencyValue.amount
        ? initialValues.currencyValue.amount
        : 0
    "
    (formControlEmitter)="setCurrencyFormControl($event)"
  ></fsx-currency-component>

  <fsx-boolean-component
    #booleanField
    *ngIf="additionalFieldSpec.booleanFieldDefinition"
    [resolver]="resolver"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [fieldType]="additionalFieldSpec.fieldType"
    [width]="'100%'"
    [height]="'inherit'"
    [fieldDefinition]="additionalFieldSpec.booleanFieldDefinition"
    [initialValue]="
      initialValues
        ? initialValues.booleanValue
          ? initialValues.booleanValue
          : false
        : false
    "
    [existingAdditionalValues]="existingAdditionalValues"
    [combinedFilingData]="combinedFilingData"
    (formControlEmitter)="setBooleanFormControl($event)"
    (updateAdditionalFieldValues)="updateNestedAdditionalField($event)"
  ></fsx-boolean-component>

  <fsx-profile-single-selection
    #singleSelectionField
    *ngIf="additionalFieldSpec.selectionFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [fieldType]="additionalFieldSpec.fieldType"
    [width]="'200px'"
    [height]="'auto'"
    [initialValue]="
      initialValues && initialValues.selectionValue
        ? initialValues.selectionValue[0]
        : ''
    "
    [selectionType]="selectionType.StringSelectionFieldDefinition"
    [fieldDefinition]="additionalFieldSpec.selectionFieldDefinition"
    [resolver]="resolver"
    [existingAdditionalValues]="existingAdditionalValues"
    [combinedFilingData]="combinedFilingData"
    (formControlEmitter)="setSelectionFormControl($event)"
    (updateAdditionalFieldValues)="updateNestedAdditionalField($event)"
    (filesUploadedFromAdditionalFieldEvent)="
      filesUploadedFromSelectionFieldEventHandler($event)
    "
    (fileRemovedFromAdditionalFieldEvent)="
      fileRemovedFromSelectionFieldEventHandler($event)
    "
  >
  </fsx-profile-single-selection>

  <fsx-efm-search-component
    #searchField
    *ngIf="additionalFieldSpec.searchFieldDefinition"
    [fieldDefinition]="additionalFieldSpec.searchFieldDefinition"
    [caption]="additionalFieldSpec.caption"
    [resolver]="resolver"
    [initialValue]="initialValues?.searchResultItem ?? null"
    (optionSelected)="setSearchFormControl($event)"
  >
  </fsx-efm-search-component>

  <fsx-file-upload-wrapper
    #fileUploadField
    *ngIf="additionalFieldSpec.fileFieldDefinition"
    [additionalFieldSpec]="additionalFieldSpec"
    [fileUploadDocumentInfos]="fileUploadDocumentInfos"
    (filesUploadedWrapperEvent)="onFilesUploadedEvent($event)"
    (fileRemovedEvent)="fileRemovedEventHandler($event)"
  >
  </fsx-file-upload-wrapper>

  <fsx-contact-component
    #contactField
    *ngIf="additionalFieldSpec.contactFieldDefinition"
    [initialValues]="
      initialValues
        ? initialValues.contactValues
          ? initialValues.contactValues
          : []
        : []
    "
    [resolver]="resolver"
    [fieldDefinition]="additionalFieldSpec.contactFieldDefinition"
    (formArrayEmitter)="setContactFormArray($event)"
  >
  </fsx-contact-component>

  <fsx-participant-component
    #participantField
    *ngIf="additionalFieldSpec.participantFieldDefinition && combinedFilingData"
    [initialValues]="
      initialValues
        ? initialValues.participantValues
          ? initialValues.participantValues
          : []
        : []
    "
    [caption]="additionalFieldSpec.caption"
    [helpText]="additionalFieldSpec.helpText ?? ''"
    [hint]="additionalFieldSpec.hint ?? ''"
    [combinedFilingData]="combinedFilingData"
    [resolver]="resolver"
    [participantFieldDefinition]="
      additionalFieldSpec.participantFieldDefinition
    "
    (selectedParticipantEvent)="setParticipantSelectedValue($event)"
  >
  </fsx-participant-component>
</ng-container>
