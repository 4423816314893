import { NgModule } from '@angular/core';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { SharedModule } from '@fsx/fsx-shared';
import { TransactionActivityRoutingModule } from './transaction-activity.routing.module';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionActivityComponent } from './transaction-activity.component';
import { StatusComponent } from './status/status.component';
import { DocumentHistoryComponent } from './document-history/document-history.component';
import { FilingMessageComponent } from '../../../../../libs/components/src/lib/components/filing-message/filing-message.component';

@NgModule({
  declarations: [
    TransactionActivityComponent,
    TransactionDetailsComponent,
    StatusComponent,
    DocumentHistoryComponent,
  ],
  imports: [
    SharedModule,
    TransactionActivityRoutingModule,
    FsxUiComponentsModule,
    FilingMessageComponent,
  ],
})
export class TransactionActivityModule {}
