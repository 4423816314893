import { Component, Inject, OnDestroy } from '@angular/core';
import {
  CombinedFilingData,
  Filing,
  TRANSACTION_ID,
  ICombinedFilingApiService,
  FsxCombinedFilingApiService,
} from '@fsx/fsx-shared';
import {
  merge,
  of,
  map,
  filter,
  switchMap,
  tap,
  BehaviorSubject,
  takeUntil,
  Subject,
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TransactionsEventService } from '../transactions/services/transactions-event.service';

@Component({
  selector: 'fsx-transaction-activity',
  templateUrl: './transaction-activity.component.html',
  styleUrls: ['./transaction-activity.component.scss'],
})
export class TransactionActivityComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  filingId = this.route.snapshot.params[TRANSACTION_ID] as string;

  private combinedFilingData$$ = new BehaviorSubject<CombinedFilingData | null>(
    null
  );

  combinedFilingData$ = this.combinedFilingData$$.asObservable().pipe(
    filter(
      (combinedFilingData: CombinedFilingData | null) => !!combinedFilingData
    ),
    map(
      (combinedFilingData: CombinedFilingData | null) =>
        combinedFilingData as CombinedFilingData
    )
  );

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(FsxCombinedFilingApiService)
    private readonly combinedFilingApiService: ICombinedFilingApiService,
    private readonly transactionsEventService: TransactionsEventService
  ) {
    const updatedTransactionFilingId$ =
      this.transactionsEventService.transactionUpdated$.pipe(
        map((filing: Filing) => {
          return filing.id;
        })
      );

    const filingIdToLoadFor$ = merge(
      of(this.filingId), // Use the snapshot to trigger this stream on first load,
      updatedTransactionFilingId$
    );

    const loadCombinedFilingData$ = filingIdToLoadFor$.pipe(
      filter((filingId: string) => this.filingId === filingId),
      switchMap((filingId: string) => {
        return this.combinedFilingApiService
          .getCombinedFilingData(filingId)
          .pipe(
            tap((combinedFilingData: CombinedFilingData) => {
              this.combinedFilingData$$.next(combinedFilingData);
            })
          );
      })
    );

    loadCombinedFilingData$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
