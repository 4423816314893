import { Component, Input, OnChanges } from '@angular/core';
import { CombinedFilingData } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-total-documents',
  templateUrl: './total-documents.component.html',
  styleUrls: ['./total-documents.component.scss'],
})
export class TotalDocumentsComponent implements OnChanges {
  @Input() combinedFilingData!: CombinedFilingData | null;

  public totalDocuments = 0;

  ngOnChanges(): void {
    this.totalDocuments =
      this.combinedFilingData?.caseRequest?.documents?.length || 0;
  }
}
