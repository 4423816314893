import { Inject, Injectable, InjectionToken } from '@angular/core';
import { combineLatest } from 'rxjs';
import {
  FsxAddDocumentOrchestrationService,
  IAddDocumentOrchestrationService,
} from '../../documents/services/add-document-orchestration.service';
import {
  FsxAddLeadDocumentOrchestrationService,
  IAddLeadDocumentOrchestrationService,
} from '../../documents/services/add-lead-documents-orchestration.service';
import {
  FsxAddSupportingDocumentOrchestrationService,
  IAddSupportingDocumentOrchestrationService,
} from '../../documents/services/add-supporting-document-orchestration.service';
import {
  FsxRemoveDocumentOrchestrationService,
  IRemoveDocumentOrchestrationService,
} from '../../documents/services/remove-document-orchestration.service';
import {
  FsxReplaceDocumentFileOrchestrationService,
  IReplaceDocumentFileOrchestrationService,
} from '../../documents/services/replace-document-file-orchestration.service';
import {
  FsxUpdateDocumentOrchestrationService,
  IUpdateDocumentOrchestrationService,
} from '../../documents/services/update-document-orchestration.service';
import {
  FsxUploadFilesOrchestrationService,
  IUploadFilesOrchestrationService,
} from '../../documents/services/upload-files-orchestration.service';
import {
  FsxAddContactAsParticipantOrchestrationService,
  IAddContactAsParticipantOrchestrationService,
} from '../../parties/orchestration-services/add-contact-as-participant-orchestration.service';
import {
  FsxAddDefaultParticipantOrchestrationService,
  IAddDefaultParticipantOrchestrationService,
} from '../../parties/orchestration-services/add-default-participant-orchestration.service';
import {
  FsxAddSelectedContactsAsParticipantsOrchestrationService,
  IAddSelectedContactsAsParticipantsOrchestrationService,
} from '../../parties/orchestration-services/add-selected-contacts-as-participants-orchestration.service';
import {
  FsxAddSelectedContactsAsRepresentationOrchestrationService,
  IAddSelectedContactsAsRepresentationOrchestrationService,
} from '../../parties/orchestration-services/add-selected-contacts-as-representatives-orchestration.service';
import {
  FsxClearParticipantOrchestrationService,
  IClearParticipantOrchestrationService,
} from '../../parties/orchestration-services/clear-participant-orchestration.service';
import {
  FsxClearRepresentationOrchestrationService,
  IClearRepresentationOrchestrationService,
} from '../../parties/orchestration-services/clear-representation-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  IRemoveParticipantOrchestrationService,
} from '../../parties/orchestration-services/remove-participant-orchestration.service';
import {
  FsxRemoveRepresentationOrchestrationService,
  IRemoveRepresentationOrchestrationService,
} from '../../parties/orchestration-services/remove-representation-orchestration.service';
import {
  FsxUpdateCaseRequestOrchestrationService,
  IUpdateCaseRequestOrchestrationService,
} from '../../parties/orchestration-services/update-case-request-orchestration.service';
import {
  FsxUpdateParticipantOrchestrationService,
  IUpdateParticipantOrchestrationService,
} from '../../parties/orchestration-services/update-participant-orchestration.service';
import {
  FsxUpdateRepresentationOrchestrationService,
  IUpdateRepresentationOrchestrationService,
} from '../../parties/orchestration-services/update-representation-orchestration.service';
import {
  FsxUploadAdditionalFieldFilesOrchestrationService,
  IUploadAdditionalFieldFilesOrchestrationService,
} from '../../documents/services/upload-additional-field-files-orchestration.service';
import {
  FsxAddRepresentationOrchestrationService,
  IAddRepresentationOrchestrationService,
} from '../../parties/orchestration-services/add-representation-orchestration.service';
import {
  FsxValidateCaseOrchestrationService,
  IValidateCaseOrchestrationService,
} from './orchestration/validate-case-orchestration.service';
import {
  FsxFirstLoadOrchestrationService,
  IFirstLoadOrchestrationService,
} from './orchestration/first-load-orchestration.service';
import {
  FsxSwitchFilingOrchestrationService,
  ISwitchFilingOrchestrationService,
} from './orchestration/switch-filing-orchestration.service';
import {
  FsxCloseFilingOrchestrationService,
  ICloseFilingOrchestrationService,
} from './orchestration/close-filing-orchestration.service';
import {
  FsxSubmitFilingOrchestrationService,
  ISubmitFilingOrchestrationService,
} from './orchestration/submit-filing-orchestration.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from './orchestration/validate-parties-orchestration.service';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from './orchestration/validate-documents-orchestration.service';
import {
  FsxValidateReviewOrchestrationService,
  IValidateReviewOrchestrationService,
} from './orchestration/validate-review-orchestration.service';
import {
  FsxLoadFilingFeesOrchestrationService,
  ILoadFilingFeesOrchestrationService,
} from './orchestration/load-filing-fees-orchestration.service';
import {
  FsxOpenParticipantFormOrchestrationService,
  IOpenParticipantFormOrchestrationService,
} from '../../shared/services/open-participant-form-orchestration.service';
import {
  FsxApplyParticipantFormUpdatesOrchestrationService,
  IApplyParticipantFormUpdatesOrchestrationService,
} from '../../shared/services/apply-participant-form-updates-orchestration.service';
import {
  FsxEditParticipantOrchestrationService,
  IEditParticipantOrchestrationService,
} from '../../parties/orchestration-services/edit-participant-orchestration.service';
import {
  FsxOpenContactsListOrchestrationService,
  IOpenContactsListOrchestrationService,
} from '../../shared/services/open-contact-list-orchestration.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFilingEditorActionsService to use at runtime.
 */
export const FsxFilingEditorActionsService =
  new InjectionToken<IFilingEditorActionsService>(
    'FsxFilingEditorActionsService'
  );

/**
 * A blueprint for a dispatcher service, which specifies the actions that can be
 * dispatched to invoke state changes from within the filing editor
 */
export interface IFilingEditorActionsService {
  /**
   * A method to trigger the loading of filing fees
   *
   * @param filingId The filing id to load the filing fees for.
   */
  loadFilingFees(filingId: string, suppressErrors: boolean): void;

  /**
   * A method to trigger the loading of the combined filing data
   *
   * @param filingId The filing id to load the CombinedFilingData for.
   */
  loadForFilingId(filingId: string): void;

  /**
   * A method to allow the user to swicth to view a different filing in the
   * filing editor.
   *
   * @param filingId The filing id of the filing to switch to.
   */
  switchFiling(filingId: string): void;

  /**
   * A method to allow closing of the filing in the filing editor
   *
   * @param filingId The filing id of the filing to close.
   */
  closeFiling(filingId: string): void;

  /**
   * A method to allow the user to attemmt to submit the filing
   *
   * @param filingId The filing id of the filing to submit.
   *
   */
  submitFiling(filingId: string): void;
}

/**
 * A concrete implementation of a dispatcher service, which specifies the actions that
 * can be dispatched to invoke state changes from within the filing editor
 */
@Injectable()
export class FilingEditorActionsService implements IFilingEditorActionsService {
  /**
   * A stream of all of the actionable streams, which we subscribe to in this service
   * constructor to keep them live and actionable whilst in the filing editor.
   */
  private actionStreams$ = combineLatest([
    this.addDefaultParticipantOrchestrationService
      .addDefaultParticpantToCaseRequest$,
    this.addContactAsParticipantOrchestrationService
      .addContactAsParticipantOrchestration$,
    this.clearParticipantOrchestrationService.clearParticipantInCaseRequest$,
    this.removeParticipantOrchestrationService
      .removeParticipantFromCaseRequest$,
    this.uploadFilesOrchestrationService.uploadFilesStream$,
    this.uploadAdditionalFieldFilesOrchestrationService.uploadFilesStream$,
    this.addSelectedContactsAsParticipantsOrchestrationService
      .addParticipantsToCaseRequest$,
    this.addRepresentationOrchestrationService.addRepresentationToCaseRequest$,
    this.removeRepresentationOrchestrationService
      .removeRepresentationFromPartyInCaseRequest$,
    this.updateRepresentationOrchestrationService
      .updateRepresentationToCaseRequest$,
    this.clearRepresentationOrchestrationService
      .clearRepresentationToCaseRequest$,
    this.updateParticipantOrchestrationService.updateParticipantOnCaseRequest$,
    this.removeDocumentOrchestrationService.removeDocumentStream$,
    this.addSelectedContactsAsRepresentationOrchestrationService
      .addContactsAsRepresentativesStream$,
    this.updateDocumentOrchestrationService.updateDocumentStream$,
    this.addDocumentOrchestrationService.addDocumentStream$,
    this.addSupportingDocumentOrchestrationService.addSupportingDocumentStream$,
    this.addLeadDocumentOrchestrationService.addLeadDocumentStream$,
    this.updateCaseRequestOrchestrationService.updateCaseRequestStream$,
    this.replaceDocumentFileOrchestrationService.replaceDocumentFileStream$,
    this.validateCaseOrchestrationService.validateCase$,
    this.firstLoadOrchestrationService.loadCombinedFilingData$,
    this.switchFilingOrchestrationService.switchFilingInFilingEditor$,
    this.closeFilingOrchestrationService.closeFilingInFilingEditor$,
    this.submitFilingOrchestrationService.submitFilingOrchestration$,
    this.closeFilingOrchestrationService.closeFilingInFilingEditor$,
    this.validatePartiesOrchestrationService.validateParties$,
    this.validateDocumentsOrchestrationService.validateDocuments$,
    this.validateReviewOrchestrationService.validateReview$,
    this.openParticipantFormOrchestrationService
      .openParticipantFormOrchestration$,
    this.loadFilingFeesOrchestrationService.loadFilingFees$,
    this.applyParticipantFormUpdatesOrchestrationService
      .applyParticipantFormUpdatesOrchestration$,
    this.editParticipantOrchestrationService.editParticipantInCaseRequest$,
    this.openContactsListOrchestrationService.openContactsListOrchestration$,
  ]);

  constructor(
    @Inject(FsxAddDefaultParticipantOrchestrationService)
    private readonly addDefaultParticipantOrchestrationService: IAddDefaultParticipantOrchestrationService,
    @Inject(FsxAddContactAsParticipantOrchestrationService)
    private readonly addContactAsParticipantOrchestrationService: IAddContactAsParticipantOrchestrationService,
    @Inject(FsxClearParticipantOrchestrationService)
    private readonly clearParticipantOrchestrationService: IClearParticipantOrchestrationService,
    @Inject(FsxRemoveParticipantOrchestrationService)
    private readonly removeParticipantOrchestrationService: IRemoveParticipantOrchestrationService,
    @Inject(FsxUploadFilesOrchestrationService)
    private readonly uploadFilesOrchestrationService: IUploadFilesOrchestrationService,
    @Inject(FsxUploadAdditionalFieldFilesOrchestrationService)
    private readonly uploadAdditionalFieldFilesOrchestrationService: IUploadAdditionalFieldFilesOrchestrationService,
    @Inject(FsxAddSelectedContactsAsParticipantsOrchestrationService)
    private readonly addSelectedContactsAsParticipantsOrchestrationService: IAddSelectedContactsAsParticipantsOrchestrationService,
    @Inject(FsxAddRepresentationOrchestrationService)
    private readonly addRepresentationOrchestrationService: IAddRepresentationOrchestrationService,
    @Inject(FsxRemoveRepresentationOrchestrationService)
    private readonly removeRepresentationOrchestrationService: IRemoveRepresentationOrchestrationService,
    @Inject(FsxUpdateRepresentationOrchestrationService)
    private readonly updateRepresentationOrchestrationService: IUpdateRepresentationOrchestrationService,
    @Inject(FsxClearRepresentationOrchestrationService)
    private readonly clearRepresentationOrchestrationService: IClearRepresentationOrchestrationService,
    @Inject(FsxUpdateParticipantOrchestrationService)
    private readonly updateParticipantOrchestrationService: IUpdateParticipantOrchestrationService,
    @Inject(FsxRemoveDocumentOrchestrationService)
    private readonly removeDocumentOrchestrationService: IRemoveDocumentOrchestrationService,
    @Inject(FsxAddSelectedContactsAsRepresentationOrchestrationService)
    private readonly addSelectedContactsAsRepresentationOrchestrationService: IAddSelectedContactsAsRepresentationOrchestrationService,
    @Inject(FsxUpdateDocumentOrchestrationService)
    private readonly updateDocumentOrchestrationService: IUpdateDocumentOrchestrationService,
    @Inject(FsxAddDocumentOrchestrationService)
    private readonly addDocumentOrchestrationService: IAddDocumentOrchestrationService,
    @Inject(FsxAddSupportingDocumentOrchestrationService)
    private readonly addSupportingDocumentOrchestrationService: IAddSupportingDocumentOrchestrationService,
    @Inject(FsxAddLeadDocumentOrchestrationService)
    private readonly addLeadDocumentOrchestrationService: IAddLeadDocumentOrchestrationService,
    @Inject(FsxUpdateCaseRequestOrchestrationService)
    private readonly updateCaseRequestOrchestrationService: IUpdateCaseRequestOrchestrationService,
    @Inject(FsxReplaceDocumentFileOrchestrationService)
    private readonly replaceDocumentFileOrchestrationService: IReplaceDocumentFileOrchestrationService,
    @Inject(FsxSwitchFilingOrchestrationService)
    private readonly switchFilingOrchestrationService: ISwitchFilingOrchestrationService,
    @Inject(FsxValidateCaseOrchestrationService)
    private readonly validateCaseOrchestrationService: IValidateCaseOrchestrationService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCloseFilingOrchestrationService)
    private readonly closeFilingOrchestrationService: ICloseFilingOrchestrationService,
    @Inject(FsxSubmitFilingOrchestrationService)
    private readonly submitFilingOrchestrationService: ISubmitFilingOrchestrationService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxFirstLoadOrchestrationService)
    private readonly firstLoadOrchestrationService: IFirstLoadOrchestrationService,
    @Inject(FsxValidateReviewOrchestrationService)
    private readonly validateReviewOrchestrationService: IValidateReviewOrchestrationService,
    @Inject(FsxOpenContactsListOrchestrationService)
    private readonly openContactsListOrchestrationService: IOpenContactsListOrchestrationService,
    @Inject(FsxOpenParticipantFormOrchestrationService)
    private readonly openParticipantFormOrchestrationService: IOpenParticipantFormOrchestrationService,
    @Inject(FsxLoadFilingFeesOrchestrationService)
    private readonly loadFilingFeesOrchestrationService: ILoadFilingFeesOrchestrationService,
    @Inject(FsxApplyParticipantFormUpdatesOrchestrationService)
    public readonly applyParticipantFormUpdatesOrchestrationService: IApplyParticipantFormUpdatesOrchestrationService,
    @Inject(FsxEditParticipantOrchestrationService)
    public readonly editParticipantOrchestrationService: IEditParticipantOrchestrationService
  ) {
    this.actionStreams$.subscribe();
  }

  /**
   * A method to trigger the loading of filing fees
   *
   * @param filingId The filing id to load the filing fees for.
   */
  loadFilingFees(filingId: string, suppressErrors = false): void {
    this.loadFilingFeesOrchestrationService.loadForFilingId(
      filingId,
      suppressErrors
    );
  }

  /**
   * a method to trigger the loading of the combined filing data
   *
   * @param filingId The filing id to load the CombinedFilingData for.
   */
  loadForFilingId(filingId: string): void {
    this.firstLoadOrchestrationService.loadForFilingId(filingId);
  }

  /**
   * A method to allow the user to swicth to view a different filing in the
   * filing editor.
   *
   * @param filingId The filing id of the filing to switch to.
   */
  switchFiling(filingId: string): void {
    this.switchFilingOrchestrationService.switchFiling(filingId);
  }

  /**
   * A method to allow closing of the filing in the filing editor
   *
   * @param filingId The filing id of the filing to close.
   */
  closeFiling(filingId: string): void {
    this.closeFilingOrchestrationService.closeFiling(filingId);
  }

  /**
   * A method to allow the user to attemmt to submit the filing
   *
   * @param filingId The filing id of the filing to submit.
   *
   */
  submitFiling(filingId: string): void {
    this.submitFilingOrchestrationService.submitFiling(filingId);
  }
}
