import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';
import { filter, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  CombinedFilingData,
  DataAttribute,
  FilingProfile,
  FILING_SUB_TABS,
  RequestCaseViewModel,
  RequestDocumentViewModel,
  TextFieldDefinition,
  ValidationResult,
  ValidationDetail,
  TRANSACTION_ID,
  IValidationService,
  ProblemDetails,
  FsxValidationService,
} from '@fsx/fsx-shared';
import { DocumentsGridRow } from '../documents/documents-grid/documents-grid.model';
import { PartiesGridRow } from '../parties/parties-grid/parties-grid.model';
import { ActivatedRoute } from '@angular/router';
import { FsxTextBoxComponent } from '@fsx/ui-components';
import {
  FsxFilingEditorActionsService,
  IFilingEditorActionsService,
} from '../filing-editor/services/filing-editor-actions.service';
import {
  FilingFeesData,
  FsxFilingFeesDataService,
  IFilingFeesDataService,
} from '../filing-editor/services/filing-fees-data.service';

enum SectionType {
  CaseInformation,
  CaseParties,
  CourtesyCopies,
  Documents,
  Fees,
}

interface ISection {
  type: SectionType;
  isExpanded: boolean;
  title: string;
}

@Component({
  selector: 'fsx-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Input() documentsGridRows$!: Observable<DocumentsGridRow[]>;
  @Input() combinedPartiesGridRows$!: Observable<PartiesGridRow[]>;
  @Input() validationProblemDetails!: ProblemDetails | null;

  @Output() filingEditorTabChangeEvent = new EventEmitter<FILING_SUB_TABS>();
  @Output() updateCaseRequestEvent = new EventEmitter<void>();
  @Output() updateFeesEvent = new EventEmitter<void>();

  @ViewChild('noteToClerkField') noteToClerkField!: FsxTextBoxComponent;

  /**
   * The FilingFeesData as it comes from the single source of truth
   * i.e the FilngFeesDataService
   */
  filingFeesData$: Observable<FilingFeesData> =
    this.filingFeesDataService.filingFeesData$;

  requestDocuments: RequestDocumentViewModel[] = [];

  sections: ISection[] = [
    {
      type: SectionType.CaseInformation,
      title: 'Case Details',
      isExpanded: true,
    },
    { type: SectionType.CaseParties, title: 'Parties', isExpanded: true },
    {
      type: SectionType.CourtesyCopies,
      title: 'Courtesy Copies',
      isExpanded: true,
    },
    { type: SectionType.Documents, title: 'Documents', isExpanded: true },
    { type: SectionType.Fees, title: 'Estimated Fees', isExpanded: true },
  ];
  sectionType = SectionType;
  selectedSection: number = 1;
  summary: {
    courtName: string;
    caseClass: string;
    caseType: string;
    caseNumber: string | null | undefined;
    caseName: string | null | undefined;
    clientMatter: string;
  } = {
    courtName: '',
    caseClass: '',
    caseType: '',
    caseNumber: '',
    caseName: '',
    clientMatter: '',
  };
  classification!: DataAttribute[];
  parties: CasePartyViewModel[] | null | undefined;
  documents: RequestDocumentViewModel[] | null | undefined;
  filingProfile: FilingProfile | null | undefined;
  // tslint:disable-next-line: ban-types
  courtesyCopies: Object[] | null | undefined; // TODO - type this correctly
  submitModel: {
    billingReference: string;
    waiveFees: boolean;
    courtAppointedCouncil: boolean;
    notesToClerk: string;
    onBehalfOf: string;
  } = {
    billingReference: '',
    waiveFees: false,
    courtAppointedCouncil: false,
    notesToClerk: '',
    onBehalfOf: '',
  };

  notesToClerkFieldDefinition!: TextFieldDefinition | null;
  allowNoteToClerk!: boolean;
  caseRequest!: CaseRequestViewModel;

  private destroy$: Subject<void> = new Subject<void>();
  private filingId = this.route.snapshot.params[TRANSACTION_ID];
  private combinedFilingData!: CombinedFilingData;

  constructor(
    @Inject(FsxFilingEditorActionsService)
    private readonly filingEditorActionsService: IFilingEditorActionsService,
    @Inject(FsxFilingFeesDataService)
    private readonly filingFeesDataService: IFilingFeesDataService,
    private readonly route: ActivatedRoute,
    @Inject(FsxValidationService)
    private readonly validationService: IValidationService
  ) {}

  ngOnInit(): void {
    this.getFees(true);
  }

  ngOnChanges(): void {
    if (this.combinedFilingData$) {
      this.combinedFilingData$
        .pipe(
          takeUntil(this.destroy$),
          filter((cfd: CombinedFilingData) => !!cfd),
          tap((cfd: CombinedFilingData) => {
            this.combinedFilingData = cfd;
            this.notesToClerkFieldDefinition =
              cfd.modeSpec?.noteToClerk ?? null;
            this.allowNoteToClerk = cfd.modeSpec?.allowNoteToClerk ?? false;

            this.filingProfile = cfd.filingProfile;
            const cases: RequestCaseViewModel[] | null | undefined =
              cfd.caseRequest.cases;
            this.classification = cfd.filingProfile.classification;

            if (cases && cases.length > 0) {
              const firstCase = cfd.filing.courtCases[0];
              this.summary = {
                courtName: cfd.filing.courtSummary.caption,
                caseClass: '',
                caseType: '',
                caseNumber: firstCase.caption ?? '-',
                caseName: firstCase.title ?? '-',
                clientMatter: firstCase.clientMatterKey,
              };

              this.parties = cfd.caseRequest?.parties;
              this.documents = cfd.caseRequest.documents;
              this.caseRequest = cfd.caseRequest;
            }
          })
        )
        .subscribe();
    }
    this.documentsGridRows$.subscribe((dgrs) => {
      this.requestDocuments = dgrs.map((dgr) => dgr.requestDocument);
    });

    // Display errors in console instead of in UI
    this.showErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  expandCollapseSection(section: ISection): void {
    section.isExpanded = !section.isExpanded;
  }

  goToPartiesTab(): void {
    this.filingEditorTabChangeEvent.emit(FILING_SUB_TABS.PARTIES);
  }

  goToCaseInformation(): void {
    // todo: implement
  }

  editCourtesyCopies(): void {
    // todo: implement
  }

  editDocuments(): void {
    this.filingEditorTabChangeEvent.emit(FILING_SUB_TABS.DOCUMENTS);
  }

  setNotesToClerk(noteToClerk: string) {
    this.caseRequest.noteToClerk = noteToClerk;
    this.updateCaseRequestEvent.emit();

    this.validate();

    // filing: Filing;
    // filingProfile: FilingProfile;
    // caseRequest: CaseRequestViewModel;
    // documentInfos: DocumentInfo[];
    // modeSpec: FilingModeSpec;
    this.combinedFilingData$
      .pipe(
        take(1),
        map(({ filing, caseRequest, filingProfile, modeSpec }) => {
          this.validationService.validateCaseReview(
            filing,
            caseRequest,
            filingProfile,
            modeSpec
          );
        })
      )
      .subscribe();
  }

  showErrors(): void {
    if (!!this.validationProblemDetails) {
      const validationResult = this.validationProblemDetails.validationResult;
      console.error('Validation Result:', validationResult);
      if (!!validationResult?.errors) {
        console.error('Errors:', validationResult.errors);
      }
      if (!!validationResult?.warnings) {
        console.warn('Warnings:', validationResult.warnings);
      }
      if (!!validationResult?.information) {
        console.warn('Information:', validationResult.information);
      }
      if (!!validationResult?.validations) {
        for (let err in validationResult.validations) {
          let validation = <ValidationDetail[]>(
            validationResult.validations[err]
          );
          for (let details of validation) {
            console.warn(details.field, details.caption);
          }
        }
      }
      // transforms the server errors, for display
      this.validationService.setServerValidationErrors(
        validationResult as ValidationResult
      );
    }
  }

  onUpdateFeesClicked(): void {
    this.getFees(false);
  }

  private getFees(suppressErrors: boolean): void {
    this.filingEditorActionsService.loadFilingFees(
      this.filingId,
      suppressErrors
    );
  }

  validate(): void {
    if (this.noteToClerkField) {
      this.noteToClerkField.validate();
    }
  }
}
