export interface DocumentEndPoints {
  createDocument: (filingId: string) => string;
  getConvertedDocument: (filingId: string, documentId: string) => string;
  getDocumentAccessToken: (filingId: string) => string;
  getDocumentInfoCollection: (filingId: string) => string;
  docStoreDocumentInfo: (filingId: string, documentId: string) => string;
  getOriginalDocument: (filingId: string, documentId: string) => string;
  importDocument: (filingId: string) => string;
  importUploadedDocument: (filingId: string, documentId: string) => string;
  updateDocument: (filingId: string, documentId: string) => string;
  deleteDocument: (filingId: string, documentId: string) => string;

  updateFilingDocument: (filingId: number) => string;
  deleteFilingDocument: (filingId: number, documentId: number) => string;
  addFilingDocument: (filingId: number) => string;
  createCourtesyCopyDocuments: (filingId: number) => string;
  updateCourtesyCopyDocuments: (filingId: number) => string;
  deleteCourtesyCopyDocument: (filingId: number, documentId: number) => string;
  publishDocument: (filingId: number, uniqueId: string) => string;
}

export const documentEndPoints = (versionedUrl: string): DocumentEndPoints => {
  const baseUrl = (filingId: string) =>
    `${versionedUrl}/filings/${filingId}/documents`;
  const oldBaseUrl = (filingId: number) =>
    `${versionedUrl}/filings/${filingId}/documents`;
  return {
    createDocument: (filingId: string) => `${baseUrl(filingId)}`,
    getConvertedDocument: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}/converted-pdf`,
    getDocumentAccessToken: (filingId) => `${baseUrl(filingId)}/tempuri`,
    getDocumentInfoCollection: (filingId) => baseUrl(filingId),
    docStoreDocumentInfo: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}`,
    getOriginalDocument: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}/original`,
    importDocument: (filingId) => `${baseUrl(filingId)}/import`,
    importUploadedDocument: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}/import`,
    updateDocument: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}`,
    deleteDocument: (filingId, documentId) =>
      `${baseUrl(filingId)}/${documentId}`,

    addFilingDocument: (filingId) => `${oldBaseUrl(filingId)}`,
    updateFilingDocument: (filingId) => `${oldBaseUrl(filingId)}`,
    deleteFilingDocument: (filingId, documentId) =>
      `${oldBaseUrl(filingId)}/${documentId}`,
    createCourtesyCopyDocuments: (filingId) =>
      `${oldBaseUrl(filingId)}/courtesyCopy`,
    updateCourtesyCopyDocuments: (filingId) =>
      `${oldBaseUrl(filingId)}/courtesyCopy`,
    deleteCourtesyCopyDocument: (filingId, documentId) =>
      `${oldBaseUrl(filingId)}/courtesyCopy/${documentId}`,
    publishDocument: (filingId, uniqueId) =>
      `${oldBaseUrl(filingId)}/publish/${uniqueId}`,
  };
};
