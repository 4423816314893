<div
  class="fsx-number-component"
  [ngStyle]="{
    width: width,
    display: formControl.fieldDefinition.visible ? 'block' : 'none'
  }"
  [ngClass]="{ invalid: formControl.invalid && formControl.touched }"
>
  <ng-container *ngIf="!formControl.fieldDefinition.readOnly; else readOnly">
    <label
      >{{ caption }}
      <span class="required" *ngIf="formControl.fieldDefinition.required"
        >*</span
      >
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <input
      type="text"
      [formControl]="formControl"
      [placeholder]="
        hint ? hint : formControl.fieldDefinition.allowedDecimals ? '0.00' : '0'
      "
      [ngClass]="{ invalid: formControl.invalid && formControl.touched }"
      (blur)="formatValue()"
    />

    <p
      class="error-message"
      *ngIf="formControl.invalid && (formControl.touched || initialValue)"
    >
      {{ parseError || getErrorMessage() }}
    </p>
  </ng-container>

  <ng-template #readOnly>
    <p class="readonly-text">{{ formControl.formattedValue }}</p>
    <label class="readonly-label">{{ caption }}</label>
  </ng-template>
</div>
