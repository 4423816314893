<div id="new-filing-component">
  <ng-container *ngIf="browsingCourts; else searchCourts">
    <div class="browse-courts-container">
      <button
        class="transparent text-button"
        (click)="toggleBrowsingCourts()"
        (keydown.enter)="toggleBrowsingCourts()"
      >
        <p class="static-text-small">Back to Court Search</p>
      </button>

      <p class="static-text-medium">Select Court</p>

      <fsx-court-selection-component
        *ngIf="courtSelectedData"
        fsxFocusFirstInput
        [courtSelectionData]="courtSelectedData"
        (browsedCourtSelected)="browsedCourtSelected($event)"
      >
      </fsx-court-selection-component>
    </div>
  </ng-container>

  <ng-template #searchCourts>
    <div *ngIf="courtSelectedDropdownOption; else noCourtSelected">
      <p class="static-text-medium">Filing into Court</p>

      <fsx-option-selected-card
        [cardIcon]="'court'"
        [primaryCaption]="courtSelectedDropdownOption.caption"
        [secondaryCaption]="courtPath"
        [tertiaryCaption]="courtState"
        (resetOptionEmitter)="resetCourtSelected()"
      ></fsx-option-selected-card>
    </div>
    <ng-template #noCourtSelected>
      <div class="search-courts-container">
        <p class="static-text-medium">Which Court are you filing into?</p>

        <fsx-court-search-component
          fsxFocusFirstInput
          *ngIf="courtSelectedData"
          [courtData]="courtSelectedData"
          (optionSelected)="courtSelected($event)"
        ></fsx-court-search-component>

        <button
          class="browse-courts-button transparent text-button"
          (click)="toggleBrowsingCourts()"
          (keydown.enter)="toggleBrowsingCourts()"
        >
          <p class="static-text-small">Browse for courts</p>
        </button>
      </div>
    </ng-template>
  </ng-template>

  <div class="profile-container" *ngIf="courtId">
    <div *ngIf="caseSelectedDropdownOption; else noCaseSelected">
      <p class="static-text-medium">Filing into Case</p>

      <fsx-option-selected-card
        *ngIf="attestationRequired"
        [cardIcon]="attestationAcepted ? 'case' : 'case-lock'"
        [primaryCaption]="
          attestationAcepted
            ? selectedCaseTitle ?? ''
            : caseSelectedDropdownOption.caption
        "
        [secondaryCaption]="
          attestationAcepted
            ? caseSelectedDropdownOption.caption
            : caseSelectedDropdownOption.secondaryCaption ?? ''
        "
        (resetOptionEmitter)="resetCaseSelected()"
      ></fsx-option-selected-card>
      <fsx-option-selected-card
        *ngIf="!attestationRequired"
        [cardIcon]="'case'"
        [primaryCaption]="caseSelectedDropdownOption.caption"
        [secondaryCaption]="caseSelectedDropdownOption.secondaryCaption ?? ''"
        (resetOptionEmitter)="resetCaseSelected()"
      ></fsx-option-selected-card>
    </div>
    <ng-template #noCaseSelected>
      <p class="static-text-medium">Which Case are you Filing into?</p>
      <fsx-case-search-component
        #caseSearch
        *ngIf="caseSelectedData"
        fsxFocusFirstInput
        [caseCaption]="'Case Search'"
        [casesData]="caseSelectedData"
        [useEfmKey]="true"
        (optionSelected)="caseSelected($event)"
        (searchTextChangedEvent)="searchTextChangedEventHandler($event)"
        [isLoading]="isLoading"
      ></fsx-case-search-component>
    </ng-template>
  </div>

  <div
    class="profile-container"
    *ngIf="attestationRequired && !attestationAcepted"
  >
    <div class="attestation-message">
      {{ attestationMessage }}
    </div>
    <div class="chckbx_text" *ngIf="!attestationRejected; else attRejected">
      Are you a party or an attorney on this case?
      <div class="button-container">
        <button
          class="attestation_button yes"
          (keydown.enter)="attestationYes()"
          (click)="attestationYes()"
        >
          Yes
        </button>
        <button
          class="attestation_button no"
          (keydown.enter)="attestationNo()"
          (click)="attestationNo()"
        >
          No
        </button>
      </div>
    </div>
    <ng-template class="attestation-rejected" #attRejected>
      <div class="attestation-message">
        YOU MUST BE A PARTICIPANT ON THIS CASE TO PROCEED.
      </div>
    </ng-template>
  </div>

  <div
    class="profile-container"
    *ngIf="hasSubCase && (!attestationRequired || attestationAcepted)"
  >
    <div *ngIf="subcaseSelectedDropdownOption; else noSubCaseSelected">
      <p class="static-text-medium">Sub-Case</p>

      <fsx-option-selected-card
        [cardIcon]="'sub-case'"
        [primaryCaption]="subcaseSelectedDropdownOption.caption"
        [secondaryCaption]="
          subcaseSelectedDropdownOption.secondaryCaption ?? ''
        "
        (resetOptionEmitter)="resetSubcaseSelected()"
      ></fsx-option-selected-card>
    </div>
    <ng-template #noSubCaseSelected>
      <p class="static-text-medium">Sub-Case</p>

      <fsx-case-search-component
        #subCaseSearch
        *ngIf="subcaseSelectedData"
        fsxFocusFirstInput
        [caseCaption]="'Sub-Case Search'"
        [casesData]="subcaseSelectedData"
        [useEfmKey]="true"
        (optionSelected)="subcaseSelected($event)"
        [isLoading]="isLoading"
      ></fsx-case-search-component>
    </ng-template>
  </div>
</div>
