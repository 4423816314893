import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  DocumentInfo,
  Filing,
  FilingProfile,
} from '@fsx/fsx-shared';
import { Observable, combineLatest, map } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from './case-request-data.service';
import {
  FsxFilingDataService,
  IFilingDataService,
} from './filing-data.service';
import {
  FsxFilingProfileDataService,
  IFilingProfileDataService,
} from './filing-profile-data.service';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from './document-info-data.service';
import {
  FsxFilingModeSpecLookupService,
  IFilingModeSpecLookupService,
} from 'projects/libs/shared/src/lib/services/filings/filing-mode-spec-lookup.service';

export const FsxCombinedFilingDataService =
  new InjectionToken<ICombinedFilingDataService>(
    'FsxCombinedFilingDataService'
  );

export const FsxCombinedFilingDataService2 =
  new InjectionToken<ICombinedFilingDataService>(
    'FsxCombinedFilingDataService2'
  );

export interface ICombinedFilingDataService {
  combinedFilingData$: Observable<CombinedFilingData>;
  filing$: Observable<Filing>;
}

@Injectable()
export class CombinedFilingDataService implements ICombinedFilingDataService {
  combinedFilingData$: Observable<CombinedFilingData> = combineLatest([
    this.filingDataService.filing$,
    this.caseRequestDataService.caseRequest$,
    this.filingProfileDataService.filingProfile$,
    this.documentInfoDataService.documentInfos$,
  ]).pipe(
    map(
      ([filing, caseRequest, filingProfile, documentInfos]: [
        Filing,
        CaseRequestViewModel,
        FilingProfile,
        DocumentInfo[]
      ]) => {
        return {
          filing,
          filingProfile,
          caseRequest,
          modeSpec: this.filingModeSpecLookupService.getModeSpec(
            filing.mode,
            filingProfile
          ),
          documentInfos,
        };
      }
    )
  );

  filing$: Observable<Filing> = this.combinedFilingData$.pipe(
    map((combinedFilingData: CombinedFilingData) => {
      return combinedFilingData.filing;
    })
  );

  constructor(
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingProfileDataService)
    private readonly filingProfileDataService: IFilingProfileDataService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    @Inject(FsxFilingModeSpecLookupService)
    private readonly filingModeSpecLookupService: IFilingModeSpecLookupService
  ) {}
}
