<!-- START: Edit View -->
<ng-container *ngIf="!readOnly; else readOnlyView">
  <form
    class="form-container"
    *ngFor="
      let contactFormGroup of contactFormArray.controls;
      let index = index
    "
  >
    <!-- START: Person Name -->
    <ng-container *ngIf="contactType === contactTypeEnum.Person">
      <div class="component-container name">
        <fsx-person-name-component
          #contactNameField
          *ngIf="personNameFieldDefinition"
          [height]="'min-content'"
          [personNameComponentSpec]="personNameFieldDefinition"
          [resolver]="resolver"
          [initialValues]="personNameInitialValues[index]"
          (formGroupEmitter)="setPersonNameFormGroup($event, index)"
        ></fsx-person-name-component>
      </div>
    </ng-container>
    <!-- END: Person Name -->

    <!-- START: Organization Name ToDo: Test?? -->
    <ng-container *ngIf="contactType === contactTypeEnum.Organization">
      <div class="component-container org">
        <p class="form-title">Organization</p>
        <fsx-text-component
          #organizationField
          *ngIf="organizationFieldDefinition"
          [caption]="'Title'"
          [width]="'100%'"
          [resolver]="resolver"
          [fieldDefinition]="organizationFieldDefinition.title"
          [initialValue]="
            organizationInitialValues ? organizationInitialValues.title : ''
          "
          (formControlEmitter)="setOrganizationFormControl($event, index)"
        ></fsx-text-component>
      </div>
    </ng-container>
    <!-- END: Organization Name -->

    <!-- START: Identification ToDo: Uhhh profile that forces this?? -->
    <div *ngIf="showBarIdentificationComponent">
      <div class="component-container">
        <p class="form-title">Identification</p>
        <fsx-identification-component
          #barNumberField
          [resolver]="resolver"
          [showBarId]="true"
          [editMode]="formMode !== contactFormMode.AddContact"
          [identificationComponentSpec]="identificationFieldDefinition"
          [initialValues]="identificationInitialValues"
          [idLength]="idLength"
          (formArrayEmitter)="setBarFormArray($event, index)"
        ></fsx-identification-component>
      </div>
    </div>
    <!-- END: Identification -->

    <!-- START: Address -->
    <div class="component-container">
      <p class="form-title">Address</p>
      <fsx-address-component
        #addressField
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [fieldDefinition]="addressFieldDefinition"
        [initialValues]="addressInitialValues"
        (formArrayEmitter)="setAddressFromArray($event, index)"
      ></fsx-address-component>
    </div>
    <!-- END: Address -->

    <!-- START: Phone -->
    <div class="component-container">
      <p class="form-title">Phone</p>
      <fsx-phone-component
        #phoneField
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [phoneComponentSpec]="phoneFieldDefinition"
        [initialValues]="phoneInitialValues"
        (formArrayEmitter)="setPhoneFormArray($event, index)"
      ></fsx-phone-component>
    </div>
    <!-- END: Phone -->

    <!-- START: Email ToDo -->
    <div class="component-container">
      <p class="form-title">Email</p>
      <fsx-email-component
        #emailField
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [emailComponentSpec]="emailFieldDefinition"
        [initialValues]="emailInitialValues"
        (formArrayEmitter)="setEmailFormArray($event, index)"
      ></fsx-email-component>
    </div>
    <!-- END: Email -->

    <!-- START: Other Identification ToDo -->
    <ng-container *ngIf="showOtherIdentificationComponent">
      <div class="component-container">
        <p class="form-title">Identifiers</p>
        <fsx-identification-component
          #identificationField
          [resolver]="resolver"
          [showOtherId]="showOtherIdentificationComponent"
          [editMode]="formMode !== contactFormMode.AddContact"
          [identificationComponentSpec]="identificationFieldDefinition"
          [initialValues]="otherIdentificationInitialValues"
          [idLength]="idLength"
          (formArrayEmitter)="setOtherIdFormArray($event, index)"
        ></fsx-identification-component>
      </div>
    </ng-container>
    <!-- END: Other Identification -->

    <!--    &lt;!&ndash; START: Alias ToDo Dont worry about this right now. AFTER JTI revisit -->
    <!--    &lt;!&ndash;      <ng-container *ngIf="(contactProfile || combinedFilingData?.filingProfile) && aliasFieldDefinition">&ndash;&gt;-->
    <!--    &lt;!&ndash;        <div class="component-container">&ndash;&gt;-->
    <!--    &lt;!&ndash;          <fsx-alias-component&ndash;&gt;-->
    <!--    &lt;!&ndash;            (formArrayEmitter)="setAliasFromArray($event)"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [profile]="contactProfile || combinedFilingData.filingProfile"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [participantSpec]="participantSpec"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [editMode]="formMode !== contactsFormMode.newContact"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [resolver]="resolver"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [aliasFieldDefinition]="aliasFieldDefinition"&ndash;&gt;-->
    <!--    &lt;!&ndash;            [initialValues]="aliasInitialValues"&ndash;&gt;-->
    <!--    &lt;!&ndash;          ></fsx-alias-component>&ndash;&gt;-->
    <!--    &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--&lt;!&ndash;      </ng-container>&ndash;&gt;-->
    <!--    &lt;!&ndash; END:  &ndash;&gt;-->
  </form>
</ng-container>
<!-- END: Edit View -->

<!-- START: Readonly View -->
<ng-template #readOnlyView> Read Only Mode </ng-template>
<!-- END: Readonly View -->
