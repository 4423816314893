<!-- START: Message Container -->
<div
  class="filing-message-container"
  fxLayout="row"
  fxLayoutAlign="start stretch"
>
  <!-- START: Error Stripe -->
  <div *ngIf="isError" fxFlex="4px" class="error-stripe"></div>
  <!-- END: Error Stripe -->

  <!-- START: Message -->
  <div fxFlex="auto" class="filing-message" fxLayout="row">
    <img
      *ngIf="!isError"
      src="../../assets/icons/chat-default.svg"
      alt="chat"
      class="msg-icon"
    />
    <div fxLayout="column">
      <header class="title" [class.rejected]="isError">
        {{ isError ? "Rejected" : "Message from Clerk" }}
      </header>
      <div class="description">
        <span class="label" *ngIf="isError">Description:</span>
        <span class="text">{{ description }}</span>
      </div>
    </div>
  </div>
  <!-- END: Message -->
</div>
<!-- END: Message Container -->
