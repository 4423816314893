import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  FsxCaseRequestUpdateService,
  ICaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { concatMap, Observable, Subject } from 'rxjs';

export const FsxUpdateCaseRequestOrchestrationService =
  new InjectionToken<IUpdateCaseRequestOrchestrationService>(
    'FsxUpdateCaseRequestOrchestrationService'
  );

export interface IUpdateCaseRequestParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  partialCaseRequest: Partial<CaseRequestViewModel>;
}

export interface IUpdateCaseRequestOrchestrationService {
  updateCaseRequestStream$: Observable<CaseRequestViewModel>;
  updateCaseRequest(params: IUpdateCaseRequestParams): void;
}

@Injectable()
export class UpdateCaseRequestOrchestrationService
  implements IUpdateCaseRequestOrchestrationService
{
  private updateCaseRequest$$ = new Subject<IUpdateCaseRequestParams>();

  updateCaseRequestStream$: Observable<CaseRequestViewModel> =
    this.updateCaseRequest$$.pipe(
      concatMap((params: IUpdateCaseRequestParams) => {
        const backupCaseRequest: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.caseRequestUpdateService.optimisticPatchOrRestoreCaseRequest(
          {
            ...params,
            backupCaseRequest,
            optimistic: true,
          }
        );
      })
    );

  constructor(
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  updateCaseRequest(params: IUpdateCaseRequestParams): void {
    this.updateCaseRequest$$.next(params);
  }
}
