import { Injectable } from '@angular/core';
import {
  CaseRequestViewModel,
  DEFAULT_PARTICIPANT,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultParticipantService {
  private getDefaultParticipant(
    uniqueIdentifier: string
  ): Observable<RequestParticipantViewModel> {
    return of({
      ...DEFAULT_PARTICIPANT,
      name: uniqueIdentifier,
    } as RequestParticipantViewModel);
  }

  public addDefaultParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParticipant(uniqueIdentifier).pipe(
      map((defaultParticipant: RequestParticipantViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequestParticipants.push(defaultParticipant);
        caseRequest.participants = caseRequestParticipants;
        return caseRequest;
      })
    );
  }

  public setAsDefaultParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParticipant(uniqueIdentifier).pipe(
      map((defaultParticipant: RequestParticipantViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequest.participants = caseRequestParticipants.map((p, i) => {
          return p.name === uniqueIdentifier ? defaultParticipant : p;
        });
        return caseRequest;
      })
    );
  }
}
