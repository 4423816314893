import { Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the ICaseRequestHelperService to use at runtime.
 */
export const FsxCaseRequestHelperService =
  new InjectionToken<ICaseRequestHelperService>('FsxCaseRequestHelperService');

/**
 * A blueprint for a helper service, which allows us to easily look up common
 * caseRequest object properties and derived values.
 */
export interface ICaseRequestHelperService {
  getDocuments(caseRequest: CaseRequestViewModel): RequestDocumentViewModel[];
  getLeadDocuments(
    caseRequest: CaseRequestViewModel
  ): RequestDocumentViewModel[];
  getCountOfLeadDocuments(caseRequest: CaseRequestViewModel): number;
  getLastDocumentIsLeadDocument(caseRequest: CaseRequestViewModel): boolean;
}

/**
 * A concrete implementation of a helper service, which allows us to easily look
 * up common caseRequest object properties and derived values.
 */
@Injectable()
export class CaseRequestHelperService implements ICaseRequestHelperService {
  getDocuments(caseRequest: CaseRequestViewModel): RequestDocumentViewModel[] {
    return caseRequest.documents || [];
  }

  getLeadDocuments(
    caseRequest: CaseRequestViewModel
  ): RequestDocumentViewModel[] {
    return this.getDocuments(caseRequest).filter(
      (reqDoc: RequestDocumentViewModel) => {
        return reqDoc.isLeadDocument;
      }
    );
  }

  getCountOfLeadDocuments(caseRequest: CaseRequestViewModel): number {
    return this.getLeadDocuments(caseRequest).length;
  }

  getLastDocumentIsLeadDocument(caseRequest: CaseRequestViewModel): boolean {
    const lastDocument: RequestDocumentViewModel | undefined =
      caseRequest.documents?.[caseRequest.documents.length - 1];
    const isLeadDocument: boolean = lastDocument
      ? lastDocument.isLeadDocument
      : true;
    return isLeadDocument;
  }
}
