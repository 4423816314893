<div id="representation-item" fxLayout="column">
  <div
    id="representation-header-row"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <fsx-row-validation
      [cssClass]="'tile'"
      style="height: 32px"
      [isValid]="
        repGridRow.participant.isValid && repGridRow.representation.isValid
      "
    ></fsx-row-validation>

    <div id="caption" fxFlex="60">{{ repGridRow.representation.caption }}</div>
    <div fxFlex="60">{{ getBarNumber(repGridRow) }}</div>
    <div
      id="role"
      fxFlex="20"
      [ngStyle]="{ 'margin-right': isReadOnly ? '74px' : 'initial' }"
    >
      <!-- ToDo: Pass in readonly and fix read-only view in basic-single-selection component -->
      <fsx-basic-single-selection
        [height]="'24px'"
        [fieldType]="fieldType.Selection"
        [selectionType]="selectionType"
        [inputDropdownOptions]="attorneysList"
        [readOnly]="isReadOnly"
        (click)="onAttorneyDropdownClicked($event)"
        (formControlEmitter)="setBasicAttorneyFormControl($event)"
        (selectedValue)="
          onAttorneyTypeSelected({
            value: $event,
            caseParty: partyGridRow.party,
            representation: repGridRow.representation,
            additionalFields: null
          })
        "
      >
      </fsx-basic-single-selection>
    </div>
    <div id="type" fxFlex="10">&nbsp;</div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center end">
      <div class="hover-icons" fxLayout="row" fxLayoutAlign="center center">
        <ng-container *ngIf="!isReadOnly">
          <span
            class="material-icons-outlined edit-participant-icon"
            (click)="onRepresentationClicked()"
          >
          </span>

          <button
            class="icon-button delete-icon-button"
            fsxPreventKeyboardEventBubbling
            (keydown.enter)="
              onRemoveRepresentationClicked(
                $event,
                this.repGridRow.representation,
                this.partyGridRow.party
              )
            "
            (click)="
              onRemoveRepresentationClicked(
                $event,
                this.repGridRow.representation,
                this.partyGridRow.party
              )
            "
          >
            <div class="form-delete-button"></div>
          </button>
        </ng-container>

        <span
          id="toggle-expand-detail-row-icon"
          class="material-icons-outlined collapse-icon"
          (click)="onToggleExpandRow($event)"
        >
          {{
            this.expandedRowIndex === this.rowIndex
              ? "expand_less"
              : "expand_more"
          }}
        </span>
      </div>
    </div>
  </div>
  <div
    id="representation-detail-row"
    *ngIf="this.expandedRowIndex === this.rowIndex"
  >
    <fsx-participant-table
      [showIcons]="false"
      [participantsMap]="participantsMap"
      [combinedFilingData]="combinedFilingData"
      [participant]="repGridRow.participant"
      [participantSpec]="repParticipantSpec"
      (editParticipantEvent)="onRepresentationClicked()"
    >
    </fsx-participant-table>
    <div class="additional-fields-container">
      <fsx-additional-fields-component
        *ngFor="
          let addlFieldSpec of participantSpecs[0].representation
            ?.additionalFields;
          let index = index
        "
        [combinedFilingData]="combinedFilingData"
        [additionalFieldSpec]="addlFieldSpec"
        [initialValues]="
          resolver.getAdditionalFieldInitialValue(
            addlFieldSpec,
            additionalFieldValues
          )
        "
        [existingAdditionalValues]="additionalFieldValues"
        [resolver]="resolver"
        (additionalFieldsValuesEmitter)="
          setAdditionalFieldValues($event, partyGridRow.rowIndex, repGridRow)
        "
      ></fsx-additional-fields-component>
    </div>
  </div>
</div>
