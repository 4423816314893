import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowValidationComponent } from './row-validation/row-validation.component';
import { RowHeaderValidationComponent } from './row-header-validation/row-header-validation.component';
import { TabValidationComponent } from './tab-validation/tab-validation.component';
import { FilingChecklistComponent } from './filing-checklist/filing-checklist.component';
import { FilingChecklistIconComponent } from './filing-checklist-icon/filing-checklist-icon.component';

@NgModule({
  declarations: [
    RowValidationComponent,
    RowHeaderValidationComponent,
    TabValidationComponent,
    FilingChecklistComponent,
    FilingChecklistIconComponent,
  ],
  imports: [CommonModule],
  exports: [
    RowValidationComponent,
    RowHeaderValidationComponent,
    TabValidationComponent,
    FilingChecklistComponent,
    FilingChecklistIconComponent,
  ],
})
export class ValidationModule {}
