<div
  class="fsx-textbox-component"
  [ngStyle]="{
    width: width,
    display: formControlSpecs[0].visible ? 'block' : 'none'
  }"
  [ngClass]="{
    invalid: textAreaFormControl.invalid && textAreaFormControl.touched
  }"
>
  <label [class]="labelClasses"
    >{{ caption }}
    <span class="required" *ngIf="formControlSpecs[0].required">*</span>
    <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
  </label>
  <textarea
    #textArea
    autocomplete="never"
    [rows]="inputHeight"
    [formControl]="textAreaFormControl"
    (blur)="setAddress(textArea)"
    [ngStyle]="{
      height: height
    }"
    [ngClass]="{
      invalid: textAreaFormControl.invalid && textAreaFormControl.touched
    }"
    [placeholder]="placeholder"
    (keydown.enter)="preventEvent($event)"
    (keyup)="onTextChanged($event)"
    (focus)="onFocus()"
    (blur)="removeFocus()"
  ></textarea>

  <div class="errors-container">
    <p
      class="hint-text"
      *ngIf="inputFocused"
      [ngClass]="{
        invalid:
          textAreaFormControl.invalid && textAreaFormControl.value?.length > 0
      }"
    >
      {{ textAreaFormControl.value?.length || 0 }}/{{
        formControlSpecs[0].maxLength || 0
      }}
    </p>

    <p class="error-message">
      {{
        textAreaFormControl.invalid && textAreaFormControl.touched
          ? getErrorMessage()
          : ""
      }}
    </p>
  </div>
</div>
