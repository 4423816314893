import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ContactSummaryViewModel } from '@fsx/fsx-shared';
import { Sort as MaterialSort } from '@angular/material/sort';
import {
  FsxSelectedContactsService,
  ISelectedContactsService,
} from '../contacts-list/selected-contacts.service';
import {
  FsxContactsListService,
  IContactsListService,
} from '../contacts-list/contacts-list.service';
import {
  FsxContactsListTwoService,
  IContactsListTwoService,
} from './contacts-list-two.service';
import { ContactsSearchTypeEnum } from '../contacts.model';

/**
 * The config object for the contacts list component.
 */
export interface ContactsListConfig {
  searchType: ContactsSearchTypeEnum;

  /**
   * The callback function to run when user clicks the add button. This is
   * specified by the calling component so that it can be handled in different
   * contexts.
   *
   * @param contactSummaries The selected contact summaries.
   */
  addCallback: (contactSummaries: ContactSummaryViewModel[]) => void;
}

@Component({
  selector: 'fsx-contacts-list-two',
  templateUrl: './contacts-list-two.component.html',
  styleUrls: ['./contacts-list-two.component.scss'],
})
export class ContactsListTwoComponent {
  /**
   * The collection of ContactSummary objects to display.
   */
  @Input() contactSummaries!: ContactSummaryViewModel[];

  /**
   * The collection of user-selected ContactSummary objects to emit back up
   * to the parent component. This is used by the ContactsListPanel component
   * to enable/disable the "Add" button based on the number of selections.
   */
  @Output() selectedContactsChangedEvent = new EventEmitter<
    ContactSummaryViewModel[]
  >();

  /**
   * An output event to trigger opening of the contact form from the parent component.
   * We use an event here so that the parent (panel component) can hook into the
   * orchestration's in progress stream to display the loading indicator.
   */
  @Output() editContactEvent = new EventEmitter<string>();

  /**
   * The column names for the material grid, expressed as an array of strings.
   */
  displayedColumns: string[] = [
    'select-contact',
    'caption',
    'type',
    'primary-email',
    'client-name',
    'hover-icons',
  ];

  constructor(
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxContactsListTwoService)
    readonly contactsListTwoService: IContactsListTwoService,
    @Inject(FsxSelectedContactsService)
    readonly selectedContactsService: ISelectedContactsService
  ) {}

  /**
   * A handler function for the material table's matSortChange event.
   * Here we convert the sort.active property into a column name that the
   * API can use to sort on.
   *
   * @param sort
   */
  onSortChanged(sort: MaterialSort) {
    let column: string = 'caption';
    if (sort) {
      if (sort.active === 'caption') {
        column = 'caption';
      } else if (sort.active === 'type') {
        column = 'type';
      } else if (sort.active === 'primary-email') {
        column = 'primaryEmailAddress';
      } else if (sort.active === 'client-name') {
        column = 'clientNameText';
      }
    }

    // Set the sort in the service to trigger the re-load.
    this.contactsListTwoService.setSort(column);
  }

  /**
   * A handler function for the contact checkbox click event.
   *
   * @param contact The ContactSummary object that was checked.
   */
  onContactCheckboxClicked(contact: ContactSummaryViewModel): void {
    if (contact.isSelected) {
      this.selectedContactsService.removeSelectedContact(contact);
    } else {
      this.selectedContactsService.addSelectedContact(contact);
    }
  }

  /**
   * A handler function of the edit button's click event. When invoked
   * we proceed by opening the Contact Form Panel.
   *
   * @param row The row which the clicked edit button resides.
   * @param rowIndex The index of the row on which the clicked edit button resides.
   */
  onEditIconClicked(row: ContactSummaryViewModel, rowIndex: number) {
    this.contactsListService.queryConfig.selectedRow = rowIndex;
    this.contactsListService.setSelectedContactId(row.id);
    this.editContactEvent.emit(row.id);
  }

  /**
   * A handler function for the infinite scroll's scrolled event.
   */
  onScrollDown() {
    this.contactsListTwoService.nextPage();
  }
}
