<form
  class="fsx-phone-component"
  *ngFor="let phoneFormGroup of phoneFormArray.controls; let index = index"
>
  <ng-container *ngIf="phoneComponentSpec">
    <fsx-profile-single-selection
      #categoryField
      *ngIf="phoneComponentSpec.category"
      [caption]="'Category'"
      [fieldType]="fieldType.Selection"
      [width]="'250px'"
      [resolver]="resolver"
      [fieldDefinition]="phoneComponentSpec.category"
      [selectionType]="selectionType"
      [initialValue]="initialValues[index] ? initialValues[index].category : ''"
      (formControlEmitter)="setControl($event, 'category', index)"
    ></fsx-profile-single-selection>

    <fsx-text-component
      #phoneNumberField
      *ngIf="phoneComponentSpec.fullNumber"
      [caption]="'Phone Number'"
      [width]="'250px'"
      [resolver]="resolver"
      [fieldDefinition]="phoneComponentSpec.fullNumber"
      [initialValue]="
        initialValues[index] ? initialValues[index].fullNumber : ''
      "
      (formControlEmitter)="setControl($event, 'fullNumber', index)"
    ></fsx-text-component>

    <button
      *ngIf="phoneFormArray.length > phoneFormArray.formArraySpecs.minRequired"
      class="icon-button"
      [ngClass]="{ disabled: phoneFormArray.disabled }"
      (keydown.enter)="delete(index); preventEvent($event)"
      (click)="delete(index)"
    >
      <div class="form-delete-button"></div>
    </button>
  </ng-container>
</form>

<button
  class="icon-button"
  [ngClass]="{ disabled: phoneFormArray.disabled }"
  (keydown.enter)="addNewForm(); preventEvent($event)"
  (click)="addNewForm()"
  *ngIf="
    phoneFormArray.length < phoneFormArray.formArraySpecs.maxAllowed &&
    showAddNewForm
  "
>
  <p class="add-new-form">
    <span class="material-icons add-icon"> add_circle_outline </span>
    <span>Add&nbsp;</span>
    <span *ngIf="phoneFormArray.length > 0">Another&nbsp;</span>
    <span>Phone</span>
  </p>
</button>
