import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseFileUploadComponent,
  IUploadedFile,
} from './base-file-upload.component';

@Component({
  selector: 'fsx-file-upload-inline',
  templateUrl: './file-upload-inline.component.html',
  styleUrls: ['./file-upload-inline.component.scss'],
})
export class FileUploadInlineComponent extends BaseFileUploadComponent {
  @ViewChild('fileDropRef1') fileDropEl!: ElementRef;
  @Output() filesUploadedInlineEvent: EventEmitter<IUploadedFile[]> =
    new EventEmitter<IUploadedFile[]>();

  displayMultileFileUploadNotPermittedMessage: boolean = false;

  override prepareFilesList(files: FileList | null): void {
    this.displayMultileFileUploadNotPermittedMessage = false;

    if (files && files.length > 1) {
      console.error('Multiple file upload is not permitted here');
      this.displayMultileFileUploadNotPermittedMessage = true;
      return;
    }

    super.prepareFilesList(files);
    this.fileDropEl.nativeElement.value = '';
    this.filesUploadedInlineEvent.emit(this.files);
  }
}
