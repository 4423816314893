import { authEndPoints } from './auth.endpoints';
import { caseEndPoints } from './case.endpoints';
import { contactEndPoints } from './contact.endpoints';
import { courtSelectionEndPoints } from './court.endpoints';
import { documentEndPoints } from './document.endpoints';
import { ApiVersion, FSXEndpoints } from './endpoints.model';
import { filingEndPoints } from './filing.endpoints';
import { groupEndPoints } from './group.endpoints';
import { participantEndPoints } from './participant.endpoints';
import { userEndPoints } from './user.endpoints';

export function fsxApi(baseURL: string, version: ApiVersion): FSXEndpoints {
  const versionedUrl = `${baseURL}/${version}`;

  return {
    contact: contactEndPoints(versionedUrl),
    user: {
      ...authEndPoints(versionedUrl),
      ...userEndPoints(versionedUrl),
    },
    filing: filingEndPoints(versionedUrl),
    court: courtSelectionEndPoints(versionedUrl),
    cases: caseEndPoints(versionedUrl),
    participant: participantEndPoints(versionedUrl),
    document: documentEndPoints(versionedUrl),
    group: groupEndPoints(versionedUrl),
  };
}
