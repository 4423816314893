import { Injectable } from '@angular/core';
import {
  AddressFormService,
  AliasFormService,
  EmailFormService,
  FilingProfile,
  IdentificationsFormService,
  ParticipantSpec,
  PersonFormService,
  PhoneFormService,
} from '@fsx/fsx-shared';
import {
  ContactViewModel,
  ContactType,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartiesFormService {
  constructor(
    private readonly personFormService: PersonFormService,
    private readonly aliasFormService: AliasFormService,
    private readonly phoneFormService: PhoneFormService,
    private readonly emailFormService: EmailFormService,
    private readonly addressFormService: AddressFormService,
    private readonly identificationsFormService: IdentificationsFormService
  ) {}

  public transformContactToParticipant(
    contact: ContactViewModel,
    participantSpec: ParticipantSpec,
    filingProfile: FilingProfile
  ): Observable<RequestParticipantViewModel | undefined> {
    let participant: RequestParticipantViewModel | undefined;

    if (participantSpec && filingProfile) {
      participant = {
        caption: contact.caption,
        linkedContact: {
          id: contact.id,
          caption: contact.caption,
          contactType: this.getContactType(contact.type ?? ContactType.Unknown),
          clientNameText: contact.effectiveClientNameText,
        },
        name: contact.id,
        contactType: this.getContactType(contact.type ?? ContactType.Person),
        person:
          contact.type === ContactType.Person
            ? this.personFormService.getPersonNameFromFilingProfile(
                contact.person,
                participantSpec
              )
            : null,
        organization:
          contact.type === ContactType.Organization
            ? {
                caption: contact.organization?.caption ?? '',
                title: contact.organization?.title ?? '',
              }
            : null,
        aliases: this.aliasFormService.getAliasesFromFilingProfile(
          contact.aliases,
          participantSpec,
          filingProfile
        ),
        addresses: this.addressFormService.getAddressFromFilingProfile(
          contact.addresses,
          participantSpec.address ?? undefined,
          filingProfile
        ),
        emails: this.emailFormService.getEmailsFromFilingProfile(
          contact.emails,
          participantSpec.email ?? undefined,
          filingProfile
        ),
        identifications:
          this.identificationsFormService.getIdentificationsFromFilingProfile(
            contact.identifications,
            participantSpec.identification ?? undefined,
            filingProfile
          ),
        phones: this.phoneFormService.getPhonesFromFilingProfile(
          contact.phones,
          participantSpec.phone ?? undefined,
          filingProfile
        ),
      } as unknown as RequestParticipantViewModel;
    }

    return of(participant);
  }

  private getContactType(type: ContactType): ContactType {
    let contactType = type;
    if (type === ContactType.TenantOrganization) {
      contactType = ContactType.Organization;
    } else if (
      type === ContactType.Actor ||
      type === ContactType.User ||
      type === ContactType.Unknown
    ) {
      contactType = ContactType.Person;
    }
    return contactType;
  }
}
