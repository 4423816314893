import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CombinedFilingData } from '@fsx/fsx-shared';
import { TotalSizeService } from './total-size.service';

@Component({
  selector: 'fsx-total-size',
  templateUrl: './total-size.component.html',
  styleUrls: ['./total-size.component.scss'],
})
export class TotalSizeComponent implements OnInit, OnChanges {
  @Input() combinedFilingData!: CombinedFilingData | null;

  public totalSize!: {
    size: number;
  };

  public totalAllowedSize = 0;

  constructor(private readonly totalSizeService: TotalSizeService) {}

  ngOnInit(): void {
    this.totalSize = this.totalSizeService.totalSize;
  }

  ngOnChanges(): void {
    this.totalAllowedSize =
      this.combinedFilingData?.modeSpec?.totalFileSizeMaxBytes || 0;
  }
}
