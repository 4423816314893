import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  share,
  switchMap,
  take,
} from 'rxjs';
import {
  ContactFormGroup,
  ContactFormMode,
  ContactViewModel,
} from '@fsx/fsx-shared';
import { FsxPanelService, IPanelService } from './panel.service';
import {
  FsxContactFormService,
  IContactFormService,
} from '../../contacts/contact-form/contact-form.service';
import { FormGroup } from '@angular/forms';
import {
  FsxContactsListService,
  IContactsListService,
} from '../../contacts/contacts-list/contacts-list.service';
import {
  FsxContactsListTwoService,
  IContactsListTwoService,
} from '../../contacts/contacts-list-two/contacts-list-two.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAddOrUpdateContactOrchestrationService to use at runtime.
 */
export const FsxAddOrUpdateContactOrchestrationService =
  new InjectionToken<IAddOrUpdateContactOrchestrationService>(
    'FsxAddOrUpdateContactOrchestrationService'
  );

/**
 * The parameters to pass to the orchestration service so that it can do its work.
 */
export interface AddOrUpdateContactOrchestrationParams {
  contactFormGroup: FormGroup<ContactFormGroup>;
  formMode: ContactFormMode;
}

/**
 * A blueprint for an orchestration service, which handles the adding/updating
 * of a single contact
 */
export interface IAddOrUpdateContactOrchestrationService {
  /**
   * The orchestration steps needed to add/update a single contact object.
   */
  addOrUpdateContactOrchestration$: Observable<void>;

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  addOrUpdateContact(params: AddOrUpdateContactOrchestrationParams): void;
}

/**
 * A concrete implementation an orchestration service, which handles the
 * adding/updating of a single contact
 */
@Injectable()
export class AddOrUpdateContactOrchestrationService
  implements IAddOrUpdateContactOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addOrUpdateContactAction$ =
    new Subject<AddOrUpdateContactOrchestrationParams>();

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * stored in a BehaviorSubject.
   */
  private isOrchestrationInProgress$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean> =
    this.isOrchestrationInProgress$$.asObservable();

  /**
   * The orchestration steps needed to add/update a single contact object.
   */
  addOrUpdateContactOrchestration$: Observable<void> =
    this.addOrUpdateContactAction$.pipe(
      switchMap((params: AddOrUpdateContactOrchestrationParams) => {
        // Orchestration has started so turn on the loading indicator.
        this.isOrchestrationInProgress$$.next(true);

        return this.contactsListService.selectedContactId$.pipe(
          take(1),
          switchMap((contactId: string | null) => {
            return this.contactFormService
              .submitForm(params.contactFormGroup, params.formMode, contactId)
              .pipe(
                map((_submitFormResult: ContactViewModel | null) => {
                  // Trigger the retrieval of a single ContactSummary record, which gets used
                  // to update the contacts list inline.
                  if (contactId) {
                    this.contactsListTwoService.reloadContact(contactId);
                  }

                  // Close the contacts form panel.
                  this.panelService.closeCurrentDialog();

                  // Orchestration has finished so turn off the loading indicator.
                  this.isOrchestrationInProgress$$.next(false);
                  return;
                })
              );
          })
        );
      }),
      share()
    );

  /**
   *
   * @param contactFormService The service which we use to attempt submission.
   * (May want to create a ContactFormSubmissionService containing just the submit method).
   *
   * @param panelService The utility service for opening/closing panels. Here we use it to
   * close the panel after an attempt to submit.
   */
  public constructor(
    @Inject(FsxContactFormService)
    private readonly contactFormService: IContactFormService,
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxContactsListTwoService)
    readonly contactsListTwoService: IContactsListTwoService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The parameters needed to initiate this orchestration.
   */
  addOrUpdateContact(params: AddOrUpdateContactOrchestrationParams): void {
    this.addOrUpdateContactAction$.next(params);
  }
}
