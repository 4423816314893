import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FilingProfile,
  Identification,
  IdentificationCategory,
  IdentificationCommonCategory,
  IdentificationSpec,
  IdentificationViewModel,
  PhoneFormService,
  RequestParticipant,
} from '@fsx/fsx-shared';
import { NewContact } from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  IdentificationFormGroup,
} from '@fsx/ui-components';

@Injectable({
  providedIn: 'root',
})
export class IdentificationsFormService {
  constructor(public readonly phoneFormService: PhoneFormService) {}

  public setIdentificationsCategories(
    contact: NewContact | RequestParticipant,
    barIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined,
    otherIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined
  ): void {
    if (!contact.identifications) {
      contact.identifications = [];
    }
    if (barIdentifications?.controls) {
      barIdentifications.controls.forEach((identification) => {
        if (
          identification.controls.regionKey.value &&
          identification.controls.identificationKey.value
        ) {
          let newId = identification.value as Identification;
          newId.category = {
            name: 'Bar Number',
            caption: 'Bar Number',
            commonCategory: IdentificationCommonCategory.BarNumber,
          };
          contact.identifications.push(newId);
        }
      });
    }
    if (otherIdentifications?.controls) {
      otherIdentifications.controls.forEach((identification) => {
        if (
          identification.controls.regionKey.value &&
          identification.controls.identificationKey.value
        ) {
          let newId = identification.value as Identification;
          const identificationCategory =
            identification.controls.category?.value;
          const dropdownOptions =
            identification.controls.category?.dropdownOptions;
          const selectedOption = dropdownOptions?.find(
            (option) => option.name === identificationCategory
          );

          if (newId.category && selectedOption) {
            newId.category = {
              name: selectedOption.name,
              caption: selectedOption.caption,
              commonCategory: selectedOption.category
                ?.commonCategory as IdentificationCommonCategory,
            };
          }
          contact.identifications.push(newId);
        }
      });
    }
  }

  public getIdentificationsFromFilingProfile(
    contactIdentifications: IdentificationViewModel[] | undefined,
    identificationSpec: IdentificationSpec | undefined,
    filingProfile: FilingProfile | null
  ): IdentificationViewModel[] {
    const participantIdentifications: IdentificationViewModel[] = [];
    const identificationsList = identificationSpec?.category?.listReference;
    let selectedCategory: IdentificationCategory | undefined;

    contactIdentifications?.forEach((id) => {
      if (identificationsList?.list === 'AdditionalList') {
        const list = filingProfile?.additionalLists?.find(
          (list) => list.name === identificationsList.additionalListName
        );
        const listElement = list?.items.find(
          (item) => item.name === id.category?.name
        );
        if (listElement && id.category) {
          selectedCategory = id.category;
        }
      } else {
        selectedCategory = filingProfile?.identificationCategories?.find(
          (item) => item.commonCategory === id.category?.commonCategory
        );
      }

      const newIdentification: IdentificationViewModel = {
        ...id,
        category: selectedCategory,
      } as IdentificationViewModel;

      if (
        newIdentification.category &&
        identificationSpec &&
        participantIdentifications.length <= identificationSpec.maxAllowed
      ) {
        participantIdentifications.push(newIdentification);
      }
    });

    return participantIdentifications;
  }
}
