import { Injectable, InjectionToken } from '@angular/core';
import { Filing, ROUTE, TransactionTab } from '@fsx/fsx-shared';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFilingTabsService to use at runtime.
 */
export const FsxFilingTabsService = new InjectionToken<IFilingTabsService>(
  'FsxFilingTabsService'
);

/**
 * A blueprint for a ui state service, which stores the open filing tabs for display
 * in the filing editor.
 */
export interface IFilingTabsService {
  /**
   * The array of filing tabs exposed as an Obseravble
   */
  filingTabs$: Observable<TransactionTab[]>;

  /**
   * A method to allow a filing tab to be created and added to the filing tabs array
   *
   * @param filing The filing to create a filing tab instance from.
   */
  addTab(filing: Filing): Observable<TransactionTab[]>;

  /**
   * A method to allow removal of a filing tab from the filing tabs array
   *
   * @param filingId The id of the filing associated with the filing tab to remove.
   */
  removeTab(filingId: string): Observable<TransactionTab[]>;
}

/**
 * A concrete implementation of a ui state service, which stores the open filing
 * tabs for display in the filing editor.
 */
@Injectable()
export class FilingTabsService implements IFilingTabsService {
  /**
   * The array of filing tabs stored in a BehaviorSubject so that it can be easily
   * exposed as an Observable
   */
  private filingTabs$$ = new BehaviorSubject<TransactionTab[]>([]);

  /**
   * The array of filing tabs exposed as an Obseravble
   */
  filingTabs$: Observable<TransactionTab[]> = this.filingTabs$$.asObservable();

  /**
   * A method to allow a filing tab to be created and added to the filing tabs array
   *
   * @param filing The filing to create a filing tab instance from.
   * @returns The resulting filing tabs array.
   */
  addTab(filing: Filing): Observable<TransactionTab[]> {
    return this.filingTabs$.pipe(
      map((filingTabs: TransactionTab[]) => {
        const exists: boolean = !!filingTabs.find(
          (tab) => tab.filingId === filing.id
        );
        if (!exists) {
          filingTabs.push({
            title: filing.caption,
            path: `${ROUTE.TRANSACTIONS}/${filing.id as string}`,
            filingId: filing.id,
          });
        }
        return filingTabs;
      }),
      take(1), // Ensures the stream completes before the next emission
      tap((accFilingTabs: TransactionTab[]) => {
        this.filingTabs$$.next(accFilingTabs);
      })
    );
  }

  /**
   * A method to allow removal of a filing tab from the filing tabs array
   *
   * @param filingId The id of the filing associated with the filing tab to remove.
   * @returns The resulting filing tabs array.
   */
  removeTab(filingId: string): Observable<TransactionTab[]> {
    return this.filingTabs$.pipe(
      map((filingTabs: TransactionTab[]) => {
        return filingTabs.filter((tab: TransactionTab) => {
          return tab.filingId !== filingId;
        });
      }),
      take(1), // Ensures the stream completes before the next emission
      tap((accFilingTabs: TransactionTab[]) => {
        this.filingTabs$$.next(accFilingTabs);
      })
    );
  }
}
