import {
  RequestDocumentCaseViewModel,
  RequestDocumentViewModel,
} from './../../types/view-models/api-view-models';
import { Injectable, InjectionToken } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { IUploadedFile } from '@fsx/ui-components';
import { DocumentCategory } from '../../types';

export const FsxCreateRequestDocumentService =
  new InjectionToken<ICreateRequestDocumentService>(
    'FsxCreateRequestDocumentService'
  );

export interface CreateRequestDocumentParams {
  uniqueIdentifier: string;
  isLeadDocument: boolean;
  caseId: string;
  documentCategory?: DocumentCategory;
  file?: File;
}

export interface RequestDocumentCreatedParams {
  requestDocument: RequestDocumentViewModel;
}

export interface ICreateRequestDocumentService {
  bulkCreateRequestDocumentFromFiles(
    uploadedFiles: IUploadedFile[],
    isLeadDocument: boolean,
    caseId: string
  ): Observable<RequestDocumentCreatedParams[]>;

  bulkCreateRequestDocumentFromIds(
    uniqueIdentifiers: string[],
    isLeadDocument: boolean,
    caseId: string,
    documentCategory?: DocumentCategory
  ): Observable<RequestDocumentCreatedParams[]>;
}

@Injectable()
export class CreateRequestDocumentService
  implements ICreateRequestDocumentService
{
  bulkCreateRequestDocumentFromFiles(
    uploadedFiles: IUploadedFile[],
    isLeadDocument: boolean,
    caseId: string
  ): Observable<RequestDocumentCreatedParams[]> {
    const arrayOfCreateRequestDocumentParams: CreateRequestDocumentParams[] =
      uploadedFiles.map((uploadedFile: IUploadedFile) => {
        const params: CreateRequestDocumentParams = {
          uniqueIdentifier: uuidv4(),
          isLeadDocument,
          file: uploadedFile.file,
          caseId,
        };
        return params;
      });
    return this.bulkCreateRequestDocument(arrayOfCreateRequestDocumentParams);
  }

  bulkCreateRequestDocumentFromIds(
    uniqueIdentifiers: string[],
    isLeadDocument: boolean,
    caseId: string,
    documentCategory?: DocumentCategory
  ): Observable<RequestDocumentCreatedParams[]> {
    const arrayOfCreateRequestDocumentParams: CreateRequestDocumentParams[] =
      uniqueIdentifiers.map((id: string) => {
        const params: CreateRequestDocumentParams = {
          uniqueIdentifier: id,
          isLeadDocument,
          caseId,
          documentCategory,
        };
        return params;
      });
    return this.bulkCreateRequestDocument(arrayOfCreateRequestDocumentParams);
  }

  private createRequestDocument(
    createRequestDocumentParams: CreateRequestDocumentParams
  ): Observable<RequestDocumentCreatedParams> {
    const { uniqueIdentifier, isLeadDocument, caseId, documentCategory } =
      createRequestDocumentParams;
    const fileName = createRequestDocumentParams.file?.name || '';
    const fileTitle =
      fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

    const defaultRequestDocumentCase: RequestDocumentCaseViewModel = {
      caseId: caseId,
      filedBy: [],
      filedAsTo: [],
      linkedDocuments: [],
      additionalFieldValues: [],
      isValid: true,
      isNew: true,
    };

    const requestDocument: RequestDocumentViewModel = {
      cases: [defaultRequestDocumentCase],
      id: uniqueIdentifier,
      name: fileName,
      title: fileTitle,
      fileName: fileName,
      isLeadDocument,
      isValid: true,
      isNew: true,
      category: documentCategory,
    };

    const requestDocumentCreatedParams: RequestDocumentCreatedParams = {
      requestDocument,
    };

    return of(requestDocumentCreatedParams);
  }

  private bulkCreateRequestDocument(
    arrayOfCreateRequestDocumentParams: CreateRequestDocumentParams[]
  ): Observable<RequestDocumentCreatedParams[]> {
    const arrayOfCreateRequestDocument$: Observable<RequestDocumentCreatedParams>[] =
      arrayOfCreateRequestDocumentParams.map((params) => {
        return this.createRequestDocument(params);
      });
    const createRequestDocuments$: Observable<RequestDocumentCreatedParams[]> =
      forkJoin(arrayOfCreateRequestDocument$);
    return createRequestDocuments$;
  }
}
