import { Component, Input, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { FsxBaseComponent } from '../../base/base.component';
import {
  SidePanelButtonsConfig,
  SidePanelButtonStatusEnum,
  SidePanelFooterConfig,
} from '../../../types/form-control.types';

@Component({
  selector: 'fsx-side-panel-footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FsxSidePanelFooterComponent extends FsxBaseComponent {
  @ViewChild('checkbox') checkbox!: MatCheckbox;
  @ViewChild('buttons') buttons!: HTMLElement;
  @Input() footerConfig!: SidePanelFooterConfig;

  public clickCheckbox(): void {
    this.checkbox.toggle();
  }

  public removeFocus(button: SidePanelButtonsConfig): void {
    if (button.status === SidePanelButtonStatusEnum.Focused) {
      button.status = SidePanelButtonStatusEnum.Enabled;
    }
  }
}
