import { Component, Input, OnChanges } from '@angular/core';
import { FilingState, RequestType } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-transaction-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnChanges {
  @Input() filingState!: FilingState | null | undefined;
  @Input() requestTypes!: RequestType[] | null | undefined;
  requestTypesJoined!: string;

  ngOnChanges() {
    this.requestTypesJoined = this.getRequestTypesJoined();
  }

  /**
   * Returns the request type string according to the request types array.
   */
  getRequestTypesJoined(): string {
    if (this.requestTypes) {
      if (
        this.requestTypes.includes(RequestType.Case) &&
        this.requestTypes.includes(RequestType.Service)
      ) {
        return 'File & Serve';
      } else if (
        this.requestTypes.includes(RequestType.Case) &&
        !this.requestTypes.includes(RequestType.Service)
      ) {
        return 'File Only';
      } else if (
        !this.requestTypes.includes(RequestType.Case) &&
        this.requestTypes.includes(RequestType.Service)
      ) {
        return 'Serve Only';
      }
    }
    return 'None';
  }

  protected readonly FilingState = FilingState;
  protected readonly RequestTypesJoined = RequestTypesJoined;
}

/**
 * Enum for the request types joined string.
 */
export enum RequestTypesJoined {
  FileOnly = 'File Only',
  Service = 'Serve Only',
  FileAndServe = 'File & Serve',
  None = 'None',
}
