export enum AttorneySearchTypeEnum {
  Firm = 'Firm',
  Other = 'Other',
}

export enum ContactsSearchTypeEnum {
  contacts = 'CONTACTS',
  attorneys = 'ATTORNEYS',
  none = 'NONE',
}
