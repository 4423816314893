import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Subject, switchMap, filter, mergeMap, Observable, tap } from 'rxjs';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  ParticipantCategory,
  ParticipantSpec,
} from '@fsx/fsx-shared';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxAddDefaultParticipantOrchestrationService =
  new InjectionToken<IAddDefaultParticipantOrchestrationService>(
    'FsxAddDefaultParticipantOrchestrationService'
  );

export interface IAddParticipantEventParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
}

export interface IAddDefaultParticipantOrchestrationService {
  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel>;
  addDefaultParticipant(params: IAddParticipantEventParams): void;
}

@Injectable()
export class AddDefaultParticipantOrchestrationService
  implements IAddDefaultParticipantOrchestrationService
{
  private addDefaultParticipant$$ = new Subject<IAddParticipantEventParams>();

  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addDefaultParticipant$$.pipe(
      filter((params: IAddParticipantEventParams) => {
        const filteredParticipants = params.caseRequest.parties?.filter(
          (party: CasePartyViewModel) => {
            return (
              party.participantCategory?.commonCategory ===
              params.participantCategory.commonCategory
            );
          }
        );
        const isMaxParticipants =
          params.participantSpec.maxAllowed ===
          (filteredParticipants?.length ?? 0);
        return !isMaxParticipants;
      }),
      mergeMap((params: IAddParticipantEventParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        const uniqueIdentifier = uuidv4();
        return this.caseRequestBuilderService
          .addDefaultPartyAndParticipant(
            uniqueIdentifier,
            params.caseRequest,
            params.participantCategory
          )
          .pipe(
            switchMap(() => {
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  params.filingId,
                  params.caseRequest,
                  caseRequestBackup
                )
                .pipe(
                  tap(() => {
                    this.validatePartiesOrchestrationService.validateParties();
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addDefaultParticipant(params: IAddParticipantEventParams): void {
    this.addDefaultParticipant$$.next(params);
  }
}
