import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiVersion,
  endpoints,
  EnvConfig,
  ENVIRONMENT,
  fsxApi,
  FSXEndpoints,
  IAppConfig,
} from '@fsx/fsx-shared';
import { OAuthService } from 'angular-oauth2-oidc';
import packageFile from '../../../../../package.json';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ApplicationInsightsService } from '../app-insights/application-insights.service';
import { authCodeFlowConfig } from '../auth';
import { PerfumePerfMonitoringService } from '../perfume-perf/perfume.service';

export const ENV_CONFIG = new InjectionToken<Observable<EnvConfig>>(
  'envConfig'
);
export const ENV_CONFIG_VALUE = new InjectionToken<EnvConfig>('envConfigValue');

@Injectable({
  providedIn: 'root',
})
export class AppConfig implements IAppConfig {
  public fsxApi!: FSXEndpoints;
  private _envConfigAsObj!: EnvConfig;
  private envConfig$: BehaviorSubject<EnvConfig> =
    new BehaviorSubject<EnvConfig>({
      ApiServer: { BaseURL: '' },
      Environment: {},
      API_VERSION: ApiVersion.v1,
    } as EnvConfig);

  public get envConfig(): Observable<EnvConfig> {
    return this.envConfig$.asObservable();
  }

  public get envConfigAsObj(): EnvConfig {
    return this._envConfigAsObj;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly oauthService: OAuthService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly perfumePerfMonitoringService: PerfumePerfMonitoringService,
    private readonly router: Router
  ) {}

  public load$(configName?: ENVIRONMENT): Observable<string | Error> {
    const configFileName =
      configName === ENVIRONMENT.LOCAL ? `config.local.json` : 'config.json';
    // const configFileName = `config.local.json`;
    return this.http.get<EnvConfig>(`./assets/config/${configFileName}`).pipe(
      switchMap((configFile: EnvConfig) =>
        this.setEnvConfig(configFile, configName ?? ENVIRONMENT.PROD)
      ),
      switchMap((configFile: EnvConfig) => {
        this.setupLogging(configFile);
        return this.setupAuth(configFile);
      }),
      map(() => {
        return 'success';
      }),
      catchError((err: Error) => {
        console.error('Error bootstrapping app:\n', err);
        return of(err);
      }),
      take(1)
    );
  }

  private setEnvConfig(
    configFile: EnvConfig,
    configName: ENVIRONMENT
  ): Observable<EnvConfig> {
    const envConfig: EnvConfig = {
      ApiServer: {
        BaseURL: configFile.ApiServer.BaseURL,
      },
      ApplicationInsights: {
        InstrumentationKey: configFile.ApplicationInsights?.InstrumentationKey
          ? configFile.ApplicationInsights?.InstrumentationKey
          : {},
      },
      API_VERSION: ApiVersion.v1,
      CONFIG_FILE: configName,
      Endpoints: endpoints(
        `${configFile.ApiServer.BaseURL}/${configFile.API_VERSION}`
      ),
      Environment: {
        Env: configFile.Environment?.Env
          ? configFile.Environment?.Env
          : configName,
      },
      IdentityServer: {
        BaseURL: configFile.IdentityServer.BaseURL,
      },
      MAX_FILE_SIZE: configFile.MAX_FILE_SIZE,
      NG_APP_VERSION: packageFile?.version,
      production: configName === ENVIRONMENT.PROD,
    };

    this.fsxApi = fsxApi(envConfig.ApiServer.BaseURL, ApiVersion.v1);
    this._envConfigAsObj = envConfig;
    this.envConfig$.next(envConfig);
    return of(envConfig);
  }

  private setupLogging(envConfig: EnvConfig): void {
    this.applicationInsightsService.loadAppInsights(
      envConfig.ApplicationInsights?.InstrumentationKey ?? {}
    );
    this.perfumePerfMonitoringService.initPerfume();
  }

  private setupAuth(configFile: EnvConfig): Observable<boolean> {
    if (!navigator.onLine) {
      return of(false);
    }
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(
      authCodeFlowConfig(configFile.IdentityServer.BaseURL)
    );

    return from(
      this.oauthService.loadDiscoveryDocumentAndLogin({
        onTokenReceived: (info) => {
          this.router.navigate([info.state]);
        },
      })
    );
  }
}
