import { Injectable } from '@angular/core';
import { PdfViewerComponent } from './pdf-viewer.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface OpenPdfViewerParams {
  filingId: string;
  documentId: string;
  renderingName: string;
  fileName: string;
  documentName: string;
  fileSize: string;
  documentCategory?: string;
  access?: string;
  associatedParty?: string;
  leadingDocument?: boolean | string;
  documentIndex?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  public pdfViewerDialogRef!: MatDialogRef<PdfViewerComponent>;
  constructor(private readonly dialog: MatDialog) {}

  public openPdfViewer(pdfViewerParams: OpenPdfViewerParams): void {
    this.pdfViewerDialogRef = this.dialog.open(PdfViewerComponent, {
      width: 'calc(100% - 60px)',
      height: '100vh',
      data: pdfViewerParams,
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      hasBackdrop: false,
      panelClass: 'pdf-viewer-dialog',
      closeOnNavigation: true,
    });
  }
}
