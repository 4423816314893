export interface UserEndPoints {
  getSelf: string;
  getAvailableAttorneys: string;
  getAuthorizers: (courtId: string) => string;
  getCourtesyRecipients: (courtId: string) => string;
  createCourtesyCopyRecipientAddress: (filingId: number) => string;
  updateCourtesyCopyRecipientAddress: (filingId: number) => string;
  deleteCourtesyCopyRecipientAddress: (filingId: number) => string;
  getCourtesyCopyRecipientAddress: (filingId: number) => string;
}

export const userEndPoints = (versionedUrl: string): UserEndPoints => {
  const baseUrl = `${versionedUrl}/users`;

  const courtsUrl = (courtId: string) => `${baseUrl}/courts/${courtId}`;
  const recipientAddressUrl = (filingId: number) =>
    `${baseUrl}/filings/${filingId}/courtesyCopyRecipients/addresses`;

  return {
    getSelf: `${baseUrl}/me`,

    getAvailableAttorneys: `${baseUrl}/attorneys`,

    getAuthorizers: (courtId) => `${courtsUrl(courtId)}/authorizers`,

    getCourtesyRecipients: (courtId) =>
      `${courtsUrl(courtId)}/courtCourtesyCopyRecipients`,

    createCourtesyCopyRecipientAddress: recipientAddressUrl,

    updateCourtesyCopyRecipientAddress: recipientAddressUrl,

    deleteCourtesyCopyRecipientAddress: recipientAddressUrl,

    getCourtesyCopyRecipientAddress: recipientAddressUrl,
  };
};
