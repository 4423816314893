import { Pipe, PipeTransform } from '@angular/core';
import { DocumentInfo, DocumentRenderingInfo } from '@fsx/fsx-shared';
import { DocumentInfoHelperService } from 'projects/libs/shared/src/lib/services/filings/document-info-helper.service';

@Pipe({
  name: 'convertedPdf',
})
export class ConvertedPdfPipe implements PipeTransform {
  constructor(private documentInfoHelperService: DocumentInfoHelperService) {}

  transform(documentInfo: DocumentInfo): DocumentRenderingInfo | null {
    return this.documentInfoHelperService.getConvertedPdfRendering(
      documentInfo
    );
  }
}
