<div class="contacts-search-component-container">
  <label
    class="input-label"
    for="contacts-search"
    *ngIf="displaySearchTypeLabel"
    >{{
      searchType === contactsSearchType.contacts
        ? "Search Party"
        : "Representation"
    }}
  </label>

  <div class="search-icon-container" *ngIf="!inputFocused && !searchTerm.value">
    <span class="material-icons search-icon"> search </span>
  </div>

  <mat-form-field>
    <input
      #searchInput
      *ngIf="searchType === contactsSearchType.contacts"
      id="contacts-search"
      type="text"
      fsxFocusInput
      autocomplete="off"
      [formControl]="searchTerm"
      [matAutocomplete]="auto"
      [placeholder]="inputFocused ? '' : 'Name / Client # / Email / Party Type'"
      (click)="loadContacts()"
      (focus)="focusInput(true)"
      (blur)="focusInput(false)"
      class="contacts-search-input"
      matInput
      [ngClass]="{
        'input-focused': inputFocused,
        'input-with-value': !inputFocused && searchTerm.value,
        disabled: searchTerm.disabled
      }"
    />
    <input
      #searchInput
      *ngIf="searchType !== contactsSearchType.contacts"
      id="contacts-search"
      type="text"
      autocomplete="off"
      [formControl]="searchTerm"
      [matAutocomplete]="auto"
      [placeholder]="inputFocused ? '' : 'Select or Type Name / Bar Number'"
      (click)="loadContacts()"
      (focus)="focusInput(true)"
      (blur)="focusInput(false)"
      class="contacts-search-input"
      matInput
      [ngClass]="{
        'input-focused': inputFocused,
        'input-with-value': !inputFocused && searchTerm.value,
        disabled: searchTerm.disabled
      }"
    />
    <button
      class="clear-icon-button"
      *ngIf="searchTerm.value"
      (keydown.enter)="clearInput()"
    >
      <span class="material-icons-outlined cancel" (click)="clearInput()">
        cancel
      </span>
    </button>

    <mat-autocomplete
      class="contacts-list"
      [ngClass]="{ 'mru-contacts-list': !searchTerm.value?.length }"
      #auto="matAutocomplete"
    >
      <mat-progress-bar
        *ngIf="showLoading"
        mode="indeterminate"
      ></mat-progress-bar>

      <label class="dropdown-options-label" *ngIf="!searchTerm.value?.length"
        >Recently Used</label
      >

      <ng-container *ngIf="filteredOptions | async as options">
        <div *ngIf="!options.length" class="no-items-container">
          <p class="placeholder-text">No Results</p>
        </div>
      </ng-container>

      <mat-option
        class="contact"
        *ngFor="
          let contact of filteredOptions
            | async
            | contactsIdFilter: excludedContactsIds
        "
        (onSelectionChange)="selectedContact(contact.contactSummary)"
        [value]="contact.contactSummary.caption"
      >
        <div class="contact-caption-container">
          <p class="dropdown-option-primary-text">
            {{ contact.contactSummary.caption }}
          </p>
          <p class="dropdown-option-primary-text">
            {{
              searchType === contactsSearchType.contacts
                ? contact.contactSummary.clientNameText
                : contact.barNumber
            }}
          </p>
        </div>
        <p class="dropdown-option-secondary-text">
          {{ contact.contactSummary.primaryEmailAddress }}
        </p>
      </mat-option>

      <mat-option
        class="manage-contacts-container"
        (onSelectionChange)="onManageContactsClicked()"
      >
        <p class="actionable-text">
          Manage
          {{
            searchType === contactsSearchType.contacts
              ? "Contacts"
              : "Representation"
          }}
        </p>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
