<div
  class="dropzone"
  fsxUploadDropTarget
  (fileDropped)="onFileDropped($event)"
  fxFill
>
  <div class="dropzone-inner" fxFlex>
    <span class="caption"> Drop Documents Here </span>
  </div>
</div>
