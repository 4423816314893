import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[fsxUploadDropTarget]',
})
export class FileUploadDropTargetDirective {
  @HostBinding('class.fileover') fileOver: boolean = false;
  @Output() fileDropped = new EventEmitter<FileList>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = false;

    if ($event.dataTransfer) {
      let files = $event.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
