import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxDocumentValidationService,
  IDocumentValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-validation.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';

export const FsxValidateDocumentsOrchestrationService =
  new InjectionToken<IValidateDocumentsOrchestrationService>(
    'FsxValidateDocumentsOrchestrationService'
  );

export interface IValidateDocumentsOrchestrationService {
  validateDocuments$: Observable<CaseRequestViewModel>;
  validateDocuments(): void;
}

@Injectable()
export class ValidateDocumentsOrchestrationService
  implements IValidateDocumentsOrchestrationService
{
  private validateDocuments$$ = new Subject<void>();

  // DO NOT DELETE: may need this validation trigger later
  private documentsTabDeactivated$: Observable<void> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.DOCUMENTS &&
          current.name !== FILING_SUB_TABS.DOCUMENTS
        );
      }),
      map(() => {
        return;
      })
    );

  validateDocuments$: Observable<CaseRequestViewModel> = merge(
    // this.documentsTabDeactivated$, // DO NOT DELETE: may need this validation trigger later
    this.validateDocuments$$
    // TODO: Add anymore document validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(([_, combinedFilingData]: [void, CombinedFilingData]) => {
      const { caseRequest, filingProfile, modeSpec } = combinedFilingData;

      caseRequest.isDocumentsValid =
        this.documentValidationService.validateAllDocuments(
          caseRequest,
          modeSpec,
          filingProfile
        );

      // Trigger a filing-editor instance-wide update of the validated caseRequest object
      this.caseRequestDataService.setCaseRequestData(caseRequest);

      return caseRequest;
    })
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxDocumentValidationService)
    private readonly documentValidationService: IDocumentValidationService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService
  ) {}

  validateDocuments(): void {
    this.validateDocuments$$.next();
  }
}
