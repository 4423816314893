import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
  FilingProfile,
  CombinedFilingData,
  DocumentInfo,
  UploadFileParams,
  ICaseRequestUpdateService,
  IUploadFileService,
  FsxCaseRequestUpdateService,
  FsxUploadFileService,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { Subject, Observable, switchMap, from, mergeMap } from 'rxjs';
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from '../../filing-editor/services/filing-editor-events.service';

export const FsxUploadAdditionalFieldFilesOrchestrationService =
  new InjectionToken<IUploadAdditionalFieldFilesOrchestrationService>(
    'FsxUploadAdditionalFieldFilesOrchestrationService'
  );

export class DocumentInfoAndUploadedFile {
  uploadedFile: IUploadedFile;
  documentInfo: DocumentInfo;

  constructor(documentInfo: DocumentInfo, uploadedFile: IUploadedFile) {
    this.documentInfo = documentInfo;
    this.uploadedFile = uploadedFile;
  }
}

export interface UploadAdditionalFilesParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[];
  filingProfile: FilingProfile;
  combinedFilingData: CombinedFilingData;
}

export interface IUploadAdditionalFieldFilesOrchestrationService {
  uploadFilesStream$: Observable<CaseRequestViewModel>;
  uploadFiles(params: UploadAdditionalFilesParams): void;
  cancelUpload(requestDocument: RequestDocumentViewModel): void;
}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class UploadAdditionalFieldFilesOrchestrationService
  implements IUploadAdditionalFieldFilesOrchestrationService
{
  private uploadFilesParams$$ = new Subject<UploadAdditionalFilesParams>();

  private cancelUpload$$ = new Subject<RequestDocumentViewModel>();

  uploadFilesStream$: Observable<CaseRequestViewModel> =
    this.uploadFilesParams$$.pipe(
      mergeMap((uploadFilesParams: UploadAdditionalFilesParams) => {
        const { filingId, caseRequest, documentInfoAndUploadedFiles } =
          uploadFilesParams;
        const caseRequestBackup = { ...caseRequest };
        return from(documentInfoAndUploadedFiles).pipe(
          mergeMap(
            (documentInfoAndUploadedFile: DocumentInfoAndUploadedFile) => {
              const { uploadedFile, documentInfo } =
                documentInfoAndUploadedFile;
              const uploadDocumentParams: UploadFileParams = {
                filingId,
                documentId: documentInfo.id,
                uploadedFile,
              };
              this.filingEditorEventService.dispatchFileUploadStartedEvent({
                filingId,
                caseRequest,
              });
              return this.uploadFileService
                .uploadFile(uploadDocumentParams)
                .pipe(
                  switchMap(() => {
                    return this.caseRequestUpdateService.optimisticPutOrRestore(
                      filingId,
                      caseRequest,
                      caseRequestBackup
                    );
                  })
                );
            }
          )
        );
      })
    );

  constructor(
    @Inject(FsxUploadFileService)
    private readonly uploadFileService: IUploadFileService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService
  ) {}

  uploadFiles(params: UploadAdditionalFilesParams): void {
    this.uploadFilesParams$$.next(params);
  }

  cancelUpload(requestDocument: RequestDocumentViewModel): void {
    this.cancelUpload$$.next(requestDocument);
  }
}
