import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AddressFormService,
  AliasCategory,
  EmailFormService,
  ParticipantSpec,
  PersonFormService,
  PhoneFormService,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  AliasCommonCategory,
  AliasFieldDefinition,
  ContactAliasViewModel,
  ContactAliasFormGroup,
  FilingProfile,
  NewContactViewModel,
  RequestParticipantAliasViewModel,
} from '@fsx/fsx-shared';
import { FormArrayWithModel } from '@fsx/ui-components';

@Injectable({
  providedIn: 'root',
})
export class AliasFormService {
  constructor(
    private readonly personFormService: PersonFormService,
    private readonly addressFormService: AddressFormService,
    public readonly phoneFormService: PhoneFormService,
    private readonly emailFormService: EmailFormService
  ) {}

  public setAliasCategories(
    contact: NewContactViewModel | RequestParticipantViewModel,
    aliases: FormArrayWithModel<FormGroup<ContactAliasFormGroup>>
  ): void {
    aliases.controls.forEach((alias, index) => {
      const aliasCategory = alias.controls.category?.value;
      const dropdownOptions = alias.controls.category?.dropdownOptions;
      const selectedOption = dropdownOptions?.find(
        (option) => option.name === aliasCategory
      );

      if (
        contact?.aliases &&
        contact?.aliases[index] &&
        contact?.aliases[index].category &&
        selectedOption
      ) {
        contact.aliases[index].category = {
          name: selectedOption.name,
          caption: selectedOption.caption,
          commonCategory: selectedOption.category
            ?.commonCategory as AliasCommonCategory,
        };
      }
    });
  }

  public getAliasesFromFilingProfile(
    aliases: ContactAliasViewModel[] | undefined,
    participantSpec: ParticipantSpec,
    filingProfile: FilingProfile
  ): RequestParticipantAliasViewModel[] {
    const participantAliases: RequestParticipantAliasViewModel[] = [];
    const aliasList = participantSpec.alias?.allowedAliasCategoriesList;
    let selectedCategory: AliasCategory | undefined;

    aliases?.forEach((alias) => {
      if (aliasList && aliasList.list === 'AdditionalList') {
        const list = filingProfile?.additionalLists?.find(
          (list) => list.name === aliasList.additionalListName
        );
        const listElement = list?.items?.find(
          (item) => item.name === alias.category?.name
        );
        if (listElement && alias.category) {
          selectedCategory = alias.category;
        }
      } else {
        selectedCategory = filingProfile.aliasCategories?.find(
          (item) => item.commonCategory === alias.category.commonCategory
        );
      }

      const aliasProfile = filingProfile?.alias?.find((profile) => {
        return profile.aliasCategory?.name === selectedCategory?.name;
      });

      if (
        (!aliasProfile && !selectedCategory) ||
        (participantSpec.alias &&
          participantAliases.length > participantSpec.alias.maxAllowed)
      ) {
        return;
      }

      const newParticipantAlias: RequestParticipantAliasViewModel = {
        caption: alias.caption,
        category: {
          name: selectedCategory?.name ?? '',
          caption: selectedCategory?.caption ?? '',
          commonCategory:
            selectedCategory?.commonCategory ?? AliasCommonCategory.None,
        },
        contactType: alias.contactType,
        organization: {
          caption: alias.organization?.caption ?? '',
          title: alias.organization?.title ?? '',
        },
        person: this.personFormService.getPersonNameFromFilingProfile(
          alias.person ?? undefined,
          participantSpec
        ),
        addresses: this.addressFormService.getAddressFromFilingProfile(
          alias.addresses,
          aliasProfile?.address ?? undefined,
          filingProfile
        ),
        emails: this.emailFormService.getEmailsFromFilingProfile(
          alias.emails,
          aliasProfile?.email ?? undefined,
          filingProfile
        ),
        phones: this.phoneFormService.getPhonesFromFilingProfile(
          alias.phones,
          aliasProfile?.phone ?? undefined,
          filingProfile
        ),
        identifications: [],
      } as unknown as RequestParticipantAliasViewModel;

      participantAliases.push(newParticipantAlias);
    });

    return participantAliases;
  }
}
