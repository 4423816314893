import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { EnvConfig, ContactSummary } from '@fsx/fsx-shared';
import { ENV_CONFIG } from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IUserApiService to use at runtime.
 */
export const FsxUserApiService = new InjectionToken<IUserApiService>(
  'FsxUserApiService'
);

/**
 * A blueprint for an API service, which allows us to access server data for
 * the currently logged in user.
 */
export interface IUserApiService {
  /**
   * A method for accessing the currently logged in user on the sever.
   */
  getSelf(): Observable<ContactSummary>;
}

/**
 * A concrete implementation of an API service, which allows us to access server data
 * for the currently logged in user.
 */
@Injectable()
export class UserApiService implements IUserApiService {
  public constructor(
    @Inject(ENV_CONFIG) public envConfig: Observable<EnvConfig>,
    private readonly http: HttpClient
  ) {}

  /**
   * A method for accessing the currently logged in user on the sever.
   */
  getSelf(): Observable<ContactSummary> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.user.getSelf}`;
        return this.http.get<ContactSummary>(url);
      })
    );
  }
}
