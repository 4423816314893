export function hasValues(object: any): boolean {
  let hasValues = false;

  Object.values(object).forEach((value) => {
    if (!!value) {
      hasValues = true;
    }
  });

  return hasValues;
}
