export enum CaseAction {
  NEW_CASE,
  EXISTING_CASE,
  MULTIPLE_CASES,
}

export interface DataListBase {
  label: string;
  action?: unknown;
}

export interface Cases extends DataListBase {
  imgSrc: string;
  svgIcon: string;
  value?: CaseAction;
}
