import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  RequestDocumentViewModel,
  DocumentSpec,
  CaseRequestViewModel,
  RequestDocumentCaseViewModel,
  ParticipantFieldSpec,
  RequestDocumentParticipantViewModel,
  IValidatable,
  DocumentCommonCategory,
  FilingModeSpec,
  FilingProfile,
} from '../../../types';
import {
  FsxAdditionalFieldsValidationService,
  IAdditionalFieldsValidationService,
} from './additional-fields-validation.service';
import {
  FsxDocumentFilesValidationService,
  IDocumentFilesValidationService,
} from './document-files-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxDocumentValidationService =
  new InjectionToken<IDocumentValidationService>(
    'FsxDocumentValidationService'
  );

export interface IDocumentValidationService {
  validateAllDocuments(
    caseRequest: CaseRequestViewModel, // Needed to pass the documents that we want to validate
    modeSpec: FilingModeSpec, // Needed for the documentSpecs that we want to validate against
    filingProfile: FilingProfile // Only needed down stream by other validation services (Do they really need it?)
  ): boolean;

  validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class DocumentValidationService implements IDocumentValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxDocumentFilesValidationService)
    private readonly documentFilesValidationService: IDocumentFilesValidationService,
    @Inject(FsxAdditionalFieldsValidationService)
    private readonly additionalFieldsValidationService: IAdditionalFieldsValidationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  public validateAllDocuments(
    caseRequest: CaseRequestViewModel,
    modeSpec: FilingModeSpec,
    filingProfile: FilingProfile
  ): boolean {
    let result = true;

    // Check against profile rules first...
    modeSpec.leadDocument.forEach(
      (docSpec: DocumentSpec, docSpecIndex: number) => {
        const caseRequestDocuments: RequestDocumentViewModel[] =
          caseRequest.documents || [];
        const documentsForSpec: RequestDocumentViewModel[] =
          caseRequestDocuments.filter((doc: RequestDocumentViewModel) => {
            const documentCategoryName = doc.category?.name || '';
            return documentCategoryName === docSpec.documentCategory.name;
          });

        const countForSpec: number = documentsForSpec.length;

        const docSpecMinErrorCode: string = 'docSpecMin' + docSpecIndex;
        const documentOrDocuments: string =
          docSpec.file?.minRequired === 1 ? 'Document' : 'Documents';
        const docSpecFileMinRequired: number = docSpec.file?.minRequired || 0;
        if (countForSpec < docSpecFileMinRequired) {
          result = false;
          this.validationErrorsService.addValidationError({
            errorCode: docSpecMinErrorCode,
            errorMessage: `Must provide at least ${docSpec.file?.minRequired} ${documentOrDocuments} of type ${docSpec.documentCategory.caption}`,
            group: ValidationGroupConstants.documents,
          });
        } else {
          this.validationErrorsService.removeValidationError(
            docSpecMinErrorCode
          );
        }
      }
    );

    if (modeSpec?.leadDocument && caseRequest.documents) {
      if (
        !this.validateDocuments(
          modeSpec?.leadDocument,
          DocumentCommonCategory.LeadDocument,
          caseRequest.documents,
          caseRequest,
          filingProfile,
          caseRequest
        )
      ) {
        result = false;
      }
    }

    if (modeSpec?.supportingDocument && caseRequest.documents) {
      if (
        !this.validateDocuments(
          modeSpec?.supportingDocument,
          DocumentCommonCategory.SupportingDocument,
          caseRequest.documents,
          caseRequest,
          filingProfile,
          caseRequest
        )
      ) {
        result = false;
      }
    }

    return result;
  }

  private validateDocuments(
    documentsSpecs: DocumentSpec[],
    documentCategory: DocumentCommonCategory,
    documents: RequestDocumentViewModel[],
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    let result = true;

    for (const spec of documentsSpecs) {
      if (documentCategory === spec.documentCategory.commonCategory) {
        for (const document of documents) {
          if (document.category?.name === spec.documentCategory.name) {
            if (
              !this.validateDocument(
                document,
                spec,
                scope,
                filingProfile,
                caseRequest
              )
            ) {
              result = false;
            }
          }
        }
      }
    }

    return result;
  }

  public validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    document.isValid = true;

    // if it's newly added, don't validate, so as not to overwhelm the user
    if (document.isNew) {
      return true;
    }

    if (!document.category || !document.category.name) {
      return this.validationHelperService.markItemAsInvalid(document, scope);
    }

    if (
      !this.textFieldValidationService.validateTextField(
        document,
        filingProfile,
        spec.fileName,
        document.fileName
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(document, scope);
    }

    if (
      !this.textFieldValidationService.validateTextField(
        document,
        filingProfile,
        spec.title,
        document.title
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(document, scope);
    }

    // Temporarily disabled document file validation as does not play well
    // with the polling (creating false positives). Can re-enable when polling
    // and validation generally have more tests.
    const validateDocumentInfos = false;
    if (validateDocumentInfos) {
      if (document.id) {
        if (
          !this.documentFilesValidationService.validateDocumentFiles(
            [document.id],
            spec.file,
            scope,
            document
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(
            document,
            scope
          );
        }
      } else {
        return this.validationHelperService.markItemAsInvalid(document, scope);
      }
    }

    if (document.cases) {
      for (let documentCase of document.cases) {
        if (
          !this.validateDocumentParticipant(
            documentCase,
            spec.filedBy,
            documentCase.filedBy,
            document,
            filingProfile,
            caseRequest
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(
            document,
            scope
          );
        }

        if (
          !this.validateDocumentParticipant(
            documentCase,
            spec.asTo,
            documentCase.filedAsTo,
            scope,
            filingProfile,
            caseRequest
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(
            document,
            scope
          );
        }

        if (
          !this.additionalFieldsValidationService.validateAdditionalFields(
            documentCase.additionalFieldValues,
            spec.additionalFields,
            documentCase.caseId,
            caseRequest,
            filingProfile,
            caseRequest
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(
            document,
            scope
          );
        }
      }
    }

    return true;
  }

  private validateDocumentParticipant(
    documentCase: RequestDocumentCaseViewModel,
    spec: ParticipantFieldSpec | null | undefined,
    documentParticipants:
      | RequestDocumentParticipantViewModel[]
      | null
      | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec || !spec.participantFieldDefinition) {
      return true;
    }

    if (!documentParticipants) {
      documentParticipants = [];
    }

    if (
      documentParticipants.length < spec.participantFieldDefinition.minRequired
    ) {
      return this.validationHelperService.markItemAsInvalid(
        documentCase,
        scope
      );
    }

    if (
      documentParticipants.length > spec.participantFieldDefinition.maxAllowed
    ) {
      return this.validationHelperService.markItemAsInvalid(
        documentCase,
        scope
      );
    }

    // TODO - do we need to validate the participants, as per the server validation?

    if (
      !this.additionalFieldsValidationService.validateAdditionalFields(
        documentCase.additionalFieldValues,
        spec.participantFieldDefinition.additionalFields,
        documentCase.caseId,
        scope,
        filingProfile,
        caseRequest
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(
        documentCase,
        scope
      );
    }

    return true;
  }
}
