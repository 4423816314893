import { Injectable } from '@angular/core';
import {
  ContactViewModel,
  ParticipantCategory,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface ICreateRepresentationFromContactParams {
  contact: ContactViewModel;
  participantCategory: ParticipantCategory;
}

@Injectable({
  providedIn: 'root',
})
export class CreateRepresentationService {
  public createRepresentationFromContact(
    params: ICreateRepresentationFromContactParams
  ): Observable<RequestParticipantRepresentationViewModel> {
    const representation: RequestParticipantRepresentationViewModel = {
      caption: params.contact.caption,
      participantName: uuidv4(),
      efmKey: null,
      participantCategory: params.participantCategory,
      additionalFieldValues: null,
    } as RequestParticipantRepresentationViewModel;

    return of(representation);
  }
}
