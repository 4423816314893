import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ContactViewModel,
  FilingProfile,
  ParticipantSpec,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxAddRepresentationOrchestrationService =
  new InjectionToken<IAddRepresentationOrchestrationService>(
    'FsxAddRepresentationOrchestrationService'
  );

export interface IAddRepresentationParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  partyToAddTo: CasePartyViewModel;
  contact: ContactViewModel;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
}

export interface IAddRepresentationOrchestrationService {
  addRepresentationToCaseRequest$: Observable<CaseRequestViewModel>;
  addRepresentation(params: IAddRepresentationParams): void;
}

@Injectable()
export class AddRepresentationOrchestrationService
  implements IAddRepresentationOrchestrationService
{
  private addRepresentation$$ = new Subject<IAddRepresentationParams>();

  addRepresentationToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addRepresentation$$.pipe(
      switchMap((params: IAddRepresentationParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.caseRequestBuilderService
          .createRepresentationAndParticipantThenSetInCaseRequest({ ...params })
          .pipe(
            switchMap(() => {
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  params.filingId,
                  params.caseRequest,
                  caseRequestBackup
                )
                .pipe(
                  tap(() => {
                    this.validatePartiesOrchestrationService.validateParties();
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addRepresentation(params: IAddRepresentationParams): void {
    this.addRepresentation$$.next(params);
  }
}
