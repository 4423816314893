import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TransactionTab } from '@fsx/fsx-shared';
import { ExistingFilingPanelComponent } from '../../transactions/existing-filing-panel/existing-filing-panel.component';
import { NewFilingPanelComponent } from '../../transactions/new-filing-panel/new-filing-panel.component';

@Component({
  selector: 'fsx-filing-tabs',
  templateUrl: './filing-tabs.component.html',
  styleUrls: ['./filing-tabs.component.scss'],
})
export class FilingTabsComponent {
  @Input() filingTabs!: TransactionTab[];
  @Input() selectedTab!: string | null;
  @Output() filingTabClickedEvent = new EventEmitter<string>();
  @Output() closeFilingClickedEvent: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private readonly dialog: MatDialog) {}

  public onTabClicked(tab: TransactionTab) {
    this.filingTabClickedEvent.emit(tab.filingId);
  }

  public onCloseButtonClicked(event: Event, tab: TransactionTab) {
    event.stopPropagation();
    this.closeFilingClickedEvent.emit(tab.filingId);
  }

  onNewCaseButtonClicked() {
    this.dialog.open(NewFilingPanelComponent, {
      data: {
        reference: this.dialog,
      },
      width: '500px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  public onExistingCaseButtonClicked(): void {
    this.dialog.open(ExistingFilingPanelComponent, {
      data: {
        reference: this.dialog,
      },
      width: '500px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }
}
