import { PersonalNameViewModel } from '@fsx/fsx-shared';

export function getPersonFullName(
  personName: PersonalNameViewModel | undefined
): string {
  const first = personName?.givenName ?? '';
  const middle = personName?.middleName ?? '';
  const last = personName?.surName ?? '';
  return [first, middle, last].filter((name) => name.length).join(' ');
}

export function formatContactName(
  name?: PersonalNameViewModel,
  options: { includeSuffix: boolean; respectFullNameValueIfSet: boolean } = {
    includeSuffix: true,
    respectFullNameValueIfSet: true,
  }
): string {
  if (
    !name ||
    (name.givenName === undefined &&
      name.middleName === undefined &&
      name.surName === undefined &&
      name.fullName === undefined)
  ) {
    return '';
  }
  let formattedName = '';
  if (name.fullName && options.respectFullNameValueIfSet) {
    formattedName = name.fullName;
  } else if (name.givenName && name.middleName && !name.surName) {
    formattedName = `${name.givenName} ${name.middleName}`;
  } else if (name.givenName && !name.middleName && !name.surName) {
    formattedName = name.givenName;
  } else if (
    name.givenName &&
    name.surName &&
    name.suffix &&
    options.includeSuffix
  ) {
    formattedName = `${name?.surName} ${name.suffix} ${name?.givenName} ${name.middleName}`;
  } else if (name.givenName && name.surName && name.middleName) {
    formattedName = `${name?.surName} ${name?.givenName} ${name.middleName}`;
  } else if (name.givenName && name.surName) {
    formattedName = `${name?.surName} ${name?.givenName}`;
  } else if (!name.givenName) {
    formattedName = name?.surName ? `${name?.surName}` : '';
  }

  return formattedName;
}
