import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseFileUploadComponent,
  IUploadedFile,
} from './base-file-upload.component';

@Component({
  selector: 'fsx-file-upload-large',
  templateUrl: './file-upload-large.component.html',
  styleUrls: ['./file-upload-large.component.scss'],
})
export class FileUploadLargeComponent extends BaseFileUploadComponent {
  @ViewChild('fileDropRef2') fileDropEl!: ElementRef;
  @Output() filesUploadedLargeEvent: EventEmitter<IUploadedFile[]> =
    new EventEmitter<IUploadedFile[]>();

  override prepareFilesList(files: FileList | null): void {
    super.prepareFilesList(files);
    this.fileDropEl.nativeElement.value = '';
    this.filesUploadedLargeEvent.emit(this.files);
  }
}
