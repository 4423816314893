import { Component, Inject } from '@angular/core';
import {
  FsxFilingChecklistService,
  IFilingChecklistService,
} from '../filing-checklist/filing-checklist.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'fsx-filing-checklist-icon',
  templateUrl: './filing-checklist-icon.component.html',
  styleUrls: ['./filing-checklist-icon.component.scss'],
})
export class FilingChecklistIconComponent {
  isValid$: Observable<boolean> = this.filingChecklistService.isValidCrossTab$;

  constructor(
    @Inject(FsxFilingChecklistService)
    private readonly filingChecklistService: IFilingChecklistService
  ) {}

  onChecklistIconClicked() {
    this.filingChecklistService.toggleVisibilty();
  }
}
