import { Injectable } from '@angular/core';
import {
  FilingProfile,
  PartiesFormService,
  ContactViewModel,
  RequestParticipantViewModel,
  ParticipantSpec,
} from '@fsx/fsx-shared';
import { filter, map, Observable } from 'rxjs';

export interface ICreateParticipantFromContactParams {
  contact: ContactViewModel;
  uniqueIdentifier: string;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
}

@Injectable({
  providedIn: 'root',
})
export class CreateParticipantService {
  constructor(private readonly partiesFormService: PartiesFormService) {}

  public createParticipantFromContact(
    params: ICreateParticipantFromContactParams
  ): Observable<RequestParticipantViewModel> {
    return this.partiesFormService
      .transformContactToParticipant(
        params.contact,
        params.participantSpec,
        params.filingProfile
      )
      .pipe(
        filter(
          (participant: RequestParticipantViewModel | undefined) =>
            !!participant
        ),
        map(
          (participant: RequestParticipantViewModel | undefined) =>
            participant as RequestParticipantViewModel
        ),
        map((participant: RequestParticipantViewModel) => {
          participant.name = params.uniqueIdentifier;
          return participant;
        })
      );
  }
}
