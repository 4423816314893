import { Injectable, InjectionToken } from '@angular/core';
import { Filing, FilingMode } from '@fsx/fsx-shared';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
} from 'rxjs';

export const FsxFilingDataService = new InjectionToken<IFilingDataService>(
  'FsxFilingDataService'
);

/**
 * A blueprint for a state service, which stores the Filing object as a single
 * source of truth that we can safely expose derivatons from.
 */
export interface IFilingDataService {
  /**
   * The filing exposed as an OBservable
   */
  filing$: Observable<Filing>;

  /**
   * The filingMode derived from the filing's mode property.
   */
  filingMode$: Observable<FilingMode>;

  /**
   * A method to allow the setting of the filing
   */
  setFilingData(filing: Filing): void;
}

/**
 * A concrete implementation of a state service, which stores the Filing object
 * as a single source of truth that we can safely expose derivatons from.
 */
@Injectable()
export class FilingDataService implements IFilingDataService {
  /**
   * The filing stored in a BehaviorSubject so that it can be easliy exposed
   * as an Observable
   */
  private filing$$ = new BehaviorSubject<Filing | null>(null);
  filing$: Observable<Filing> = this.filing$$.asObservable().pipe(
    filter((filing: Filing | null) => !!filing),
    map((filing: Filing | null) => filing as Filing),
    shareReplay(1)
  );

  /**
   * The filingMode derived from the filing's mode property. Restricted to emit
   * through only when the current value differs from the previously emitted value
   * (if one exists).
   */
  filingMode$: Observable<FilingMode> = this.filing$.pipe(
    map((filing: Filing) => filing.mode),
    distinctUntilChanged((filingMode1: FilingMode, filingMode2: FilingMode) => {
      return filingMode1 === filingMode2;
    })
  );

  /**
   * A method to allow the setting of the filing
   */
  setFilingData(filing: Filing): void {
    const newFilingObj = JSON.parse(JSON.stringify(filing));
    this.filing$$.next(newFilingObj);
  }
}
