import { Injectable, InjectionToken } from '@angular/core';
import { ContactSummaryViewModel } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationIndicatorService to use at runtime.
 */
export const FsxSelectedContactsService =
  new InjectionToken<ISelectedContactsService>('FsxSelectedContactsService');

/**
 * A blueprint for a state service, which stores the selected contacts
 * on the Contacts List modal.
 */
export interface ISelectedContactsService {
  /**
   * The selected contacts exposed as an Observable.
   */
  selectedContacts$: Observable<ContactSummaryViewModel[]>;

  /**
   * A method to allow contacts to be added to the list of
   * selected contacts.
   *
   * @param contact The contact to add to the list of selected contacts.
   */
  addSelectedContact(contact: ContactSummaryViewModel): void;

  /**
   * A method to allow removal of a contact from the list of selected contacts.
   *
   * @param contact The contact to remove from the list of selected contacts.
   */
  removeSelectedContact(contact: ContactSummaryViewModel): void;

  /**
   * A method to allow for the removal of all selected contacts.
   */
  clearSelectedContacts(): void;
}

/**
 * A concrete implementation of a state service, which stores the selected contacts
 * on the Contacts List modal.
 */
@Injectable()
export class SelectedContactsService implements ISelectedContactsService {
  /**
   * The selected contacts stored in a BehaviorSubject so that they can be easily
   * exposed as an Observable.
   */
  private selectedContacts$$ = new BehaviorSubject<ContactSummaryViewModel[]>(
    []
  );

  /**
   * The selected contacts exposed as an Observable.
   */
  selectedContacts$: Observable<ContactSummaryViewModel[]> =
    this.selectedContacts$$.asObservable();

  /**
   * A method to allow contacts to be added to the list of
   * selected contacts.
   *
   * @param contact The contact to add to the list of selected contacts.
   */
  addSelectedContact(contact: ContactSummaryViewModel): void {
    this.selectedContacts$$.next([...this.selectedContacts$$.value, contact]);
  }

  /**
   * A method to allow removal of a contact from the list of selected contacts.
   *
   * @param contact The contact to remove from the list of selected contacts.
   */
  removeSelectedContact(contact: ContactSummaryViewModel): void {
    const filteredSelectedContacts: ContactSummaryViewModel[] =
      this.selectedContacts$$.value.filter((c: ContactSummaryViewModel) => {
        return contact.id !== c.id;
      });
    this.selectedContacts$$.next(filteredSelectedContacts);
  }

  /**
   * A method to allow for the removal of all selected contacts.
   */
  clearSelectedContacts(): void {
    this.selectedContacts$$.next([]);
  }
}
