import { FieldCategory } from '@fsx/fsx-shared';

export function sanitizeValue(value: string, type: string): string {
  switch (type) {
    case FieldCategory.Number:
      return value.replace(/\s/g, '');
    case FieldCategory.Currency:
      return value.replace(/\s/g, '');
    case FieldCategory.Text:
      return value.trim();
    default:
      return value;
  }
}
