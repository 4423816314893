import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  IValidatable,
  EmailAddressSpec,
  EmailAddressViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxEmailAddressValidationService =
  new InjectionToken<IEmailAddressValidationService>(
    'FsxEmailAddressValidationService'
  );

export interface IEmailAddressValidationService {
  validateEmailAddresses(
    emailAddresses: EmailAddressViewModel[],
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class EmailAddressValidationService
  implements IEmailAddressValidationService
{
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService
  ) {}

  public validateEmailAddresses(
    emailAddresses: EmailAddressViewModel[],
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!emailAddresses) {
      emailAddresses = [];
    }

    if (emailAddresses.length < spec.minRequired) {
      scope.isValid = false;
      return false;
    }

    if (emailAddresses.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (let index = 0; index < emailAddresses.length; index++) {
      const emailAddress = emailAddresses[index];

      if (
        !this.validateEmailAddress(emailAddress, spec, scope, filingProfile)
      ) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    return true;
  }

  public validateEmailAddress(
    emailAddress: EmailAddressViewModel,
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    emailAddress.isValid = true;

    return this.textFieldValidationService.validateTextField(
      scope,
      filingProfile,
      spec.address,
      emailAddress.address
    );
  }
}
