import { Injectable, InjectionToken } from '@angular/core';
import {
  AccessCommonCategoryDomainCategoryValue,
  AddressCommonCategoryDomainCategoryValue,
  AliasCommonCategoryDomainCategoryValue,
  EmailCommonCategoryDomainCategoryValue,
  FilingProfile,
  IdentificationCommonCategoryDomainCategoryValue,
  PhoneCommonCategoryDomainCategoryValue,
  ProfileListReference,
  ProfileLists,
} from '../../../types';

export const FsxValidationHelperFpService =
  new InjectionToken<IValidationHelperFpService>(
    'FsxValidationHelperFpService'
  );

type DomainCategoryValueList =
  | AddressCommonCategoryDomainCategoryValue[]
  | PhoneCommonCategoryDomainCategoryValue[]
  | EmailCommonCategoryDomainCategoryValue[]
  | AliasCommonCategoryDomainCategoryValue[]
  | IdentificationCommonCategoryDomainCategoryValue[]
  | AccessCommonCategoryDomainCategoryValue[];

export interface IValidationHelperFpService {
  getSelectedEfmKey(
    listReference: ProfileListReference,
    caseRequestValue: string | null | undefined,
    filingProfile: FilingProfile
  ): string | undefined;
}

@Injectable()
export class ValidationHelperFpService implements IValidationHelperFpService {
  public getSelectedEfmKey(
    listReference: ProfileListReference,
    caseRequestValue: string | null | undefined,
    filingProfile: FilingProfile
  ): string | undefined {
    if (!caseRequestValue) {
      return undefined;
    }

    switch (listReference.list) {
      case ProfileLists.AdditionalList:
        return this.FindEfmKeyInAdditionalList(
          listReference.additionalListName,
          caseRequestValue,
          filingProfile
        );
      case ProfileLists.CaseAdditionalList:
        return caseRequestValue;
      default:
        return this.findEfmKeyInNamedList(
          listReference.list,
          caseRequestValue,
          filingProfile
        );
    }
  }

  private FindEfmKeyInAdditionalList(
    listName: string,
    name: string | null | undefined,
    filingProfile: FilingProfile
  ): string | undefined {
    if (!name) {
      return undefined;
    }

    const list = filingProfile.additionalLists.find((l) => l.name === listName);

    return list?.items.find((i) => i.name === name)?.efmKey;
  }

  private findEfmKeyInNamedList(
    listType: ProfileLists,
    name: string | null | undefined,
    filingProfile: FilingProfile
  ): string | undefined {
    if (!name) {
      return undefined;
    }

    let list: DomainCategoryValueList;
    switch (listType) {
      case ProfileLists.AddressCategories:
        list = filingProfile.addressCategories;
        break;
      case ProfileLists.AliasCategories:
        list = filingProfile.aliasCategories;
        break;
      case ProfileLists.EmailCategories:
        list = filingProfile.emailCategories;
        break;
      case ProfileLists.IdentificationCategories:
        list = filingProfile.identificationCategories;
        break;
      case ProfileLists.PhoneCategories:
        list = filingProfile.phoneCategories;
        break;
    }

    return undefined;
  }
}
