import { NgModule } from '@angular/core';
import { NewFilingPanelComponent } from './new-filing-panel/new-filing-panel.component';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { NewFilingComponent } from './new-filing/new-filing.component';
import { OptionSelectedCardComponent } from './option-selected-card/option-selected-card.component';
import { SharedModule } from '@fsx/fsx-shared';
import { ExistingFilingPanelComponent } from './existing-filing-panel/existing-filing-panel.component';
import { ExistingFilingComponent } from './existing-filing/existing-filing.component';
import { TransactionsComponent } from './transactions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityMenuBarComponent } from './activity-menu-bar/activity-menu-bar.component';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { ContactsModule } from '../contacts/contacts.module';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    TransactionsComponent,
    TransactionsListComponent,
    NewFilingPanelComponent,
    NewFilingComponent,
    ExistingFilingPanelComponent,
    ExistingFilingComponent,
    OptionSelectedCardComponent,
    ActivityMenuBarComponent,
  ],
  imports: [
    CommonModule,
    ContactsModule,
    SharedModule,
    FsxUiComponentsModule,
    MatTooltipModule,
    InfiniteScrollModule,
  ],
})
export class TransactionsModule {}
