<div class="home-container">
  <fsx-contacts
    [actionButtons]="contactActionButtons"
    [searchType]="contactsSearchType.contacts"
  ></fsx-contacts>
  <br />
  <br />
  <fsx-contacts-search
    [searchType]="contactsSearchType.contacts"
  ></fsx-contacts-search>
  <br />
  <br />
  <fsx-contacts [searchType]="contactsSearchType.attorneys"></fsx-contacts>
  <br />
  <br />
  <fsx-contacts-search
    [searchType]="contactsSearchType.attorneys"
  ></fsx-contacts-search>
</div>
