import { SelectionFieldDefinition } from '@fsx/ui-components';
import { FilingProfile, IValidatable } from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const FsxSelectionFieldValidationService =
  new InjectionToken<ISelectionFieldValidationService>(
    'FsxSelectionFieldValidationService'
  );

export interface ISelectionFieldValidationService {
  validateSelectionFields(
    fieldValues: string[] | null | undefined,
    spec: SelectionFieldDefinition | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;

  validateSelectionField(
    fieldValue: string | null | undefined,
    spec: SelectionFieldDefinition | null | undefined,
    scope: IValidatable | null | undefined,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class SelectionFieldValidationService
  implements ISelectionFieldValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService
  ) {}

  public validateSelectionFields(
    fieldValues: string[] | null | undefined,
    spec: SelectionFieldDefinition | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!fieldValues) {
      fieldValues = [];
    }

    if (fieldValues.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (fieldValues.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const fieldValue of fieldValues) {
      if (
        !this.validationHelperService.validateListReference(
          spec.listReference,
          fieldValue,
          scope,
          filingProfile
        )
      ) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    return true;
  }

  public validateSelectionField(
    fieldValue: string | null | undefined,
    spec: SelectionFieldDefinition | null | undefined,
    scope: IValidatable | null | undefined,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (
      spec.minRequired === 1 &&
      spec.maxAllowed === 1 &&
      scope &&
      !fieldValue
    ) {
      scope.isValid = false;
      return false;
    }

    return this.validationHelperService.validateListReference(
      spec.listReference,
      fieldValue,
      scope,
      filingProfile
    );
  }
}
