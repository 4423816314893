import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  RequestParticipantViewModel,
  ParticipantSpec,
  CaseRequestViewModel,
  ContactType,
  CasePartyViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxAddressValidationService,
  IAddressValidationService,
} from './address-validation.service';
import {
  FsxAliasValidationService,
  IAliasValidationService,
} from './alias-validation.service';
import {
  FsxEmailAddressValidationService,
  IEmailAddressValidationService,
} from './email-address-validation.service';
import {
  FsxIdentificationValidationService,
  IIdentificationValidationService,
} from './identification-validation.service';
import {
  FsxOrganizationValidationService,
  IOrganizationValidationService,
} from './organization-validation.service';
import {
  FsxPersonNameValidationService,
  IPersonNameValidationService,
} from './person-name-validation.service';
import {
  FsxPhoneValidationService,
  IPhoneValidationService,
} from './phone-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxSingleParticipantValidationService =
  new InjectionToken<ISingleParticipantValidationService>(
    'FsxSingleParticipantValidationService'
  );

export interface ISingleParticipantValidationService {
  validateSingleParticipant(
    participant: RequestParticipantViewModel,
    spec: ParticipantSpec | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class SingleParticipantValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxAddressValidationService)
    private readonly addressValidationService: IAddressValidationService,
    @Inject(FsxEmailAddressValidationService)
    private readonly emailAddressValidationService: IEmailAddressValidationService,
    @Inject(FsxIdentificationValidationService)
    private readonly identificationValidationService: IIdentificationValidationService,
    @Inject(FsxPhoneValidationService)
    private readonly phoneValidationService: IPhoneValidationService,
    @Inject(FsxOrganizationValidationService)
    private readonly organizationValidationService: IOrganizationValidationService,
    @Inject(FsxPersonNameValidationService)
    private readonly personNameValidationService: IPersonNameValidationService,
    @Inject(FsxAliasValidationService)
    private readonly aliasValidationService: IAliasValidationService
  ) {}

  public validateSingleParticipant(
    participant: RequestParticipantViewModel,
    spec: ParticipantSpec | undefined,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!participant) {
      throw new Error('No participant found to validate');
    }

    if (!spec.allowPerson && participant.contactType === ContactType.Person) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !spec.allowOrganization &&
      participant.contactType === ContactType.Organization
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !this.textFieldValidationService.validateTextField(
        participant,
        filingProfile,
        spec.parentOrganizationCaption,
        participant.parentOrganizationCaption
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !this.addressValidationService.validateAddresses(
        participant.addresses,
        spec.address,
        scope,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !this.emailAddressValidationService.validateEmailAddresses(
        participant.emails,
        spec.email,
        scope,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !this.identificationValidationService.validateIdentifications(
        participant.identifications,
        spec.identification,
        scope,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      !this.phoneValidationService.validatePhones(
        participant.phones,
        spec.phone,
        scope,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(participant, scope);
    }

    if (
      spec.allowOrganization &&
      participant.contactType === ContactType.Organization
    ) {
      if (
        !this.organizationValidationService.validateOrganization(
          participant.organization,
          spec.organization,
          scope,
          filingProfile
        )
      ) {
        return this.validationHelperService.markItemAsInvalid(
          participant,
          scope
        );
      }
    }

    if (spec.allowPerson && participant.contactType === ContactType.Person) {
      if (
        !this.personNameValidationService.validatePersonName(
          participant.person?.personalName,
          spec.person?.personalName,
          scope,
          filingProfile
        )
      ) {
        return this.validationHelperService.markItemAsInvalid(
          participant,
          scope
        );
      }
    }

    const participantNameLower: string = participant.name.toLocaleLowerCase();
    const caseParties: CasePartyViewModel[] | undefined =
      caseRequest.parties?.filter(
        (p) => p.participantName.toLocaleLowerCase() === participantNameLower
      );

    if (caseParties) {
      for (let i = 0; i < caseParties.length; i++) {
        const party: CasePartyViewModel = caseParties[i];
        if (
          !this.aliasValidationService.validateAliases(
            participant.aliases,
            party.caseId,
            spec.alias,
            scope,
            filingProfile
          )
        ) {
          return this.validationHelperService.markItemAsInvalid(
            participant,
            scope
          );
        }
      }
    }

    return true;
  }
}
