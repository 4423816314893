import { Injectable } from '@angular/core';
import {
  hasCharset,
  hasMaxLength,
  hasMaxValue,
  hasMinLength,
  hasMinRequired,
  hasMinValue,
  hasRequired,
} from '../helpers';
import { FormControlWithModel, FormControlWithoutModel } from '../models';
import { FieldDefinition, ReferenceResolver } from '../types';

import {
  maxValueValidator,
  minValueValidator,
  charsetValidator,
  minLengthValidator,
  maxLengthValidator,
  requiredValidator,
  minRequiredValidator,
} from '../validators';

@Injectable({
  providedIn: 'root',
})
export class FSXFormControlService {
  public resolver: ReferenceResolver | undefined;
  public forceMultiLine = false;
  public createFormControl<T extends FieldDefinition>(
    fieldDefinition: T,
    fieldType: string,
    initialValue?: string | boolean | number | Date | null,
    resolver?: ReferenceResolver,
    forceMultiLine?: boolean
  ): FormControlWithModel<T> {
    const formControlWithModel = new FormControlWithModel(
      fieldType,
      initialValue,
      fieldDefinition
    );
    this.resolver = resolver;
    this.forceMultiLine = forceMultiLine ?? false;
    this.setFormControlValidators<T>(formControlWithModel);
    return formControlWithModel;
  }

  public createFormControlWithoutProfile(
    fieldType: string,
    initialValue?: string | null
  ): FormControlWithoutModel {
    const formControlWithModel = new FormControlWithoutModel(
      fieldType,
      initialValue
    );
    return formControlWithModel;
  }

  private setFormControlValidators<T extends FieldDefinition>(
    formControlWithModel: FormControlWithModel<T>
  ): void {
    if (!formControlWithModel.fieldDefinition) {
      return;
    }

    const fieldDefinition = formControlWithModel.fieldDefinition;
    if (hasMaxValue(fieldDefinition) && fieldDefinition.maxValue) {
      formControlWithModel.addValidators(
        maxValueValidator(
          fieldDefinition.maxValue,
          fieldDefinition.allowedDecimals ?? fieldDefinition.allowDecimals
        )
      );
    }

    if (
      hasMinValue(fieldDefinition) &&
      fieldDefinition.minValue !== undefined &&
      fieldDefinition.required
    ) {
      formControlWithModel.addValidators(
        minValueValidator(
          fieldDefinition.minValue,
          fieldDefinition.allowedDecimals ?? fieldDefinition.allowDecimals
        )
      );
    }

    if (hasCharset(fieldDefinition) && this.resolver) {
      formControlWithModel.addValidators(
        charsetValidator(
          this.resolver,
          fieldDefinition.characterProfileName,
          this.forceMultiLine
        )
      );
    }

    if (
      hasMinLength(fieldDefinition) &&
      fieldDefinition.minLength &&
      fieldDefinition.required
    ) {
      formControlWithModel.addValidators(
        minLengthValidator(fieldDefinition.minLength)
      );
    }

    if (hasMaxLength(fieldDefinition) && fieldDefinition.maxLength) {
      formControlWithModel.addValidators(
        maxLengthValidator(fieldDefinition.maxLength)
      );
    }

    if (hasMinRequired(fieldDefinition) && fieldDefinition.minRequired) {
      formControlWithModel.addValidators(
        minRequiredValidator(fieldDefinition.minRequired)
      );
    }

    if (hasRequired(fieldDefinition) && fieldDefinition.required) {
      formControlWithModel.addValidators(requiredValidator());
    }
  }
}
