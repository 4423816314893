import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import {
  ParticipantCategory,
  ParticipantFormMode,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { FsxPanelService, IPanelService } from './panel.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IOpenParticipantFormOrchestrationService to use at runtime.
 */
export const FsxOpenParticipantFormOrchestrationService =
  new InjectionToken<IOpenParticipantFormOrchestrationService>(
    'FsxOpenParticipantFormOrchestrationService'
  );

/**
 * The parameters to pass to the orchestration service so
 * that it can do its work.
 */
export interface OpenParticipantFormOrchestrationParams {
  formMode: ParticipantFormMode;
  participantCategory: ParticipantCategory;
  isRepresentation: boolean;
  participant: RequestParticipantViewModel;
}

/**
 * A blueprint for an orchestration service, which handles the opening of the participant form.
 */
export interface IOpenParticipantFormOrchestrationService {
  /**
   * The orchestration steps needed to open the contacts form.
   */
  openParticipantFormOrchestration$: Observable<void>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openParticipantForm(params: OpenParticipantFormOrchestrationParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the opening of
 * the participant form.
 */
@Injectable()
export class OpenParticipantFormOrchestrationService
  implements IOpenParticipantFormOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private openParticipantFormAction$ =
    new Subject<OpenParticipantFormOrchestrationParams>();

  /**
   * The orchestration steps needed to open the contacts form.
   */
  openParticipantFormOrchestration$: Observable<void> =
    this.openParticipantFormAction$.pipe(
      map((params: OpenParticipantFormOrchestrationParams) => {
        this.panelService.openParticipantFormPanel({
          participantFormConfig: params,
          injector: this.injector,
        });
        return;
      })
    );

  /**
   *
   * @param panelService The utility service for opening panels.
   * @param injector The injector to pass on to the mat dialog, used to ensure the dialog uses the same providers.
   */
  public constructor(
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    private readonly injector: Injector
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openParticipantForm(params: OpenParticipantFormOrchestrationParams): void {
    this.openParticipantFormAction$.next(params);
  }
}
