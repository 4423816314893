import { Component, Input, OnChanges } from '@angular/core';
import { ContactsSearchTypeEnum } from '../../contacts.model';

/**
 * The input configuration object properties, which the panel header
 * componnet uses to create the view model for display in the template.
 */
export interface ContactsListPanelHeaderConfig {
  searchType: ContactsSearchTypeEnum;
}

/**
 * The view model object properties, which are derived from the input
 * configuration object properties.
 */
export interface ContactsListPanelHeaderViewModel {
  title: string;
}

@Component({
  selector: 'fsx-contacts-list-panel-header',
  templateUrl: './contacts-list-panel-header.component.html',
  styleUrls: ['./contacts-list-panel-header.component.scss'],
})
export class ContactsListPanelHeaderComponent implements OnChanges {
  /**
   * The input configuration object, which we can create the view model from.
   */
  @Input() config!: ContactsListPanelHeaderConfig;

  /**
   * The view model object, which we derive from the input configuration object.
   */
  vm!: ContactsListPanelHeaderViewModel;

  ngOnChanges(): void {
    this.createViewModelFromConfig();
  }

  private createViewModelFromConfig(): void {
    this.vm = {
      title:
        this.config.searchType === ContactsSearchTypeEnum.contacts
          ? 'Contact List'
          : 'Representation',
    };
  }
}
