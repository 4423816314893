import { Injectable, Inject } from '@angular/core';
import { EMPTY, Subject, catchError, switchMap } from 'rxjs';
import {
  Filing,
  FsxFilingApiService,
  IFilingApiService,
} from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class BookmarkTransactionOrchestrationService {
  private bookmarkTransactionParams$$ = new Subject<Filing>();

  public bookmarkTransaction$ = this.bookmarkTransactionParams$$.pipe(
    switchMap((filing: Filing) => {
      const bookmarkOrUnbookmark$ = filing.bookmarked
        ? this.filingApiService.unbookmark(filing)
        : this.filingApiService.bookmark(filing);

      return bookmarkOrUnbookmark$.pipe(
        catchError((err) => {
          console.error(
            `Error attempting to bookmark/unbookmark transaction ${filing.id}`,
            err
          );
          return EMPTY;
        })
      );
    })
  );

  public constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  public bookmarkTransaction(filing: Filing) {
    this.bookmarkTransactionParams$$.next(filing);
  }
}
