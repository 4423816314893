import { Component, Input } from '@angular/core';
import { FsxBaseComponent } from '../../base/base.component';
import { SidePanelHeaderConfig } from '../../../types/form-control.types';

@Component({
  selector: 'fsx-side-panel-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class FsxSidePanelHeaderComponent extends FsxBaseComponent {
  @Input() headerConfig!: SidePanelHeaderConfig;
}
