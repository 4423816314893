import { Pipe, PipeTransform } from '@angular/core';
import { Filing } from '@fsx/fsx-shared';

export enum FilingCaptionTitleEnum {
  Caption,
  Title,
  TID,
}
@Pipe({
  name: 'filingCaptionTitle',
})
export class FilingCaptionTitlePipe implements PipeTransform {
  transform(filing: Filing, valueType: FilingCaptionTitleEnum): string {
    const courtCase = filing.courtCases[0];
    if (!!courtCase) {
      if (valueType === FilingCaptionTitleEnum.Caption && !!courtCase.caption) {
        return courtCase.caption;
      } else if (
        valueType === FilingCaptionTitleEnum.Title &&
        !!courtCase.title
      ) {
        return courtCase.title;
      } else if (
        valueType === FilingCaptionTitleEnum.TID &&
        !!courtCase.transactionEfmKey
      ) {
        return courtCase.transactionEfmKey;
      }
    }
    return '-';
  }
}
