import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestParticipantViewModel,
  ParticipantSpec,
  IValidatable,
  CasePartyViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxSingleParticipantValidationService,
  ISingleParticipantValidationService,
} from './single-participant-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxParticipantValidationService =
  new InjectionToken<IParticipantValidationService>(
    'FsxParticipantValidationService'
  );

export interface IParticipantValidationService {
  validateParticipants(
    participants: RequestParticipantViewModel[] | null | undefined,
    participantSpecs: ParticipantSpec[],
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;

  validateParticipant(
    participant: RequestParticipantViewModel,
    specs: ParticipantSpec[],
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class ParticipantValidationService
  implements IParticipantValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxSingleParticipantValidationService)
    private readonly singleParticipantValidationService: ISingleParticipantValidationService
  ) {}

  public validateParticipants(
    participants: RequestParticipantViewModel[] | null | undefined,
    participantSpecs: ParticipantSpec[],
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    let isValid = true;

    participants?.forEach((participant) => {
      // Ensure that the participant is considered valid on each run, until proven otherwise.
      participant.isValid = true;

      // Proceed to validate the participant
      if (
        !this.validateParticipant(
          participant,
          participantSpecs,
          scope,
          filingProfile,
          caseRequest
        )
      ) {
        scope.isValid = false;
        isValid = false;
      }
    });

    return isValid;
  }

  public validateParticipant(
    participant: RequestParticipantViewModel,
    specs: ParticipantSpec[],
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!specs) {
      return true;
    }

    let isRepresentation = false;
    const participantName = participant.name.toLowerCase();
    let participantParty = caseRequest.parties?.find(
      (party) => party.participantName.toLowerCase() === participantName
    );
    let participantCategoryName: string | undefined;
    let partiesWithMatchingRepresentationParticipant:
      | CasePartyViewModel[]
      | undefined;

    if (participantParty) {
      if (!!participantParty.efmKey) {
        // existing party in a SubF doesn't need validating
        return true;
      }
      participantCategoryName = participantParty.participantCategory?.name;
    } else {
      // no party that matches, could it be a representation?
      partiesWithMatchingRepresentationParticipant =
        caseRequest.parties?.filter((p) =>
          p.representation?.some((r) => r.participantName === participantName)
        );

      if (
        partiesWithMatchingRepresentationParticipant &&
        partiesWithMatchingRepresentationParticipant.length > 0
      ) {
        // find the representation that matches and get the category from that
        const represenation =
          partiesWithMatchingRepresentationParticipant[0].representation?.find(
            (r) => r.participantName === participantName
          );

        if (represenation) {
          if (!!represenation.efmKey) {
            // existing party in a SubF doesn't need validating
            return true;
          }
          participantCategoryName = represenation.participantCategory?.name;
          isRepresentation = true;
        }
      }
    }

    const participantSpecs = specs.filter(
      (spec) => spec.participantCategory.name === participantCategoryName
    );

    for (let spec of participantSpecs) {
      if (
        !this.singleParticipantValidationService.validateSingleParticipant(
          participant,
          spec,
          scope,
          filingProfile,
          caseRequest
        )
      ) {
        if (isRepresentation && partiesWithMatchingRepresentationParticipant) {
          // if the participant is a representative, we need to mark the assocated party as invalid
          partiesWithMatchingRepresentationParticipant.forEach((party) => {
            party.isRepresentationValid = false;
            party.isValid = false;
          });
        }
        return this.validationHelperService.markItemAsInvalid(
          participant,
          scope
        );
      }
    }

    return true;
  }
}
