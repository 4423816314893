import { NavLink, ROUTE } from '../../types';

export const NAV_LINKS: NavLink[] = [
  {
    route: ROUTE.HOME,
    title: 'Dashboard',
    svgIcon: 'nav-home',
    cssIcon: 'ico-generic-nav-home',
    classes: 'navbar__list-item',
    tooltipVisible: false,
    shortcutKey: 'Alt + d',
  },
  {
    route: ROUTE.TRANSACTIONS,
    title: 'Filings List',
    svgIcon: 'nav-filing',
    cssIcon: 'ico-generic-nav-filing',
    classes: 'navbar__list-item',
    tooltipVisible: false,
    shortcutKey: 'Alt + f',
  },
  // toDo: Disabled as per Kendall
  // {
  //   route: ROUTE.HELP,
  //   title: 'Help',
  //   svgIcon: 'nav-lifesaver',
  //   cssIcon: 'ico-generic-nav-lifesaver',
  //   classes: 'navbar__list-item navbar__list-item--push-link',
  //   tooltipVisible: false,
  //   shortcutKey: 'Alt + ?',
  // },
];
