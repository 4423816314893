import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  ContactSummary,
  ContactSummaryViewModel,
  ContactType,
  ContactViewModel,
  CreateDocumentInfoService,
  DocumentInfo,
  FieldCategory,
  FilingMode,
  FilingProfile,
  FsxCaseRequestUpdateService,
  FsxContactApiService,
  FsxFilingApiService,
  FsxValidationService,
  ICaseRequestUpdateService,
  IContactApiService,
  IFilingApiService,
  IValidationService,
  ParticipantCategory,
  ParticipantCommonCategory,
  ParticipantFormMode,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FilesUploadedEventParams,
  FormControlWithoutModel,
  SelectionFieldType,
} from '@fsx/ui-components';
import {
  FsxAdditionalFieldsComponent,
  FsxBasicSingleSelectionComponent,
} from '@fsx/ui-components';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';
import {
  RemoveRepresentationEventParams,
  UpdateRepresentationEventParams,
} from '../representation-grid-item/representation-grid-item.component';
import {
  AttorneySelectedEventParams,
  ContactSummariesSelectedEventParams,
  ParticipantSelectedEventParams,
} from '../representation-grid/representation-grid.component';
import { PartiesGridRow } from './parties-grid.model';
import {
  Observable,
  Subject,
  asyncScheduler,
  combineLatest,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import { DocumentInfoAndUploadedFile } from '../../documents/services/upload-additional-field-files-orchestration.service';
import { FilesUploadedFromAdditionalFieldEventParams } from '../../documents/document-form/document-form.component';
import {
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import {
  FsxOpenParticipantFormOrchestrationService,
  IOpenParticipantFormOrchestrationService,
} from '../../shared/services/open-participant-form-orchestration.service';

export interface PartiesGridOptions {
  title: string;
  descriptor: string;
  expanded: boolean;
}

export interface ContactSelectedEventParams {
  contact: ContactViewModel;
  participantName: string;
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
}

export interface AddParticipantEventParams {
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
}

export interface EditParticipantEventParams {
  participant: RequestParticipantViewModel;
  participantCategory: ParticipantCategory;
}

export interface EditRepresentationEventParams {
  participant: RequestParticipantViewModel;
  representation: RequestParticipantRepresentationViewModel;
  participantCategory: ParticipantCategory | undefined | null;
}

export interface SelectParticipantsEventParams {
  excludedContactIds?: string[];
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
  participantName: string;
}

export interface UpdateParticipantEventParams {
  additionalFieldValues: AdditionalFieldValue[] | null;
  participantCategory: ParticipantCategory;
  caseParty: CasePartyViewModel;
  partyIndex: number;
}

export interface ParticipantViewMinMaxValues {
  isReadOnly: boolean;
  minRequired: number;
  maxAllowed: number;
}

@Component({
  selector: 'fsx-parties-grid',
  templateUrl: './parties-grid.component.html',
  styleUrls: ['./parties-grid.component.scss'],
})
export class PartiesGridComponent implements OnChanges, OnInit, OnDestroy {
  @Input() participantCommonCategory!: ParticipantCommonCategory;
  @Input() participantSpecs!: ParticipantSpec[];
  @Input() attorneySpecs!: ParticipantSpec[];
  @Input() partiesGridOptions!: PartiesGridOptions;
  @Input() partiesGridRows: PartiesGridRow[] | null = null;
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() combinedGridRows!: PartiesGridRow[] | null;
  @Input() validationFilteredClass!: string;
  @Input() isInitiating!: boolean;

  @Output() addParticipantEvent = new EventEmitter<AddParticipantEventParams>();
  @Output() contactSelectedEvent =
    new EventEmitter<ContactSelectedEventParams>();
  @Output() removeParticipantEvent = new EventEmitter<CasePartyViewModel>();
  @Output() clearParticipantEvent = new EventEmitter<CasePartyViewModel>();
  @Output() editParticipantEvent =
    new EventEmitter<EditParticipantEventParams>();
  @Output() selectParticipantsEvent =
    new EventEmitter<SelectParticipantsEventParams>();
  @Output() attorneySelectedEvent =
    new EventEmitter<AttorneySelectedEventParams>();
  @Output() contactSummariesSelectedEvent =
    new EventEmitter<ContactSummariesSelectedEventParams>();
  @Output() removeRepresentationEvent =
    new EventEmitter<RemoveRepresentationEventParams>();
  @Output() updateRepresentationEvent =
    new EventEmitter<UpdateRepresentationEventParams>();
  @Output() clearRepresentationEvent = new EventEmitter<CasePartyViewModel>();
  @Output() editRepresentationEvent =
    new EventEmitter<EditRepresentationEventParams>();
  @Output() updateParticipantEvent =
    new EventEmitter<UpdateParticipantEventParams>();
  @Output() addRepresentationEvent =
    new EventEmitter<AttorneySelectedEventParams>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();
  @Output() validationFilteredEvent = new EventEmitter<string>();

  @ViewChildren('partyTypeField')
  partyTypeFields!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxAdditionalFieldsComponent>;

  public contactsSearchType = ContactsSearchTypeEnum;
  public participantsListFormControl!: FormControlWithoutModel;
  public attorneysListFormControl!: FormControlWithoutModel;
  public participantsList: DropdownOption<void>[] = [];
  public attorneysList: DropdownOption<void>[] = [];

  public participantCategory!: ParticipantCategory;
  public showHoverButtons: boolean = false;
  public hoverRowIndex: number = 0;
  public expandedRowIndex: number | null = null;
  public isMaxAllowed: boolean = false;
  public currentParticipantSpec: ParticipantSpec | undefined;
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  public attorneyListDefault!: string;
  public filingProfile!: FilingProfile | undefined;
  public participantListCaption!: string;
  public existingPartiesContactIds!: string[] | undefined;
  public additionalFieldValues: AdditionalFieldValue[][] = [[]];
  public resolver!: FsxReferenceResolver;
  public partiesMap = new Map<string, ParticipantViewMinMaxValues>();
  public subCategoriesInitialValues: string[] = [];
  public subcategoriesSelected: string[] = [];

  errorCount!: number;
  partyFileUploadDocumentInfos!: DocumentInfo[];

  private destroy$: Subject<void> = new Subject<void>();

  protected readonly FilingMode = FilingMode;

  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    @Inject(FsxValidationService)
    private readonly validationService: IValidationService,
    @Inject(FsxOpenParticipantFormOrchestrationService)
    private readonly openParticipantFormOrchestrationService: IOpenParticipantFormOrchestrationService,
    private readonly createDocumentInfoService: CreateDocumentInfoService,
    private readonly injector: Injector
  ) {}

  ngOnChanges(): void {
    this.checkIsMaxAllowed();
    this.filingProfile = this.combinedFilingData?.filingProfile;
    this.existingPartiesContactIds = this.combinedGridRows?.map(
      (row) => row.participant.linkedContact?.id as string
    );
    this.setParticipantsMap();
    this.validate();

    if (this.combinedFilingData) {
      const documentInfos = this.combinedFilingData.documentInfos || [];
      const caseRequestParties =
        this.combinedFilingData.caseRequest.parties || [];
      if (caseRequestParties.length > 0) {
        const partyAdditionalFieldValues =
          caseRequestParties[0].additionalFieldValues || [];
        const partyFileValues: string[] = partyAdditionalFieldValues.flatMap(
          (addlFieldValue: AdditionalFieldValue) => {
            return addlFieldValue.fileValues || [];
          }
        );

        // Should refresh the documentInfos for this party.
        // Does not work, no file size displayed after file uploads.
        // This probably won't work until polling is moved, or extended to work across all tabs.
        this.partyFileUploadDocumentInfos = documentInfos.filter(
          (docInfo: DocumentInfo) => {
            return partyFileValues.includes(docInfo.id);
          }
        );
      }
    }
  }

  ngOnInit(): void {
    this.setParticipantsMap();
    if (this.filingProfile) {
      this.resolver = new FsxReferenceResolver(this.filingProfile, {
        filingApi: this.filingApiService,
        filingId: this.combinedFilingData?.filing.id,
        cfd: this.combinedFilingData ?? undefined,
        caseRequestUpdateService: this.caseRequestUpdateService,
      });
    }
    this.setParticipantList(this.participantSpecs);
    this.createParticipantsListDropdown();
    this.checkIsMaxAllowed();
    this.partiesGridRows?.forEach((partyGridRow, index) => {
      if (partyGridRow.party.additionalFieldValues) {
        this.additionalFieldValues[index] =
          partyGridRow.party.additionalFieldValues;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  rowIndex(index: number): number {
    return index;
  }

  public setAdditionalFieldValues(
    value: AdditionalFieldValue,
    partyIndex: number
  ) {
    this.resolver.updateAdditionalFieldValues(
      this.additionalFieldValues[partyIndex],
      value
    );
    if (
      this.combinedFilingData?.caseRequest &&
      this.combinedFilingData.caseRequest.parties
    ) {
      this.combinedFilingData.caseRequest.parties[
        partyIndex
      ].additionalFieldValues = this.additionalFieldValues[partyIndex];
      this.resolver.updateCaseRequestPut(
        this.combinedFilingData.caseRequest,
        this.combinedFilingData.filing.id
      );
    }
  }

  private checkIsMaxAllowed() {
    const particpantSpecs = this.participantSpecs;
    const partiesGridRows = this.partiesGridRows;
    if (particpantSpecs && partiesGridRows) {
      this.isMaxAllowed =
        partiesGridRows.length ===
        (this.currentParticipantSpec?.maxAllowed || 0);
    }
    if (partiesGridRows?.length === this.currentParticipantSpec?.maxAllowed) {
      console.warn('The maximum number of participants have been added');
    }
  }

  onToggleExpandTitleRow(event: Event) {
    event.stopPropagation();
    this.partiesGridOptions.expanded = !this.partiesGridOptions.expanded;
  }

  selectedContactEventHandler(
    contact: ContactViewModel,
    participantName: string
  ) {
    if (!this.currentParticipantSpec) {
      console.error('Cannot continue without a particpant spec');
      return;
    }

    // Get the recentlyUsedContactIds array from session storage (if it exists),
    // otherwise create a new empty array to push to.
    const localStorageRecentlyUsedContactIds: string | null =
      localStorage.getItem('recentlyUsedContactIds');
    const recentlyUsedContactIds: string[] = localStorageRecentlyUsedContactIds
      ? JSON.parse(localStorageRecentlyUsedContactIds)
      : [];

    // The maximum number of recentlyUsedContactIds that we can store until we
    // have to start removing entries
    const maxRecentlyUsedContacts: number = 3;

    // Here we check if the contactId already exists.
    // - If it does exists it will return the index in the array
    // - if it does not exist it will return -1 (handled next)
    const existingContactIdIndex: number = recentlyUsedContactIds.findIndex(
      (contactId) => {
        return contactId === contact.id;
      }
    );

    // Here we set the index of the element that we will remove
    // (only used if maxRecentlyUsedContacts size is reached)
    const removeContactdFromIndex: number =
      existingContactIdIndex !== -1 ? existingContactIdIndex : 0;

    // We always push the most recently used contact id to the end of the array
    // so that the most recently used contactIds can be brought back in the
    // correct order, Last in First out (LIFO);
    recentlyUsedContactIds.push(contact.id);

    // Conditionoally remove a contactId entry from the array
    // (if the last push exceeded maxRecentlyUsedContacts size)
    if (recentlyUsedContactIds.length > maxRecentlyUsedContacts) {
      recentlyUsedContactIds.splice(removeContactdFromIndex, 1);
    }

    // Stringify and set the array back into localStorage
    localStorage.setItem(
      'recentlyUsedContactIds',
      JSON.stringify(recentlyUsedContactIds)
    );

    this.contactSelectedEvent.next({
      contact,
      participantName,
      participantSpec: this.currentParticipantSpec,
      participantCategory: this.participantCategory,
    });
  }

  clearParticipantEventHandler(partyToClear: CasePartyViewModel) {
    this.clearParticipantEvent.emit(partyToClear);
  }

  editParticipantEventHandler(participant: RequestParticipantViewModel) {
    this.editParticipantEvent.emit({
      participant,
      participantCategory: this.participantCategory,
    });
  }

  editRepresentationEventHandler(params: EditRepresentationEventParams) {
    this.editRepresentationEvent.emit(params);
  }

  onAddParticipantClicked() {
    if (!this.currentParticipantSpec) {
      console.error('Cannot continue without a particpant spec');
      return;
    }

    this.optimisticallySetExpandedRowIndex();
    this.checkIsMaxAllowed();

    this.addParticipantEvent.emit({
      participantCategory: this.participantCategory,
      participantSpec: this.currentParticipantSpec,
    });
  }

  private optimisticallySetExpandedRowIndex() {
    const newRowIndex = this.partiesGridRows ? this.partiesGridRows.length : 0;
    this.expandedRowIndex = newRowIndex;
  }

  onRemoveParticipantClicked(event: Event, row: PartiesGridRow) {
    event.stopPropagation();
    this.checkIsMaxAllowed();
    this.removeParticipantEvent.emit(row.party);
  }

  onToggleExpandDetailRow(event: Event, index: number, row: PartiesGridRow) {
    event.stopPropagation();
    this.expandedRowIndex = this.expandedRowIndex !== index ? index : null;
    this.validateParticipant(row);
    this.validateFormFields(index);
  }

  private validateParticipant(row: PartiesGridRow): void {
    const spec = this.getParticipantSpec(row.party.participantCategory?.name!);
    this.validationService.validateParticipant(
      row.participant,
      [spec],
      this.combinedFilingData.caseRequest, // Passed in once for the scope (not always guarenteed to be caseRequest, but is in this instance)
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest // Passed in again for the caseRequest proper, used in dependent services
    );
    this.validationService.validateParty(
      row.party,
      spec,
      this.combinedFilingData.caseRequest,
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.modeSpec
    );
  }

  private validateFormFields(index: number): void {
    setTimeout(() => {
      if (this.partyTypeFields) {
        this.partyTypeFields
          .toArray()
          .filter((fld) => fld.id === index)
          .forEach((fld) => fld.validate());
      }

      if (this.additionalFields) {
        this.additionalFields
          .toArray()
          .filter((fld) => fld.id === index)
          .forEach((fld) => fld.validate());
      }
    });
  }

  private getParticipantSpec(categoryName: string): ParticipantSpec {
    return this.participantSpecs.find(
      (pSpec: ParticipantSpec) =>
        pSpec.participantCategory.name === categoryName
    )!;
  }

  onParticipantTypeSelected(value: string) {
    this.currentParticipantSpec = this.participantSpecs.find(
      (pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === value;
      }
    );

    const selectedOption = this.participantsList.find(
      (option) => option.name === value
    );

    if (selectedOption) {
      this.participantCategory = {
        name: selectedOption.name,
        caption: selectedOption.caption,
        commonCategory:
          selectedOption.commonCategory as ParticipantCommonCategory,
      };
    }
  }

  onDataRowHoverStateChanged(show: boolean, rowIndex: number) {
    this.showHoverButtons = show;
    this.hoverRowIndex = rowIndex;
  }

  onParticipantCheckboxClicked(event: Event) {
    event.stopPropagation();
  }

  onOpenContactsIconClicked(participantName: string) {
    if (!this.currentParticipantSpec) {
      console.error('Cannot continue without a particpant spec');
      return;
    }

    this.selectParticipantsEvent.emit({
      excludedContactIds: this.existingPartiesContactIds,
      participantCategory: this.participantCategory,
      participantSpec: this.currentParticipantSpec,
      participantName,
    });
  }

  /**
   * A handler function for the "Party Type" dropdown's selectedValue
   * event. We hook into it here to trigger a PATCH request to apply
   * the update on the server.
   *
   * @param params The params object needed to initiate orchestration.
   */
  onPartyTypeSelected(params: {
    /**
     * The selected ParticipantCategory name (id). Needed here to
     * lookup the ParticipantCategory that we want to set.
     */
    value: string;

    /**
     * The CaseParty object to set the ParticipantCategory on.
     */
    caseParty: CasePartyViewModel;

    /**
     * The index of the CaseParty object in the CaseRequest.parties
     * array. This is needed for the subsequent PATCH request.
     */
    partyIndex: number;
  }): void {
    // Guard clause to prevent infinite loop
    const isSameValue =
      params.caseParty.participantCategory?.name === params.value;
    if (isSameValue) {
      return;
    }

    // Lookup the ParticipantSpec using the selected ParticipantCategory name.
    const participantSpec: ParticipantSpec = this.participantSpecs.find(
      (pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === params.value;
      }
    )!;

    // Trigger the update
    this.updateParticipantEvent.emit({
      participantCategory: participantSpec.participantCategory,
      caseParty: params.caseParty,
      partyIndex: params.partyIndex,
      additionalFieldValues: null,
    });
  }

  public setValues(values: string[], partyIndex: number) {
    this.subcategoriesSelected = values;
    if (
      this.combinedFilingData?.caseRequest &&
      this.combinedFilingData.caseRequest.parties
    ) {
      this.combinedFilingData.caseRequest.parties[
        partyIndex
      ].participantSubCategoryNames = values;
      this.resolver.updateCaseRequestPut(
        this.combinedFilingData.caseRequest,
        this.combinedFilingData.filing.id
      );
    }
  }

  public setBasicPartyFormControl(controls: FormControlWithoutModel) {
    this.participantsListFormControl = controls;
  }

  private createParticipantsListDropdown(): void {
    const firstOrDefault =
      this.participantsList.length > 0 ? this.participantsList[0].name : '';
    this.onParticipantTypeSelected(firstOrDefault);
  }

  private setParticipantList(participantSpecs: ParticipantSpec[]): void {
    this.participantsList = participantSpecs.map((pSpec: ParticipantSpec) => {
      return { ...pSpec.participantCategory, selected: false };
    });

    // set caption to display available party type when only 1 option
    if (this.participantsList.length === 1) {
      this.participantListCaption = this.participantsList[0].caption;
    } else {
      this.participantListCaption = '(no party selected)';
    }
  }

  attorneySelectedEventHandler(params: AttorneySelectedEventParams): void {
    this.attorneySelectedEvent.emit(params);
  }

  removeRepresentationEventHandler(params: {
    representationToRemove: RequestParticipantRepresentationViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): void {
    this.removeRepresentationEvent.emit(params);
  }

  updateRepresentationEventHandler(params: {
    attorneyParticipantSpec: ParticipantSpec | null;
    attorneyParticipantCategory: ParticipantCategory | null;
    caseParty: CasePartyViewModel;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }): void {
    this.updateRepresentationEvent.emit(params);
  }

  clearRepresentationEventHandler(caseParty: CasePartyViewModel) {
    this.clearRepresentationEvent.emit(caseParty);
  }

  contactSummariesSelectedEventHandler(
    params: ContactSummariesSelectedEventParams
  ) {
    this.contactSummariesSelectedEvent.emit(params);
  }

  selectedContactSummariesEventHandler(contactSummaries: ContactSummary[]) {
    const initialIndex = this.partiesGridRows?.length
      ? this.partiesGridRows?.length - 1
      : 0;
    for (let i = 1; i < contactSummaries.length; i++) {
      if (this.currentParticipantSpec) {
        this.addParticipantEvent.emit({
          participantCategory: this.participantCategory,
          participantSpec: this.currentParticipantSpec,
        });
      }
    }
    asyncScheduler.schedule(() => {
      contactSummaries.forEach((contactSummary, counter = 0) => {
        this.contactApiService
          .getContact(contactSummary.id)
          .subscribe((contact: ContactViewModel) => {
            this.selectedContactEventHandler(
              contact,
              this.partiesGridRows?.at(initialIndex + counter++)?.participant
                .name ?? ''
            );
          });
      });
    });
  }

  isGhostRow(row: PartiesGridRow): boolean {
    return (
      !!row.participant.contactType &&
      row.participant.contactType === ContactType.Unknown
    );
  }

  addRepresentationEventHandler(params: ParticipantSelectedEventParams): void {
    this.panelService.openContactsListPanel({
      contactsListConfig: {
        searchType: ContactsSearchTypeEnum.attorneys,
        addCallback: (contactSummaries: ContactSummaryViewModel[]) => {
          contactSummaries.forEach((contactSummary) => {
            if (contactSummary.id) {
              this.contactApiService
                .getContact(contactSummary.id)
                .pipe(
                  tap((contact: ContactViewModel) => {
                    let attorneySelectedEventParams: AttorneySelectedEventParams =
                      {
                        contact: contact,
                        participantSpec: params.participantSpec,
                        partyToAddTo: params.partyToAddTo,
                      };
                    this.attorneySelectedEvent.emit(
                      attorneySelectedEventParams
                    );
                  }),
                  take(1)
                )
                .subscribe();
            }
          });
        },
      },
      injector: this.injector,
    });
  }

  private setParticipantsMap(): void {
    this.partiesGridRows?.forEach((partyGridRow) => {
      const spec = this.participantSpecs.find(
        (spec) =>
          spec.participantCategory.name ===
          partyGridRow.party.participantCategory?.name
      );
      if (!!spec) {
        const partyVals: ParticipantViewMinMaxValues = {
          isReadOnly: !!partyGridRow.party.efmKey,
          minRequired: spec?.minRequired as number,
          maxAllowed: spec?.maxAllowed as number,
        };
        this.partiesMap.set(partyGridRow.participant.name, partyVals);

        partyGridRow.representationGridRows.forEach((representationGridRow) => {
          const repVals: ParticipantViewMinMaxValues = {
            isReadOnly: !!representationGridRow.representation.efmKey,
            minRequired: spec.representation?.minRequired as number,
            maxAllowed: spec.representation?.maxAllowed as number,
          };
          this.partiesMap.set(
            representationGridRow.representation.participantName,
            repVals
          );
        });
      }
    });
  }

  private validate(): void {
    if (this.partiesGridRows) {
      this.errorCount = this.partiesGridRows.reduce(
        (acc, curr) =>
          curr.participant.isValid === false ||
          curr.party.isValid == false ||
          curr.party.isRepresentationValid === false
            ? acc + 1
            : acc,
        0
      );
    }
  }

  validationFilterChanged(filteredClass: string) {
    this.validationFilteredClass = filteredClass;
    this.validationFilteredEvent.emit(filteredClass);
  }

  public filesUploadedFromPartiesAdditionalFieldEventHandler(
    params: FilesUploadedEventParams,
    row: PartiesGridRow
  ) {
    of(params)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Create the array of documentIds to update the filesValue array with (below)
                const documentIds: string[] = documentInfoAndUploadedFiles.map(
                  (
                    documentInfoAndUploadedFile: DocumentInfoAndUploadedFile
                  ) => {
                    return documentInfoAndUploadedFile.documentInfo.id;
                  }
                );

                // Get the existing additionalFieldValues or an empty array
                // - This is what we update and pass to the updateParticipantEvent
                const existingAdditionalFieldValues =
                  row.party.additionalFieldValues || [];

                // Try to find an AdditionalFieldValue object for the "participant-file-selection" spec
                let specAdditionalFieldValue: AdditionalFieldValue | undefined =
                  existingAdditionalFieldValues.find(
                    (f) => f.additionalFieldName === params.additionalFieldName
                  );

                if (specAdditionalFieldValue) {
                  // If found, push to the existing fileValues array
                  documentIds.forEach((id: string) => {
                    specAdditionalFieldValue!.fileValues?.push(id);
                  });
                } else {
                  // If not found then create it and populate fileValues array
                  specAdditionalFieldValue = {
                    additionalFieldName: params.additionalFieldName,
                    fileValues: [...documentIds],
                  };

                  // Push the new AdditionalFieldValue object to the list of existing AdditionalFieldValue objects
                  existingAdditionalFieldValues.push(specAdditionalFieldValue);
                }

                const participantCategory: ParticipantCategory =
                  row.party.participantCategory!;

                this.updateParticipantEvent.emit({
                  participantCategory,
                  caseParty: row.party,
                  partyIndex: row.partyIndex,
                  additionalFieldValues: existingAdditionalFieldValues,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...params,
                  documentInfoAndUploadedFiles,
                });
              }
            )
          );
        })
      )
      .subscribe();
  }

  /**
   * A handler function for the "Add Party" button's click event.
   * Here we open the participant form in the participant form panel
   * to allow the user to enter the new participant's details.
   *
   * @param participant The default RequestParticipant object to open
   * the panel with. This will be an bare-bones RequestParticipant with
   * only the name (id) property set. From the user perspective they are
   * adding a new record, but in code we are editing the existing object.
   */
  onAddPartyButtonClicked(participant: RequestParticipantViewModel) {
    // Get the selected ParticipantCategory name from the "Party Type" dropdown.
    const participantCategoryName = this.participantsListFormControl.value;

    // Use the selected ParticpantCategory name to lookup the ParticipantSpec
    // in the FilingProfile.
    const participantSpec = this.combinedFilingData.modeSpec?.participant.find(
      (spec) => {
        return spec.participantCategory.name === participantCategoryName;
      }
    )!;

    // Open the ParticipantForm using the ParticipantSpec's ParticipantCategory
    this.openParticipantFormOrchestrationService.openParticipantForm({
      formMode: ParticipantFormMode.AddParticipant,
      participant: participant,
      isRepresentation: false,
      participantCategory: participantSpec.participantCategory,
    });
  }
}
