import { Injectable } from '@angular/core';
import { ContactAliasViewModel, NewContactViewModel } from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class OrganizationFormService {
  public setOrganizationValues(
    contact: NewContactViewModel | ContactAliasViewModel
  ): void {
    if (contact.organization && !contact.organization?.caption) {
      contact.organization.caption = '';
    }
    if (contact.organization && !contact.organization?.title) {
      contact.organization.title = '';
    }
  }
}
