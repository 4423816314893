import { Injectable } from '@angular/core';
import {
  ContactViewModel,
  ParticipantCategory,
  CasePartyViewModel,
  CaseRequestViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

export interface ICreatePartyFromContactParams {
  contact: ContactViewModel;
  participantCategory: ParticipantCategory;
  uniqueIdentifier: string;
  caseRequest: CaseRequestViewModel;
}

export interface ICreatePartyFromParticipantParams {
  participant: RequestParticipantViewModel;
  participantCategory: ParticipantCategory;
  uniqueIdentifier: string;
  caseRequest: CaseRequestViewModel;
}

@Injectable({
  providedIn: 'root',
})
export class CreatePartyService {
  public createPartyFromContact(
    params: ICreatePartyFromContactParams
  ): Observable<CasePartyViewModel> {
    const caseRequestCases = params.caseRequest.cases;
    const firstRequestCase = caseRequestCases?.length
      ? caseRequestCases[0]
      : null;

    const requestCaseParty: CasePartyViewModel = {
      caption: params.contact.caption,
      caseId: firstRequestCase?.caseId || '',
      participantName: params.uniqueIdentifier,
      efmKey: null,
      participantCategory: params.participantCategory,
      participantSubCategoryNames: [],
      representation: [],
      isSelfRepresented: false,
      additionalFieldValues: null,
    } as unknown as CasePartyViewModel;

    return of(requestCaseParty);
  }

  public createPartyFromParticipant(
    params: ICreatePartyFromParticipantParams
  ): Observable<CasePartyViewModel> {
    const caseRequestCases = params.caseRequest.cases;
    const firstRequestCase = caseRequestCases?.length
      ? caseRequestCases[0]
      : null;

    const requestCaseParty: CasePartyViewModel = {
      caption: params.participant.caption,
      caseId: firstRequestCase?.caseId || '',
      participantName: params.uniqueIdentifier,
      efmKey: null,
      participantCategory: params.participantCategory,
      participantSubCategoryNames: [],
      representation: [],
      isSelfRepresented: false,
      additionalFieldValues: null,
    } as unknown as CasePartyViewModel;

    return of(requestCaseParty);
  }
}
