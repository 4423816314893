import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

export interface RowValidationChanges extends SimpleChanges {
  errorCount: SimpleChange;
}

export type ValidationHeaderDisplayMode = 'inline' | null;

@Component({
  selector: 'fsx-row-header-validation',
  templateUrl: './row-header-validation.component.html',
  styleUrls: ['./row-header-validation.component.scss'],
})
export class RowHeaderValidationComponent implements OnChanges {
  @Input() errorCount!: number;
  @Input() mode?: ValidationHeaderDisplayMode;
  @Output() errorFilterChanged = new EventEmitter<string>();

  filterClass: string = '';
  displayClass: string = '';
  errorCountDisplay: string = '';
  errorCountTitle: string = '';

  private isFiltered = false;

  ngOnChanges(changes: RowValidationChanges): void {
    if (changes && changes.errorCount) {
      this.errorCount = changes.errorCount.currentValue;
      this.errorCountDisplay =
        this.errorCount > 9 ? '' : this.errorCount.toString();

      this.setErrorCountTitle();
      this.displayClass = this.mode ? this.mode.toString() : '';
    }
  }

  filterErrorRows($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.isFiltered = !this.isFiltered;
    this.filterClass = this.isFiltered ? 'filtered' : '';
    this.errorFilterChanged.emit(this.filterClass);
    this.setErrorCountTitle();
  }

  private setErrorCountTitle() {
    const suffix = this.errorCount === 1 ? '' : 's';
    const prefix = this.isFiltered ? 'showing ' : '';

    this.errorCountTitle = `${prefix}${this.errorCount} error${suffix}`;
  }
}
