import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { FsxPanelService, IPanelService } from './panel.service';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IOpenContactsListOrchestrationService to use at runtime.
 */
export const FsxOpenContactsListOrchestrationService =
  new InjectionToken<IOpenContactsListOrchestrationService>(
    'FsxOpenContactsListOrchestrationService'
  );

/**
 * The parameters to pass to the orchestration service so
 * that it can do its work.
 */
export interface OpenContactsListOrchestrationParams {
  searchType: ContactsSearchTypeEnum;
}

/**
 * A blueprint for an orchestration service, which handles the opening of the contacts list.
 */
export interface IOpenContactsListOrchestrationService {
  /**
   * The orchestration steps needed to open the contacts list.
   */
  openContactsListOrchestration$: Observable<void>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactsList(params: OpenContactsListOrchestrationParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the opening of
 * the contacts list.
 */
@Injectable()
export class OpenContactsListOrchestrationService
  implements IOpenContactsListOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private openContactsListAction$ =
    new Subject<OpenContactsListOrchestrationParams>();

  /**
   * The orchestration steps needed to open the contacts list.
   */
  openContactsListOrchestration$: Observable<void> =
    this.openContactsListAction$.pipe(
      map((_params: OpenContactsListOrchestrationParams) => {
        this.panelService.openContactsListPanel({
          contactsListConfig: {
            searchType: ContactsSearchTypeEnum.contacts,
            addCallback: () => {
              return;
            },
          },
          injector: this.injector,
        });

        return;
      })
    );

  /**
   *
   * @param panelService The utility service for opening panels.
   * @param injector The injector to pass on to the mat dialog, used to ensure the dialog uses the same providers.
   */
  public constructor(
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    private readonly injector: Injector
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactsList(params: OpenContactsListOrchestrationParams): void {
    this.openContactsListAction$.next(params);
  }
}
