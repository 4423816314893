import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiCallForOfflineQueue,
  FsxApiSearchQuery,
  HttpMethod,
  HttpReqOptions,
} from '@fsx/fsx-shared';
import { stringify } from 'qs';
import { BehaviorSubject, filter, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

export function buildHttpQueryParam(query: FsxApiSearchQuery): HttpParams {
  const qs = stringify(query, {
    skipNulls: true,
    arrayFormat: 'indices',
    allowDots: true,
  });
  return new HttpParams({ fromString: qs });
}

@Injectable({
  providedIn: 'root',
})
export class ApiV2Service {
  private apiCallsQueue$: Subject<ApiCallForOfflineQueue[]> = new Subject();
  private loading$$ = new BehaviorSubject<boolean>(false);

  public constructor(private readonly http: HttpClient) {}

  get<T>(url: string, options?: HttpReqOptions<'body', 'json'>): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'arraybuffer' | 'blob'>
  ): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'blob'>
  ): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'arraybuffer'>
  ): Observable<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T>(url: string, options?: HttpReqOptions<any, any>): Observable<T> {
    this.loading$$.next(true);

    return this.addCallsToQueueIfOffline({
      url,
      body: null,
      httpOptions: options,
      httpReqMethod: HttpMethod.PATCH,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.get<T>(url, options)),
      take(1),
      finalize(() => this.loading$$.next(false))
    );
  }

  post<B, T>(
    url: string,
    body: B,
    options: HttpReqOptions<'body', 'json'> = {}
  ): Observable<T> {
    this.loading$$.next(true);
    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.POST,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.post<T>(url, body, options)),
      take(1),
      finalize(() => this.loading$$.next(false))
    );
  }

  private addCallsToQueueIfOffline(
    offlineRequest: ApiCallForOfflineQueue
  ): Observable<boolean> {
    if (!navigator.onLine) {
      const body = offlineRequest.body;
      const addToOfflineQueue: ApiCallForOfflineQueue = {
        body,
        httpOptions: offlineRequest.httpOptions,
        httpReqMethod: offlineRequest.httpReqMethod,
        url: offlineRequest.url,
      };

      return this.apiCallsQueue$.pipe(
        tap((updatedApiCallsQueue: ApiCallForOfflineQueue[]) => {
          updatedApiCallsQueue.unshift(addToOfflineQueue);
          this.apiCallsQueue$.next(updatedApiCallsQueue);
        }),
        map(() => navigator.onLine)
      );
    }
    return of(navigator.onLine);
  }

  patch<B, R>(url: string, body: B, options = {}): Observable<R> {
    this.loading$$.next(true);
    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.PATCH,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.patch<R>(url, body, options)),
      take(1),
      finalize(() => this.loading$$.next(false))
    );
  }

  put<B, R>(url: string, body: B, options = {}): Observable<R> {
    this.loading$$.next(true);
    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.PUT,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.put<R>(url, body, options)),
      take(1),
      finalize(() => this.loading$$.next(false))
    );
  }
}
