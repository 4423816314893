export interface AuthEndPoints {
  auth: string;
  authByUsername: (username: string) => string;
}

export const authEndPoints = (versionedUrl: string): AuthEndPoints => ({
  auth: `${versionedUrl}/devauth`,

  authByUsername: (username: string): string =>
    `${versionedUrl}/devauth/${username}`,
});
