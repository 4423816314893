import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ContactFormMode,
  ContactType,
  ContactViewModel,
} from '@fsx/fsx-shared';

/**
 * A configuration object consisting of the minimal properties that the
 * contact form panel header component needs to do it'S work.
 */
export interface ContactFormPanelHeaderConfig {
  /**
   * The mode the form should operate in (Add or Edit mode).
   */
  formMode: ContactFormMode;

  /**
   * The contact being edited, used in Edit mode to set the form fields.
   * Or null when in Add mode,
   */
  contact: ContactViewModel | null;

  /**
   * The contact type being added, used in Add mode to set the header
   * title text and icon when adding a contact (Person or Organization).
   */
  contactType?: ContactType;
}

/**
 * The view model consisting of properties derived from the passed in
 * config object. Passed to the template to render the header.
 */
export interface ContactFormPanelHeaderViewModel {
  title: string;
  contactTypeCaption: string;
  titleIconPath: string;
}

@Component({
  selector: 'fsx-contact-form-panel-header',
  templateUrl: 'contact-form-panel-header.component.html',
  styleUrls: ['./contact-form-panel-header.component.scss'],
})
export class ContactFormPanelHeaderComponent implements OnChanges {
  /**
   * The config object which we pass in and use to create the view model object from.
   */
  @Input() config!: ContactFormPanelHeaderConfig;

  /**
   * The view model object to pass to the view, derived from the config object.
   */
  vm!: ContactFormPanelHeaderViewModel;

  ngOnChanges(_changes: SimpleChanges): void {
    const contactType =
      this.config.contactType ||
      this.config.contact?.type ||
      ContactType.Person;
    this.setVmPropertiesForContactType(contactType);
    this.setVmPropertiesForFormMode(this.config.formMode);
  }

  private setVmPropertiesForFormMode(formMode: ContactFormMode) {
    if (formMode === ContactFormMode.AddContact) {
      this.vm = {
        ...this.vm,
        title: 'Add New Contact',
      };
    } else {
      this.vm = {
        ...this.vm,
        title: `${this.config.contact?.person?.personalName?.surName}, 
          ${this.config.contact?.person?.personalName?.givenName}`,
      };
    }
  }

  private setVmPropertiesForContactType(type: ContactType) {
    if (type === ContactType.Person) {
      this.vm = {
        ...this.vm,
        titleIconPath: '../../../assets/icons/contact_icon.svg',
      };
    } else {
      this.vm = {
        ...this.vm,
        titleIconPath: '../../../assets/icons/org_icon.svg',
      };
    }
  }
}
