import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fsx-option-selected-card',
  templateUrl: './option-selected-card.component.html',
  styleUrls: ['./option-selected-card.component.scss'],
})
export class OptionSelectedCardComponent {
  @Output() resetOptionEmitter = new EventEmitter<void>();

  @Input() primaryCaption!: string;
  @Input() secondaryCaption!: string | null;
  @Input() tertiaryCaption!: string | null;
  @Input() cardIcon!: string;

  public resetCourtSelected(): void {
    this.resetOptionEmitter.emit();
  }
}
