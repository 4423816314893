import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PollingState } from '../filing-editor.component';

@Component({
  selector: 'fsx-filing-submission-progress',
  templateUrl: './filing-submission-progress.component.html',
  styleUrls: ['./filing-submission-progress.component.scss'],
})
export class FilingSubmissionProgressComponent implements OnInit {
  @Input() pollingState$!: Observable<PollingState>;
  pollingState!: PollingState;

  ngOnInit(): void {
    this.pollingState$.subscribe((state) => {
      this.pollingState = state;
    });
  }

  protected readonly PollingState = PollingState;
}
