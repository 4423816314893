import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { FieldCategory, TextFieldDefinition } from '@fsx/fsx-shared';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { FormControlWithModel } from '../../models/form-control.model';
import { FSXFormControlService } from '../../services';
import { ReferenceResolver } from '../../types';
import { FsxBaseComponent } from '../base/base.component';

@Component({
  selector: 'fsx-textbox-component',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class FsxTextBoxComponent
  extends FsxBaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() fieldType: string = FieldCategory.Text;
  @Input() width!: string;
  @Input() height!: string;
  @Input() inputHeight!: number;
  @Input() initialValues!: (string | undefined | null)[];
  @Input() formControlSpecs: TextFieldDefinition[] = [];
  @Input() resolver!: ReferenceResolver;
  @Input() forceMultiLine!: boolean;
  @Input() disabled!: boolean;
  @Input() labelClasses!: string | undefined | null;
  @Input() placeholder = '';
  @Input() formControlsArray = new FormArray<
    FormControlWithModel<TextFieldDefinition>
  >([]);

  @Output() formArrayEmitter = new EventEmitter<
    FormArray<FormControlWithModel<TextFieldDefinition>>
  >();

  @Output() textChanged = new EventEmitter<string>();

  public textAreaFormControl!: FormControlWithModel<TextFieldDefinition>;
  private textChanged$$: Subject<string> = new Subject();
  private destroy$: Subject<unknown> = new Subject();

  public inputFocused = false;

  constructor(private readonly fsxFormControlService: FSXFormControlService) {
    super();
  }

  public ngOnInit(): void {
    this.formControlSpecs.forEach((controlSpec, index) => {
      this.formControlsArray.push(
        this.fsxFormControlService.createFormControl(
          controlSpec,
          FieldCategory.Text,
          this.initialValues[index] ?? '',
          this.resolver,
          this.forceMultiLine
        )
      );
    });

    this.textAreaFormControl = this.fsxFormControlService.createFormControl(
      this.formControlSpecs[0],
      this.fieldType,
      this.initialValues[0] && this.initialValues[1]
        ? this.initialValues[0] + ',\n' + this.initialValues[1]
        : this.initialValues[0]
        ? this.initialValues[0]
        : '',
      this.resolver,
      this.forceMultiLine
    );

    this.formArrayEmitter.emit(this.formControlsArray);

    this.textAreaFormControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.formControlsArray.controls.forEach((control) => {
            control.markAsDirty();
            control.updateValueAndValidity();
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.textAreaFormControl.statusChanges
      .pipe(
        distinctUntilChanged(),
        tap((status) => {
          this.formControlsArray.controls.forEach((control) => {
            if (status === 'INVALID') {
              control.markAsTouched();
            } else if (status === 'VALID') {
              control.markAsUntouched();
            } else if (status === 'DISABLED') {
              control.disable();
            }
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.textChanged$$
      .pipe(
        debounceTime(700),
        tap((value: string) => {
          this.textChanged.emit(value);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnChanges(): void {
    if (this.textAreaFormControl) {
      this.disabled
        ? this.textAreaFormControl.disable()
        : this.textAreaFormControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public setAddress(textArea: HTMLTextAreaElement): void {
    textArea.value = textArea.value.trim();
    this.textAreaFormControl.setValue(textArea.value);
    this.textAreaFormControl.updateValueAndValidity();

    const values = this.textAreaFormControl.value?.split('\n');

    this.formControlsArray.controls.forEach((control, index) => {
      if (values && values[index]) {
        control.setValue(values[index].trim());
      } else {
        control.setValue(null);
      }
    });

    if (values && values?.length > this.formControlsArray.controls.length) {
      values.forEach((value: string, index: number) => {
        if (index >= this.formControlsArray.controls.length) {
          this.formControlsArray
            .at(this.formControlsArray.controls.length - 1)
            .setValue(
              `${
                this.formControlsArray.controls[
                  this.formControlsArray.controls.length - 1
                ].value
              } ` + value.trim()
            );
        }
      });
    }
  }

  public getErrorMessage(): string {
    return Object.values({ ...this.textAreaFormControl.errors })[0];
  }

  public onTextChanged(event: KeyboardEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = target.value;
    this.textChanged$$.next(value);
  }

  public focusInput(): void {
    this.inputFocused = true;
  }

  public removeFocus(): void {
    this.inputFocused = false;
    this.textAreaFormControl.updateValueAndValidity();
  }

  public onFocus() {
    this.formatValue();
    this.focusInput();
  }

  public formatValue(): void {
    const value: string = this.textAreaFormControl.value;
    if (value && typeof value === 'string') {
      this.textAreaFormControl.setValue(value.trim());
    }
  }

  public validate(): void {
    this.textAreaFormControl.markAsTouched();
    this.textAreaFormControl.markAsDirty();
    this.textAreaFormControl.updateValueAndValidity({ emitEvent: false });
  }
}
