/* eslint-disable  @typescript-eslint/no-explicit-any */
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FsxApiSearchQuery, ResultSet } from './query.interfaces';

export const DEFAULT_LIMIT = 20;

// Limit for items for dropdowns
export const DEFAULT_LIMIT_DROPDOWN = 100;

export enum HttpMethod {
  GET = 'get',
  DELETE = 'delete',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
}

export type PostObserveType = 'body' | 'events' | 'response';
export type ResponseType = 'blob' | 'json' | 'text' | 'arraybuffer';
export type GetResponseType = 'blob' | 'text';
export type HttpPostReqOptions = HttpReqOptions<'body', ResponseType>;

export interface HttpResponseOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  context?: HttpContext;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface HttpReqOptions<O, R> {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: O;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: R;
  withCredentials?: boolean;
}

export interface ApiCallForOfflineQueue {
  url: string;
  httpReqMethod: HttpMethod;
  body: unknown;
  httpOptions?: HttpReqOptions<PostObserveType, ResponseType>;
}

export interface IApiService {
  isDeviceOnline: Observable<boolean>;
  loading: boolean;

  // get<T>(url: string, options: HttpReqOptions<PostObserveType, ResponseType>): Observable<T>;
  // post<B, T>(url: string, body: B, options: HttpReqOptions<PostObserveType, ResponseType>): Observable<T>;
  // put(url: string, body: unknown, options: HttpResponseOptions): Observable<HttpResponse<unknown> | ArrayBuffer>;

  search<T>(url: string, params: FsxApiSearchQuery): Observable<ResultSet<T>>;
  get<T>(url: string, options: HttpReqOptions<any, any>): Observable<T>;
  post<B, T>(
    url: string,
    body: B,
    options: HttpReqOptions<any, any>
  ): Observable<T>;
  put<T>(
    url: string,
    body: T,
    options: HttpReqOptions<any, any>
  ): Observable<T>;
  // patch<T>(url: string, body: T, options: HttpReqOptions<any, any>);
  delete<T>(url: string, options: { [key: string]: unknown }): Observable<T>;
  buildHttpQueryParams(query: FsxApiSearchQuery): HttpParams;
}
