import { Pipe, PipeTransform } from '@angular/core';
import { DocumentInfo } from '@fsx/fsx-shared';
import { DocumentInfoHelperService } from 'projects/libs/shared/src/lib/services/filings/document-info-helper.service';
import { FileSizePipe } from './file-size.pipe';

@Pipe({
  name: 'convertedPdfFileSize',
})
export class ConvertedPdfFileSizePipe implements PipeTransform {
  constructor(private documentInfoHelperService: DocumentInfoHelperService) {}

  transform(documentInfo: DocumentInfo): string {
    const size: number =
      this.documentInfoHelperService.getConvertedPdfSize(documentInfo);
    const fileSizePipe: FileSizePipe = new FileSizePipe();
    const result: string = fileSizePipe.transform(size);
    return result;
  }
}
