<form
  class="fsx-alias-component"
  *ngFor="let aliasFormGroup of aliasFormArray.controls; let index = index"
>
  <ng-container *ngIf="aliasSelectionFieldDefinition">
    <ng-container>
      <div class="type-container">
        <fsx-profile-single-selection
          #categoryField
          [caption]="'Category'"
          [fieldType]="fieldType.Selection"
          [width]="'200px'"
          [height]="'63px'"
          [resolver]="resolver"
          [fieldDefinition]="aliasSelectionFieldDefinition"
          [selectionType]="selectionType.AliasCategorySelectionFieldDefinition"
          [initialValue]="
            initialValues[index] ? initialValues[index].category : null
          "
          (formControlEmitter)="setControl($event, 'category', index)"
          (selectedValue)="setCategory($event, index)"
        ></fsx-profile-single-selection>

        <button
          class="icon-button"
          fsxPreventKeyboardEventBubbling
          [ngClass]="{ disabled: aliasFormArray.disabled }"
          (keydown.enter)="delete(index)"
          (click)="delete(index)"
        >
          <div class="form-delete-button"></div>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="
      aliasFormGroup.controls.category?.value &&
      aliasComponentFieldDefinition[index]
    "
  >
    <ng-container
      *ngIf="
        contactType === ContactType.Person && aliasSpecsArray[index].allowPerson
      "
    >
      <fsx-person-name-component
        #personNameField
        *ngIf="aliasComponentFieldDefinition[index].personNameFieldDefinition"
        [personNameComponentSpec]="
          aliasComponentFieldDefinition[index].personNameFieldDefinition
        "
        [resolver]="resolver"
        [initialValues]="personNameInitialValues[index]"
        (formGroupEmitter)="setPersonControl($event, 'person', index)"
      ></fsx-person-name-component>
    </ng-container>

    <ng-container
      *ngIf="
        contactType === ContactType.Organization &&
        aliasSpecsArray[index].allowOrganization
      "
    >
      <fsx-text-component
        #titleField
        *ngIf="aliasComponentFieldDefinition[index].organizationFieldDefinition"
        [caption]="'Title'"
        [width]="'100%'"
        [resolver]="resolver"
        [fieldDefinition]="
          aliasComponentFieldDefinition[index].organizationFieldDefinition
            ?.title
        "
        [initialValue]="
          organizationInitialValues[index]
            ? organizationInitialValues[index].title
            : ''
        "
        (formControlEmitter)="
          setOrganizationControl($event, 'organization', index)
        "
      ></fsx-text-component>
    </ng-container>

    <ng-container
      *ngIf="aliasComponentFieldDefinition[index].addressFieldDefinition"
    >
      <p class="form-title">Address</p>
      <fsx-address-component
        #addressField
        [resolver]="resolver"
        [editMode]="editMode"
        [fieldDefinition]="
          aliasComponentFieldDefinition[index].addressFieldDefinition
        "
        [initialValues]="
          !!addressInitialValues[index] ? addressInitialValues[index] : []
        "
        (formArrayEmitter)="setControl($event, 'addresses', index)"
      ></fsx-address-component>
    </ng-container>

    <ng-container
      *ngIf="aliasComponentFieldDefinition[index].phoneFieldDefinition"
    >
      <p class="form-title">Phone</p>
      <fsx-phone-component
        #phoneField
        [resolver]="resolver"
        [editMode]="editMode"
        [phoneComponentSpec]="
          aliasComponentFieldDefinition[index].phoneFieldDefinition
        "
        [initialValues]="
          !!phoneInitialValues[index] ? phoneInitialValues[index] : []
        "
        (formArrayEmitter)="setControl($event, 'phones', index)"
      ></fsx-phone-component>
    </ng-container>

    <ng-container
      *ngIf="aliasComponentFieldDefinition[index].emailFieldDefinition !== null"
    >
      <p class="form-title">Email</p>
      <fsx-email-component
        #emailField
        [resolver]="resolver"
        [editMode]="editMode"
        [emailComponentSpec]="
          aliasComponentFieldDefinition[index].emailFieldDefinition
        "
        [initialValues]="
          !!emailInitialValues[index] ? emailInitialValues[index] : []
        "
        (formArrayEmitter)="setControl($event, 'emails', index)"
      ></fsx-email-component>
    </ng-container>
  </ng-container>
</form>

<button
  class="icon-button"
  [ngClass]="{ disabled: aliasFormArray.disabled }"
  fsxPreventKeyboardEventBubbling
  *ngIf="
    aliasSelectionFieldDefinition &&
    aliasFormArray.length < aliasFormArray.formArraySpecs.maxAllowed
  "
  (keydown.enter)="addNewAliasForm()"
  (click)="addNewAliasForm()"
>
  <p class="add-new-form">
    <span class="material-icons add-icon"> add_circle_outline </span>
    Add {{ aliasFormArray.length > 0 ? "Another" : "New" }} Alias
  </p>
</button>
