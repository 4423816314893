import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FieldCategory,
  hasValues,
  IdentificationViewModel,
  IdentificationSpec,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import { asyncScheduler } from 'rxjs';
import {
  FormArrayWithModel,
  FormControlWithModel,
} from '../../models/form-control.model';
import {
  IdentificationFormGroup,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../types/form-control.types';
import { FsxBaseComponent } from '../base/base.component';
import {
  FsxProfileSingleSelectionComponent,
  FsxTextComponent,
  ReferenceResolver,
} from '../../../public-api';

@Component({
  selector: 'fsx-identification-component',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss'],
})
export class FsxIdentificationComponent
  extends FsxBaseComponent
  implements OnInit
{
  @Input() identificationComponentSpec!: IdentificationSpec | null;
  @Input() resolver!: ReferenceResolver;
  @Input() idLength: number = 0;
  @Input() initialValues: IdentificationViewModel[] = [];
  @Input() showBarId!: boolean;
  @Input() showOtherId!: boolean;
  @Input() editMode!: boolean;
  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<IdentificationFormGroup>>
  >(true);

  @ViewChildren('idTypeField')
  idTypeFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('idStateField')
  idStateFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('idTextField') idTextFields!: QueryList<FsxTextComponent>;

  public identificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;
  public showAddNewForm = false;
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType;

  ngOnInit(): void {
    const identificationFormGroups = this.initialValues.length
      ? this.initialValues.map(
          () =>
            new FormGroup<IdentificationFormGroup>(
              {} as IdentificationFormGroup
            )
        )
      : [new FormGroup<IdentificationFormGroup>({} as IdentificationFormGroup)];

    this.identificationFormArray = new FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >([...identificationFormGroups], {
      minRequired: this.identificationComponentSpec?.minRequired ?? 0,
      maxAllowed: this.identificationComponentSpec?.maxAllowed ?? 0,
    });

    this.identificationFormArray.valueChanges.subscribe(() => {
      const formGroup = this.identificationFormArray.at(
        this.identificationFormArray.length - 1
      ) as FormGroup<IdentificationFormGroup>;

      if (formGroup) {
        asyncScheduler.schedule(() => {
          // Including old check just in case of any ripple effect this may create.
          let idFormArrLength = this.identificationFormArray.length;
          let maxAllowed = this.identificationComponentSpec?.maxAllowed;
          if (!!maxAllowed) {
            this.showAddNewForm = this.editMode && idFormArrLength < maxAllowed;
          } else {
            this.showAddNewForm = this.editMode
              ? hasValues(formGroup.value)
              : hasValues(formGroup.value) && formGroup.dirty;
          }
        });
      }
    });

    this.formArrayEmitter.emit(this.identificationFormArray);
  }

  public setControl(
    control:
      | FormControlWithModel<SelectionFieldDefinition>
      | FormControlWithModel<TextFieldDefinition>,
    controlName: keyof IdentificationFormGroup,
    index: number
  ): void {
    const formGroup = this.getIdentificationFormGroup(index);
    formGroup.setControl(controlName, control);
  }

  public getIdentificationFormGroup(
    index: number
  ): FormGroup<IdentificationFormGroup> {
    let formGroup = this.identificationFormArray.at(
      index
    ) as FormGroup<IdentificationFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<IdentificationFormGroup>(
        {} as IdentificationFormGroup
      );
    }
    return formGroup;
  }

  public delete(index: number): void {
    if (this.identificationFormArray.disabled) {
      return;
    }

    this.identificationFormArray.removeAt(index);
    this.initialValues.splice(index, 1);

    if (!this.identificationFormArray.controls.length) {
      this.addNewForm();
    }
  }

  public addNewForm(): void {
    if (this.identificationFormArray.enabled) {
      this.identificationFormArray.push(
        new FormGroup<IdentificationFormGroup>({} as IdentificationFormGroup)
      );
    }
  }

  public validate(): void {
    if (this.shouldValidateFormArray(this.identificationFormArray)) {
      if (this.idTypeFields) {
        this.idTypeFields.forEach((fld) => fld.validate());
      }
      if (this.idStateFields) {
        this.idStateFields.forEach((fld) => fld.validate());
      }
      if (this.idTextFields) {
        this.idTextFields.forEach((fld) => fld.validate());
      }
    }
  }

  public textChangedEventHandler(event: string, index: number): void {
    const idFormGroup = this.getIdentificationFormGroup(index);
    if (!!idFormGroup) {
      idFormGroup.controls.identificationKey.setValue(event);
      this.formArrayEmitter.emit(this.identificationFormArray);
    }
  }
}
