import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import {
  Filing,
  FilingState,
  FsxFilingApiService,
  IFilingApiService,
  TRANSACTIONS_EDITOR,
} from '@fsx/fsx-shared';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoneDraftTransactionGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const filingId = route.params['filingId'] as string;
    return this.filingApiService.getFiling(filingId).pipe(
      map((filing: Filing) => {
        if (filing.status === FilingState.Draft) {
          this.router.navigate([TRANSACTIONS_EDITOR, filing.id]);
          return false;
        }
        return true;
      })
    );
  }
}
