import {
  AddressCategory,
  AddressCategorySelectionFieldDefinition,
  AddressProfileSpec,
  AddressSpec,
  AliasCategory,
  BooleanFieldDefinition,
  CurrencyFieldDefinition,
  DateFieldDefinition,
  EmailCategory,
  EmailCategorySelectionFieldDefinition,
  IdentificationCategory,
  IdentificationCategorySelectionFieldDefinition,
  NumberFieldDefinition,
  PhoneCategory,
  PhoneCategorySelectionFieldDefinition,
  ProfileListReference,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
  SelectionDependentFields,
  StringSelectionFieldDefinition,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import { FormControlWithModel } from '../models';

export type FieldDefinition =
  | NumberFieldDefinition
  | BooleanFieldDefinition
  | CurrencyFieldDefinition
  | DateFieldDefinition
  | TextFieldDefinition
  | StringSelectionFieldDefinition;

export type SelectionFieldDefinition =
  | StringSelectionFieldDefinition
  | EmailCategorySelectionFieldDefinition
  | AddressCategorySelectionFieldDefinition
  | PhoneCategorySelectionFieldDefinition
  | IdentificationCategorySelectionFieldDefinition
  | AliasCategorySelectionFieldDefinition;

export interface AliasCategorySelectionFieldDefinition {
  /** @format int32 */
  minRequired: number;

  /** @format int32 */
  maxAllowed: number;
  listReference: ProfileListReference;
  defaultValue: AliasCategory | null;
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export type DropdownOptionsTypes =
  | void
  | EmailCategory
  | AddressCategory
  | PhoneCategory
  | IdentificationCategory
  | AliasCategory;

export type AddressComponentFieldDefinition = AddressSpec & AddressProfileSpec;

export enum SelectionFieldType {
  StringSelectionFieldDefinition = 'StringSelectionFieldDefinition',
  EmailCategorySelectionFieldDefinition = 'EmailCategorySelectionFieldDefinition',
  AddressCategorySelectionFieldDefinition = 'AddressCategorySelectionFieldDefinition',
  PhoneCategorySelectionFieldDefinition = 'PhoneCategorySelectionFieldDefinition',
  IdentificationCategorySelectionFieldDefinition = 'IdentificationCategorySelectionFieldDefinition',
  AliasCategorySelectionFieldDefinition = 'AliasCategorySelectionFieldDefinition',
}

export interface EmailFormGroup {
  address: FormControlWithModel<TextFieldDefinition>;
  category: FormControlWithModel<SelectionFieldDefinition>;
}

export interface IdentificationFormGroup {
  identificationKey: FormControlWithModel<TextFieldDefinition>;
  category: FormControlWithModel<SelectionFieldDefinition>;
  regionKey: FormControlWithModel<SelectionFieldDefinition>;
}

export interface PhoneFormGroup {
  category: FormControlWithModel<SelectionFieldDefinition>;
  fullNumber: FormControlWithModel<TextFieldDefinition>;
}

export interface PersonNameFormGroup {
  prefix: FormControlWithModel<TextFieldDefinition>;
  givenName: FormControlWithModel<TextFieldDefinition>;
  middleName: FormControlWithModel<TextFieldDefinition>;
  surName: FormControlWithModel<TextFieldDefinition>;
  suffix: FormControlWithModel<TextFieldDefinition>;
  fullName: FormControlWithModel<TextFieldDefinition>;
}

export interface AddressFormGroup {
  country: FormControlWithModel<SelectionFieldDefinition>;
  category: FormControlWithModel<SelectionFieldDefinition>;
  addressLine1: FormControlWithModel<TextFieldDefinition>;
  addressLine2: FormControlWithModel<TextFieldDefinition>;
  locality: FormControlWithModel<TextFieldDefinition>;
  postalCode: FormControlWithModel<TextFieldDefinition>;
  administrativeArea: FormControlWithModel<SelectionFieldDefinition>;
}

export interface DocumentFormGroup {
  fileName: FormControlWithModel<TextFieldDefinition>;
  title: FormControlWithModel<TextFieldDefinition>;
  documentType: FormControlWithModel<SelectionFieldDefinition>;
  accessType: FormControlWithModel<SelectionFieldDefinition>;
}

export interface DropdownOption<T> {
  category?: T;
  commonCategory?: string;
  sequence?: number;
  name: string;
  caption: string;
  selected: boolean;
  secondaryCaption?: string;
  tertiaryCaption?: string;
  efmKey?: string;
  icon?: string;
  rating?: number;
  keywords?: KeyWords[];
  itemPath?: string[];
}

export interface FormArraySpecs {
  minRequired: number;
  maxAllowed: number;
}

export interface SidePanelHeaderConfig {
  title: string;
  subtitle?: string;
  titleColor: string;
  titleIcon?: string;
  titleIconColor?: string;
  titleIconPath?: string;
  titlePosition?: string;
  headerBackground?: string;
  exitIcon: boolean;
  exitAction?: () => void;
}

export enum SidePanelButtonStatusEnum {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Focused = 'focused',
}
export interface SidePanelButtonsConfig {
  buttonCaption: string;
  buttonType: string;
  status?: SidePanelButtonStatusEnum;
  action: () => void;
}

export interface SidePanelFooterConfig {
  buttons: SidePanelButtonsConfig[];
  checkboxCaption: string;
}

export interface SearchParameters {
  startsWith: boolean;
  includes: boolean;
}

export interface KeyWords {
  word: string;
  value: number;
}

export class RepresentationGridRow {
  rowIndex: number;
  representation: RequestParticipantRepresentationViewModel;
  participant: RequestParticipantViewModel;

  constructor(
    rowIndex: number,
    representation: RequestParticipantRepresentationViewModel,
    participant: RequestParticipantViewModel
  ) {
    this.rowIndex = rowIndex;
    this.representation = representation;
    this.participant = participant;
  }
}
