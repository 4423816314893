import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFieldDefinition } from '@fsx/fsx-shared';
import moment from 'moment';
import { Moment } from 'moment';
import { FormControlWithModel } from '../../models/form-control.model';
import { FSXFormControlService } from '../../services';

@Component({
  selector: 'fsx-date-component',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class FsxDateComponent implements OnInit {
  @Input() fieldDefinition!: DateFieldDefinition;
  @Input() initialValue!: string;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() fieldType!: string;
  @Input() width!: string;
  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<DateFieldDefinition>
  >();

  private startDate!: Moment;
  private endDate!: Moment;

  public formControl!: FormControlWithModel<DateFieldDefinition>;
  constructor(private readonly fsxFormControlService: FSXFormControlService) {}

  // TODO - time zone support from definition

  ngOnInit(): void {
    this.startDate = moment().startOf('day');
    this.endDate = moment().endOf('day');

    this.formControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      this.fieldType,
      this.initialValue
    );
    this.formControlEmitter.emit(this.formControl);
  }

  public myFilter = (value: Moment | null): boolean => {
    if (value) {
      // no min/max, all dates allowed
      if (
        this.formControl.fieldDefinition.daysToDateRangeStart === 0 &&
        this.formControl.fieldDefinition.daysToDateRangeEnd === 0
      ) {
        return true;
      }

      const minDate = moment(this.startDate).add(
        this.formControl.fieldDefinition.daysToDateRangeStart,
        'd'
      );
      const maxDate = moment(this.endDate).add(
        this.formControl.fieldDefinition.daysToDateRangeEnd,
        'd'
      );

      // only a min
      if (
        this.formControl.fieldDefinition.daysToDateRangeStart !== 0 &&
        this.formControl.fieldDefinition.daysToDateRangeEnd === 0
      ) {
        if (value.isBefore(minDate)) {
          return false;
        }
        return true;
      }

      // only a max
      if (
        this.formControl.fieldDefinition.daysToDateRangeStart === 0 &&
        this.formControl.fieldDefinition.daysToDateRangeEnd !== 0
      ) {
        if (value.isAfter(maxDate)) {
          return false;
        }
        return true;
      }

      // both min and max
      if (value.isBefore(minDate) || value.isAfter(maxDate)) {
        return false;
      }

      return true;
    }

    return false;
  };

  public getErrorMessage(): string {
    const key = 0;
    const value = 1;
    const error = Object.entries({ ...this.formControl.errors }).reverse();

    if (typeof error[0][value] === 'string') {
      return error[0][value];
    }

    if (error[0][key] === 'matDatepickerFilter') {
      return 'The selected day is not available';
    }

    if (error[0][key] === 'matDatepickerParse') {
      return 'Please use the following date format (mm/dd/yyyy)';
    }

    return '';
  }

  public validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
