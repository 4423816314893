import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { DetailsComponent } from './details.component';

@NgModule({
  declarations: [DetailsComponent],
  imports: [FsxUiComponentsModule, CommonModule],
  exports: [DetailsComponent],
})
export class DetailsModule {}
