import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  take,
  tap,
  zip,
} from 'rxjs';
import { ContactsSearchTypeEnum } from '../contacts/contacts.model';
import { RepresentationGridRow } from '../../../../../libs/components/src/lib';
import { FilesUploadedFromAdditionalFieldEventParams } from '../documents/document-form/document-form.component';
import {
  ParticipantSpec,
  CasePartyViewModel,
  RequestParticipantViewModel,
  RequestParticipantRepresentationViewModel,
  CombinedFilingData,
  FilingProfile,
  FilingModeSpec,
  TRANSACTION_ID,
  ParticipantCommonCategory,
  FilingMode,
  ContactSummaryViewModel,
  ParticipantCategory,
  AdditionalFieldValue,
  ParticipantFormMode,
} from '@fsx/fsx-shared';
import {
  FsxAddContactAsParticipantOrchestrationService,
  IAddContactAsParticipantOrchestrationService,
  IAddContactAsParticipantParams,
} from './orchestration-services/add-contact-as-participant-orchestration.service';
import {
  FsxAddDefaultParticipantOrchestrationService,
  IAddDefaultParticipantOrchestrationService,
  IAddParticipantEventParams,
} from './orchestration-services/add-default-participant-orchestration.service';
import {
  FsxAddRepresentationOrchestrationService,
  IAddRepresentationOrchestrationService,
  IAddRepresentationParams,
} from './orchestration-services/add-representation-orchestration.service';
import {
  FsxAddSelectedContactsAsParticipantsOrchestrationService,
  IAddSelectedContactsAsParticipantsOrchestrationService,
} from './orchestration-services/add-selected-contacts-as-participants-orchestration.service';
import {
  FsxAddSelectedContactsAsRepresentationOrchestrationService,
  IAddSelectedContactsAsRepresentationOrchestrationService,
  IAddSelectedContactsAsRepresentativesParams,
} from './orchestration-services/add-selected-contacts-as-representatives-orchestration.service';
import {
  FsxClearParticipantOrchestrationService,
  IClearParticipantEventParams,
  IClearParticipantOrchestrationService,
} from './orchestration-services/clear-participant-orchestration.service';
import {
  FsxClearRepresentationOrchestrationService,
  IClearRepresentationOrchestrationService,
  IClearRepresentationParams,
} from './orchestration-services/clear-representation-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  IRemoveParticipantEventParams,
  IRemoveParticipantOrchestrationService,
} from './orchestration-services/remove-participant-orchestration.service';
import {
  FsxRemoveRepresentationOrchestrationService,
  IRemoveRepresentationEventParams,
  IRemoveRepresentationOrchestrationService,
} from './orchestration-services/remove-representation-orchestration.service';
import {
  IUpdateParticipantParams,
  IUpdateParticipantOrchestrationService,
  FsxUpdateParticipantOrchestrationService,
} from './orchestration-services/update-participant-orchestration.service';
import {
  IUpdateRepresentationParams,
  IUpdateRepresentationOrchestrationService,
  FsxUpdateRepresentationOrchestrationService,
} from './orchestration-services/update-representation-orchestration.service';
import {
  AddParticipantEventParams,
  ContactSelectedEventParams,
  EditParticipantEventParams,
  EditRepresentationEventParams,
  SelectParticipantsEventParams,
  UpdateParticipantEventParams,
} from './parties-grid/parties-grid.component';
import { PartiesGridRow } from './parties-grid/parties-grid.model';
import {
  AttorneySelectedEventParams,
  ContactSummariesSelectedEventParams,
} from './representation-grid/representation-grid.component';
import {
  FsxOpenParticipantFormOrchestrationService,
  IOpenParticipantFormOrchestrationService,
} from '../shared/services/open-participant-form-orchestration.service';
import {
  FsxPanelService,
  IPanelService,
} from '../shared/services/panel.service';

export interface GridVm {
  partiesGridRows: PartiesGridRow[];
  participantSpecs: ParticipantSpec[];
  attorneySpecs: ParticipantSpec[];
}

export interface PartyAndParticipant {
  party: CasePartyViewModel;
  participant: RequestParticipantViewModel;
  partyIndex: number;
}

export interface RepresentationAndParticipant {
  representation: RequestParticipantRepresentationViewModel;
  participant: RequestParticipantViewModel;
}

@Component({
  selector: 'fsx-parties',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss'],
})
export class PartiesComponent implements OnDestroy, OnInit {
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Input() validationFilteredClass!: string;
  @Output() combinedPartiesGridRowsUpdatedEvent = new EventEmitter<
    PartiesGridRow[]
  >();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  private partiesWithParticipants$!: Observable<PartyAndParticipant[]>;

  private representationWithParticipants$!: Observable<
    RepresentationAndParticipant[]
  >;

  filingProfile$!: Observable<FilingProfile>;

  modeSpec!: Observable<FilingModeSpec | null | undefined>;

  participantSpecs$!: Observable<ParticipantSpec[] | undefined>;

  private filingId = this.route.snapshot.params[TRANSACTION_ID];

  private destroy$: Subject<void> = new Subject<void>();

  participantCommonCategory = ParticipantCommonCategory;

  initiatingGridVm$!: Observable<GridVm>;

  additionalGridVm$!: Observable<GridVm>;

  combinedPartiesGridRows$!: Observable<PartiesGridRow[]>;

  protected readonly FilingMode = FilingMode;

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(FsxAddDefaultParticipantOrchestrationService)
    private readonly addDefaultParticipantOrchestrationService: IAddDefaultParticipantOrchestrationService,
    @Inject(FsxAddContactAsParticipantOrchestrationService)
    private readonly addContactAsParticipantOrchestrationService: IAddContactAsParticipantOrchestrationService,
    @Inject(FsxClearParticipantOrchestrationService)
    private readonly clearParticipantOrchestrationService: IClearParticipantOrchestrationService,
    @Inject(FsxRemoveParticipantOrchestrationService)
    private readonly removeParticipantOrchestrationService: IRemoveParticipantOrchestrationService,
    @Inject(FsxAddRepresentationOrchestrationService)
    private readonly addRepresentationOrchestrationService: IAddRepresentationOrchestrationService,
    @Inject(FsxRemoveRepresentationOrchestrationService)
    private readonly removeRepresentationOrchestrationService: IRemoveRepresentationOrchestrationService,
    @Inject(FsxUpdateRepresentationOrchestrationService)
    private readonly updateRepresentationOrchestrationService: IUpdateRepresentationOrchestrationService,
    @Inject(FsxUpdateParticipantOrchestrationService)
    private readonly updateParticipantOrchestrationService: IUpdateParticipantOrchestrationService,
    @Inject(FsxClearRepresentationOrchestrationService)
    private readonly clearRepresentationOrchestrationService: IClearRepresentationOrchestrationService,
    @Inject(FsxAddSelectedContactsAsParticipantsOrchestrationService)
    private readonly addSelectedContactsAsParticipantsOrchestrationService: IAddSelectedContactsAsParticipantsOrchestrationService,
    @Inject(FsxAddSelectedContactsAsRepresentationOrchestrationService)
    private readonly addSelectedContactsAsRepresentationOrchestrationService: IAddSelectedContactsAsRepresentationOrchestrationService,
    @Inject(FsxOpenParticipantFormOrchestrationService)
    private readonly openParticipantFormOrchestrationService: IOpenParticipantFormOrchestrationService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    readonly injector: Injector
  ) {}

  ngOnInit(): void {
    this.partiesWithParticipants$ = this.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        const parties: CasePartyViewModel[] =
          combinedFilingData?.caseRequest?.parties || [];
        const participants: RequestParticipantViewModel[] =
          combinedFilingData?.caseRequest?.participants || [];
        return parties.map((party: CasePartyViewModel, index: number) => {
          const participant: RequestParticipantViewModel = participants.find(
            (p) => p.name === party.participantName
          )!;
          const partyAndParticipant: PartyAndParticipant = {
            party,
            participant,
            partyIndex: index,
          };
          return partyAndParticipant;
        });
      })
    );

    this.representationWithParticipants$ = this.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        const representation: RequestParticipantRepresentationViewModel[] =
          combinedFilingData?.caseRequest?.parties?.flatMap(
            (p: CasePartyViewModel) => p.representation || []
          ) || [];
        const participants: RequestParticipantViewModel[] =
          combinedFilingData?.caseRequest?.participants || [];
        return representation.map(
          (representation: RequestParticipantRepresentationViewModel) => {
            const participant: RequestParticipantViewModel = participants.find(
              (p) => p.name === representation.participantName
            )!;
            const representationAndParticipant: RepresentationAndParticipant = {
              representation,
              participant,
            };
            return representationAndParticipant;
          }
        );
      })
    );

    this.filingProfile$ = this.combinedFilingData$.pipe(
      map((combinedFilingData) => combinedFilingData.filingProfile)
    );

    this.modeSpec = this.combinedFilingData$.pipe(
      map((combinedFilingData) => combinedFilingData.modeSpec)
    );

    this.participantSpecs$ = this.modeSpec.pipe(
      map((modeSpec: FilingModeSpec | null | undefined) => {
        return modeSpec?.participant;
      })
    );

    this.initiatingGridVm$ = this.getGridVm(
      ParticipantCommonCategory.InitiatingParty
    );

    this.additionalGridVm$ = this.getGridVm(
      ParticipantCommonCategory.AdditionalParty
    );

    this.combinedPartiesGridRows$ = combineLatest([
      this.initiatingGridVm$,
      this.additionalGridVm$,
    ]).pipe(
      map(([initiatingGridVm, additionalGridVm]: [GridVm, GridVm]) => {
        const initiatingPartiesGridRows: PartiesGridRow[] =
          initiatingGridVm.partiesGridRows;
        const additionalPartiesGridRows: PartiesGridRow[] =
          additionalGridVm.partiesGridRows;
        const combinedPartiesGridRows: PartiesGridRow[] = [
          ...initiatingPartiesGridRows,
          ...additionalPartiesGridRows,
        ];
        return combinedPartiesGridRows;
      }),
      tap((combinedPartiesGridRows: PartiesGridRow[]) => {
        this.combinedPartiesGridRowsUpdatedEvent.emit(combinedPartiesGridRows);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addParticipantEventHandler(params: AddParticipantEventParams): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addParticipantParams: IAddParticipantEventParams = {
            ...params,
            caseRequest: combinedFilingData.caseRequest,
            filingId: this.filingId,
          };
          this.addDefaultParticipantOrchestrationService.addDefaultParticipant(
            addParticipantParams
          );
        })
      )
      .subscribe();
  }

  contactSelectedEventHandler(params: ContactSelectedEventParams): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addContactAsParticipantParams: IAddContactAsParticipantParams =
            {
              ...params,
              filingId: this.filingId,
              caseRequest: combinedFilingData.caseRequest,
              filingProfile: combinedFilingData.filingProfile,
            };
          this.addContactAsParticipantOrchestrationService.addContactAsParticipant(
            addContactAsParticipantParams
          );
        })
      )
      .subscribe();
  }

  attorneySelectedEventHandler(params: AttorneySelectedEventParams): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addRepresentationParams: IAddRepresentationParams = {
            ...params,
            filingId: this.filingId,
            caseRequest: combinedFilingData.caseRequest,
            participantSpec: params.participantSpec,
            filingProfile: combinedFilingData.filingProfile,
          };
          this.addRepresentationOrchestrationService.addRepresentation(
            addRepresentationParams
          );
        })
      )
      .subscribe();
  }

  contactSummariesSelectedEventHandler(
    params: ContactSummariesSelectedEventParams
  ): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          // TODO Call into orchestration service here...
          const addContactsAsRepresentativesParams: IAddSelectedContactsAsRepresentativesParams =
            {
              ...params,
              participantSpec: params.participantSpec,
              participantCategory: params.participantSpec.participantCategory,
              caseRequest: combinedFilingData.caseRequest,
              filingId: this.filingId,
              filingProfile: combinedFilingData.filingProfile,
            };
          this.addSelectedContactsAsRepresentationOrchestrationService.addSelectedContactsAsRepresentatives(
            addContactsAsRepresentativesParams
          );
        })
      )
      .subscribe();
  }

  removeParticipantEventHandler(partyToRemove: CasePartyViewModel): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const removeParticipantParams: IRemoveParticipantEventParams = {
            partyToRemove,
            caseRequest: combinedFilingData.caseRequest,
            filingId: this.filingId,
          };
          this.removeParticipantOrchestrationService.removeParticipant(
            removeParticipantParams
          );
        })
      )
      .subscribe();
  }

  clearParticipantEventHandler(partyToClear: CasePartyViewModel): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const clearParticipantParams: IClearParticipantEventParams = {
            partyToClear,
            caseRequest: combinedFilingData.caseRequest,
            filingId: this.filingId,
          };
          this.clearParticipantOrchestrationService.clearParticipant(
            clearParticipantParams
          );
        })
      )
      .subscribe();
  }

  editParticipantEventHandler(params: EditParticipantEventParams): void {
    this.openParticipantFormOrchestrationService.openParticipantForm({
      formMode: ParticipantFormMode.EditParticipant,
      participant: params.participant,
      participantCategory: params.participantCategory,
      isRepresentation: false,
    });
  }

  editRepresentationEventHandler(params: EditRepresentationEventParams): void {
    this.openParticipantFormOrchestrationService.openParticipantForm({
      formMode: ParticipantFormMode.EditRepresentation,
      participant: params.participant,
      participantCategory: params.participantCategory!,
      isRepresentation: true,
    });
  }

  public addSelectedContactsAsParticipantsEventHandler(params: {
    selectedContactSummaries: ContactSummaryViewModel[];
    participantCategory: ParticipantCategory;
    participantSpec: ParticipantSpec;
    participantName: string;
  }) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData) => {
          this.addSelectedContactsAsParticipantsOrchestrationService.addSelectedContactsAsParticipants(
            {
              filingId: combinedFilingData.filing.id,
              caseRequest: combinedFilingData.caseRequest,
              contactSummaries: params.selectedContactSummaries,
              participantCategory: params.participantCategory,
              participantSpec: params.participantSpec,
              filingProfile: combinedFilingData.filingProfile,
              participantName: params.participantName,
            }
          );
        })
      )
      .subscribe();
  }

  selectParticipantsEventHandler(params: SelectParticipantsEventParams) {
    this.panelService.openContactsListPanel({
      contactsListConfig: {
        searchType: ContactsSearchTypeEnum.contacts,
        addCallback: (contactSummaries: ContactSummaryViewModel[]) => {
          this.addSelectedContactsAsParticipantsEventHandler({
            ...params,
            selectedContactSummaries: contactSummaries,
          });
        },
      },
      injector: this.injector,
    });
  }

  removeRepresentationEventHandler(params: {
    representationToRemove: RequestParticipantRepresentationViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const removeParticipantParams: IRemoveRepresentationEventParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            representationToRemove: params.representationToRemove,
            partyToRemoveFrom: params.partyToRemoveFrom,
          };
          this.removeRepresentationOrchestrationService.removeParticipant(
            removeParticipantParams
          );
        })
      )
      .subscribe();
  }

  updateRepresentationEventHandler(params: {
    attorneyParticipantSpec: ParticipantSpec | null;
    attorneyParticipantCategory: ParticipantCategory | null;
    caseParty: CasePartyViewModel;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const updateRepresentationParams: IUpdateRepresentationParams = {
            ...params,
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            caseParty: params.caseParty,
            representation: params.representation,
            participantCategory: params.attorneyParticipantCategory,
            additionalFields: params.additionalFields,
          };
          this.updateRepresentationOrchestrationService.updateRepresentation(
            updateRepresentationParams
          );
        })
      )
      .subscribe();
  }

  clearRepresentationEventHandler(caseParty: CasePartyViewModel) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const clearRepresentationParams: IClearRepresentationParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            caseParty: caseParty,
          };
          this.clearRepresentationOrchestrationService.clearRepresentation(
            clearRepresentationParams
          );
        })
      )
      .subscribe();
  }

  updateParticipantEventHandler(params: UpdateParticipantEventParams) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const updateParticipantParams: IUpdateParticipantParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            partyIndex: params.partyIndex,
            participantCategory: params.participantCategory,
            additionalFieldValues: params.additionalFieldValues,
          };
          this.updateParticipantOrchestrationService.updateParticipant(
            updateParticipantParams
          );
        })
      )
      .subscribe();
  }

  getPartiesWithParticipants(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<PartyAndParticipant[]> {
    return this.partiesWithParticipants$.pipe(
      map((partiesWithParticipants: PartyAndParticipant[]) => {
        return partiesWithParticipants.filter((obj) => {
          return (
            obj.party.participantCategory?.commonCategory ===
            participantCommonCategory
          );
        });
      })
    );
  }

  getRepresentationWithParticipants(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<RepresentationAndParticipant[]> {
    return this.representationWithParticipants$.pipe(
      map((representationWithParticipants: RepresentationAndParticipant[]) => {
        return representationWithParticipants.filter((obj) => {
          return (
            obj.representation.participantCategory?.commonCategory ===
            participantCommonCategory
          );
        });
      })
    );
  }

  getParticipantSpecs(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<ParticipantSpec[] | undefined> {
    return this.participantSpecs$.pipe(
      map((participantSpecs: ParticipantSpec[] | undefined) => {
        return participantSpecs?.filter((pSpec: ParticipantSpec) => {
          return (
            pSpec.participantCategory.commonCategory ===
            participantCommonCategory
          );
        });
      })
    );
  }

  getParticipantSpecsTruthy(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<ParticipantSpec[]> {
    return this.getParticipantSpecs(participantCommonCategory).pipe(
      filter((participantSpecs: ParticipantSpec[] | undefined) => {
        return !!participantSpecs;
      }),
      map((participantSpecs: ParticipantSpec[] | undefined) => {
        return participantSpecs as ParticipantSpec[];
      })
    );
  }

  validationFilteredEventHandler(filteredClass: string): void {
    this.validationFilteredClass = filteredClass;
  }

  private getPartiesGridRows(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<PartiesGridRow[]> {
    return zip([
      this.getPartiesWithParticipants(participantCommonCategory),
      this.representationWithParticipants$,
    ]).pipe(
      map(
        ([partiesWithParticipants, allRepresentationWithParticipants]: [
          PartyAndParticipant[],
          RepresentationAndParticipant[]
        ]) => {
          return partiesWithParticipants.map(
            (pObj: PartyAndParticipant, pIndex: number) => {
              const representationWithParticipants =
                allRepresentationWithParticipants.filter((rObj) => {
                  const partyRepresentationIds: string[] =
                    pObj.party.representation?.map((r) => r.participantName) ||
                    [];
                  return (
                    partyRepresentationIds.indexOf(rObj.participant.name) > -1
                  );
                });

              const representationGridRows = representationWithParticipants.map(
                (rObj: RepresentationAndParticipant, rIndex: number) => {
                  return new RepresentationGridRow(
                    rIndex,
                    rObj.representation,
                    rObj.participant
                  );
                }
              );
              return new PartiesGridRow(
                pIndex,
                pObj.party,
                pObj.participant,
                representationGridRows,
                pObj.partyIndex
              );
            }
          );
        }
      )
    );
  }

  private getGridVm(
    participantCommonCategory: ParticipantCommonCategory
  ): Observable<GridVm> {
    return combineLatest([
      this.getPartiesGridRows(participantCommonCategory),
      this.getParticipantSpecsTruthy(participantCommonCategory),
      this.getParticipantSpecsTruthy(ParticipantCommonCategory.Attorney),
    ]).pipe(
      map(
        ([partiesGridRows, participantSpecs, attorneySpecs]: [
          PartiesGridRow[],
          ParticipantSpec[],
          ParticipantSpec[]
        ]) => {
          return { partiesGridRows, participantSpecs, attorneySpecs };
        }
      )
    );
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedFromAdditionalFieldEventParams
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }
}
