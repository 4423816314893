import { Injectable, Type } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  NavigationStart,
  ResolveEnd,
  Router,
} from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppInsightsConfig, LogEvent } from '@fsx/fsx-shared';
import { SeverityLevel } from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private _appInsights!: ApplicationInsights;
  private appInsights$!: BehaviorSubject<ApplicationInsights>;
  private startupQueueErrors: Error[] = [];
  private startupQueueEvents: LogEvent[] = [];

  public get appInsights(): Observable<ApplicationInsights | null> {
    if (!this.appInsights$?.value) {
      return of(null);
    }
    return this.appInsights$.asObservable();
  }

  public constructor(protected readonly router: Router) {}

  loadAppInsights({ instrumentationKey }: AppInsightsConfig): void {
    if (
      this._appInsights ||
      !instrumentationKey ||
      instrumentationKey === '#{ApplicationInsights__InstrumentationKey}#'
    ) {
      return;
    }

    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        enableAjaxPerfTracking: true,
        enableDebugExceptions: true,
        enablePerfMgr: true,
      },
      queue: [
        () => {
          this._appInsights.addTelemetryInitializer((envelope) => {
            if (envelope.tags) {
              envelope.tags['ai.cloud.role'] = 'Filing Flow UI';
            }
          });
        },
      ],
    });
    console.warn('app insights initiated');
    this.appInsights$ = new BehaviorSubject<ApplicationInsights>(
      this._appInsights
    );
    this._appInsights.loadAppInsights();
    this.appInsights$.next(this._appInsights);
    this._appInsights.trackPageView();
    this.appInsights$.next(this._appInsights);
    this.listenOnEvents();
  }

  public listenOnEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this._appInsights?.startTrackEvent('PAGE LOAD TIME');
      }

      if (event instanceof ResolveEnd) {
        const activatedComponent = this.getActivatedComponent(event.state.root);

        if (activatedComponent) {
          this.logPageView(event.urlAfterRedirects);
        }
      }

      if (event instanceof NavigationEnd) {
        this._appInsights?.stopTrackEvent('PAGE LOAD TIME');
      }
    });
  }

  logException(exception: Error): void {
    if (!this._appInsights) {
      this.startupQueueErrors.push(exception);
    } else {
      this._appInsights.trackException({
        exception,
        severityLevel: SeverityLevel.Warning,
      });

      if (this.startupQueueErrors.length > 0) {
        const firstError: Error = this.startupQueueErrors.pop() as Error;
        this.logException(firstError);
      }
    }
  }

  logEvent({ name, properties }: LogEvent): void {
    if (!this._appInsights) {
      this.startupQueueEvents.push({ name, properties });
    } else {
      this._appInsights.trackEvent({ name: name }, properties);

      if (this.startupQueueEvents.length > 0) {
        const firstEvent: LogEvent = this.startupQueueEvents.pop() as LogEvent;
        this.logEvent(firstEvent);
      }
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: unknown }
  ) {
    this._appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logTrace(message: string, properties?: { [key: string]: unknown }) {
    this._appInsights.trackTrace({ message: message }, properties);
  }

  public setUserId(userId: string) {
    if (!userId) {
      return;
    }
    this._appInsights?.setAuthenticatedUserContext(userId);
  }

  public clearUserId() {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(uri?: string, name?: string) {
    this._appInsights?.trackPageView({
      uri,
      name,
    });
  }

  private getActivatedComponent(
    snapshot: ActivatedRouteSnapshot
  ): Type<unknown> | string | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
