<div class="fsx-side-panel-footer-component" *ngIf="footerConfig">
  <div class="checkbox-container">
    <mat-checkbox
      #checkbox
      color="primary"
      *ngIf="footerConfig.checkboxCaption"
    ></mat-checkbox>
    <label *ngIf="footerConfig.checkboxCaption" (click)="clickCheckbox()">{{
      footerConfig.checkboxCaption
    }}</label>
  </div>

  <div id="buttons-container" #buttons>
    <ng-container *ngFor="let button of footerConfig.buttons">
      <button
        (click)="button.action()"
        (keydown.tab)="removeFocus(button)"
        [ngClass]="[button.buttonType || '', button.status || '']"
      >
        {{ button.buttonCaption }}
      </button>
    </ng-container>
  </div>
</div>
