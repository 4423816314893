<div
  class="fsx-date-component"
  [ngStyle]="{
    width: width,
    display: formControl.fieldDefinition.visible ? 'block' : 'none'
  }"
  [ngClass]="{ invalid: formControl.invalid && formControl.touched }"
>
  <ng-container *ngIf="!formControl.fieldDefinition.readOnly; else readOnly">
    <label
      >{{ caption }}
      <span class="required" *ngIf="formControl.fieldDefinition.required"
        >*</span
      >
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <input
      matInput
      [placeholder]="hint || 'mm/dd/yy'"
      [formControl]="formControl"
      [matDatepickerFilter]="myFilter"
      [matDatepicker]="picker"
      [ngClass]="{
        invalid: formControl.invalid && formControl.touched
      }"
    />
    <div class="picker-container">
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        [disableRipple]="true"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <p class="error-message" *ngIf="formControl.invalid && formControl.touched">
      {{ getErrorMessage() }}
    </p>
  </ng-container>

  <ng-template #readOnly>
    <p class="readonly-text">
      {{ formControl.value | date: "MM/dd/yyyy" }}
    </p>
    <label class="readonly-label">{{ caption }}</label>
  </ng-template>
</div>
