<!-- START: Progress Container -->
<div
  class="progress-container"
  fxFill
  fxLayout="column"
  fxLayoutAlign="bottom start"
  *ngIf="pollingState !== PollingState.None"
>
  <!-- START: Progress Content -->
  <ng-container [ngSwitch]="pollingState">
    <!-- START: In Progress State -->
    <ng-container *ngSwitchCase="PollingState.InProgress">
      <mat-progress-bar
        class="submit-progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
      <div fxFlex fxLayout="column" fxFill class="header">
        <div fxFlex="none" class="main">Submission in Progress</div>
        <div fxFlex="none" class="sub">Please wait while we get a response</div>
      </div>
    </ng-container>
    <!-- END: In Progress State -->

    <!-- START: Generic Error State -->
    <ng-container *ngSwitchCase="PollingState.ServerError">
      <div fxFlex fxLayout="column" fxFill class="header failed">
        <div
          fxFlex="none"
          class="main"
          fxLayout="row"
          fxLayoutGap="2px"
          fxLayoutAlign="center center"
        >
          <mat-icon fxFlex="none" class="material-symbols-outlined"
            >warning</mat-icon
          >
          <span fxFlex="none">An Error Has Occurred</span>
        </div>
        <div fxFlex="none" class="sub"><span>Please try again.</span></div>
      </div>
    </ng-container>
    <!-- END: In Progress State -->

    <!-- START: Failed State -->
    <ng-container *ngSwitchCase="PollingState.UserError">
      <div fxFlex fxLayout="column" fxFill class="header failed">
        <div
          fxFlex="none"
          class="main"
          fxLayout="row"
          fxLayoutGap="2px"
          fxLayoutAlign="center center"
        >
          <mat-icon fxFlex="none" class="material-symbols-outlined"
            >error</mat-icon
          >
          <span fxFlex="none">Additional Information Required</span>
        </div>
        <div fxFlex="none" class="sub">
          <span>Please review the indicated tabs</span>
        </div>
      </div>
    </ng-container>
    <!-- END: Failed State -->
  </ng-container>
  <!-- END: Progress Content -->
</div>
<!-- END: Progress Container -->
