import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const FSX_DATE_FORMAT = {
  parse: {
    dateInput: 'l',
  },
  display: {
    dateA11yLabel: 'LL',
    dateInput: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};

export class CustomFSXDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}
