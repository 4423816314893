import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  FilingModeSpec,
  CasePartyViewModel,
  RepresentationSpec,
  IValidatable,
  RequestParticipantRepresentationViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxAdditionalFieldsValidationService,
  IAdditionalFieldsValidationService,
} from './additional-fields-validation.service';
import {
  FsxSingleParticipantValidationService,
  ISingleParticipantValidationService,
} from './single-participant-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxRepresentationValidationService =
  new InjectionToken<IRepresentationValidationService>(
    'FsxRepresentationValidationService'
  );

export interface IRepresentationValidationService {
  validateRepresentations(
    party: CasePartyViewModel | null | undefined,
    spec: RepresentationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
    caseRequest: CaseRequestViewModel
  ): boolean;

  validateRepresentation(
    representation:
      | RequestParticipantRepresentationViewModel
      | null
      | undefined,
    spec: RepresentationSpec | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class RepresentationValidationService
  implements IRepresentationValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxAdditionalFieldsValidationService)
    private readonly additionalFieldsValidationService: IAdditionalFieldsValidationService,
    @Inject(FsxSingleParticipantValidationService)
    private readonly singleParticipantValidationService: ISingleParticipantValidationService
  ) {}

  public validateRepresentations(
    party: CasePartyViewModel | null | undefined,
    spec: RepresentationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec || !party) {
      return true;
    }

    if (modeSpec.allowFilingBySelfRepresentedUsers && party.isSelfRepresented) {
      party.isRepresentationValid = true;
      return true;
    }

    const representations:
      | RequestParticipantRepresentationViewModel[]
      | null
      | undefined = party.representation ?? [];

    if (representations.length < spec.minRequired) {
      party.isRepresentationValid = false;
      return this.validationHelperService.markItemAsInvalid(party, scope);
    }

    if (representations.length > spec.maxAllowed) {
      party.isRepresentationValid = false;
      return this.validationHelperService.markItemAsInvalid(party, scope);
    }

    const seenParticipantNames = new Set<string>();
    for (let index = 0; index < representations.length; index++) {
      const representation = representations[index];

      if (
        !seenParticipantNames.add(
          representation.participantName.toLocaleLowerCase()
        )
      ) {
        party.isRepresentationValid = false;
        return this.validationHelperService.markItemAsInvalid(
          representation,
          scope
        );
      }

      if (
        !this.validateRepresentation(
          representation,
          spec,
          party.caseId,
          scope,
          filingProfile,
          modeSpec,
          caseRequest
        )
      ) {
        party.isRepresentationValid = false;
        party.isValid = false;
        return this.validationHelperService.markItemAsInvalid(
          representation,
          scope
        );
      }
    }

    party.isRepresentationValid = true;

    return true;
  }

  public validateRepresentation(
    representation:
      | RequestParticipantRepresentationViewModel
      | null
      | undefined,
    spec: RepresentationSpec | null | undefined,
    caseId: string | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
    caseRequest: CaseRequestViewModel
  ): boolean {
    if (!spec || !representation) {
      return true;
    }

    representation.isValid = true;

    if (!representation.participantName) {
      // representation is required
      representation.isValid = false;
      return false;
    }

    const participant = caseRequest.participants?.find(
      (p) => p.name === representation.participantName
    );

    if (!participant) {
      // representation not among the participants
      representation.isValid = false;
      return false;
    }

    // const party = this.caseRequest.parties?.find(p => p.participantName === participant.name);
    const participantSpec = modeSpec.participant.find(
      (spec) =>
        spec.participantCategory.name ===
        representation.participantCategory?.name
    );
    if (
      !this.singleParticipantValidationService.validateSingleParticipant(
        participant,
        participantSpec,
        scope,
        filingProfile,
        caseRequest
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(
        representation,
        scope
      );
    }

    if (
      !this.additionalFieldsValidationService.validateAdditionalFields(
        representation.additionalFieldValues,
        spec.additionalFields,
        caseId,
        scope,
        filingProfile,
        caseRequest
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(
        representation,
        scope
      );
    }

    representation.isValid = true;

    return true;
  }
}
