import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CreateDocumentInfoParams,
  DocumentInfoCreatedParams,
  UploadFileParams,
  CreateDocumentInfoService,
  CaseRequestViewModel,
  RequestDocumentViewModel,
  ProfileRuleCheckResult,
  CombinedFilingData,
  ICaseRequestUpdateService,
  IUploadFileService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxUploadFileService,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { FilingProfileRuleCheckService } from 'projects/libs/shared/src/lib/services/filings/filing-profile-rule-check.service';
import {
  Subject,
  Observable,
  switchMap,
  from,
  mergeMap,
  filter,
  forkJoin,
  withLatestFrom,
  tap,
} from 'rxjs';
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from '../../filing-editor/services/filing-editor-events.service';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxUploadFilesOrchestrationService =
  new InjectionToken<IUploadFilesOrchestrationService>(
    'FsxUploadFilesOrchestrationService'
  );

export class DocumentAndFile {
  requestDocument: RequestDocumentViewModel;
  uploadedFile: IUploadedFile;

  constructor(
    requestDocument: RequestDocumentViewModel,
    uploadedFile: IUploadedFile
  ) {
    this.requestDocument = requestDocument;
    this.uploadedFile = uploadedFile;
  }
}

export interface UploadFilesParams {
  documentsAndFiles: DocumentAndFile[];
}

export interface IUploadFilesOrchestrationService {
  uploadFilesStream$: Observable<CaseRequestViewModel>;
  uploadFiles(params: UploadFilesParams): void;
  cancelUpload(requestDocument: RequestDocumentViewModel): void;
}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class UploadFilesOrchestrationService
  implements IUploadFilesOrchestrationService
{
  private uploadFilesParams$$ = new Subject<UploadFilesParams>();

  private cancelUpload$$ = new Subject<RequestDocumentViewModel>();

  uploadFilesStream$: Observable<CaseRequestViewModel> =
    this.uploadFilesParams$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      mergeMap(
        ([uploadFilesParams, combinedFilingData]: [
          UploadFilesParams,
          CombinedFilingData
        ]) => {
          const { documentsAndFiles } = uploadFilesParams;
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;

          const caseRequestBackup = JSON.parse(
            JSON.stringify(caseRequest)
          ) as CaseRequestViewModel;
          return from(documentsAndFiles).pipe(
            mergeMap((documentAndFile: DocumentAndFile) => {
              const { requestDocument, uploadedFile } = documentAndFile;

              const isAllowedFileType$: Observable<ProfileRuleCheckResult> =
                this.filingProfileRuleCheckService.isAllowedFileType({
                  filingId,
                  combinedFilingData,
                  uploadedFile,
                  requestDocument,
                });

              const isSmallerThanMaxFileSize$: Observable<ProfileRuleCheckResult> =
                this.filingProfileRuleCheckService.isSmallerThanMaxFileSize({
                  filingId,
                  combinedFilingData,
                  uploadedFile,
                  requestDocument,
                });

              const filingProfileRuleChecks$ = forkJoin([
                isAllowedFileType$,
                isSmallerThanMaxFileSize$,
              ]).pipe(
                filter((profileRuleCheckResults: ProfileRuleCheckResult[]) => {
                  const failedProfileRuleChecks: ProfileRuleCheckResult[] =
                    profileRuleCheckResults.filter((result) => !result.passed);
                  return failedProfileRuleChecks.length === 0;
                })
              );

              return filingProfileRuleChecks$.pipe(
                mergeMap(() => {
                  const { requestDocument, uploadedFile } = documentAndFile;
                  const createDocumentInfoParams: CreateDocumentInfoParams = {
                    filingId,
                    uploadedFile,
                  };
                  return this.createDocumentInfoService
                    .createDocumentInfo(createDocumentInfoParams)
                    .pipe(
                      switchMap(
                        (
                          documentInfoCreatedParams: DocumentInfoCreatedParams
                        ) => {
                          const { documentInfo } = documentInfoCreatedParams;
                          // Here we update the original RequestDocument id with the newly created DocumentInfo id.
                          // This is how we keep the RequestDocument married to the DocumentInfo which ensures we get
                          // the upload status (from DocumentInfo) on the grid (for the RequestDocument).
                          const documentInfoId: string = documentInfo.id;
                          const name: string =
                            requestDocument.name || documentInfo.displayName;
                          const title: string =
                            requestDocument.title || documentInfo.displayName;
                          const fileName: string =
                            requestDocument.fileName ||
                            documentInfo.displayName;
                          const partialRequestDocument: Partial<RequestDocumentViewModel> =
                            {
                              ...requestDocument,
                              id: documentInfoId,
                              name,
                              title,
                              fileName, // Also updating name, title and fileName unless already set on requestDocument
                            };
                          return this.caseRequestBuilderService
                            .updateRequestDocument({
                              caseRequest,
                              requestDocument,
                              partialRequestDocument,
                              combinedFilingData,
                            })
                            .pipe(
                              switchMap(() => {
                                const uploadDocumentParams: UploadFileParams = {
                                  filingId,
                                  documentId: documentInfoId,
                                  uploadedFile,
                                };
                                this.filingEditorEventService.dispatchFileUploadStartedEvent(
                                  { filingId, caseRequest }
                                );
                                return this.caseRequestUpdateService
                                  .optimisticPutOrRestore(
                                    filingId,
                                    caseRequest,
                                    caseRequestBackup
                                  )
                                  .pipe(
                                    mergeMap(() => {
                                      return this.uploadFileService
                                        .uploadFile(uploadDocumentParams)
                                        .pipe(
                                          switchMap(() => {
                                            return this.caseRequestUpdateService
                                              .optimisticPutOrRestore(
                                                filingId,
                                                caseRequest,
                                                caseRequestBackup
                                              )
                                              .pipe(
                                                tap(() => {
                                                  this.validateDocumentsOrchestrationService.validateDocuments();
                                                })
                                              );
                                          })
                                        );
                                    })
                                  );
                              })
                            );
                        }
                      )
                    );
                })
              );
            })
          );
        }
      )
    );

  constructor(
    @Inject(FsxUploadFileService)
    private readonly uploadFileService: IUploadFileService,
    private readonly createDocumentInfoService: CreateDocumentInfoService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    private readonly filingProfileRuleCheckService: FilingProfileRuleCheckService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService
  ) {}

  uploadFiles(params: UploadFilesParams): void {
    this.uploadFilesParams$$.next(params);
  }

  cancelUpload(requestDocument: RequestDocumentViewModel): void {
    this.cancelUpload$$.next(requestDocument);
  }
}
