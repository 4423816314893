import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ProblemDetails } from '@fsx/fsx-shared';
import { Observable, Subject, map, switchMap } from 'rxjs';
import {
  FsxFilingFeesGetterService,
  IFilingFeesGetterService,
} from '../filing-fees-getter.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the ILoadFilingFeesOrchestrationService to use at runtime.
 */
export const FsxLoadFilingFeesOrchestrationService =
  new InjectionToken<ILoadFilingFeesOrchestrationService>(
    'FsxLoadFilingFeesOrchestrationService'
  );

/**
 * A blueprint for an orchestration service that handles the loading of Filing Fees data
 * for a given filing id
 */
export interface ILoadFilingFeesOrchestrationService {
  loadFilingFees$: Observable<void>;
  loadForFilingId(filingId: string, suppressErrors: boolean): void;
}

/**
 * The error object returned by the GetFees call, which contains the
 * ProblemDetails and ValidationResult objects.
 */
export interface GetFeesError extends Error {
  error: ProblemDetails;
}

/**
 * A concrete implementation of an orchestration service that handles the loading of
 * Filing Fees data for a given filing id.
 */
@Injectable()
export class LoadFilingFeesOrchestrationService
  implements ILoadFilingFeesOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private loadForFilingId$$ = new Subject<{
    filingId: string;
    suppressErrors: boolean;
  }>();

  /**
   * The orchestration steps needed to load and set the filing fees data
   */
  loadFilingFees$: Observable<void> = this.loadForFilingId$$.pipe(
    switchMap((params: { filingId: string; suppressErrors: boolean }) => {
      return this.filingFeesGetterService
        .getFilingFees(params.filingId, params.suppressErrors)
        .pipe(
          map(() => {
            return;
          })
        );
    })
  );

  /**
   *
   * @param filingFeesGetterService The worker service, which handles the retrieval of fees.
   */
  public constructor(
    @Inject(FsxFilingFeesGetterService)
    private readonly filingFeesGetterService: IFilingFeesGetterService
  ) {}

  /**
   * A method to allow the orchestration to be triggered from components
   * @param filingId
   */
  loadForFilingId(filingId: string, suppressErrors = false): void {
    this.loadForFilingId$$.next({ filingId, suppressErrors });
  }
}
