import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CasePartyViewModel,
  CaseRequestViewModel,
  CombinedFilingData,
  FieldCategory,
  FilingProfile,
  NamedListItem,
  ParticipantFieldDefinition,
  RequestDocumentParticipant,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FormControlWithoutModel,
  FsxBasicSingleSelectionComponent,
  ParticipantTableComponent,
  ReferenceResolver,
  SelectionFieldType,
} from '@fsx/ui-components';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { DocumentsGridRow } from './participant.model';

@Component({
  selector: 'fsx-participant-component',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss'],
})
export class FsxParticipantComponent implements OnInit, OnChanges {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() participantFieldDefinition!: ParticipantFieldDefinition;
  @Input() width!: string;
  @Input() validate$!: Observable<Moment | null | undefined>;
  @Input() resolver!: ReferenceResolver;
  @Input() addlFieldSpec!: AdditionalFieldSpec[];
  @Input() initialValues!: RequestDocumentParticipant[] | undefined;
  @Output() selectedParticipantEvent = new EventEmitter<
    RequestDocumentParticipant[]
  >();
  @Output() removeParticipantEvent =
    new EventEmitter<RequestParticipantViewModel>();
  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>(
    true
  );

  @ViewChild('selectionComponent')
  selectionComponent!: FsxBasicSingleSelectionComponent;
  @ViewChildren('participantField')
  participantFields!: QueryList<ParticipantTableComponent>;

  filteredDropdownOptions: DropdownOption<void>[] = [];
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  public additionalFieldValues: AdditionalFieldValue[] = [];
  public inputDropdownOptions: DropdownOption<void>[] = [];
  public partyDropdownOptions: DropdownOption<void>[] = [];
  public representationDropdownOptions: DropdownOption<void>[] = [];
  public filteredParties: CasePartyViewModel[] = [];
  public representations: RequestParticipantRepresentationViewModel[] = [];
  public filteredRepresentations: RequestParticipantRepresentationViewModel[] =
    [];
  public selectedParticipants: RequestDocumentParticipant[] = [];
  public selectedParticipantsViewModel: RequestParticipantViewModel[] = [];
  minRequired!: number;
  maxAllowed!: number;
  additionalFieldSpecs!: AdditionalFieldSpec[] | null;
  required!: boolean;

  ngOnInit(): void {
    this.setInputDropdownOptions(
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest
    );
    this.setFilteredDropdownOptions();

    this.minRequired = this.participantFieldDefinition?.minRequired || 0;
    this.maxAllowed = this.participantFieldDefinition?.maxAllowed || 99;
    this.additionalFieldSpecs =
      this.participantFieldDefinition?.additionalFields || null;
    this.required = this.selectedParticipants.length < this.minRequired;
    if (this.initialValues) {
      this.selectedParticipants = this.initialValues;
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
    }
  }

  ngOnChanges(): void {
    this.required = this.selectedParticipants.length < this.minRequired;
    this.setInputDropdownOptions(
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest
    );
    this.setFilteredDropdownOptions();
  }
  private setInputDropdownOptions(
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ) {
    // Set the AdditionalList
    const additionalList = filingProfile.additionalLists.find((list) => {
      return (
        list.name ===
        this.participantFieldDefinition.allowedParticipantCategoriesList
          .additionalListName
      );
    });

    this.representations = [];
    if (caseRequest.parties) {
      caseRequest.parties.forEach((party) => {
        if (party.representation) {
          party.representation.forEach((rep) => {
            this.representations.push(rep);
          });
        }
      });
    }

    // Filter the participants and representations by the looked up asToAdditionalList allowed category names
    this.filteredParties =
      caseRequest.parties?.filter((party: CasePartyViewModel) => {
        const allowedNames: string[] =
          additionalList?.items.map(
            (namedListItem: NamedListItem) => namedListItem.name
          ) || [];
        const isAllowedCategoryName: boolean = allowedNames.some(
          (name: string) => name === party.participantCategory?.name
        );
        return isAllowedCategoryName;
      }) ?? [];

    this.filteredRepresentations = this.representations.filter((rep) => {
      const allowedNames: string[] =
        additionalList?.items.map(
          (namedListItem: NamedListItem) => namedListItem.name
        ) || [];
      const isAllowedCategoryName: boolean = allowedNames.some(
        (name: string) => name === rep.participantCategory?.name
      );
      return isAllowedCategoryName;
    });

    // Set dropdown options
    this.partyDropdownOptions = this.filteredParties?.map((p) => {
      const dropdownOption: DropdownOption<void> = {
        name: p.participantName,
        caption: p.caption,
        selected: false,
      };
      return dropdownOption;
    });

    this.representationDropdownOptions = this.filteredRepresentations?.map(
      (p) => {
        const dropdownOption: DropdownOption<void> = {
          name: p.participantName,
          caption: p.caption,
          selected: false,
        };
        return dropdownOption;
      }
    );

    this.inputDropdownOptions = this.partyDropdownOptions.concat(
      this.representationDropdownOptions
    );
  }

  private setFilteredDropdownOptions() {
    this.filteredDropdownOptions = this.inputDropdownOptions.filter((opt) => {
      const isSelected: boolean = !this.selectedParticipants.some(
        (sp: RequestDocumentParticipant) => sp.participantName === opt.name
      );
      return isSelected;
    });
  }

  onParticipantSelected(
    value: string,
    addlFieldValues: AdditionalFieldValue[]
  ) {
    if (!!value) {
      const rdp: RequestDocumentParticipant = {
        participantName: value,
        additionalFieldValues: addlFieldValues,
      };
      this.updateSelectedParticipantsArray(this.selectedParticipants, rdp);
      this.selectedParticipantsViewModel = [];
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
      this.selectedParticipantEvent.emit(this.selectedParticipants);

      if (this.selectionComponent) {
        this.selectionComponent.clearSelection();
      }
    }
  }

  public updateSelectedParticipantsArray(
    array: RequestDocumentParticipant[],
    element: RequestDocumentParticipant
  ) {
    const i = array.findIndex(
      (_element) => _element.participantName === element.participantName
    );
    if (i > -1) array[i] = element;
    else array.push(element);
  }

  public updateSelectedParticipantsViewModelArray(
    participants: RequestDocumentParticipant[],
    participantsViewModel: RequestParticipantViewModel[]
  ) {
    participants.forEach((participant) => {
      const pvm = this.combinedFilingData.caseRequest.participants?.find(
        (pt) => {
          return participant.participantName === pt.name;
        }
      );
      if (pvm) {
        participantsViewModel.push(pvm);
      }
    });
  }

  clearParticipantEventHandler(
    participantToClear: RequestParticipantViewModel
  ) {
    this.selectedParticipants =
      this.selectedParticipants.filter((rp) => {
        return rp.participantName !== participantToClear.name;
      }) ?? [];
    this.selectedParticipantsViewModel = [];
    if (this.selectedParticipants) {
      this.updateSelectedParticipantsViewModelArray(
        this.selectedParticipants,
        this.selectedParticipantsViewModel
      );
    }
    this.selectedParticipantEvent.emit(this.selectedParticipants);
  }

  setAdditionalFieldValues(
    addlFields: AdditionalFieldValue[],
    participant: RequestParticipantViewModel
  ) {
    this.onParticipantSelected(participant.name, addlFields);
  }

  public validate(): void {
    if (this.selectionComponent) {
      this.selectionComponent.validate();
    }
    if (this.participantFields) {
      this.participantFields.forEach((fld) => fld.validate());
    }
  }
}
