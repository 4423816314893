import {
  CurrencyFieldDefinition,
  DateFieldDefinition,
  NumberFieldDefinition,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import { FieldDefinition } from '../types';

type RequiredTypes =
  | NumberFieldDefinition
  | CurrencyFieldDefinition
  | TextFieldDefinition
  | DateFieldDefinition;

export function hasRequired(
  fieldDefinition: FieldDefinition
): fieldDefinition is RequiredTypes {
  return (fieldDefinition as RequiredTypes).required !== undefined;
}
