import { Component, Inject } from '@angular/core';
import {
  FsxFilingTabsService,
  IFilingTabsService,
  TransactionTab,
} from '@fsx/fsx-shared';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'fsx-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent {
  public workspaceTabCount$: Observable<number> =
    this.filingTabsService.filingTabs$.pipe(
      map((tabs: TransactionTab[]) => {
        return tabs.length;
      })
    );

  constructor(
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService
  ) {}
}
