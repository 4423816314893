import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  QueryList,
  ViewChildren,
  Inject,
} from '@angular/core';
import {
  FilesUploadedEventParams,
  FormControlWithModel,
  FsxAdditionalFieldsComponent,
  FsxTextBoxComponent,
  FsxTextComponent,
} from '@fsx/ui-components';
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  filter,
  of,
  switchMap,
  takeUntil,
  tap,
  take,
} from 'rxjs';
import { FsxReferenceResolver } from '../shared/resolvers/list-reference.resolver';
import { DocumentInfoAndUploadedFile } from '../documents/services/upload-additional-field-files-orchestration.service';
import {
  FsxUpdateCaseRequestOrchestrationService,
  IUpdateCaseRequestOrchestrationService,
  IUpdateCaseRequestParams,
} from '../parties/orchestration-services/update-case-request-orchestration.service';
import { FilesUploadedFromAdditionalFieldEventParams } from '../documents/document-form/document-form.component';
import {
  CombinedFilingData,
  DocumentInfo,
  AdditionalFieldValue,
  AdditionalFieldSpec,
  RequestCaseViewModel,
  Filing,
  DataAttribute,
  TextFieldDefinition,
  CaseRequestViewModel,
  CurrencyFieldDefinition,
  FieldCategory,
  CreateDocumentInfoService,
  CurrencyValue,
  FilingCaseUpdate,
  FilingUpdate,
  IFilingApiService,
  FsxFilingApiService,
} from '@fsx/fsx-shared';
import {
  FsxValidateCaseOrchestrationService,
  IValidateCaseOrchestrationService,
} from '../filing-editor/services/orchestration/validate-case-orchestration.service';

@Component({
  selector: 'fsx-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Input() documentInfos$!: Observable<DocumentInfo[]>;
  @Input() additionalFieldValues!: AdditionalFieldValue[];
  @Input() resolver!: FsxReferenceResolver;
  @Input() additionalFieldsDefinition!: AdditionalFieldSpec[];
  @Input() case!: RequestCaseViewModel;
  @Input() filing!: Filing;

  @Output() setAdditionalFieldValuesEvent =
    new EventEmitter<AdditionalFieldValue>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  @ViewChild('caseNameField') caseNameField!: FsxTextBoxComponent;
  @ViewChild('clientMatterField') clientMatterField!: FsxTextComponent;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxAdditionalFieldsComponent>;

  summary: {
    courtName: string;
    caseClass: string;
    caseType: string;
    caseName: string | null | undefined;
  } = { courtName: '', caseClass: '', caseType: '', caseName: '' };
  classification!: DataAttribute[];

  caseNameFieldDefinition: TextFieldDefinition = {
    required: true,
    minLength: 1,
    maxLength: 200,
    characterProfileName: 'alphanumericTextboxCharset',
    readOnly: false,
    visible: true,
  };

  clientNumberFieldDefinition: TextFieldDefinition = {
    required: true,
    minLength: 1,
    maxLength: 100,
    characterProfileName: 'alphanumericTextboxCharset',
    readOnly: true,
    visible: true,
  };

  filingId!: string;
  scheduledAt!: string;
  caseRequest!: CaseRequestViewModel;
  combinedFilingData!: CombinedFilingData;
  documentFileUploadDocumentInfos!: DocumentInfo[];

  private destroy$: Subject<void> = new Subject<void>();

  public amountInControversyFieldDefinition!: CurrencyFieldDefinition | null;
  public fieldType = FieldCategory.Currency;
  public amountInControversyInitialValue!: number;
  public amountInControversyFormControl!: FormControlWithModel<CurrencyFieldDefinition>;

  constructor(
    @Inject(FsxValidateCaseOrchestrationService)
    private readonly validateCaseOrchestrationService: IValidateCaseOrchestrationService,
    @Inject(FsxUpdateCaseRequestOrchestrationService)
    private readonly updateCaseRequestOrchestrationService: IUpdateCaseRequestOrchestrationService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly createDocumentInfoService: CreateDocumentInfoService
  ) {}

  ngOnInit(): void {
    this.combinedFilingData$
      .pipe(
        takeUntil(this.destroy$),
        filter((cfd: CombinedFilingData) => !!cfd),
        tap((cfd: CombinedFilingData) => {
          this.combinedFilingData = cfd;
          this.caseNameFieldDefinition.readOnly =
            !cfd.filingProfile.allowsProposingCaseName;

          this.filing = cfd.filing;
          this.filingId = cfd.filing.id;
          this.caseRequest = cfd.caseRequest;

          if (cfd.caseRequest.cases) {
            this.case = cfd.caseRequest.cases[0];
          }

          this.scheduledAt = cfd.filing.scheduledAt ?? '';
          const cases: RequestCaseViewModel[] | null | undefined =
            cfd.caseRequest.cases;
          this.classification = cfd.filingProfile.classification;

          if (
            cfd.modeSpec &&
            cfd.modeSpec.case &&
            cfd.modeSpec.case.additionalFields
          ) {
            this.additionalFieldsDefinition =
              cfd.modeSpec.case.additionalFields;
            this.amountInControversyFieldDefinition =
              cfd.modeSpec.case.amountInControversy ?? null;
          }

          if (cases && cases.length > 0) {
            const firstCase = cases[0];
            if (firstCase.amountInControversy) {
              this.amountInControversyInitialValue =
                firstCase.amountInControversy?.amount ?? 0;
            }
            this.summary = {
              courtName: cfd.filing.courtSummary.caption,
              caseClass: '',
              caseType: '',
              caseName: firstCase.title,
            };
          }
        })
      )
      .subscribe();

    this.documentInfos$
      .pipe(
        tap((documentInfos) => {
          const documentFileValues: string[] =
            this.case.additionalFieldValues?.flatMap(
              (addlFieldValue: AdditionalFieldValue) => {
                return addlFieldValue.fileValues || [];
              }
            ) || [];

          this.documentFileUploadDocumentInfos = documentInfos.filter(
            (docInfo: DocumentInfo) => {
              return documentFileValues.includes(docInfo.id);
            }
          );
        })
      )
      .subscribe();

    if (this.caseRequest.cases) {
      this.additionalFieldValues =
        this.caseRequest.cases[0].additionalFieldValues ?? [];
    } else {
      this.additionalFieldValues = [];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAdditionalFieldValuesEmitted(values: AdditionalFieldValue) {
    this.setAdditionalFieldValuesEvent.emit(values);
    this.validateCaseOrchestrationService.validateCase();
  }

  onCaseNameTextChanged(newName: string) {
    let filingCaseUpdate: FilingCaseUpdate = {
      title: newName,
      clientMatterKey:
        this.combinedFilingData.filing.courtCases[0].clientMatterKey,
      efmKey: this.combinedFilingData.filing.courtCases[0].efmKey,
    };
    let filingUpdate: FilingUpdate = {
      caption: this.combinedFilingData.filing.courtCases[0].caption ?? 'Test',
      scheduledAt: this.combinedFilingData.filing.scheduledAt,
      authorizerUserId: this.combinedFilingData.filing.authorizerUserId,
      cases: [filingCaseUpdate],
    };
    this.filingApiService
      .updateFiling(this.combinedFilingData.filing.id, filingUpdate)
      .pipe(
        take(1),
        tap((filing) => {
          this.filing = filing;
          let caseRequest = this.combinedFilingData.caseRequest;
          if (caseRequest.cases) {
            this.filing.courtCases[0].title = newName; // Update the case name in the filing object
            this.combinedFilingData.filing.courtCases[0].title = newName;
            caseRequest.cases[0].title = newName;
          }
          this.resolver.updateCaseRequestPut(
            this.combinedFilingData.caseRequest,
            this.combinedFilingData.filing.id
          );
          this.validateCaseOrchestrationService.validateCase();
        })
      )
      .subscribe();
  }

  public filesUploadedAdditionalFieldEventHandler(
    params: FilesUploadedEventParams
  ) {
    of(params)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id
            );

          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update case here
                const caseRequestCases: RequestCaseViewModel[] =
                  this.combinedFilingData.caseRequest.cases || [];

                const updatedCases = caseRequestCases.map((cs) => {
                  if (cs.caseId === this.case.caseId) {
                    const existingAdditionalFieldValues: AdditionalFieldValue[] =
                      this.case.additionalFieldValues || [];
                    const newAdditionalFieldValues: AdditionalFieldValue[] =
                      documentInfoAndUploadedFiles.map(
                        (
                          documentInfoAndUploadedFile: DocumentInfoAndUploadedFile
                        ) => {
                          // Convert each DocumentInfoAndUploadedFile to an additionalFieldValue object
                          const additionalFieldValue: AdditionalFieldValue = {
                            additionalFieldName:
                              filesUploadedEventParams.additionalFieldName,
                            fileValues: [
                              documentInfoAndUploadedFile.documentInfo.id,
                            ],
                          };
                          return additionalFieldValue;
                        }
                      );

                    cs.additionalFieldValues = [
                      ...existingAdditionalFieldValues,
                      ...newAdditionalFieldValues,
                    ];
                  }

                  return cs;
                });

                const partialCaseRequest: Partial<CaseRequestViewModel> = {
                  cases: updatedCases,
                };

                const params: IUpdateCaseRequestParams = {
                  filingId: this.combinedFilingData.filing.id,
                  caseRequest: this.combinedFilingData.caseRequest,
                  partialCaseRequest,
                };

                this.updateCaseRequestOrchestrationService.updateCaseRequest(
                  params
                );

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...filesUploadedEventParams,
                  documentInfoAndUploadedFiles,
                });

                this.validateCaseOrchestrationService.validateCase();
              }
            )
          );
        })
      )
      .subscribe();
  }

  fileRemovedAdditionalFieldEventHandler(documentInfo: DocumentInfo) {
    const caseRequestCases: RequestCaseViewModel[] =
      this.combinedFilingData.caseRequest.cases || [];

    const updatedCases = caseRequestCases.map((cs) => {
      if (cs.caseId === this.case.caseId) {
        const existingAdditionalFieldValues: AdditionalFieldValue[] =
          this.case.additionalFieldValues || [];

        const updatedAdditionalFieldValues = existingAdditionalFieldValues.map(
          (addlFieldVal: AdditionalFieldValue) => {
            // Filter out the fileValue that has the id we want to remove
            const fileValues: string[] = addlFieldVal.fileValues || [];
            addlFieldVal.fileValues = fileValues.filter((fileValue: string) => {
              return fileValue !== documentInfo.id;
            });
            return addlFieldVal;
          }
        );

        cs.additionalFieldValues = updatedAdditionalFieldValues;
      }

      return cs;
    });

    const partialCaseRequest: Partial<CaseRequestViewModel> = {
      cases: updatedCases,
    };

    const params: IUpdateCaseRequestParams = {
      filingId: this.combinedFilingData.filing.id,
      caseRequest: this.combinedFilingData.caseRequest,
      partialCaseRequest,
    };

    this.updateCaseRequestOrchestrationService.updateCaseRequest(params);
  }

  validate(): void {
    if (this.caseNameField) {
      this.caseNameField.validate();
    }
    if (this.clientMatterField) {
      this.clientMatterField.validate();
    }
    if (this.additionalFields) {
      this.additionalFields.forEach((f) => f.validate());
    }
  }

  setAmountInControversyFormControl(
    formControl: FormControlWithModel<CurrencyFieldDefinition>
  ) {
    this.amountInControversyFormControl = formControl;

    this.amountInControversyFormControl.valueChanges
      .pipe(
        debounceTime(500),
        tap((value) => {
          let caseRequest = this.combinedFilingData.caseRequest;
          if (caseRequest.cases) {
            let currencyValue: CurrencyValue | null;

            if (value === null) {
              currencyValue = null;
            } else {
              currencyValue = {
                amount: value,
              };
            }

            caseRequest.cases[0].amountInControversy = currencyValue;
            this.resolver.updateCaseRequestPut(
              caseRequest,
              this.combinedFilingData.filing.id
            );
            this.validateCaseOrchestrationService.validateCase();
          }
        })
      )
      .subscribe();
  }
}
