import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { serverValidationErrorCode } from './validation-result-mapping.service';
import { ValidationGroup } from './validation-group-errors.service';

/**
 * The data structure of the object to store in the ValidationErrorService.
 */
export interface ValidationError {
  /**
   * The code to identify the error so that it can be removed
   * on successful revalidation.
   */
  errorCode: string;

  /**
   * The message to display in the checklist.
   */
  errorMessage: string;

  /**
   * The validation group that the error resides in.
   */
  group: ValidationGroup;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationErrorsService to use at runtime.
 */
export const FsxValidationErrorsService =
  new InjectionToken<IValidationErrorsService>('FsxValidationErrorsService');

/**
 * A blueprint for a state service, which stores validation errors
 */
export interface IValidationErrorsService {
  /**
   * The validationErrors exposed as an Observable
   */
  validationErrors$: Observable<ValidationError[]>;

  /**
   * A method to allow setting of the validation errors
   */
  setValidationErrors(validationErrors: ValidationError[]): void;

  /**
   * A method to allow adding of a validation error
   */
  addValidationError(validationError: ValidationError): void;

  /**
   * A method to allow removal of a validation error by error code
   */
  removeValidationError(errorCode: string): void;

  /**
   * A method to allow for the quick removal of server validation errors.
   */
  removeServerValidationErrors(): void;

  /**
   * A method to allow retrieval of validation error messages by group.
   *
   * @param group The validation group to get teh validation errors for.
   */
  getValidationErrors(group: ValidationGroup): Observable<ValidationError[]>;
}

/**
 * A concrete implementation of a state service, which validation errors
 */
@Injectable()
export class ValidationErrorsService implements IValidationErrorsService {
  /**
   * The validation errors stored in a BehaviorSubject so that they can be easily
   * exposed as an Observable.
   */
  private readonly validationErrors$$ = new BehaviorSubject<ValidationError[]>(
    []
  );

  /**
   * The validation errors exposed as an Observable
   */
  validationErrors$: Observable<ValidationError[]> =
    this.validationErrors$$.asObservable();

  /**
   * A method to allow setting of the validation errors
   */
  setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors$$.next(validationErrors);
  }

  /**
   * A method to allow adding of a validation error
   */
  addValidationError(validationError: ValidationError): void {
    const filteredValidationErrors: ValidationError[] =
      this.filterValidationErrorFromArray(validationError.errorCode);
    this.validationErrors$$.next([
      ...filteredValidationErrors,
      validationError,
    ]);
  }

  /**
   * A method to allow removal of a validation error by error code
   */
  removeValidationError(errorCode: string): void {
    const filteredValidationErrors: ValidationError[] =
      this.filterValidationErrorFromArray(errorCode);
    this.validationErrors$$.next([...filteredValidationErrors]);
  }

  private filterValidationErrorFromArray(errorCode: string): ValidationError[] {
    return this.validationErrors$$.value.filter(
      (validationError: ValidationError) => {
        return validationError.errorCode !== errorCode;
      }
    );
  }

  /**
   * A method to allow for the quick removal of server validation errors.
   */
  removeServerValidationErrors(): void {
    const filteredValidationErrors: ValidationError[] =
      this.validationErrors$$.value.filter(
        (validationError: ValidationError) => {
          return !validationError.errorCode.includes(serverValidationErrorCode);
        }
      );
    this.validationErrors$$.next([...filteredValidationErrors]);
  }

  /**
   * A method to allow retrieval of validation error messages by group.
   *
   * @param group The validation group to get teh validation errors for.
   */
  getValidationErrors(group: ValidationGroup): Observable<ValidationError[]> {
    return this.validationErrors$.pipe(
      map((validationErrors: ValidationError[]) => {
        return validationErrors.filter(
          (validationError) =>
            validationError.group.groupName === group.groupName
        );
      })
    );
  }
}
