import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { RequestDocument } from '../types';

@Injectable()
@Pipe({
  name: 'documentOrderNumber',
})
export class DocumentOrderNumberPipe implements PipeTransform {
  transform(requestDocuments: RequestDocument[], index: number): string {
    let leadDocumentNumber = 0;
    let supportingDocumentNumber = 0;
    const documentOrderNumbers: string[] = requestDocuments.reduce(
      (acc: string[], reqDoc: RequestDocument) => {
        if (reqDoc.isLeadDocument) {
          leadDocumentNumber++;
          supportingDocumentNumber = 0;
          acc.push(`${leadDocumentNumber}`);
        } else {
          supportingDocumentNumber++;
          acc.push(`${leadDocumentNumber}.${supportingDocumentNumber}`);
        }
        return acc;
      },
      []
    );
    return documentOrderNumbers[index];
  }
}
