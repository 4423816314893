import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ContactViewModel, ContactSummaryViewModel } from '../../types';
import {
  FsxContactApiService,
  IContactApiService,
} from './contact-api.service';

@Injectable({
  providedIn: 'root',
})
export class CreateContactsService {
  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService
  ) {}

  public createContactsFromContactSummaries(
    contactSummaries: ContactSummaryViewModel[]
  ): Observable<ContactViewModel[]> {
    // Convert array of ContactSummaries into array of api calls that retrieve the Contact
    const arrayOfGetContacts$: Observable<ContactViewModel>[] =
      contactSummaries.map((contactSummary: ContactSummaryViewModel) => {
        return this.contactApiService.getContact(contactSummary.id);
      });

    // Convert the array of observables into a single observable
    const contacts$: Observable<ContactViewModel[]> =
      combineLatest(arrayOfGetContacts$);

    // return the array of contacts as an observable
    return contacts$;
  }
}
