<!-- START: Transaction Container -->
<div class="details-container" fxLayout="column" *ngIf="!!transaction">
  <ng-container *ngIf="!showDocumentHistory; else documentHistory">
    <!-- START: Transaction Info Container -->
    <div
      fxFlex="none"
      class="transaction-info-container"
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="start"
    >
      <!-- START: Transaction ID, Status-Bar & Actions -->
      <div
        fxFlex="none"
        class="info"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex="none" class="number">Transaction {{ transaction.id }}</div>
        <div
          fxFlex="none"
          class="icon bookmark"
          [ngClass]="{
            bookmarked:
              !!combinedFilingData &&
              !!combinedFilingData.filing &&
              combinedFilingData.filing.bookmarked
          }"
          (click)="onBookmarkedClicked($event, combinedFilingData?.filing)"
        ></div>
        <div fxFlex="auto" class="status-container">
          <div class="status-bar">
            <fsx-transaction-status
              [requestTypes]="combinedFilingData?.filing?.requestTypes"
              [filingState]="combinedFilingData?.filing?.status"
            ></fsx-transaction-status>
          </div>
        </div>
        <button class="primary action-btn" [matMenuTriggerFor]="mainMenu">
          <span fxLayout="row" fxLayoutAlign="center center">
            <span>Actions</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </span>
        </button>
        <mat-menu #mainMenu="matMenu">
          <button mat-menu-item (click)="onViewReceiptClicked($event)">
            <!--            <span>View Submission Receipt</span> -->
            <!-- ToDo: Uniting both options to one for "P0" -->
            <span>View/Print Submission Receipt</span>
          </button>
          <mat-divider></mat-divider>
          <!-- ToDo: Disabling this for submission receipt "P0" -->
          <!--          <button mat-menu-item>-->
          <!--            <span>Print Submission Receipt</span>-->
          <!--          </button>-->
          <!--          <mat-divider></mat-divider>-->
          <button mat-menu-item>
            <span>Begin SubF</span>
          </button>
        </mat-menu>
      </div>
      <!-- END: Transaction ID, Status-Bar & Actions -->

      <!-- START: Rejection Reason -->
      <ng-container
        *ngIf="combinedFilingData?.filing?.status === FilingState.Rejected"
      >
        <fsx-filing-message
          [isError]="true"
          [description]="transaction.rejectionReason ?? '-'"
        ></fsx-filing-message>
        <mat-divider class="divider"></mat-divider>
      </ng-container>
      <!-- END: Rejection Reason -->

      <!-- START: Message to filer -->
      <ng-container
        *ngIf="combinedFilingData?.filing?.status === FilingState.Accepted"
      >
        <fsx-filing-message
          *ngIf="!!firstCase.statusReason"
          [isError]="false"
          [description]="firstCase.statusReason"
        ></fsx-filing-message>
      </ng-container>
      <!-- END: Message to filer -->

      <!-- END: Rejection Reason   -->
      <div
        fxFlex="none"
        class="details"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayoutGap="10px"
      >
        <div
          fxFlex="none"
          class="detail"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="37px"
        >
          <div fxFlex="255px" fxFlexAlign="start" class="detail">
            <div class="value">
              {{ transaction.dateSubmitted | date: "MM/dd/YYYY hh:mmaaa" }}
            </div>
            <div class="caption">Date Submitted</div>
          </div>
        </div>

        <div fxFlex="none" class="detail">
          <div class="value case-number">{{ transaction.caseNumber }}</div>
          <div class="caption">Case Number</div>
        </div>

        <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex class="detail">
            <div class="value">{{ transaction.caseName }}</div>
            <div class="caption">Case Name</div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Transaction Info Container -->

    <!-- START: Transaction Details Container -->
    <div
      *ngIf="!!transaction.details"
      fxFlex="none"
      class="transaction-details-container"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <header fxFlex="none" class="title" (click)="collapseDetails()">
        <mat-icon class="icon">{{
          !isDetailsCollapsed ? "arrow_drop_up" : "arrow_drop_down"
        }}</mat-icon
        >Transaction Details
      </header>

      <div
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="37px"
        [ngClass]="{ collapsed: isDetailsCollapsed }"
      >
        <div
          fxFlex="none"
          fxLayout="column"
          fxLayoutGap="10px"
          fxLayoutAlign="start"
          class="column"
        >
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.submittedBy }}</div>
            <div class="caption">Submitted By</div>
          </div>
          <div fxFlex="none" class="detail">
            <div class="value">
              {{ transaction.details.clientMatterNumber }}
            </div>
            <div class="caption">Client/Matter Number</div>
          </div>
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.court }}</div>
            <div class="caption">Court</div>
          </div>
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.caseClass }}</div>
            <div class="caption">Case Class</div>
          </div>
        </div>

        <div
          fxFlex="255px"
          fxLayout="column"
          fxLayoutGap="10px"
          fxLayoutAlign="start"
          class="column"
        >
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.authorizer }}</div>
            <div class="caption">Authorizer</div>
          </div>
          <div fxFlex="none" class="detail">
            <ng-container [ngSwitch]="transaction.details.totalFees.total">
              <div *ngSwitchCase="'-'" class="fee value">-</div>
              <div *ngSwitchCase="'loading'" class="fee value">Loading...</div>
              <div *ngSwitchDefault class="fee value number">
                {{
                  transaction.details.totalFees.total
                    | currency
                      : transaction.details.totalFees.currencyCode
                      : "symbol"
                }}
              </div>
            </ng-container>
            <div class="caption">Total Fees</div>
          </div>
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.assignedJudge }}</div>
            <div class="caption">Assigned Judge</div>
          </div>
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.caseType }}</div>
            <div class="caption">Case Type</div>
          </div>
        </div>

        <div
          fxFlex="auto"
          fxLayout="column"
          fxLayoutGap="10px"
          fxLayoutAlign="start"
          class="column"
          fxFlexAlign="start"
        >
          <div fxFlex="none" class="detail">
            <div class="value">{{ transaction.details.modeType }}</div>
            <div class="caption">Mode/Type</div>
          </div>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
    </div>
    <!-- END: Transaction Details Container -->

    <!-- START: Transaction Documents Container -->
    <div
      fxFlex="none"
      class="transaction-documents-container"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <div fxFlex="none" fxLayout="column" fxLayoutAlign="center start">
        <div
          fxFlex="none"
          class="title"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <div fxFlex="auto">
            Documents ({{ transaction.documents.length }})
          </div>
          <!--        ToDo: Disabled For now. This is supposed to be Batch Actions for Documents. -->
          <!--          <button class="primary" class="action-btn">-->
          <!--            <div fxLayout="row" fxLayoutAlign="center center">-->
          <!--              <span>Actions</span>-->
          <!--              <mat-icon>arrow_drop_down</mat-icon>-->
          <!--            </div>-->
          <!--          </button>-->
        </div>
        <div
          fxFlex="none"
          class="section-header row"
          fxLayout="row"
          fxLayoutAlign="start end"
          fxLayoutGap="10px"
        >
          <div fxFlex="4px"></div>
          <mat-checkbox fxFlex="14px" color="primary"></mat-checkbox>
          <div fxFlex="20px" class="section-header column"></div>
          <div fxFlex="250px" class="section-header column">Title</div>
          <div fxFlex="120px" class="section-header column">Access</div>
          <div fxFlex="100px" class="section-header column">PDF</div>
          <div fxFlex="104px" class="section-header column">Status</div>
          <div fxFlex="auto"></div>
        </div>
        <!-- ToDo: NgFor Each document  -->
        <ng-container
          *ngFor="
            let transactionDocument of transaction.documents;
            let docIndex = index
          "
        >
          <div
            fxFlex="none"
            class="section-content row"
            fxLayout="row"
            fxLayoutAlign="start end"
            fxLayoutGap="10px"
          >
            <div
              fxFlex="4px"
              class="error"
              [ngClass]="{
                stripe:
                  transactionDocument.status ===
                  DocumentReviewRequestStatus.Rejected
              }"
            ></div>
            <div fxFlex="auto" fxLayout="column">
              <div fxFlex="none" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="14px" class="checkbox-col">
                  <mat-checkbox *ngIf="false" color="primary"></mat-checkbox>
                </div>
                <div fxFlex="20px" class="column order-number">
                  {{ requestDocuments | documentOrderNumber: docIndex }}
                </div>
                <div fxFlex="250px" class="column title" fxLayout="column">
                  <div fxFlex="none" class="name">
                    <span
                      class="download"
                      (click)="openPdfViewer(transactionDocument, docIndex)"
                      >{{ transactionDocument.documentName }}</span
                    >
                  </div>
                  <div fxFlex="none" class="subtitle">
                    {{ transactionDocument.documentCategory }}
                  </div>
                </div>
                <div fxFlex="120px" class="column access">
                  {{ transactionDocument.access }}
                </div>
                <div fxFlex="100px" class="column pdf" fxLayout="column">
                  <div fxFlex="none" class="size">
                    {{ transactionDocument.pdfSize }}
                  </div>
                  <div fxFlex="none" class="pages">
                    Pages: {{ transactionDocument.pdfPages }}
                  </div>
                </div>
                <div
                  fxFlex="104px"
                  class="column status"
                  [ngClass]="{
                    'error active':
                      transactionDocument.status ===
                      DocumentReviewRequestStatus.Rejected
                  }"
                >
                  {{ transactionDocument.status }}
                </div>
                <div
                  fxFlex="auto"
                  fxLayout="row"
                  class="icons"
                  fxFlexAlign="center"
                >
                  <span
                    class="icon stamp"
                    matTooltip="Clerk Stamped"
                    [matTooltipClass]="'fsx-tooltip'"
                    [matTooltipShowDelay]="200"
                    [matTooltipHideDelay]="0"
                    [matTooltipDisabled]="
                      !transactionDocument.availableRenderings.clerkStamped
                    "
                    [ngClass]="{
                      disabled:
                        !transactionDocument.availableRenderings.clerkStamped
                    }"
                    (click)="downloadClerkStamped(transactionDocument)"
                  ></span>
                  <span
                    class="icon pdf"
                    matTooltip="Original"
                    [matTooltipClass]="'fsx-tooltip'"
                    [matTooltipShowDelay]="200"
                    [matTooltipHideDelay]="0"
                    [matTooltipDisabled]="
                      !transactionDocument.availableRenderings.original
                    "
                    [ngClass]="{
                      disabled:
                        !transactionDocument.availableRenderings.original
                    }"
                    (click)="downloadConvertedPdf(transactionDocument)"
                  ></span>
                  <span
                    class="icon history"
                    matTooltip="Document History"
                    [matTooltipClass]="'fsx-tooltip'"
                    [matTooltipShowDelay]="200"
                    [matTooltipHideDelay]="0"
                    (click)="goToDocumentHistory(transactionDocument, docIndex)"
                  ></span>
                </div>
              </div>
              <div
                *ngIf="
                  transactionDocument.status ===
                  DocumentReviewRequestStatus.Rejected
                "
                fxFlex="none"
                fxLayout="column"
                class="error"
              >
                <span class="reason">Rejection Reason:</span>
                <span class="message">
                  {{ transactionDocument.statusReason }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- END: Transaction Documents Container -->

    <!-- START: Transaction Documents Served Container -->
    <div
      *ngIf="showDocumentsServed"
      fxFlex
      class="transaction-documents-served-container"
      fxLayout="column"
    >
      <div fxFlex="none" fxLayout="column" fxLayoutAlign="center start">
        <div fxFlex="none" class="title">Documents Served (6)</div>
        <div
          fxFlex="none"
          class="section-header row"
          fxLayout="row"
          fxLayoutAlign="start end"
          fxLayoutGap="30px"
        >
          <div fxFlex="10px" class="section-header column"></div>
          <div fxFlex="220px" class="section-header column">Title</div>
          <div fxFlex="80px" class="section-header column">Service Date</div>
          <div fxFlex="200px" class="section-header column">
            Service Contact / Email
          </div>
          <div fxFlex="50px" class="section-header column">Status</div>
        </div>
      </div>
      <!-- ToDo: NgFor each document served  -->
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">1</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">1.1</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">2</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">3</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">4</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
      <div
        fxFlex="none"
        class="section-content row"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="10px" class="column order-number">4.1</div>
        <div fxFlex="220px" class="column title" fxLayout="column">
          <div fxFlex="none" class="name">
            Loremem ipsum ... amet, conse lorem
          </div>
          <div fxFlex="none" class="subtitle">Amended Filing</div>
        </div>
        <div fxFlex="80px" class="column service-date" fxLayout="column">
          <div fxFlex="none" class="date">02/14/2022</div>
          <div fxFlex="none" class="time">16:32 PM</div>
        </div>
        <div fxFlex="200px" class="column contact-email" fxLayout="column">
          <div fxFlex="none" class="contact">Richard Nicholas Miller</div>
          <div fxFlex="none" class="email">
            richard.nic.miller@mycompany.com
          </div>
        </div>
        <div fxFlex="50px" class="column status">Opened</div>
      </div>
    </div>
    <!-- END: Transaction Documents Container -->

    <!-- START: Transaction Parties Container -->
    <div
      fxFlex="none"
      class="transaction-parties-container"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <div fxFlex="none" fxLayout="column" fxLayoutAlign="center start">
        <div fxFlex="none" class="title">
          Parties ({{ transaction.parties.length }})
        </div>
        <div
          fxFlex="none"
          class="section-header row"
          fxLayout="row"
          fxLayoutAlign="start end"
          fxLayoutGap="10px"
        >
          <div fxFlex="210px" class="section-header column">Party / Type</div>
          <div fxFlex="200px" class="section-header column">
            Representation / Role
          </div>
          <div fxFlex class="section-header column">Firm</div>
        </div>
      </div>
      <ng-container *ngFor="let participant of transaction.parties">
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          class="section-content row"
        >
          <div
            fxFlex="210px"
            fxLayout="column"
            fxLayoutGap="2px"
            class="column"
          >
            <div fxFlex="none" class="party">{{ participant.name }}</div>
            <div fxFlex="none" class="type">{{ participant.type }}</div>
          </div>

          <ng-container
            *ngIf="participant.representation?.length; else noRepresentation"
          >
            <!--  ToDo: NgFor each representation  -->
            <div
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="10px"
              class="column"
            >
              <div
                *ngFor="let representation of participant.representation"
                fxFlex="none"
                fxLayout="row"
                fxLayoutGap="10px"
              >
                <div fxFlex="200px">
                  <div class="representation">{{ representation.name }}</div>
                  <div class="role">{{ representation.role }}</div>
                </div>
                <div fxFlex fxFlexAlign="start">
                  <div class="firm">{{ representation.firm }}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noRepresentation>
            <div
              fxFlex="auto"
              fxLayout="column"
              fxLayoutGap="10px"
              class="column"
            >
              <div fxFlex="none" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="200px">
                  <div class="representation">Self-Represented</div>
                </div>
                <div fxFlex fxFlexAlign="start">
                  <div class="firm">-</div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <!-- END: Transaction Parties Container -->

    <!-- START: Transaction Fees Container -->
    <div fxFlex="none" class="transaction-fees-container" fxLayout="column">
      <header fxFlex="none" class="title">
        {{ transaction.isEstimate ? "Estimated" : "" }} Transaction Fees
      </header>
      <!-- ToDo: NgFor Each Fee Item   -->
      <ng-container *ngFor="let transactionFee of transaction.fees">
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="row fees"
          fxLayoutGap="48px"
        >
          <div fxFlex="auto" class="caption">{{ transactionFee.caption }}</div>
          <div fxFlex="120px" class="value">
            {{
              transactionFee.total
                | currency: transactionFee.currencyCode:"symbol"
            }}
          </div>
        </div>
      </ng-container>

      <div
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="row fees total"
        fxLayoutGap="48px"
      >
        <div fxFlex="auto" class="caption">Total</div>
        <div fxFlex="120px" class="value">
          <ng-container [ngSwitch]="transaction.details.totalFees.total">
            <div *ngSwitchCase="'-'">-</div>
            <div *ngSwitchCase="'loading'">Loading...</div>
            <div *ngSwitchDefault class="number">
              {{
                transaction.details.totalFees.total
                  | currency
                    : transaction.details.totalFees.currencyCode
                    : "symbol"
              }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- END: Transaction Fees Container -->
  </ng-container>

  <ng-template #documentHistory>
    <fsx-transaction-document-history
      [filingId]="transaction.filingId"
      [transactionDocument]="activeDocument"
      [transactionDocumentIndex]="activeDocumentIndex"
      [transactionId]="transaction.id"
      [requestDocuments]="requestDocuments"
      [dateSubmitted]="transaction.dateSubmitted"
      [mode]="transaction.details.type"
      (closeHistoryEvent)="showDocumentHistory = !showDocumentHistory"
      (viewPdfDocumentEvent)="openPdfViewer(activeDocument, 0)"
    ></fsx-transaction-document-history>
  </ng-template>
</div>
<!-- END: Transaction Container -->
