import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  ContactViewModel,
  Filter,
  FsxContactApiService,
  IContactApiService,
  Sort,
} from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';

interface QueryConfig {
  filter: Filter[];
  sort: Sort[];
  skip: number;
  searchTerm: string | null;
  selectedRow: number;
}

export const FsxContactsListService = new InjectionToken<IContactsListService>(
  'FsxContactsListService'
);

export interface IContactsListService {
  selectedContactId$: Observable<string | null>;
  selectedContactIDValue: string | null;
  selectedContact$: Observable<ContactViewModel | null>;
  queryConfig: QueryConfig;
  setSelectedContactId(id: string | null): void;
  getSelectedContactIdValue(): string | null;
  getSelectedContactIdValue(): string | null;
  newQueryConfig(): QueryConfig;
}

@Injectable()
export class ContactsListService implements IContactsListService {
  private selectedContactId$$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  selectedContactId$: Observable<string | null> =
    this.selectedContactId$$.asObservable();

  selectedContactIDValue!: string | null;

  selectedContact$: Observable<ContactViewModel | null> =
    this.selectedContactId$.pipe(
      switchMap((contactId: string | null) => {
        return contactId !== null
          ? this.contactApiService.getContact(contactId)
          : of(null);
      })
    );

  queryConfig: QueryConfig = this.newQueryConfig();

  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService
  ) {}

  setSelectedContactId(id: string | null): void {
    this.selectedContactId$$.next(id);
  }

  getSelectedContactIdValue(): string | null {
    return this.selectedContactId$$.getValue();
  }

  newQueryConfig(): QueryConfig {
    return {
      filter: [],
      sort: [],
      skip: 0,
      searchTerm: null,
      selectedRow: 0,
    };
  }
}
