import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CombinedFilingData } from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';

@Component({
  selector: 'fsx-documents-actions',
  templateUrl: './documents-actions.component.html',
  styleUrls: ['./documents-actions.component.scss'],
})
export class DocumentsActionsComponent {
  @Input() combinedFilingData!: CombinedFilingData | null;
  @Input() isMaxLeadDocumentsAllowed!: boolean;
  @Output() filesUploadedEvent = new EventEmitter<IUploadedFile[]>();
  @Output() addDocumentEvent = new EventEmitter<void>();

  onFilesUploaded(uploadedFiles: IUploadedFile[]): void {
    this.filesUploadedEvent.emit(uploadedFiles);
  }

  onAddDocumentClicked() {
    this.addDocumentEvent.emit();
  }
}
