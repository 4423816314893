import { Injectable } from '@angular/core';
import { DocumentRenderingInfo } from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class TotalSizeService {
  totalSize = {
    size: 0,
  };

  reset(): void {
    this.totalSize.size = 0;
  }

  calculate(renderings: DocumentRenderingInfo[]): void {
    this.totalSize.size = renderings?.reduce(
      (acc: number, cur: DocumentRenderingInfo) => {
        const total = acc + cur.size;
        return total;
      },
      0
    );
  }
}
