<div
  id="contact-link-card"
  fxLayout="row"
  fxLayoutAlign="space-between"
  fxFlex="100"
>
  <div class="section-container" fxFlex>
    <p class="primary-text bold">{{ linkedContact.caption }}</p>
    <p class="secondary-text">Name</p>
  </div>
  <div class="section-container" fxFlex>
    <p class="primary-text">{{ linkedContact.contactType }}</p>
    <p class="secondary-text">Entity</p>
  </div>
  <div class="section-container" fxFlex>
    <p class="primary-text">123456</p>
    <p class="secondary-text">Client No.</p>
  </div>
  <button
    class="edit-contact-button"
    (click)="onEditContactClicked(linkedContact.id)"
  >
    Edit Contact
  </button>
</div>
