<div
  id="contacts-form"
  fsxFocusFirstInput
  *ngIf="contactProfile || combinedFilingData?.filingProfile"
>
  <fsx-contact-type-selection
    *ngIf="formMode === contactFormMode.AddContact"
    [config]="{ disabled: contactFormGroup.disabled }"
    (contactTypeChangedEvent)="setActiveForm($event)"
  >
  </fsx-contact-type-selection>

  <form [formGroup]="contactFormGroup" class="form-container">
    <ng-container *ngIf="contactType === ContactType.Person">
      <div class="component-container name">
        <fsx-person-name-component
          #personNameField
          *ngIf="personNameFieldDefinition"
          [personNameComponentSpec]="personNameFieldDefinition"
          [resolver]="resolver"
          [initialValues]="personNameInitialValues"
          (formGroupEmitter)="setPersonNameFormGroup($event)"
        ></fsx-person-name-component>
      </div>
    </ng-container>

    <ng-container *ngIf="contactType === ContactType.Organization">
      <div class="component-container org">
        <fsx-text-component
          #organizationField
          *ngIf="organizationFieldDefinition"
          [caption]="'Title'"
          [width]="'100%'"
          [resolver]="resolver"
          [fieldDefinition]="organizationFieldDefinition.title"
          [initialValue]="
            organizationInitialValues ? organizationInitialValues.title : ''
          "
          (formControlEmitter)="setOrganizationFormControl($event)"
        ></fsx-text-component>
      </div>
    </ng-container>

    <div *ngIf="showBarIdentificationComponent">
      <div class="component-container">
        <p class="form-title">Bar Number</p>
        <fsx-identification-component
          #barNumberField
          [resolver]="resolver"
          [showBarId]="showBarIdentificationComponent"
          [editMode]="formMode !== contactFormMode.AddContact"
          [identificationComponentSpec]="identificationFieldDefinition"
          [initialValues]="barIdentificationInitialValues"
          [idLength]="idLength"
          (formArrayEmitter)="setBarFormArray($event)"
        ></fsx-identification-component>
      </div>
    </div>

    <div class="component-container">
      <p class="form-title">Address</p>
      <fsx-address-component
        #addressField
        *ngIf="addressFieldDefinition"
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [fieldDefinition]="addressFieldDefinition"
        [initialValues]="addressInitialValues"
        (formArrayEmitter)="setAddressFromArray($event)"
      ></fsx-address-component>
    </div>

    <div class="component-container">
      <p class="form-title">Phone</p>
      <fsx-phone-component
        #phoneField
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [phoneComponentSpec]="phoneFieldDefinition"
        [initialValues]="phoneInitialValues"
        (formArrayEmitter)="setPhoneFormArray($event)"
      ></fsx-phone-component>
    </div>

    <div class="component-container">
      <p class="form-title">Email</p>
      <fsx-email-component
        #emailField
        [resolver]="resolver"
        [editMode]="formMode !== contactFormMode.AddContact"
        [emailComponentSpec]="emailFieldDefinition"
        [initialValues]="emailInitialValues"
        (formArrayEmitter)="setEmailFormArray($event)"
      ></fsx-email-component>
    </div>

    <ng-container *ngIf="showOtherIdentificationComponent">
      <div class="component-container">
        <p class="form-title">Identifiers</p>
        <fsx-identification-component
          #identificationField
          [resolver]="resolver"
          [showOtherId]="showOtherIdentificationComponent"
          [editMode]="formMode !== contactFormMode.AddContact"
          [identificationComponentSpec]="identificationFieldDefinition"
          [initialValues]="otherIdentificationInitialValues"
          [idLength]="idLength"
          (formArrayEmitter)="setOtherIdFormArray($event)"
        ></fsx-identification-component>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        (contactProfile || combinedFilingData.filingProfile) &&
        aliasFieldDefinition
      "
    >
      <div class="component-container">
        <p class="form-title">Aliases</p>
        <fsx-alias-component
          #aliasField
          [contactType]="contactType"
          (formArrayEmitter)="setAliasFromArray($event)"
          [profile]="contactProfile || combinedFilingData.filingProfile"
          [participantSpec]="participantSpec"
          [editMode]="formMode !== contactFormMode.AddContact"
          [resolver]="resolver"
          [aliasFieldDefinition]="aliasFieldDefinition"
          [initialValues]="aliasInitialValues"
        ></fsx-alias-component>
      </div>
    </ng-container>
  </form>
</div>
