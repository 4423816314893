import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  DefaultParticipantService,
  CasePartyViewModel,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxDefaultPartyService,
  IDefaultPartyService,
} from '@fsx/fsx-shared';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxClearParticipantOrchestrationService =
  new InjectionToken<IClearParticipantOrchestrationService>(
    'FsxClearParticipantOrchestrationService'
  );

export interface IClearParticipantEventParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  partyToClear: CasePartyViewModel;
}

export interface IClearParticipantOrchestrationService {
  clearParticipantInCaseRequest$: Observable<CaseRequestViewModel>;
  clearParticipant(params: IClearParticipantEventParams): void;
}

@Injectable({
  providedIn: 'root',
})
export class ClearParticipantOrchestrationService
  implements IClearParticipantOrchestrationService
{
  private clearParticipant$$ = new Subject<IClearParticipantEventParams>();

  clearParticipantInCaseRequest$: Observable<CaseRequestViewModel> =
    this.clearParticipant$$.pipe(
      switchMap((params: IClearParticipantEventParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.caseRequestBuilderService
          .removeRepresentationAndParticipants({
            ...params,
            partyToRemoveFrom: params.partyToClear,
          })
          .pipe(
            switchMap(() => {
              return this.defaultPartyService
                .setAsDefaultParty(
                  params.caseRequest,
                  params.partyToClear.participantName,
                  params.partyToClear.participantCategory!
                )
                .pipe(
                  switchMap(() => {
                    return this.defaultParticipantService
                      .setAsDefaultParticipant(
                        params.partyToClear.participantName,
                        params.caseRequest
                      )
                      .pipe(
                        switchMap(() => {
                          return this.caseRequestUpdateService
                            .optimisticPutOrRestore(
                              params.filingId,
                              params.caseRequest,
                              caseRequestBackup
                            )
                            .pipe(
                              tap(() => {
                                this.validatePartiesOrchestrationService.validateParties();
                              })
                            );
                        })
                      );
                  })
                );
            })
          );
      })
    );

  constructor(
    private readonly defaultParticipantService: DefaultParticipantService,
    @Inject(FsxDefaultPartyService)
    private readonly defaultPartyService: IDefaultPartyService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  clearParticipant(params: IClearParticipantEventParams): void {
    this.clearParticipant$$.next(params);
  }
}
