import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TransactionDocument } from '../services/transaction-info.service';
import {
  DocumentReviewRequestEvent,
  FsxFilingApiService,
  IFilingApiService,
  RequestDocument,
} from '@fsx/fsx-shared';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'fsx-transaction-document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss'],
})
export class DocumentHistoryComponent implements OnInit {
  @Input() filingId!: string;
  @Input() transactionDocument!: TransactionDocument;
  @Input() transactionDocumentIndex!: number;
  @Input() transactionId!: string | null | undefined;
  @Input() dateSubmitted!: string | null | undefined;
  @Input() requestDocuments!: RequestDocument[];
  @Input() mode!: string | undefined;

  @Output() closeHistoryEvent = new EventEmitter<void>();
  @Output() viewPdfDocumentEvent = new EventEmitter<void>();

  documentHistory$!: Observable<DocumentReviewRequestEvent[]>;

  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  ngOnInit() {
    if (!!this.transactionDocument) {
      // ToDo: This is the actual stuff for when the BE is fixed
      this.documentHistory$ = this.filingApiService
        .getDocumentHistory(
          this.filingId,
          this.transactionDocument.id,
          this.transactionDocument?.reviewRequestName as string
        )
        .pipe(
          map((dh) => {
            return dh.sort((a, b) => {
              return (
                new Date(b.eventAt).getTime() - new Date(a.eventAt).getTime()
              );
            });
          })
        );
    }
  }

  /**
   * Closes the document history dialog.
   */
  close(): void {
    this.closeHistoryEvent.emit();
  }

  /**
   * Opens the selected document in the pdf viewer.
   */
  public onViewPdfClicked(): void {
    this.viewPdfDocumentEvent.emit();
  }
}
