import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactType } from '@fsx/fsx-shared';

/**
 * The config object properties for the ContactTypeSelectionComponent.
 */
export interface ContactTypeSelectionConfig {
  disabled: boolean;
}

@Component({
  selector: 'fsx-contact-type-selection',
  templateUrl: './contact-type-selection.component.html',
  styleUrls: ['./contact-type-selection.component.scss'],
})
export class ContactTypeSelectionComponent {
  /**
   * An input property for configuring the look and feel and
   * behaviour of the ContactTypeSelectionComponent instance.
   */
  @Input() config!: ContactTypeSelectionConfig;

  /**
   * An output property for raising an event to notify parent
   * components of a change in the contactType selection.
   */
  @Output() contactTypeChangedEvent = new EventEmitter<ContactType>();

  /**
   * The ContactType enum. Used in ngClass expressions on the buttons
   * in the template to switch CSS classes based on the selected
   * contactType.
   */
  ContactType = ContactType;

  /**
   * The selected contactType.
   * - Defaulted to Person, the first button in the template.
   */
  contactType: ContactType = ContactType.Person;

  /**
   * A method for handling the contact type button click events.
   *
   * @param contactType The newly selected contactType.
   */
  onContactTypeButtonClicked(contactType: ContactType): void {
    this.contactType = contactType;
    this.contactTypeChangedEvent.emit(contactType);
  }
}
