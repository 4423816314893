import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  CHILD_ROUTE,
  FsxCombinedFilingApiService,
  ICombinedFilingApiService,
  RECENT_TRANSACTION_ID,
  TRANSACTIONS_EDITOR,
} from '@fsx/fsx-shared';
import { Observable, filter, map, switchMap, catchError } from 'rxjs';

@Component({
  selector: 'fsx-activity-menu-bar',
  templateUrl: './activity-menu-bar.component.html',
  styleUrls: ['./activity-menu-bar.component.scss'],
})
export class ActivityMenuBarComponent implements OnInit {
  @Input()
  workspaceTabCount: number | null = 0;

  recentTransactionId: string | null = null;

  childRouteEnum = CHILD_ROUTE;

  filingId!: string;

  activeChildRoute$: Observable<string> = this.route.firstChild!?.url.pipe(
    map((url: UrlSegment[]) => {
      return url[0].path as string;
    })
  );

  activityTransactionName!: Observable<string>;

  filingId$: Observable<string> = this.route.firstChild!?.url.pipe(
    filter((url: UrlSegment[]) => {
      return url[0].path === CHILD_ROUTE.ACTIVITY;
    }),
    map((url: UrlSegment[]) => {
      return url[1].path;
    })
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(FsxCombinedFilingApiService)
    private readonly combinedFilingApiService: ICombinedFilingApiService
  ) {}

  ngOnInit(): void {
    this.recentTransactionId = localStorage.getItem(RECENT_TRANSACTION_ID);
    this.activityTransactionName = this.filingId$.pipe(
      switchMap((filingId: string) => {
        return this.combinedFilingApiService
          .getCombinedFilingData(filingId)
          .pipe(
            map((combinedFilingData) => {
              return combinedFilingData.filing.caption ?? '-';
            })
          );
      }),
      catchError((e) => {
        console.error('Error getting transaction name:', e);
        return '-';
      })
    );
  }

  onWorkspaceClicked() {
    this.router.navigateByUrl(
      `${TRANSACTIONS_EDITOR}/${this.recentTransactionId}`
    );
  }
}
