import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      (String(control.value) &&
        String(control.value).length &&
        String(control.value) !== 'null') ||
      !control.touched
    ) {
      return null;
    }
    return { required: 'This field is required' };
  };
}
