import moment from 'moment';
import { DateFieldDefinition, IValidatable } from '../../../types';
import { Injectable, InjectionToken } from '@angular/core';

export const FsxDateValidationService =
  new InjectionToken<IDateValidationService>('FsxDateValidationService');

export interface IDateValidationService {
  validateDate(
    value: string | null | undefined,
    spec: DateFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean;
}

@Injectable()
export class DateValidationService implements IDateValidationService {
  public validateDate(
    value: string | null | undefined,
    spec: DateFieldDefinition | null | undefined,
    scope: IValidatable
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!value) {
      if (spec.required) {
        scope.isValid = false;
        return false;
      }
    }

    const dateValue = moment(value);
    const tzDateValue = dateValue.tz(spec.timeZone);
    const dateRangeStart = tzDateValue.add(spec.daysToDateRangeStart, 'days');
    const dateRangeEnd = tzDateValue.add(spec.daysToDateRangeEnd, 'days');

    if (dateValue < dateRangeStart) {
      scope.isValid = false;
      return false;
    }

    if (dateValue > dateRangeEnd) {
      scope.isValid = false;
      return false;
    }

    return true;
  }
}
