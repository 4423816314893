<div class="filing-editor-container" fxLayout="row" fxFill>
  <!-- START: Editor Content -->
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFill
    (click)="disableOverlay()"
    *ngIf="activeSubTabItem$ | async as activeSubTabItem"
  >
    <div class="filing-tabs-row" fxFlex="nogrow">
      <fsx-filing-tabs
        (filingTabClickedEvent)="filingTabClickedEventHandler($event)"
        (closeFilingClickedEvent)="closeFilingClickedEventHandler($event)"
        [filingTabs]="(filingTabs$ | async)!"
        [selectedTab]="routeFilingId$ | async"
      >
      </fsx-filing-tabs>
    </div>
    <!-- Start: Filing Tabs   -->
    <ng-container
      *ngIf="
        (pollingState$ | async) !== PollingState.Succeeded;
        else submissionReceipt
      "
    >
      <div class="filing-sub-tabs-and-actions-row">
        <div fxFill>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="nogrow">
              <fsx-filing-sub-tabs-container></fsx-filing-sub-tabs-container>
            </div>
            <div fxLayout="column" fxFlex>
              <div
                class="action-buttons"
                fxLayout="row"
                fxLayoutAlign="end center"
              >
                <fsx-documents-actions
                  [hidden]="
                    activeSubTabItem.name !== filingSubTabsEnum.DOCUMENTS
                  "
                  [combinedFilingData]="combinedFilingData$ | async"
                  [isMaxLeadDocumentsAllowed]="
                    (isMaxLeadDocumentsAllowed$ | async)!
                  "
                  (filesUploadedEvent)="filesUploadedEventHandler($event)"
                  (addDocumentEvent)="addDocumentEventHandler()"
                >
                </fsx-documents-actions>
                <fsx-filing-checklist-icon></fsx-filing-checklist-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="filing-content-row"
        fxLayout="row"
        [ngClass]="{
          review: activeSubTabItem.name === filingSubTabsEnum.REVIEW,
          'footer-height': showingFooter
        }"
      >
        <div class="filing-content-row-container">
          <fsx-details
            [combinedFilingData$]="combinedFilingData$"
            [documentInfos$]="documentInfos$"
            [additionalFieldValues]="additionalFieldValues"
            [resolver]="resolver"
            [additionalFieldsDefinition]="additionalFieldsDefinition"
            [hidden]="activeSubTabItem.name !== filingSubTabsEnum.DETAILS"
            [case]="case"
            [filing]="filing"
            (setAdditionalFieldValuesEvent)="setAdditionalFieldValues($event)"
            (filesUploadedFromAdditionalFieldEvent)="
              filesUploadedFromAdditionalFieldEventHandler($event)
            "
          >
          </fsx-details>

          <fsx-parties
            [combinedFilingData$]="combinedFilingData$"
            [hidden]="activeSubTabItem.name !== filingSubTabsEnum.PARTIES"
            (combinedPartiesGridRowsUpdatedEvent)="
              combinedPartiesGridRowsUpdatedEventHandler($event)
            "
            (filesUploadedFromAdditionalFieldEvent)="
              filesUploadedFromAdditionalFieldEventHandler($event)
            "
          >
          </fsx-parties>

          <fsx-documents
            [combinedFilingData$]="combinedFilingData$"
            [isMaxLeadDocumentsAllowed$]="isMaxLeadDocumentsAllowed$"
            [isMinLeadDocumentsRequired$]="isMinLeadDocumentsRequired$"
            [hidden]="activeSubTabItem.name !== filingSubTabsEnum.DOCUMENTS"
            (filesUploadedEvent)="filesUploadedEventHandler($event)"
            (filesUploadedFromAdditionalFieldEvent)="
              filesUploadedFromAdditionalFieldEventHandler($event)
            "
            (updateDocumentEvent)="updateDocumentEventHandler($event)"
            (documentsGridRowsUpdatedEvent)="
              documentsGridRowsUpdatedEventHandler($event)
            "
            (filesLoadedEvent)="filesLoadedEventHandler($event)"
          >
          </fsx-documents>

          <fsx-review
            [combinedFilingData$]="combinedFilingData$"
            [combinedPartiesGridRows$]="combinedPartiesGridRows$"
            [documentsGridRows$]="documentsGridRows$"
            [hidden]="activeSubTabItem.name !== filingSubTabsEnum.REVIEW"
            [validationProblemDetails]="validationResult$ | async"
            (updateCaseRequestEvent)="updateCaseRequestEventHandler()"
          >
          </fsx-review>
        </div>
        <div class="filing-content-row-checklist">
          <fsx-filing-checklist
            [combinedFilingData$]="combinedFilingData$"
            [dataRebind$]="subTabsUpdatedTimestamp$"
          ></fsx-filing-checklist>
        </div>
      </div>
      <div class="filing-footer-row" fxFlex="nogrow">
        <fsx-filing-footer
          [pollingState$]="pollingState$"
          (submitFilingEvent)="submitFilingEventHandler()"
          (participantsListNotEmptyEmitter)="
            participantsListNotEmptyEventHandler($event)
          "
          (authorizerSelectedEvent)="authorizerSelectedEventHandler($event)"
        >
        </fsx-filing-footer>
      </div>
    </ng-container>
    <!-- End: Filing Tabs   -->

    <!-- Start: Submission Receipt -->
    <ng-template #submissionReceipt>
      <fsx-filing-submission-confirmation
        [combinedFilingData$]="combinedFilingData$"
        (submissionReceiptClickedEvent)="submissionReceiptClicked()"
      ></fsx-filing-submission-confirmation>
    </ng-template>
    <!-- End: Submission Receipt  -->
  </div>
  <!-- END: Editor Content -->

  <!-- START: In Progress Overlay -->
  <div
    class="overlay"
    *ngIf="(pollingState$ | async) === PollingState.InProgress"
  ></div>
  <!-- END: In Progress Overlay -->
</div>
