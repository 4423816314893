import { Injectable } from '@angular/core';
import {
  DocumentCommonCategoryDomainCategoryValue,
  DocumentSpec,
  FileFieldDefinition,
  FileType,
  FileTypeProfile,
  TextFieldDefinition,
} from '../../types';
import { CombinedFilingData } from './combined-filing-api.service';

@Injectable({
  providedIn: 'root',
})
export class FilingProfileHelperService {
  getMaxLeadDocumentsAllowed(combinedFilingData: CombinedFilingData): number {
    return combinedFilingData.modeSpec?.maxLeadDocumentsAllowed || 9999;
  }

  getMinLeadDocumentsRequired(combinedFilingData: CombinedFilingData): number {
    return combinedFilingData.modeSpec?.minLeadDocumentsRequired || 0;
  }

  geDocumentSpecs(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): DocumentSpec[] {
    const documentSpecs: DocumentSpec[] = isLeadDocument
      ? combinedFilingData.modeSpec?.leadDocument || []
      : combinedFilingData.modeSpec?.supportingDocument || [];
    return documentSpecs;
  }

  geDocumentCategories(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): DocumentCommonCategoryDomainCategoryValue[] {
    const documentSpecs: DocumentSpec[] = this.geDocumentSpecs(
      combinedFilingData,
      isLeadDocument
    );
    const documentCategories: DocumentCommonCategoryDomainCategoryValue[] =
      documentSpecs.map(
        (documentSpec: DocumentSpec) => documentSpec.documentCategory
      );
    return documentCategories;
  }

  getDocumentSpecByIndex(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean,
    index: number
  ): DocumentSpec | undefined {
    return (
      this.geDocumentSpecs(combinedFilingData, isLeadDocument)?.[index] ||
      undefined
    );
  }

  getFirstDocumentSpec(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): DocumentSpec | undefined {
    return (
      this.getDocumentSpecByIndex(combinedFilingData, isLeadDocument, 0) ||
      undefined
    );
  }

  getFirstDocumentSpecFileFieldDefinition(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): FileFieldDefinition | undefined {
    return (
      this.getFirstDocumentSpec(combinedFilingData, isLeadDocument)?.file ||
      undefined
    );
  }

  getFirstDocumentSpecFileTypeProfileName(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): string {
    return (
      this.getFirstDocumentSpecFileFieldDefinition(
        combinedFilingData,
        isLeadDocument
      )?.fileTypeProfileName || ''
    );
  }

  getFirstDocumentSpecMaxFileSize(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): number {
    return (
      this.getFirstDocumentSpecFileFieldDefinition(
        combinedFilingData,
        isLeadDocument
      )?.fileSizeMaxBytes || 0
    );
  }

  getFirstDocumentSpecTitleTextFieldDefinition(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): TextFieldDefinition | undefined {
    return this.getFirstDocumentSpec(combinedFilingData, isLeadDocument)?.title;
  }

  getFirstDocumentSpecFileNameTextFieldDefinition(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean
  ): TextFieldDefinition | undefined {
    return this.getFirstDocumentSpec(combinedFilingData, isLeadDocument)
      ?.fileName;
  }

  getFileTypeProfiles(
    combinedFilingData: CombinedFilingData
  ): FileTypeProfile[] {
    return combinedFilingData.filingProfile.fileTypeProfiles;
  }

  getFileTypeProfileByName(
    combinedFilingData: CombinedFilingData,
    name: string
  ): FileTypeProfile | undefined {
    return this.getFileTypeProfiles(combinedFilingData).find(
      (ftp: FileTypeProfile) => {
        return ftp.name === name;
      }
    );
  }

  getFileTypeProfileAllowedContentTypes(
    combinedFilingData: CombinedFilingData,
    fileTypeProfileName: string
  ): string[] {
    return (
      this.getFileTypeProfileByName(combinedFilingData, fileTypeProfileName)
        ?.allowedContentTypes || []
    );
  }

  getFileTypes(combinedFilingData: CombinedFilingData): FileType[] {
    return combinedFilingData.filingProfile.fileTypes;
  }

  getFileTypeByContentType(
    combinedFilingData: CombinedFilingData,
    contentType: string
  ): FileType | undefined {
    return this.getFileTypes(combinedFilingData).find((fileType: FileType) => {
      return fileType.contentType === contentType;
    });
  }

  getFileTypeExtensions(
    combinedFilingData: CombinedFilingData,
    contentType: string
  ): string[] {
    return (
      this.getFileTypeByContentType(combinedFilingData, contentType)
        ?.filenameExtensions || []
    );
  }

  getFileTypeExtensionsForAllowedContentTypes(
    combinedFilingData: CombinedFilingData
  ): string[] {
    const isLeadDocument = true;
    const fileTypeProfileName: string =
      this.getFirstDocumentSpecFileTypeProfileName(
        combinedFilingData,
        isLeadDocument
      );
    const allowedContentTypes: string[] =
      this.getFileTypeProfileAllowedContentTypes(
        combinedFilingData,
        fileTypeProfileName
      );
    const allowedFileTypeExtensions: string[] = allowedContentTypes.flatMap(
      (contentType: string) => {
        return this.getFileTypeExtensions(combinedFilingData, contentType);
      }
    );
    return allowedFileTypeExtensions;
  }

  hasSupportingDocumentSpecs(combinedFilingData: CombinedFilingData): boolean {
    const supportingDocumentSpecs: DocumentSpec[] =
      combinedFilingData.modeSpec?.supportingDocument || [];
    const hasSupportingDocumentSpecs = supportingDocumentSpecs.length > 0;
    return hasSupportingDocumentSpecs;
  }
}
