import { Inject, Injectable } from '@angular/core';
import { IUploadedFile } from '@fsx/ui-components';
import { Observable, of } from 'rxjs';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { TextFieldDefinition } from '../../types';
import {
  FsxCaseRequestHelperService,
  ICaseRequestHelperService,
} from './case-request-helper.service';
import { FilingProfileHelperService } from './filing-profile-helper.service';
import { CombinedFilingData } from './combined-filing-api.service';

export interface ProfileRuleCheckResult {
  passed: boolean;
  expected: string;
  actual: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilingProfileRulesService {
  constructor(
    @Inject(FsxCaseRequestHelperService)
    private readonly caseRequestHelperService: ICaseRequestHelperService,
    private readonly filingProfileHelperService: FilingProfileHelperService
  ) {}

  isMaxLeadDocumentsAllowed(
    combinedFilingData: CombinedFilingData
  ): Observable<boolean> {
    const countOfCaseRequestLeadDocuments: number =
      this.caseRequestHelperService.getCountOfLeadDocuments(
        combinedFilingData.caseRequest
      );
    const maxLeadDocumentsAllowed: number =
      this.filingProfileHelperService.getMaxLeadDocumentsAllowed(
        combinedFilingData
      );
    const isMaxLeadDocumentsAllowed: boolean =
      countOfCaseRequestLeadDocuments >= maxLeadDocumentsAllowed;
    return of(isMaxLeadDocumentsAllowed);
  }

  isMinLeadDocumentsRequired(
    combinedFilingData: CombinedFilingData
  ): Observable<boolean> {
    const countOfCaseRequestLeadDocuments: number =
      this.caseRequestHelperService.getCountOfLeadDocuments(
        combinedFilingData.caseRequest
      );
    const minLeadDocumentsRequired: number =
      this.filingProfileHelperService.getMinLeadDocumentsRequired(
        combinedFilingData
      );
    const isMinLeadDocumentsRequired: boolean =
      countOfCaseRequestLeadDocuments <= minLeadDocumentsRequired;
    return of(isMinLeadDocumentsRequired);
  }

  isAllowedFileType(params: {
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
  }): Observable<ProfileRuleCheckResult> {
    const { combinedFilingData, uploadedFile } = params;
    const isLeadDocument = true;
    const contentType: string = uploadedFile.file.type;
    const fileTypeProfileName: string =
      this.filingProfileHelperService.getFirstDocumentSpecFileTypeProfileName(
        combinedFilingData,
        isLeadDocument
      );
    const allowedContentTypes: string[] =
      this.filingProfileHelperService.getFileTypeProfileAllowedContentTypes(
        combinedFilingData,
        fileTypeProfileName
      );
    const isAllowedContentType: boolean =
      allowedContentTypes.includes(contentType);
    const allowedFileTypeExtensions: string[] =
      this.filingProfileHelperService.getFileTypeExtensionsForAllowedContentTypes(
        combinedFilingData
      );
    const fileNameParts: string[] = uploadedFile.file.name.split('.');
    const fileExtension: string =
      fileNameParts.length > 1
        ? fileNameParts[fileNameParts.length - 1]
        : 'unknown';
    const result: ProfileRuleCheckResult = {
      passed: isAllowedContentType,
      expected: allowedFileTypeExtensions.join(', '),
      actual: fileExtension || contentType,
    };
    return of(result);
  }

  isSmallerThanMaxFileSize(params: {
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
  }): Observable<ProfileRuleCheckResult> {
    const { combinedFilingData, uploadedFile } = params;
    const isLeadDocument: boolean = true;
    const maxFileSize: number =
      this.filingProfileHelperService.getFirstDocumentSpecMaxFileSize(
        combinedFilingData,
        isLeadDocument
      );
    const fileSize: number = uploadedFile.file.size;
    const isSmallerThanMaxFileSize = fileSize <= maxFileSize;
    const fileSizePipe: FileSizePipe = new FileSizePipe();
    const expectedFileSize: string = fileSizePipe.transform(maxFileSize, 0);
    const actualFileSize: string = fileSizePipe.transform(fileSize, 0);
    const result: ProfileRuleCheckResult = {
      passed: isSmallerThanMaxFileSize,
      expected: expectedFileSize,
      actual: actualFileSize,
    };
    return of(result);
  }

  isLessThanMaxFileNameLength(params: {
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
  }): Observable<ProfileRuleCheckResult> {
    const { combinedFilingData, uploadedFile } = params;
    const isLeadDocument: boolean = true;
    const fileNameTextFieldDefinition: TextFieldDefinition | undefined =
      this.filingProfileHelperService.getFirstDocumentSpecFileNameTextFieldDefinition(
        combinedFilingData,
        isLeadDocument
      );
    const maxFileNameLength: number =
      fileNameTextFieldDefinition?.maxLength || 999999;
    const isLessThanMaxFileNameLength: boolean =
      uploadedFile.file.name.length <= maxFileNameLength;
    const result: ProfileRuleCheckResult = {
      passed: isLessThanMaxFileNameLength,
      expected: `${maxFileNameLength}`,
      actual: `${uploadedFile.file.name.length}`,
    };
    return of(result);
  }
}
