import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((control.value && control.value.length >= length) || !control.value) {
      return null;
    }

    return { invalidMinLength: `At least ${length} characters are required` };
  };
}
