import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { asyncScheduler } from 'rxjs';
import { AliasComponentFieldDefinition } from './alias-component-field-definition';
import {
  ContactAliasFormGroup,
  ContactProfile,
  FilingProfile,
  ParticipantSpec,
  RequestParticipantAliasViewModel,
  ContactAliasViewModel,
  AliasFieldDefinition,
  ContactType,
  PhoneViewModel,
  AddressViewModel,
  EmailAddressViewModel,
  PersonalNameViewModel,
  ContactOrganizationViewModel,
  RequestContactOrganizationViewModel,
  FieldCategory,
  AliasSpec,
  ContactPersonFormGroup,
  TextFieldDefinition,
  ContactOrganizationFormGroup,
} from '../../../../../shared/src/public-api';
import { hasValues } from '../../helpers';
import { FormArrayWithModel, FormControlWithModel } from '../../models';
import {
  ReferenceResolver,
  SelectionFieldType,
  AliasCategorySelectionFieldDefinition,
  PersonNameFormGroup,
  AddressFormGroup,
  EmailFormGroup,
  PhoneFormGroup,
  SelectionFieldDefinition,
} from '../../types';
import {
  FsxAddressComponent,
  FsxEmailComponent,
  FsxPersonNameComponent,
  FsxPhoneComponent,
  FsxProfileSingleSelectionComponent,
  FsxTextComponent,
} from '../../../public-api';
import { FsxBaseComponent } from '../base/base.component';

@Component({
  selector: 'fsx-alias-component',
  templateUrl: './alias.component.html',
  styleUrls: ['./alias.component.scss'],
})
export class FsxAliasComponent extends FsxBaseComponent implements OnInit {
  /**
   * An input property for the ContactType. The alias should always take
   * on the same ContactType as the Participant/Contact to which it relates.
   */
  @Input() contactType!: ContactType;

  @Input() aliasFormArray!: FormArrayWithModel<
    FormGroup<ContactAliasFormGroup>
  >;
  @Input() profile!: ContactProfile | FilingProfile;
  @Input() participantSpec!: ParticipantSpec | undefined;
  @Input() override inputWidth!: string;
  @Input() editMode!: boolean;
  @Input() initialValues:
    | RequestParticipantAliasViewModel[]
    | ContactAliasViewModel[] = [];
  @Input() aliasFieldDefinition!: AliasFieldDefinition;
  @Input() resolver!: ReferenceResolver;
  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<ContactAliasFormGroup>>
  >(true);

  @ViewChildren('categoryField')
  categoryFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('personNameField')
  personNameFields!: QueryList<FsxPersonNameComponent>;
  @ViewChildren('titleField') titleFields!: QueryList<FsxTextComponent>;
  @ViewChildren('addressField') addressFields!: QueryList<FsxAddressComponent>;
  @ViewChildren('phoneField') phoneFields!: QueryList<FsxPhoneComponent>;
  @ViewChildren('emailField') emailFields!: QueryList<FsxEmailComponent>;

  /**
   * The ContactType enum. Used in ngIf expressions in the template
   * to toggle the PersonNameComponent and TextComponent (title) based
   * on the input contactType from the parent component.
   */
  ContactType = ContactType;

  public showAddNewForm = false;
  public phoneInitialValues: PhoneViewModel[][] = [];
  public addressInitialValues: AddressViewModel[][] = [];
  public emailInitialValues: EmailAddressViewModel[][] = [];
  public personNameInitialValues: PersonalNameViewModel[] = [];
  public organizationInitialValues:
    | ContactOrganizationViewModel[]
    | RequestContactOrganizationViewModel[] = [];
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType;
  public aliasSelectionFieldDefinition!: AliasCategorySelectionFieldDefinition;
  public aliasComponentFieldDefinition: AliasComponentFieldDefinition[] = [];
  public aliasSpecsArray: AliasSpec[] = [];

  ngOnInit(): void {
    this._setComponentsData(this.initialValues);

    if (this.aliasFieldDefinition) {
      this.aliasSelectionFieldDefinition = {
        ...this.aliasFieldDefinition,
        listReference: this.aliasFieldDefinition?.allowedAliasCategoriesList,
        defaultValue: null,
        selectionDependentFields: [],
      };
    }

    const aliasFormGroups = this.initialValues.length
      ? this.initialValues.map(
          (_, _index) =>
            new FormGroup<ContactAliasFormGroup>({
              contactType: new FormControl(this.contactType),
            } as ContactAliasFormGroup)
        )
      : [];

    this.aliasFormArray = new FormArrayWithModel<
      FormGroup<ContactAliasFormGroup>
    >([...aliasFormGroups], {
      minRequired: this.aliasFieldDefinition?.minRequired ?? 0,
      maxAllowed: this.aliasFieldDefinition?.maxAllowed ?? 1,
    });

    if (
      this.aliasFieldDefinition?.minRequired &&
      !this.aliasFormArray.controls.length
    ) {
      this.addNewAliasForm();
    }

    this.aliasFormArray.valueChanges.subscribe(() => {
      const formGroup = this.aliasFormArray.at(
        this.aliasFormArray.length - 1
      ) as FormGroup<ContactAliasFormGroup>;
      if (formGroup) {
        asyncScheduler.schedule(
          () =>
            (this.showAddNewForm = this.editMode
              ? hasValues(formGroup.value)
              : hasValues(formGroup.value) && formGroup.dirty)
        );
      }
    });

    this.formArrayEmitter.emit(this.aliasFormArray);
  }

  public delete(index: number): void {
    if (this.aliasFormArray.disabled) {
      return;
    }
    this.aliasFormArray.removeAt(index);
    this.initialValues.splice(index, 1);
  }

  public setPersonControl(
    control: FormGroup<PersonNameFormGroup>,
    controlName: keyof ContactAliasFormGroup,
    index: number
  ): void {
    const personNameForm = new FormGroup<ContactPersonFormGroup>({
      personalName: control,
    });
    const formGroup = this.getContactAliasFormGroup(index);
    formGroup.setControl(controlName, personNameForm);
  }

  public setOrganizationControl(
    control: FormControlWithModel<TextFieldDefinition>,
    controlName: keyof ContactAliasFormGroup,
    index: number
  ): void {
    const organizationForm = new FormGroup<ContactOrganizationFormGroup>({
      title: control,
    });
    const formGroup = this.getContactAliasFormGroup(index);
    formGroup.setControl(controlName, organizationForm);
  }

  public setControl(
    control:
      | FormControl<ContactType>
      | FormArrayWithModel<FormGroup<AddressFormGroup>>
      | FormArrayWithModel<FormGroup<EmailFormGroup>>
      | FormArrayWithModel<FormGroup<PhoneFormGroup>>
      | FormArrayWithModel<FormGroup<ContactAliasFormGroup>>
      | FormControlWithModel<SelectionFieldDefinition>,
    controlName: keyof ContactAliasFormGroup,
    index: number
  ): void {
    const formGroup = this.getContactAliasFormGroup(index);
    formGroup.setControl(controlName, control);
  }

  public setCategory(event: string, index: number) {
    const aliasSpec = this.resolver.getAliasCategoryFieldDefinitions(event);
    if (aliasSpec) {
      if (this.aliasSpecsArray[index]) {
        this.personNameInitialValues[index] = {} as PersonalNameViewModel;
        this.organizationInitialValues[index] =
          {} as ContactOrganizationViewModel;
        this.addressInitialValues[index] = [];
        this.emailInitialValues[index] = [];
        this.phoneInitialValues[index] = [];
      }
      this.aliasSpecsArray[index] = aliasSpec;

      const contactType =
        aliasSpec.allowOrganization && !aliasSpec.allowPerson
          ? ContactType.Organization
          : ContactType.Person;
      this.aliasFormArray.at(index).patchValue({ contactType });

      this.aliasComponentFieldDefinition[index] = {
        phoneFieldDefinition: null,
        addressFieldDefinition: null,
        emailFieldDefinition: null,
        personNameFieldDefinition: null,
        organizationFieldDefinition: null,
      };

      if (aliasSpec.person?.personalName) {
        this.aliasComponentFieldDefinition[index].personNameFieldDefinition =
          aliasSpec.person.personalName;
      }

      if (aliasSpec?.organization) {
        this.aliasComponentFieldDefinition[index].organizationFieldDefinition =
          aliasSpec.organization;
      }

      if (aliasSpec?.address) {
        const profileName = aliasSpec.address.addressProfileName;
        let addressCategoriesDefinition = this.profile.addressProfiles.find(
          (profile) => profile.name === profileName
        );

        if (!addressCategoriesDefinition) {
          addressCategoriesDefinition = this.profile.addressProfiles.find(
            (profile) => profile.name === 'default'
          );
        }

        if (addressCategoriesDefinition) {
          this.aliasComponentFieldDefinition[index].addressFieldDefinition = {
            ...aliasSpec.address,
            ...addressCategoriesDefinition.spec,
          };
        }
      }

      if (aliasSpec?.phone) {
        this.aliasComponentFieldDefinition[index].phoneFieldDefinition =
          aliasSpec.phone;
      }

      if (aliasSpec?.email) {
        this.aliasComponentFieldDefinition[index].emailFieldDefinition =
          aliasSpec.email;
      }
    }
  }

  public getContactAliasFormGroup(
    index: number
  ): FormGroup<ContactAliasFormGroup> {
    let formGroup = this.aliasFormArray.at(
      index
    ) as FormGroup<ContactAliasFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<ContactAliasFormGroup>(
        {} as ContactAliasFormGroup
      );
    }
    return formGroup;
  }

  public addNewAliasForm(): void {
    if (this.aliasFormArray.enabled) {
      this.aliasFormArray.push(
        new FormGroup<ContactAliasFormGroup>({
          contactType: new FormControl(ContactType.Person),
        } as ContactAliasFormGroup)
      );
    }
  }

  private _setComponentsData(
    contact?: RequestParticipantAliasViewModel[] | ContactAliasViewModel[]
  ): void {
    if (!contact?.length) {
      return;
    }

    contact.forEach((con, index) => {
      if (con?.person?.personalName) {
        this.personNameInitialValues[index] = con?.person?.personalName;
      }

      if (con.organization) {
        this.organizationInitialValues[index] = con.organization;
      }

      if (con.addresses) {
        this.addressInitialValues[index] = [];
        con.addresses.forEach((address) => {
          this.addressInitialValues[index].push({
            ...address,
          } as AddressViewModel);
        });
      }

      if (con.emails) {
        this.emailInitialValues[index] = [];
        con.emails.forEach((email) => {
          this.emailInitialValues[index].push(email);
        });
      }

      if (con.phones) {
        this.phoneInitialValues[index] = [];
        con.phones.forEach((phone) => {
          this.phoneInitialValues[index].push(phone);
        });
      }
    });
  }

  public validate(): void {
    // TODO - this needs to be cleverer, taking into account the specs for
    // each child-component. For example, if emails has a minRequired of 0, it's OK
    // to have an empty email (event though the email address field itself is required)
    if (this.shouldValidateFormArray(this.aliasFormArray)) {
      if (this.categoryFields) {
        this.categoryFields.forEach((fld) => fld.validate());
      }
      if (this.personNameFields) {
        this.personNameFields.forEach((fld) => fld.validate());
      }
      if (this.titleFields) {
        this.titleFields.forEach((fld) => fld.validate());
      }
      if (this.addressFields) {
        this.addressFields.forEach((fld) => fld.validate());
      }
      if (this.phoneFields) {
        this.phoneFields.forEach((fld) => fld.validate());
      }
      if (this.emailFields) {
        this.emailFields.forEach((fld) => fld.validate());
      }
    }
  }
}
