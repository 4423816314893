<div
  fxLayout="column"
  fxLayoutAlign="start"
  fxLayout.lt-sm="row"
  fxLayoutAlign.lt-sm="space-evenly"
  class="navbar"
>
  <ul
    class="navbar-list"
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayout.lt-sm="row"
    fxLayoutAlign.lt-sm="space-evenly"
  >
    <li
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center"
      *ngFor="let navLink of navLinks; index as idx"
      class="{{ navLink.classes }}"
      (mouseenter)="toggleSideNavRoutesTooltip(idx)"
      (mouseleave)="toggleSideNavRoutesTooltip(idx)"
    >
      <ng-container *ngIf="navLink.route; else elseTemplate">
        <!-- matTooltip="{{ navLink.title }}" -->
        <a
          routerLink="{{ '/' + navLink.route }}"
          routerLinkActive="navbar__link--active"
          class="navbar__link"
        >
          <mat-icon
            class="navbar__link-icon"
            svgIcon="{{ navLink.svgIcon }}"
          ></mat-icon>
        </a>
      </ng-container>
      <ng-template #elseTemplate>
        <!-- matTooltip="{{ navLink.title }} ({{ navLink.shortcutKey }})" -->
        <a
          href="{{ navLink.linkToLegacy }}"
          target="legacyTab"
          class="navbar__link"
        >
          <mat-icon
            class="navbar__link-icon"
            svgIcon="{{ navLink.svgIcon }}"
          ></mat-icon>
        </a>
      </ng-template>
    </li>
    <!-- Preferences / Logout -->
    <li
      class="navbar__list-item"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center"
    >
      <!-- matTooltip="Account Settings" -->
      <a class="navbar__link" [matMenuTriggerFor]="settingsMenu">
        <mat-icon class="navbar__link-icon" svgIcon="nav-gear"></mat-icon>
      </a>
      <mat-menu
        class="settings-menu"
        #settingsMenu="matMenu"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <button (click)="logout()" mat-menu-item>Logout</button>
      </mat-menu>
    </li>
  </ul>
</div>

<div class="navbar__logo" [fxHide.lt-sm]="true">
  <a routerLink="/" class="navbar__logo-link fsx-logo" title="Return home">
    <img
      src="assets/img/logos/los-angeles/FSX_RGB_FullColor-Light_Vertical_Los Angeles.svg"
      class="navbar__logo-image"
      loading="lazy"
      alt="File &amp; ServeXpress logo"
    />
  </a>
</div>
