import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { FilingProfile } from '@fsx/fsx-shared';
import { minimumFilingProfile } from 'projects/libs/shared/src/lib/services/core/validation/document-validation.service.spec.constants';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';

export const FsxFilingProfileDataService =
  new InjectionToken<IFilingProfileDataService>('FsxFilingProfileDataService');

export interface IFilingProfileDataService {
  filingProfile$: Observable<FilingProfile>;
  setFilingProfileData(filingProfile: FilingProfile): void;
}

@Injectable()
export class FilingProfileDataService implements IFilingProfileDataService {
  private filingProfile$$ = new BehaviorSubject<FilingProfile | null>(null);
  filingProfile$: Observable<FilingProfile> = this.filingProfile$$
    .asObservable()
    .pipe(
      filter((filingProfile: FilingProfile | null) => !!filingProfile),
      map(
        (filingProfile: FilingProfile | null) => filingProfile as FilingProfile
      ),
      shareReplay(1)
    );

  setFilingProfileData(filingProfile: FilingProfile): void {
    const newFilingProfileObj = JSON.parse(JSON.stringify(filingProfile));
    this.filingProfile$$.next(newFilingProfileObj);
  }
}

/**
 * A concrete alternative implementation of the FilingProfileDataService service,
 * which goes direct to the API to allow us to create test cases with the real
 * Filing Profile data. This saves from creating large mocks of the data, as well
 * as ensuring we're testing against real up-to-date Filing Profile data rules.
 */
@Injectable()
export class TestFilingProfileDataService implements IFilingProfileDataService {
  private civilLimitedContractualFraud: string =
    'https://api.efsp.sit.fileandserve.com/v1/courts/34d25c1b-3242-499c-b3f8-5db2f1503975/profiles/48684fb5-2ed5-4956-8385-3e11eb0c0b80';
  private minimumFilingProfile: string =
    'https://api.efsp.sit.fileandserve.com/v1/courts/32c7d9f3-9cd4-438c-80e7-69b66af5cbe6/profiles/3532e015-fc2f-4d2a-937e-e28c618d5313';

  constructor(private readonly http: HttpClient) {}

  filingProfile$: Observable<FilingProfile> = of(
    this.minimumFilingProfile
  ).pipe(
    switchMap((url: string) => {
      return this.http.get<FilingProfile>(url).pipe(
        map(() => {
          return minimumFilingProfile;
        })
      );
    })
  );

  setFilingProfileData(_filingProfile: FilingProfile): void {
    return;
  }
}
