<div class="parties-container" fxLayout="column" fxFill>
  <div *ngIf="combinedFilingData$ | async as combinedFilingData">
    <fsx-parties-grid
      *ngIf="initiatingGridVm$ | async as gridVm"
      [participantSpecs]="gridVm.participantSpecs"
      [attorneySpecs]="gridVm.attorneySpecs"
      [partiesGridRows]="gridVm.partiesGridRows"
      [participantCommonCategory]="participantCommonCategory.InitiatingParty"
      [partiesGridOptions]="
        combinedFilingData.filing.mode === FilingMode.Subsequent
          ? {
              title: 'Filed By',
              descriptor: 'Filed By',
              expanded: true
            }
          : {
              title: 'Initiating',
              descriptor: 'Initiating',
              expanded: true
            }
      "
      [isInitiating]="true"
      [combinedFilingData]="combinedFilingData"
      [combinedGridRows]="combinedPartiesGridRows$ | async"
      [validationFilteredClass]="validationFilteredClass"
      (addParticipantEvent)="addParticipantEventHandler($event)"
      (contactSelectedEvent)="contactSelectedEventHandler($event)"
      (removeParticipantEvent)="removeParticipantEventHandler($event)"
      (clearParticipantEvent)="clearParticipantEventHandler($event)"
      (editParticipantEvent)="editParticipantEventHandler($event)"
      (editRepresentationEvent)="editRepresentationEventHandler($event)"
      (selectParticipantsEvent)="selectParticipantsEventHandler($event)"
      (attorneySelectedEvent)="attorneySelectedEventHandler($event)"
      (removeRepresentationEvent)="removeRepresentationEventHandler($event)"
      (updateRepresentationEvent)="updateRepresentationEventHandler($event)"
      (clearRepresentationEvent)="clearRepresentationEventHandler($event)"
      (updateParticipantEvent)="updateParticipantEventHandler($event)"
      (contactSummariesSelectedEvent)="
        contactSummariesSelectedEventHandler($event)
      "
      (addRepresentationEvent)="attorneySelectedEventHandler($event)"
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromAdditionalFieldEventHandler($event)
      "
      (validationFilteredEvent)="validationFilteredEventHandler($event)"
    >
    </fsx-parties-grid>

    <fsx-parties-grid
      *ngIf="additionalGridVm$ | async as gridVm"
      [participantSpecs]="gridVm.participantSpecs"
      [attorneySpecs]="gridVm.attorneySpecs"
      [partiesGridRows]="gridVm.partiesGridRows"
      [participantCommonCategory]="participantCommonCategory.AdditionalParty"
      [partiesGridOptions]="{
        title: 'Other Case Participants',
        descriptor: '',
        expanded: combinedFilingData.filing.mode !== FilingMode.Subsequent
      }"
      [isInitiating]="false"
      [combinedFilingData]="combinedFilingData"
      [combinedGridRows]="combinedPartiesGridRows$ | async"
      [validationFilteredClass]="validationFilteredClass"
      (addParticipantEvent)="addParticipantEventHandler($event)"
      (contactSelectedEvent)="contactSelectedEventHandler($event)"
      (removeParticipantEvent)="removeParticipantEventHandler($event)"
      (clearParticipantEvent)="clearParticipantEventHandler($event)"
      (editParticipantEvent)="editParticipantEventHandler($event)"
      (editRepresentationEvent)="editRepresentationEventHandler($event)"
      (selectParticipantsEvent)="selectParticipantsEventHandler($event)"
      (attorneySelectedEvent)="attorneySelectedEventHandler($event)"
      (removeRepresentationEvent)="removeRepresentationEventHandler($event)"
      (updateRepresentationEvent)="updateRepresentationEventHandler($event)"
      (clearRepresentationEvent)="clearRepresentationEventHandler($event)"
      (updateParticipantEvent)="updateParticipantEventHandler($event)"
      (contactSummariesSelectedEvent)="
        contactSummariesSelectedEventHandler($event)
      "
      (addRepresentationEvent)="attorneySelectedEventHandler($event)"
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromAdditionalFieldEventHandler($event)
      "
      (validationFilteredEvent)="validationFilteredEventHandler($event)"
    >
    </fsx-parties-grid>
  </div>
</div>
