import { Injectable, InjectionToken } from '@angular/core';
import { FilingSubTabItem, FILING_SUB_TABS } from '@fsx/fsx-shared';

export const FsxFilingSubTabsFactoryService =
  new InjectionToken<IFilingSubTabsFactoryService>(
    'FsxFilingSubTabsFactoryService'
  );

export interface IFilingSubTabsFactoryService {
  getFilingSubTabItemsForOpf(): FilingSubTabItem[];
  getFilingSubTabItemsForSubF(): FilingSubTabItem[];
}

@Injectable()
export class FilingSubTabsFactoryService
  implements IFilingSubTabsFactoryService
{
  getFilingSubTabItemsForOpf(): FilingSubTabItem[] {
    return [
      {
        name: FILING_SUB_TABS.DETAILS,
        caption: 'Case Details',
        isValid: true,
        isActive: true,
        tabIndex: 0,
      },
      {
        name: FILING_SUB_TABS.PARTIES,
        caption: 'Parties',
        isValid: true,
        isActive: false,
        tabIndex: 1,
      },
      {
        name: FILING_SUB_TABS.DOCUMENTS,
        caption: 'Documents',
        isValid: true,
        isActive: false,
        tabIndex: 2,
      },
      {
        name: FILING_SUB_TABS.REVIEW,
        caption: 'Review & Submit',
        isValid: true,
        isActive: false,
        tabIndex: 3,
      },
    ];
  }

  getFilingSubTabItemsForSubF(): FilingSubTabItem[] {
    return [
      {
        name: FILING_SUB_TABS.PARTIES,
        caption: 'Sending Parties',
        isValid: true,
        isActive: true,
        tabIndex: 0,
      },
      {
        name: FILING_SUB_TABS.DOCUMENTS,
        caption: 'Documents',
        isValid: true,
        isActive: false,
        tabIndex: 1,
      },
      // ToDo: Disabled until service parties are implemented.
      // {
      //   name: FILING_SUB_TABS.SERVICE,
      //   caption: 'Service Parties',
      //   isValid: true,
      //   isActive: false,
      //   tabIndex: 2
      // },
      {
        name: FILING_SUB_TABS.REVIEW,
        caption: 'Review & Submit',
        isValid: true,
        isActive: false,
        tabIndex: 2,
      },
    ];
  }
}
