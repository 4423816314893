export enum ContactsOrParticipantFormMode {
  newContact = 'NEW_CONTACT',
  editContact = 'EDIT_CONTACT',
  editParticipant = 'EDIT_PARTICIPANT',
  editRepresentation = 'EDIT_REPRESENTATION',
}

export enum ContactFormMode {
  AddContact = 'ADD_CONTACT',
  EditContact = 'EDIT_CONTACT',
}

export enum ParticipantFormMode {
  AddParticipant = 'ADD_PARTICIPANT',
  EditParticipant = 'EDIT_PARTICIPANT',
  AddRepresentation = 'ADD_REPRESENTATION',
  EditRepresentation = 'EDIT_REPRESENTATION',
}
