import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CombinedFilingData,
  FsxCombinedFilingApiService,
  FsxFilingTabsService,
  ICombinedFilingApiService,
  IFilingTabsService,
  RECENT_TRANSACTION_ID,
} from '@fsx/fsx-shared';
import { Observable, Subject, map, merge, switchMap, tap } from 'rxjs';
import {
  FsxFilingDataService,
  IFilingDataService,
} from '../filing-data.service';
import {
  FsxFilingProfileDataService,
  IFilingProfileDataService,
} from '../filing-profile-data.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from '../document-info-data.service';

export const FsxFirstLoadOrchestrationService =
  new InjectionToken<IFirstLoadOrchestrationService>(
    'FsxFirstLoadOrchestrationService'
  );

export interface IFirstLoadOrchestrationService {
  loadCombinedFilingData$: Observable<CombinedFilingData>;
  loadForFilingId(filingId: string): void;
}

@Injectable()
export class FirstLoadOrchestrationService
  implements IFirstLoadOrchestrationService
{
  private loadForFilingId$$ = new Subject<string>();

  private filingIdToLoadFor$ = merge(
    this.loadForFilingId$$.pipe(
      tap((filingId: string) => {
        localStorage.setItem(RECENT_TRANSACTION_ID, filingId);
      })
    )
  );

  loadCombinedFilingData$: Observable<CombinedFilingData> =
    this.filingIdToLoadFor$.pipe(
      // filter((filingId: string) => this.filingId === filingId), TODO: Check with multiple tabs open to see if this is needed?
      switchMap((filingId: string) => {
        return this.combinedFilingApiService
          .getCombinedFilingData(filingId)
          .pipe(
            switchMap((combinedFilingData: CombinedFilingData) => {
              return this.filingTabsService
                .addTab(combinedFilingData.filing)
                .pipe(
                  map(() => {
                    const { caseRequest, filing, filingProfile } =
                      combinedFilingData;
                    this.caseRequestDataService.setCaseRequestData(caseRequest);
                    this.filingDataService.setFilingData(filing);
                    this.filingProfileDataService.setFilingProfileData(
                      filingProfile
                    );
                    this.documentInfoDataService.setDocumentInfos(
                      combinedFilingData.documentInfos
                    );
                    return combinedFilingData;
                  })
                );
            })
          );
      })
    );

  public constructor(
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService,
    @Inject(FsxCombinedFilingApiService)
    private readonly combinedFilingApiService: ICombinedFilingApiService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingProfileDataService)
    private readonly filingProfileDataService: IFilingProfileDataService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService
  ) {}

  loadForFilingId(filingId: string): void {
    this.loadForFilingId$$.next(filingId);
  }
}
