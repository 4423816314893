import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactReferenceViewModel } from '@fsx/fsx-shared';

@Component({
  selector: 'fsx-contact-link-card',
  templateUrl: 'contact-link-card.component.html',
  styleUrls: ['./contact-link-card.component.scss'],
})
export class ContactLinkedCardComponent {
  /**
   * The participant's linkedContact property, passed in as an input binding,
   * which we can pass straight to the template for display.
   */
  @Input() linkedContact!: ContactReferenceViewModel;

  /**
   * An event to trigger the opening of the contact form from the parent panel
   * component. We do that in the parent to absolve this presenter component
   * from any unnecessary dependencies.
   */
  @Output() editContactEvent = new EventEmitter<string>();

  /**
   * A handler function for the Edit Contact button's click event.
   *
   * @param contactId The id of the contact that we want to edit.
   */
  onEditContactClicked(contactId: string): void {
    this.editContactEvent.emit(contactId);
  }
}
