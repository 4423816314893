import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  PhoneViewModel,
  PhoneSpec,
  IValidatable,
  FilingProfile,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';

export const FsxPhoneValidationService =
  new InjectionToken<IPhoneValidationService>('FsxPhoneValidationService');

export interface IPhoneValidationService {
  validatePhones(
    phones: PhoneViewModel[],
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;

  validatePhone(
    phone: PhoneViewModel,
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean;
}

@Injectable()
export class PhoneValidationService implements IPhoneValidationService {
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService
  ) {}

  public validatePhones(
    phones: PhoneViewModel[],
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!phones) {
      phones = [];
    }

    if (phones.length < spec.minRequired) {
      scope.isValid = false;
      return false;
    }

    if (phones.length > spec.maxAllowed) {
      scope.isValid = false;
      return false;
    }

    for (let index = 0; index < phones.length; index++) {
      const phone = phones[index];

      if (!this.validatePhone(phone, spec, scope, filingProfile)) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }

  public validatePhone(
    phone: PhoneViewModel,
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    phone.isValid = true;

    return this.textFieldValidationService.validateTextField(
      scope,
      filingProfile,
      spec.fullNumber,
      phone.fullNumber
    );
  }
}
