<div class="fsx-side-panel-component">
  <header>
    <fsx-side-panel-header-component
      #header
      *ngIf="headerConfig; else customHeader"
      [headerConfig]="headerConfig"
    ></fsx-side-panel-header-component>
    <ng-template #customHeader>
      <ng-content select="[header]"></ng-content>
    </ng-template>
  </header>
  <main [ngClass]="{ 'custom-header': !headerConfig }">
    <ng-content></ng-content>
  </main>
  <mat-divider></mat-divider>
  <footer>
    <fsx-side-panel-footer-component
      #footer
      [footerConfig]="footerConfig"
    ></fsx-side-panel-footer-component>
  </footer>
</div>
