<div
  id="documents-actions-container"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="combinedFilingData?.caseRequest?.documents as documents"
>
  <p *ngIf="documents.length > 0">
    <fsx-total-documents [combinedFilingData]="combinedFilingData">
    </fsx-total-documents>
    <span>&nbsp;-&nbsp;</span>
    <fsx-total-size [combinedFilingData]="combinedFilingData"> </fsx-total-size>
  </p>
  <fsx-file-upload-small
    [id]="'main-file-upload'"
    [small]="true"
    (filesUploadedEvent)="onFilesUploaded($event)"
    *ngIf="documents.length > 0"
  >
  </fsx-file-upload-small>
  <button class="primary" (click)="onAddDocumentClicked()">Add Document</button>
</div>
